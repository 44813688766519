import React, { Fragment, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/SpinnerSmall';

// import { makeStyles } from '@mui/styles';

import AcUnitIcon from '@mui/icons-material/AcUnit';

import CloseIcon from '@mui/icons-material/Close';

import Button from '@mui/material/Button';

import { useTheme } from '@mui/styles';

// uncomment

import Table from './Table';

import ExportMenu from './export/ExportMenu';

import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

import ColumnMenu from './column-menu/ColumnMenu';

// import arrayOfData from './dummyData';

import ReactDateRange from './ReactDateRange';

// import EditDialog from './bulk-edit-menu/EditDialog';
// import CopyDialog from './copy-paste-menu/CopyDialog';

import EmailPokeDialog from './email-poke-menu/EmailPokeDialog';

import AlertDialog from './AlertDialog';

import EmailIcon from '@mui/icons-material/Email';

// import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import TableRowsIcon from '@mui/icons-material/TableRows';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';

import AssessmentIcon from '@mui/icons-material/Assessment';

import EditMenu from './edit-menu/EditMenu';

import Tooltip from '@mui/material/Tooltip';

import BottomRightCornerMenu from './bottom-right-corner-menu/BottomRightCornerMenu';

import Timeboxer from './timeboxer/Timeboxer';

import TimeboxerViewDialog from './timeboxer/report/TimeboxerViewDialog';

import ReportBuilder2 from './report-builder-2/ReportBuilder';

import PerformancePopper from './performance-popper/PerformancePopper';

import {
  allEvalFields,
  sumEvalFields,
  nonSumEvalFields,
  plannedEvalFields,
  allEvalFieldsExceptTotal
} from './fields';

// import Dialogs from './Dialogs';

// end of uncomment

// ################## imports regarding data ################################
import {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription
} from '@apollo/client';
import {
  tableDataQueryBasic,
  tableDataQuery2,
  documentBasicAddedSubscription,
  documentBasicUpdatedSubscription,
  documentAddedSubscription,
  documentUpdatedSubscription,
  refetchDataSubscription,
  addDocumentMutation,
  updateDocumentMutation,
  // tableDataQuery
  customColumnSetsQuery
} from '../../graphql/queries';

import { AuthContext } from '../../context/AuthContext';
import { AccountContext } from '../../context/AccountContext';
import { ColumnContext } from '../../context/ColumnContext';

import { DataContext } from '../../context/DataContext';
import { MutationContext } from '../../context/MutationContext';
import { InterfaceContext } from '../../context/InterfaceContext';

import { SnackbarProvider, useSnackbar } from 'notistack';

import evaluateFormula from './roitable_functions/evaluateFormula';

import calcFields from './roitable_functions/calcFields';
import calcFields2 from './roitable_functions/calcFields2';

import DataLevelSwitch from './DataLevelSwitch';

import EditIcon from '@mui/icons-material/Edit';

// Context

import { FilterContext } from '../../context/FilterContext';

import QuickCreate from './QuickCreate';

import StatusFilterMenu from './StatusFilterMenu';
import FilterMenu from './FilterMenu';

import FlightViewButton from './FlightViewButton';

import DisplayMenu from './report/views/DisplayMenu';

// RoiTable functions

import parse_fb_campaign_data from './roitable_functions/parse_fb_campaign_data';
import parse_fb_data from './roitable_functions/parse_fb_data';

import parse_fb_campaign_data_aggregated from './roitable_functions/parse_fb_campaign_data_aggregated';

import parse_google_ads_campaign_data from './roitable_functions/parse_google_ads_campaign_data';
import parse_google_ads_data from './roitable_functions/parse_google_ads_data';

import parse_google_ads_campaign_data_aggregated from './roitable_functions/parse_google_ads_campaign_data_aggregated';

import parse_cm360_campaign_data from './roitable_functions/parse_cm360_campaign_data';
import parse_cm360_campaign_data_aggregated from './roitable_functions/parse_cm360_campaign_data_aggregated';

import parse_dv360_insertion_order_data from './roitable_functions/parse_dv360_insertion_order_data';
import parse_dv360_line_item_data from './roitable_functions/parse_dv360_line_item_data';
import parse_dv360_insertion_order_data_aggregated from './roitable_functions/parse_dv360_insertion_order_data_aggregated';

import parse_snapchat_ads_campaign_data from './roitable_functions/parse_snapchat_ads_campaign_data';
import parse_snapchat_ads_ad_squad_data from './roitable_functions/parse_snapchat_ads_ad_squad_data';
import parse_snapchat_ads_campaign_data_aggregated from './roitable_functions/parse_snapchat_ads_campaign_data_aggregated';

import parse_tiktok_ads_campaign_data from './roitable_functions/parse_tiktok_ads_campaign_data';
import parse_tiktok_ads_campaign_data_aggregated from './roitable_functions/parse_tiktok_ads_campaign_data_aggregated';

import parse_pinterest_ads_campaign_data_aggregated from './roitable_functions/parse_pinterest_ads_campaign_data_aggregated';

import parse_readpeak_campaign_data_aggregated from './roitable_functions/parse_readpeak_campaign_data_aggregated';

import parse_strossle_campaign_data_aggregated from './roitable_functions/parse_strossle_campaign_data_aggregated';

import parse_amazon_dsp_order_data_aggregated from './roitable_functions/parse_amazon_dsp_order_data_aggregated';

import parse_taboola_ads_campaign_data_aggregated from './roitable_functions/parse_taboola_ads_campaign_data_aggregated';

import parse_microsoft_advertising_campaign_data_aggregated from './roitable_functions/parse_microsoft_advertising_campaign_data_aggregated';

import parse_apple_search_ads_campaign_data_aggregated from './roitable_functions/parse_apple_search_ads_campaign_data_aggregated';

import parse_adform_order_data_aggregated from './roitable_functions/parse_adform_order_data_aggregated';

import parse_linkedin_ads_campaign_group_data_aggregated from './roitable_functions/parse_linkedin_ads_campaign_group_data_aggregated';

import formatDate from './roitable_functions/formatDate';
import ColumnDialog from './column-menu/ColumnDialog';
import FieldDialog from './report/info/field-menu/FieldDialog';

import { DialogContext } from '../../context/DialogContext';
import FilterComponent from './FilterComponent';

import { columnArray as allColumns } from './column-menu/columns';

import getFastViewDataStartDate from './roitable_functions/getFastViewDataStartDate';

import {
  // atan2,
  // chain,
  // derivative,
  // e,
  // evaluate,
  // log,
  // pi,
  // pow,
  // round,
  // sqrt
  compile
} from 'mathjs';

import formatDateYYYYMMDD from './roitable_functions/formatDateYYYYMMDD';

// ######################################################################################

// const useStyles = makeStyles((theme) => ({
//   button: {
//     margin: theme.spacing(1)
//   }
// }));

const getDates = (startDate, endDate) => {
  let dates = [];

  //to avoid modifying the original date
  const theDate = new Date(startDate);
  const theEndDate = new Date(endDate);
  theDate.setHours(5);
  theEndDate.setHours(5);
  while (theDate <= theEndDate) {
    dates = [...dates, new Date(theDate)];
    theDate.setDate(theDate.getDate() + 1);
  }

  // if(dates[dates.length-1] !== theEndDate){
  //   dates.push(theEndDate);
  // }

  return dates;
};

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

const rowBaseKV = (document, documentType, source) => {
  const formulaObjectToSpread = {};

  for (let formulaKey of allEvalFields) {
    formulaObjectToSpread[formulaKey + 'Formula'] = document[formulaKey];
    formulaObjectToSpread[formulaKey + 'Sparkline'] = [];
    formulaObjectToSpread[formulaKey] = null;
    formulaObjectToSpread[formulaKey + 'Today'] = null;
    formulaObjectToSpread[formulaKey + 'Yesterday'] = null;
    formulaObjectToSpread[formulaKey + 'SumChild'] = null;
  }

  const spreadObject = {};
  for (let key of Object.keys(document)) {
    if (['nexusDocuments'].includes(key)) {
      continue;
    } else if (!allEvalFields.includes(key)) {
      spreadObject[key] = document[key];
    } else {
      spreadObject[key] = null;
    }
  }

  const documentTypeToTypeDict = {
    facebookAds: 'facebook_ads_campaign',
    googleAds: 'google_ads_campaign',
    cm360: 'cm360_campaign',
    dv360: 'dv360_insertion_order',
    snapchatAds: 'snapchat_ads_campaign',
    tikTokAds: 'tiktok_ads_campaign',
    pinterestAds: 'pinterest_ads_campaign',
    readpeak: 'readpeak_campaign',
    strossle: 'strossle_campaign',
    amazonDsp: 'amazon_dsp_order',
    taboolaAds: 'taboola_ads_campaign',
    microsoftAdvertising: 'microsoft_advertising_campaign',
    appleSearchAds: 'apple_search_ads_campaign',
    adform: 'adform_order',
    linkedIn: 'linkedin_ads_campaign_group'
  };

  return {
    // ...document,
    ...spreadObject,
    ...formulaObjectToSpread,

    // _id: document._id,

    childDocumentIds: [],

    warnings: [],
    subWarnings: [],
    snackbarWarnings: [],
    highestWarningLevel: null,

    startDate: document.startDate,
    endDate: document.endDate,
    type: document.type
      ? document.type
      : (document.isSet &&
          documentTypeToTypeDict[documentType] &&
          documentTypeToTypeDict[documentType] + '_set') ||
        documentType,

    budgetUntillNow: null,

    source,
    interactivePath: [],
    childRows: [],
    total_list_of_propagation_data: [],
    totalData: [],
    total_list_of_propagation_data_row: [],
    _members: document._members || [],
    members: document.members || [],
    _labels: document._labels || [],
    labels: document.labels || []
  };
};

const RoiTable = ({
  productPath,
  // currentManagerAccount,
  // currentAccountIds,
  stylesStoredInRedux
  // user
}) => {
  const theme = useTheme();
  const redWarningColor =
    theme.palette.mode === 'dark'
      ? 'rgb(255 84 84)' //  '#ff6b6b'
      : 'red';
  const blueWarningColor = theme.palette.mode === 'dark' ? '#9898ff' : 'blue';

  const greenWarningColor =
    theme.palette.mode === 'dark'
      ? 'lightgreen' //  '#ff6b6b'
      : 'green';

  const {
    selectedDateStart,
    setSelectedDateStart,
    selectedDateStop,
    setSelectedDateStop,
    range,
    setRange,
    range2,
    setRange2,
    advancedMode,
    copiedDocumentIds,
    setCopiedDocumentIds,
    copiedDocumentsAccountId,
    setCopiedDocumentsAccountId,
    clientId,
    dataLevel,
    setDataLevel,
    isKPIWarningsOff,
    isInvoiceWarningsOff,
    setIsKPIWarningsOff,
    daysToGraph,
    sparklineDateRange,
    setSparklineDateRange
    // startDateForLastDaysData
  } = useContext(InterfaceContext);

  const startDateForLastDaysData = getFastViewDataStartDate(
    selectedDateStart,
    selectedDateStop,
    daysToGraph
  );

  const startDateForLastDaysDataMinusOneDay = new Date(
    startDateForLastDaysData
  );
  startDateForLastDaysDataMinusOneDay.setDate(
    startDateForLastDaysDataMinusOneDay.getDate() - 1
  );
  // const startDateForLastDaysData = getFastViewDataStartDate();
  // const startDateForLastDaysDataMinusOneDay = new Date(
  //   new Date().setDate(startDateForLastDaysData.getDate() - 1)
  // );

  const today = new Date();
  let tomorrow = new Date();
  tomorrow = tomorrow.setDate(tomorrow.getDate() + 1);
  let dayAfterTomorrow = new Date();
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 2);
  const startDateForLastDaysDataMinus3Days = new Date(startDateForLastDaysData);
  startDateForLastDaysDataMinus3Days.setDate(
    new Date(startDateForLastDaysDataMinus3Days).getDate() - 3
  );
  const tomorrowPlus3Days = new Date(tomorrow);

  tomorrowPlus3Days.setDate(new Date(tomorrowPlus3Days).getDate() + 3);

  const datesToGraph = [];
  for (let date of range2) {
    if (
      new Date(date) < startDateForLastDaysDataMinus3Days ||
      new Date(date) > tomorrowPlus3Days
    ) {
      continue;
    }
    if (
      new Date(date) < new Date(startDateForLastDaysData) ||
      new Date(date) > new Date(tomorrow)
    ) {
      continue;
    }
    if (formatDateYYYYMMDD(date) > formatDateYYYYMMDD(today)) {
      continue;
    }
    datesToGraph.push(date);
  }

  // useEffect(() => {
  //   setSparklineDateRange(datesToGraph);
  // }, [selectedDateStart, selectedDateStop, daysToGraph]);

  // console.log('datesToGraph: ', datesToGraph);

  const { user, isAuthenticated } = useContext(AuthContext);
  const {
    currentManagerAccount,
    currentAccountIds,
    currentAccounts,
    subscriptionSkip,
    setSubscriptionSkip,
    nexusDocumentStatuses,
    nexusDocumentInvoiceStatuses
  } = useContext(AccountContext);

  const {
    statusFilter,
    setStatusFilter,
    // filterBySelection,
    setFilterBySelection,
    filters,
    setFilters,
    // filterByMemberId,
    // setFilterByMemberId,

    // filterByLabelId,
    // setFilterByLabelId,

    // filterByString,
    // setFilterByString,
    // filterByName,
    // setFilterByName,
    // filterByDataSourceName,
    // setFilterByDataSourceName,
    // filterByDataSource,
    // setFilterByDataSource,
    checkedBoxes,
    setCheckedBoxes
  } = useContext(FilterContext);

  const { refetchColumns } = useContext(ColumnContext);

  const {
    columnDialogOpen,
    editDialogOpen,
    setEditDialogOpen,
    emailPokeDialogOpen,
    setEmailPokeDialogOpen,
    fieldDialogOpen,
    copyDialogOpen,
    setViewDialogOpen,
    reportBuilderDialogOpen,
    setReportBuilderDialogOpen
  } = useContext(DialogContext);

  const { listOfData, setListOfData, isLoading, setIsLoading } =
    useContext(DataContext);

  const {
    subscriptionCallStack,
    setSubscriptionCallStack,
    addDocument,
    updateDocument,
    refetchCounter
  } = useContext(MutationContext);

  // const [dataLevel, setDataLevel] = useState('campaign');

  const [visColumns, setVisColumns] = useState([]);
  const [arrayOfData, setArrayOfData] = useState([]);

  const [names, setNames] = useState([]);
  const [invoiceOrderIds, setInvoiceOrderIds] = useState([]);

  // const [subscriptionSkip, setSubscriptionSkip] = useState(true);

  const [allSnackbarWarnings, setAllSnackbarWarnings] = useState([]);

  // const [copiedDocumentIds, setCopiedDocumentIds] = useState([]);
  // const [copiedDocumentsAccountId, setCopiedDocumentsAccountId] = useState([]);

  let startOfToday = new Date();
  startOfToday.setHours(0);
  startOfToday.setMinutes(0);

  const action = (key) => (
    <>
      {/* <button onClick={() => { alert(`I belong to snackbar with key ${key}`); }}>
        Alert
      </button> */}
      <Button
        style={{ maxWidth: '40px', minWidth: '40px', width: '40px' }}
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        <CloseIcon />
      </Button>
    </>
  );

  useEffect(() => {
    for (let snackbarWarning of allSnackbarWarnings) {
      enqueueSnackbar(snackbarWarning.message, {
        variant: snackbarWarning.variant,
        action
        // persist: true
      });
    }
  }, [allSnackbarWarnings]);

  const [summaryRow, setSummaryRow] = useState({
    cost: null,
    impressions: null,
    trp: null,
    spots: null
  });

  const [totalListOfPropagationData, setTotalListOfPropagationData] = useState(
    []
  );

  const [totalListOfPropagationBasicData, setTotalListOfPropagationBasicData] =
    useState([]);

  const [subscriptionUpdatesFrozen, setSubscriptionUpdatesFrozen] =
    useState(false);

  // #############################################################

  const [variables, setVariables] = useState({});
  useEffect(() => {
    setSubscriptionCallStack([]);

    let variables = {
      accountsInput: {
        managerAccountId: currentManagerAccount._id,
        accountId: currentAccountIds || []
      },
      nexusDocumentsQueryInput: {
        selectedDateStart,
        selectedDateStop,
        statusFilter: statusFilter,
        dataLevel
      },
      adAccountCampaignsInput: { selectedDateStart, selectedDateStop }
    };

    if (!variables?.accountsInput.managerAccountId) {
      return;
    }

    if (['system_admin', 'debug'].includes(user.role)) {
      console.log(
        'debug>RoiTable>row 242>currentAccountIds: ',
        currentAccountIds
      );
      console.log('debug>RoiTable>row 243>variables 65555: ', variables);
    }

    setVariables(variables);
  }, [
    currentManagerAccount?._id,
    currentAccountIds,
    selectedDateStart,
    selectedDateStop,
    dataLevel,
    statusFilter
  ]);

  // if (['system_admin', 'debug'].includes(user.role)) {
  //   console.log(
  //     'debug>RoiTable>row 242>currentAccountIds: ',
  //     currentAccountIds
  //   );
  //   console.log('debug>RoiTable>row 243>variables: ', variables);
  // }

  const [skip, toggleSkip] = useState(false);

  const [
    getData,
    { loading, error, data, refetch, client, startPolling, stopPolling }
  ] = useLazyQuery(
    productPath === 'basictable' ? tableDataQueryBasic : tableDataQuery2,
    {
      variables,

      fetchPolicy: 'no-cache',

      onCompleted: () => {
        console.log('RoiTable>Query completed.');
      },
      onError: (error) => {
        console.log('error: ', error);
      }
    }
  );

  useEffect(() => {
    if (refetchCounter) {
      // console.log('refetching');
      refetch();
    }
  }, [refetchCounter]);

  useEffect(() => {
    if (
      variables?.accountsInput?.managerAccountId &&
      selectedDateStart &&
      selectedDateStop
      // variables?.accountsInput?.accountId?.length > 0
    ) {
      const data = getData();
      // console.log('running get data>data: ', data);
    }
  }, [variables]);

  // console.log(' after running get data>data 65555: ', data);
  // useEffect(() => {
  //   console.log('65555: data: ', data);
  // }, [data]);

  // const { data: columnData, refetch: refetchColumns } = useQuery(
  //   customColumnSetsQuery,
  //   {
  //     variables: {
  //       customColumnSetsInput: { managerAccountId: currentManagerAccount._id }
  //     },
  //     fetchPolicy: 'no-cache',
  //     // skip: false
  //     // skip: currentManagerAccount._id ? skip : true
  //     skip:
  //       (currentAccountIds.length > 1 && dataLevel === 'campaign') ||
  //       currentManagerAccount._id
  //         ? skip
  //         : true
  //   }
  // );

  if (['system_admin', 'debug'].includes(user.role)) {
    console.log('debug>RoiTable>row 293>data: ', data);
  }

  // const [addDocument] = useMutation(addDocumentMutation);
  // const [updateDocument] = useMutation(updateDocumentMutation);

  const [rerender, setRerender] = useState(0);
  const [updateAccountId, setUpdateAccountId] = useState('');

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  if (window.WSDisconnectWarning && !loading) {
    enqueueSnackbar(window.WSDisconnectWarning, {
      variant: 'error',
      persist: true,
      preventDuplicate: true,
      action
    });

    // return console.log('Unallowed characters were used.');
  }

  useEffect(() => {
    setRerender(rerender + 1);
  }, [isKPIWarningsOff, isInvoiceWarningsOff]);
  // ################################################################

  useSubscription(refetchDataSubscription, {
    variables: {
      refetchDataInput: {
        currentManagerAccountId: currentManagerAccount._id,
        currentAccountIds,
        subscriptionUpdatesFrozen,
        clientId
      }
    },
    fetchPolicy: 'no-cache',
    shouldResubscribe: true,
    // skip: subscriptionSkip,
    onSubscriptionData: async ({ client, subscriptionData }) => {
      if (subscriptionUpdatesFrozen) {
        return;
      }
      //clear subscription callstacks

      const refetchAction =
        subscriptionData &&
        subscriptionData.data &&
        subscriptionData.data.refetchData &&
        subscriptionData.data.refetchData.action;

      switch (refetchAction) {
        case 'REFETCH_CUSTOM_COLUMN_SET':
          // console.log('refetchColumns()');
          refetchColumns();
          break;
        default:
          setSubscriptionCallStack([]);
          refetch();
      }
    }
  });

  // #################################################################

  useSubscription(
    productPath === 'basictable'
      ? documentBasicAddedSubscription
      : documentAddedSubscription,
    {
      variables: {
        documentsUpdatedInput: {
          currentAccountIds: currentAccountIds,
          selectedDateStart,
          selectedDateStop,
          dataLevel,
          subscriptionUpdatesFrozen,
          clientId
        }
      },
      fetchPolicy: 'no-cache',
      shouldResubscribe: true,
      // skip: subscriptionSkip,

      onSubscriptionData: async ({ client, subscriptionData }) => {
        if (subscriptionUpdatesFrozen) {
          return;
        }
        setSubscriptionCallStack([
          ...subscriptionCallStack,
          {
            subscriptionType: 'documentAddedSubscription',
            subscriptionData: subscriptionData?.data?.documentsAdded
          }
        ]);

        if (['system_admin', 'debug'].includes(user.role)) {
          console.log(
            'debug>RoiTable>row 400>documentAddedSubscription>subscriptionData: ',
            subscriptionData
          );
        }
      }
    }
  );

  const documentAdded = (subscriptionData) => {
    try {
      if (data.accounts.length <= 0) {
        return;
      }

      let shouldRerender = false;
      const copyOfListOfData = [...listOfData];

      for (let subsubscriptionData_ of subscriptionData) {
        // const documentAdded = subscriptionData;

        const {
          checkedDocumentId,
          // level,
          newNexusDocument,
          documentType,
          updateKey,
          updatedContainer,
          action,
          isCopy
        } = subsubscriptionData_;

        let preparedDocument = {
          ...newNexusDocument,
          nexusDocuments: [],
          childDocumentIds: []
        };

        const parentFolder = listOfData.filter(
          (obj) => obj._id == checkedDocumentId
        )?.[0];
        if (!parentFolder) {
          console.log('error sdfam234');
          return;
        }

        const {
          level_1_container_id: level1ContainerId,
          level_2_container_id: level2ContainerId,
          level_3_container_id: level3ContainerId,
          level_4_container_id: level4ContainerId,
          level_5_container_id: level5ContainerId,
          level_6_container_id: level6ContainerId,
          level_7_container_id: level7ContainerId,
          level_8_container_id: level8ContainerId,
          level_9_container_id: level9ContainerId,
          level_10_container_id: level10ContainerId,
          level_11_container_id: level11ContainerId,
          level_12_container_id: level12ContainerId
        } = parentFolder;

        // const copyOfAccounts = [...data.accounts];

        const accounts = data.accounts;

        // console.log('subscriptionData123: ', subscriptionData);

        let level_1_container;
        let level_2_container;
        let level_3_container;
        let level_4_container;
        let level_5_container;
        let level_6_container;
        let level_7_container;
        let level_8_container;
        let level_9_container;
        let level_10_container;
        let level_11_container;
        let level_12_container;

        let level_1_container_index;
        let level_2_container_index;
        let level_3_container_index;
        let level_4_container_index;
        let level_5_container_index;
        let level_6_container_index;
        let level_7_container_index;
        let level_8_container_index;
        let level_9_container_index;
        let level_10_container_index;
        let level_11_container_index;
        let level_12_container_index;

        let level_1_container_updated;

        let snackbarMessage;
        let snackbarVariant = 'success';

        const getContainerToBeChanged = (
          parentNexusDocument,
          childNexusDocumentId
        ) => {
          const nexusDocumentToBeChanged =
            parentNexusDocument.nexusDocuments.filter((obj) => {
              return obj._id === childNexusDocumentId;
            })[0];
          return nexusDocumentToBeChanged;
        };

        const { level } = parentFolder;

        console.log('debug96>parentFolder: ', parentFolder);
        console.log('debug96>level: ', level);

        const getContainertoBeChangedIndex = (
          parentNexusDocument,
          childNexusDocumentId
        ) => {
          const nexusDocumentToBeChangedIndex =
            parentNexusDocument.nexusDocuments.findIndex((obj) => {
              return obj._id === childNexusDocumentId;
            });
          return nexusDocumentToBeChangedIndex;
        };

        if (level >= 1) {
          level_1_container = accounts.filter((obj) => {
            return obj._id === level1ContainerId;
          })[0];
          level_1_container_index = accounts.findIndex((obj) => {
            return obj._id === level1ContainerId;
          });
          if (level_1_container_index === -1) {
            console.log('level_1_container_index===-1');
            return;
          }
        }

        if (level >= 2) {
          level_2_container = getContainerToBeChanged(
            level_1_container,
            level2ContainerId
          );

          level_2_container_index = getContainertoBeChangedIndex(
            level_1_container,
            level2ContainerId
          );
          if (level_2_container_index === -1) {
            console.log('level_2_container_index===-1');
            return;
          }
        }

        if (level >= 3) {
          level_3_container = getContainerToBeChanged(
            level_2_container,
            level3ContainerId
          );

          level_3_container_index = getContainertoBeChangedIndex(
            level_2_container,
            level3ContainerId
          );
          if (level_3_container_index === -1) {
            console.log('level_3_container_index===-1');
            return;
          }
        }

        if (level >= 4) {
          level_4_container = getContainerToBeChanged(
            level_3_container,
            level4ContainerId
          );

          level_4_container_index = getContainertoBeChangedIndex(
            level_3_container,
            level4ContainerId
          );
          if (level_4_container_index === -1) {
            console.log('level_4_container_index===-1');
            return;
          }
        }

        if (level >= 5) {
          level_5_container = getContainerToBeChanged(
            level_4_container,
            level5ContainerId
          );

          level_5_container_index = getContainertoBeChangedIndex(
            level_4_container,
            level5ContainerId
          );

          if (level_5_container_index === -1) {
            console.log('level_5_container_index===-1');
            return;
          }
        }

        if (level >= 6) {
          level_6_container = getContainerToBeChanged(
            level_5_container,
            level6ContainerId
          );

          level_6_container_index = getContainertoBeChangedIndex(
            level_5_container,
            level6ContainerId
          );

          if (level_6_container_index === -1) {
            console.log('level_6_container_index===-1');
            return;
          }
        }

        if (level >= 7) {
          level_7_container = getContainerToBeChanged(
            level_6_container,
            level7ContainerId
          );

          level_7_container_index = getContainertoBeChangedIndex(
            level_6_container,
            level7ContainerId
          );

          if (level_7_container_index === -1) {
            console.log('level_7_container_index===-1');
            return;
          }
        }

        if (level >= 8) {
          level_8_container = getContainerToBeChanged(
            level_7_container,
            level8ContainerId
          );

          level_8_container_index = getContainertoBeChangedIndex(
            level_7_container,
            level8ContainerId
          );

          if (level_8_container_index === -1) {
            console.log('level_8_container_index===-1');
            return;
          }
        }

        if (level >= 9) {
          level_9_container = getContainerToBeChanged(
            level_8_container,
            level9ContainerId
          );

          level_9_container_index = getContainertoBeChangedIndex(
            level_8_container,
            level9ContainerId
          );

          if (level_9_container_index === -1) {
            console.log('level_9_container_index===-1');
            return;
          }
        }

        if (level >= 10) {
          level_10_container = getContainerToBeChanged(
            level_9_container,
            level10ContainerId
          );

          level_10_container_index = getContainertoBeChangedIndex(
            level_9_container,
            level10ContainerId
          );

          if (level_10_container_index === -1) {
            console.log('level_10_container_index===-1');
            return;
          }
        }

        if (level >= 11) {
          level_11_container = getContainerToBeChanged(
            level_10_container,
            level11ContainerId
          );

          level_11_container_index = getContainertoBeChangedIndex(
            level_10_container,
            level11ContainerId
          );

          if (level_11_container_index === -1) {
            console.log('level_11_container_index===-1');
            return;
          }
        }

        if (level >= 12) {
          level_12_container = getContainerToBeChanged(
            level_11_container,
            level12ContainerId
          );

          level_12_container_index = getContainertoBeChangedIndex(
            level_11_container,
            level12ContainerId
          );

          if (level_12_container_index === -1) {
            console.log('level_12_container_index===-1');
            return;
          }
        }

        let containerToEdit;

        switch (level) {
          case 1:
            containerToEdit = level_1_container;
            break;
          case 2:
            containerToEdit = level_2_container;
            break;
          case 3:
            containerToEdit = level_3_container;
            break;
          case 4:
            containerToEdit = level_4_container;
            break;
          case 5:
            containerToEdit = level_5_container;
            break;
          case 6:
            containerToEdit = level_6_container;
            break;
          case 7:
            containerToEdit = level_7_container;
            break;
          case 8:
            containerToEdit = level_8_container;
            break;
          case 9:
            containerToEdit = level_9_container;
            break;
          case 10:
            containerToEdit = level_10_container;
            break;
          case 11:
            containerToEdit = level_11_container;
            break;
          case 12:
            containerToEdit = level_12_container;
            break;
        }

        const duplicates = containerToEdit.nexusDocuments.filter((obj) => {
          return obj._id === preparedDocument._id;
        });

        if (duplicates.length === 0) {
          containerToEdit.nexusDocuments.push(preparedDocument);
        } else {
          return;
        }

        switch (documentType) {
          case 'folder':
            snackbarMessage = 'New Folder Created';
            break;
          case 'task':
            snackbarMessage = 'New Task Created';
            break;
          case 'subTask':
            snackbarMessage = 'New subtask Created';
            break;
          case 'taxonomy':
            snackbarMessage = 'New taxonomy Created';
            break;
          default:
            snackbarMessage = 'New document';
        }

        if (level >= 12) {
          level_11_container.nexusDocuments.splice(
            level_12_container_index,
            1,
            level_12_container
          );
        }

        if (level >= 11) {
          level_10_container.nexusDocuments.splice(
            level_11_container_index,
            1,
            level_11_container
          );
        }

        if (level >= 10) {
          level_9_container.nexusDocuments.splice(
            level_10_container_index,
            1,
            level_10_container
          );
        }

        if (level >= 9) {
          level_8_container.nexusDocuments.splice(
            level_9_container_index,
            1,
            level_9_container
          );
        }

        if (level >= 8) {
          level_7_container.nexusDocuments.splice(
            level_8_container_index,
            1,
            level_8_container
          );
        }

        if (level >= 7) {
          level_6_container.nexusDocuments.splice(
            level_7_container_index,
            1,
            level_7_container
          );
        }

        if (level >= 6) {
          level_5_container.nexusDocuments.splice(
            level_6_container_index,
            1,
            level_6_container
          );
        }

        if (level >= 5) {
          level_4_container.nexusDocuments.splice(
            level_5_container_index,
            1,
            level_5_container
          );
        }

        if (level >= 4) {
          level_3_container.nexusDocuments.splice(
            level_4_container_index,
            1,
            level_4_container
          );
        }

        if (level >= 3) {
          level_2_container.nexusDocuments.splice(
            level_3_container_index,
            1,
            level_3_container
          );
        }
        if (level >= 2) {
          level_1_container.nexusDocuments.splice(
            level_2_container_index,
            1,
            level_2_container
          );
        }

        if (level >= 1) {
          data.accounts.splice(level_1_container_index, 1, level_1_container);
        }

        const addToParentChildRows = (rowToAdd, rowToEdit) => {
          const parentRowIndex = copyOfListOfData.findIndex((row) => {
            return (
              row._id ===
              rowToEdit[
                'level_' + (rowToEdit.level - 1).toString() + '_container_id'
              ]
            );
          });

          const parentRow = copyOfListOfData.filter((row) => {
            return (
              row._id ===
              rowToEdit[
                'level_' + (rowToEdit.level - 1).toString() + '_container_id'
              ]
            );
          })[0];

          if (parentRow.ganttBarDisplaySetting === 'CHILDREN') {
            // const childIndex = parentRow.childRows.findIndex(
            //   (childRow) => childRow._id === updatedRow._id
            // );
            parentRow.childRows.push(rowToAdd);

            addToParentChildRows(rowToAdd, parentRow);

            // updateParentChildRows(updatedRow, parentRow);
          }
        };

        const spreadDoc = rowBaseKV(preparedDocument, documentType, '');

        switch (true) {
          case documentType === 'folder' && !isCopy:
          case documentType === 'manualDataEntry' && !isCopy:
          case documentType === 'customForm' && !isCopy:
            const newRow = {
              ...spreadDoc,
              // ...rowBaseKVs,
              accountColor:
                listOfData.filter((row) => row._id === level1ContainerId)[0]
                  ?.accountColor || null,
              accountTimeZone:
                listOfData.filter((row) => row._id === level1ContainerId)[0]
                  ?.accountTimeZone || null,
              isSet: preparedDocument.isSet,
              template: preparedDocument.template,
              checkedAt: preparedDocument.checkedAt,
              personal: preparedDocument.personal,
              fields: preparedDocument.fields,
              _id: preparedDocument._id,
              childDocumentIds: [],
              name: preparedDocument.name,
              status: preparedDocument.status,
              statusUpdatedAt: preparedDocument.statusUpdatedAt,
              type: documentType,
              level: level + 1,
              accountAccessLevel: 1,
              accountId: level1ContainerId,
              level_1_container_id: level1ContainerId,
              level_2_container_id:
                level + 1 === 2 ? preparedDocument._id : level2ContainerId,
              level_3_container_id:
                level + 1 === 3 ? preparedDocument._id : level3ContainerId,
              level_4_container_id:
                level + 1 === 4 ? preparedDocument._id : level4ContainerId,
              level_5_container_id:
                level + 1 === 5 ? preparedDocument._id : level5ContainerId,
              level_6_container_id:
                level + 1 === 6 ? preparedDocument._id : level6ContainerId,
              level_7_container_id:
                level + 1 === 7 ? preparedDocument._id : level7ContainerId,
              level_8_container_id:
                level + 1 === 8 ? preparedDocument._id : level8ContainerId,
              level_9_container_id:
                level + 1 === 9 ? preparedDocument._id : level9ContainerId,
              level_10_container_id:
                level + 1 === 10 ? preparedDocument._id : level10ContainerId,
              level_11_container_id:
                level + 1 === 11 ? preparedDocument._id : level11ContainerId,
              level_12_container_id:
                level + 1 === 12 ? preparedDocument._id : level12ContainerId,
              warnings: [],
              subWarnings: [],
              snackbarWarnings: [],
              childRows: [],
              documentType,
              members: preparedDocument.members,
              labels: [],
              totalData: [],
              total_list_of_propagation_data: [],
              total_list_of_propagation_data_row: [],
              style: preparedDocument.style,
              path: parentFolder?.path + preparedDocument.name + ' / ',
              // interactivePath: [...parentFolder[0].path, {name: preparedDocument.name, _id: preparedDocument.name}]
              interactivePath: [
                ...parentFolder?.interactivePath,
                { _id: preparedDocument._id, name: preparedDocument.name }
              ],
              plannedGrossFormula: preparedDocument.plannedGross,
              plannedNetFormula: preparedDocument.plannedNet,
              plannedNetNetFormula: preparedDocument.plannedNetNet,
              plannedCtcFormula: preparedDocument.plannedCtc,

              plannedTrpFormula: preparedDocument.plannedTrp,
              plannedCppFormula: preparedDocument.plannedCpp,
              plannedTechFeeFormula: preparedDocument.plannedTechFee,
              plannedAdOpsFeeFormula: preparedDocument.plannedAdOpsFee,
              plannedAdServingCostFormula:
                preparedDocument.plannedAdServingCost,
              plannedFeePercentFormula: preparedDocument.plannedFeePercent,

              budgetFormula: preparedDocument.budget,
              costFormula: preparedDocument.cost,
              impressionsFormula: preparedDocument.impressions,
              viewableImpressionsFormula: preparedDocument.viewableImpressions,
              measurableImpressionsFormula:
                preparedDocument.measurableImpressions,
              eligibleImpressionsFormula: preparedDocument.eligibleImpressions,

              clicksFormula: preparedDocument.clicks
            };

            const status = nexusDocumentStatuses?.filter(
              (status) => status.string === newRow.status
            )[0];
            if (status) {
              newRow.statusColor = status?.color;
              newRow.statusName = status?.name;
            }

            copyOfListOfData.push(newRow);
            addToParentChildRows(newRow, newRow);
            // setListOfData(copyOfListOfData);
            break;
          default:
            console.log('rerender!!!');
            setUpdateAccountId(level1ContainerId);
            // setRerender(rerender + 1);
            shouldRerender = true;
        }

        // // ##################################

        enqueueSnackbar(snackbarMessage, {
          variant: snackbarVariant
        });
      }

      // switch (documentType) {
      //   case 'folder':
      //   case 'manualDataEntry':
      //   case 'customForm':
      //     setListOfData(copyOfListOfData);
      //     break;
      // }

      if (shouldRerender) {
        setRerender(rerender + 1);
      } else {
        setListOfData(copyOfListOfData);
      }
    } catch (error) {
      console.log('debug69>row1160>error: ', error);
    }
  };

  // const [subscriptionCallStack, setSubscriptionCallStack] = useState([]);

  if (['system_admin', 'debug'].includes(user.role)) {
    console.log(
      'debug>RoiTable>row 989>subscriptionCallStack: ',
      subscriptionCallStack
    );
  }

  const {
    data: documentUpdatedData,
    loading: documentUpdatedLoading,
    error: documentUpdatedError
  } = useSubscription(
    productPath === 'basictable'
      ? documentBasicUpdatedSubscription
      : documentUpdatedSubscription,
    {
      variables: {
        documentsUpdatedInput: {
          currentAccountIds: currentAccountIds,
          selectedDateStart,
          selectedDateStop,
          dataLevel,
          subscriptionUpdatesFrozen,
          clientId
        }
      }, //currentAccountIds, selectedDateStart, selectedDateStop.
      shouldResubscribe: true,
      // skip: currentAccountIds.length > 0,
      // skip: subscriptionSkip,
      fetchPolicy: 'no-cache',
      // currentManagerAccount?._id && currentAccountIds.length > 0 ? false : true,
      onSubscriptionData: async ({ client, subscriptionData }) => {
        if (['system_admin', 'debug'].includes(user.role)) {
          console.log(
            'debug>RoiTable>row 898>documentUpdatedSubscription>subscriptionData: ',
            subscriptionData
          );
        }

        if (subscriptionUpdatesFrozen) {
          return;
        }

        setSubscriptionCallStack([
          ...subscriptionCallStack,
          {
            subscriptionType: 'documentUpdatedSubscription',
            subscriptionData: subscriptionData.data.documentsUpdated
          }
        ]);
      }
    }
  );

  const [doneCalculating, toggleDoneCalculating] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    setIsLoading(loading || showSpinner || !doneCalculating || !data);
  }, [loading || showSpinner || !doneCalculating || !data]);

  useEffect(() => {
    // client

    if (!loading && subscriptionCallStack.length > 0) {
      // loop changes

      for (let i = 0; i < subscriptionCallStack.length; i++) {
        if (doneCalculating) {
          switch (subscriptionCallStack[i].subscriptionType) {
            case 'documentAddedSubscription':
              documentAdded(subscriptionCallStack[i].subscriptionData);
              break;
            case 'documentUpdatedSubscription':
              documentUpdated(subscriptionCallStack[i].subscriptionData);
              break;
          }

          const copyOfSubscriptionCallStack = [...subscriptionCallStack];
          copyOfSubscriptionCallStack.splice(i, 1);
          setSubscriptionCallStack(copyOfSubscriptionCallStack);
        }
      }
    }
  }, [loading, subscriptionCallStack, doneCalculating]);

  const documentUpdated = async (subscriptionData) => {
    let shouldRerender = false;
    try {
      if (data.accounts.length <= 0) {
        return;
      }

      const updatedListOfData = [...listOfData];
      for (let subsubscriptionData_ of subscriptionData) {
        // let tableDataBeforeUpdate = [...tableData];
        // let updatedTableData = [...tableData];

        // const documentUpdatedData = subscriptionData;

        const {
          accountId,
          // level,
          documentId,
          // newDocument,
          // documentType,

          // updateKey,
          updateKeys,
          updatedNexusDocument,
          action
        } = subsubscriptionData_;

        if (action === 'show_new_version_available') {
          enqueueSnackbar(
            'Please reload the page. Data out of sync. A new version is available.',
            {
              variant: 'error',
              persist: true
            }
          );
        }

        const parentFolder = listOfData.filter((obj) => obj._id == documentId);
        if (!parentFolder) {
          console.log('error sdfam234');
          return;
        }

        const {
          level_1_container_id: level1ContainerId,
          level_2_container_id: level2ContainerId,
          level_3_container_id: level3ContainerId,
          level_4_container_id: level4ContainerId,
          level_5_container_id: level5ContainerId,
          level_6_container_id: level6ContainerId,
          level_7_container_id: level7ContainerId,
          level_8_container_id: level8ContainerId,
          level_9_container_id: level9ContainerId,
          level_10_container_id: level10ContainerId,
          level_11_container_id: level11ContainerId,
          level_12_container_id: level12ContainerId,
          level,
          documentType
        } = parentFolder[0];

        const accounts = data.accounts;

        let level_1_container;
        let level_2_container;
        let level_3_container;
        let level_4_container;
        let level_5_container;
        let level_6_container;
        let level_7_container;
        let level_8_container;
        let level_9_container;
        let level_10_container;
        let level_11_container;
        let level_12_container;

        let level_1_container_index;
        let level_2_container_index;
        let level_3_container_index;
        let level_4_container_index;
        let level_5_container_index;
        let level_6_container_index;
        let level_7_container_index;
        let level_8_container_index;
        let level_9_container_index;
        let level_10_container_index;
        let level_11_container_index;
        let level_12_container_index;

        let level_1_container_updated;
        let level_2_container_updated;
        let level_3_container_updated;
        let level_4_container_updated;
        let level_5_container_updated;
        let level_6_container_updated;
        let level_7_container_updated;
        let level_8_container_updated;
        let level_9_container_updated;
        let level_10_container_updated;
        let level_11_container_updated;
        let level_12_container_updated;

        let snackbarMessage;
        let snackbarVariant = 'success';

        const getContainerToBeChanged = (supContainer, subContainerId) => {
          const containerToBeChanged = supContainer.nexusDocuments.filter(
            (obj) => {
              return obj._id === subContainerId;
            }
          )[0];

          return containerToBeChanged;
        };

        const getContainertoBeChangedIndex = (
          supContainer,
          subContainerId,
          documentType,
          finalObject,
          objectToBeChangedId
        ) => {
          let containerToBeChangedIndex;

          if (finalObject) {
            containerToBeChangedIndex = supContainer.nexusDocuments.findIndex(
              (obj) => {
                return obj._id === objectToBeChangedId;
              }
            );
          } else {
            containerToBeChangedIndex = supContainer.nexusDocuments.findIndex(
              (obj) => {
                return obj._id === subContainerId;
              }
            );
          }

          if (containerToBeChangedIndex === -1) {
            // console.log('containerToBeChangedIndex === -1');
            enqueueSnackbar(
              'Please reload the page. Data out of sync. A new version is available2.',
              {
                variant: 'error',
                persist: true
              }
            );

            setSubscriptionCallStack([]);
            console.log('refetch 798');
            refetch();
          }

          // console.log('containerToBeChangedIndex: ', containerToBeChangedIndex);

          return containerToBeChangedIndex;
        };

        let objectToBeChangedId = updatedNexusDocument._id;

        if (level >= 1) {
          level_1_container_index = accounts.findIndex((obj) => {
            return obj._id === level1ContainerId;
          });

          if (level_1_container_index === -1) {
            enqueueSnackbar(
              'Please reload the page. Data out of sync. A new version is available.',
              {
                variant: 'error',
                persist: true
              }
            );

            setSubscriptionCallStack([]);

            refetch();

            return;
          }

          level_1_container = accounts.filter((obj) => {
            return obj._id === level1ContainerId;
          })[0];
        }

        if (level >= 2) {
          level_2_container_index = getContainertoBeChangedIndex(
            level_1_container,
            level2ContainerId,
            documentType,
            level === 2,
            objectToBeChangedId
          );

          if (level_2_container_index === -1) {
            return;
          }

          level_2_container = getContainerToBeChanged(
            level_1_container,
            level2ContainerId
          );
        }

        if (level >= 3) {
          level_3_container_index = getContainertoBeChangedIndex(
            level_2_container,
            level3ContainerId,
            documentType,
            level === 3,
            objectToBeChangedId
          );

          if (level_3_container_index === -1) {
            return;
          }

          level_3_container = getContainerToBeChanged(
            level_2_container,
            level3ContainerId
          );
        }

        if (level >= 4) {
          level_4_container_index = getContainertoBeChangedIndex(
            level_3_container,
            level4ContainerId,
            documentType,
            level === 4,
            objectToBeChangedId
          );

          if (level_4_container_index === -1) {
            return;
          }

          level_4_container = getContainerToBeChanged(
            level_3_container,
            level4ContainerId
          );
        }

        if (level >= 5) {
          level_5_container_index = getContainertoBeChangedIndex(
            level_4_container,
            level5ContainerId,
            documentType,
            level === 5,
            objectToBeChangedId
          );

          if (level_5_container_index === -1) {
            return;
          }

          level_5_container = getContainerToBeChanged(
            level_4_container,
            level5ContainerId
          );
        }

        if (level >= 6) {
          level_6_container_index = getContainertoBeChangedIndex(
            level_5_container,
            level6ContainerId,
            documentType,
            level === 6,
            objectToBeChangedId
          );

          if (level_6_container_index === -1) {
            return;
          }

          level_6_container = getContainerToBeChanged(
            level_5_container,
            level6ContainerId
          );
        }

        if (level >= 7) {
          level_7_container_index = getContainertoBeChangedIndex(
            level_6_container,
            level7ContainerId,
            documentType,
            level === 7,
            objectToBeChangedId
          );

          if (level_7_container_index === -1) {
            return;
          }

          level_7_container = getContainerToBeChanged(
            level_6_container,
            level7ContainerId
          );
        }

        if (level >= 8) {
          level_8_container_index = getContainertoBeChangedIndex(
            level_7_container,
            level8ContainerId,
            documentType,
            level === 8,
            objectToBeChangedId
          );

          if (level_8_container_index === -1) {
            return;
          }

          level_8_container = getContainerToBeChanged(
            level_7_container,
            level8ContainerId
          );
        }

        if (level >= 9) {
          level_9_container_index = getContainertoBeChangedIndex(
            level_8_container,
            level9ContainerId,
            documentType,
            level === 9,
            objectToBeChangedId
          );

          if (level_9_container_index === -1) {
            return;
          }

          level_9_container = getContainerToBeChanged(
            level_8_container,
            level9ContainerId
          );
        }

        if (level >= 10) {
          level_10_container_index = getContainertoBeChangedIndex(
            level_9_container,
            level10ContainerId,
            documentType,
            level === 10,
            objectToBeChangedId
          );

          if (level_10_container_index === -1) {
            return;
          }

          level_10_container = getContainerToBeChanged(
            level_9_container,
            level10ContainerId
          );
        }

        if (level >= 11) {
          level_11_container_index = getContainertoBeChangedIndex(
            level_10_container,
            level11ContainerId,
            documentType,
            level === 11,
            objectToBeChangedId
          );

          if (level_11_container_index === -1) {
            return;
          }

          level_11_container = getContainerToBeChanged(
            level_10_container,
            level11ContainerId
          );
        }

        if (level >= 12) {
          level_12_container_index = getContainertoBeChangedIndex(
            level_11_container,
            level12ContainerId,
            documentType,
            level === 12,
            objectToBeChangedId
          );

          if (level_12_container_index === -1) {
            return;
          }

          level_12_container = getContainerToBeChanged(
            level_11_container,
            level12ContainerId
          );
        }

        const createUpdatedContainer = async (
          supContainer,
          subContainerIndex,
          subContainer,
          updatedNexusDocument
        ) => {
          let updatedContainer = {};
          let copyOfContainers = [...supContainer.nexusDocuments];
          // console.log('RoiTable>debug>row 1509> updateKey', updateKey);
          if (updateKeys.includes('startDate_endDate')) {
            updatedContainer = {
              ...subContainer,
              startDate: updatedNexusDocument['startDate'],
              endDate: updatedNexusDocument['endDate']
            };
          } else {
            updatedContainer = {
              ...subContainer
            };
            for (let updatek of updateKeys) {
              updatedContainer[updatek] = updatedNexusDocument[updatek];
            }
          }

          await copyOfContainers.splice(subContainerIndex, 1, updatedContainer);

          const container_updated = {
            ...supContainer,
            nexusDocuments: copyOfContainers
          };

          return container_updated;
        };

        switch (level) {
          case 1:
            switch (documentType) {
              case 'account':
                level_1_container_updated = {
                  ...level_1_container
                  // [updateKey]: updatedNexusDocument[updateKey]
                };

                for (let updatek of updateKeys) {
                  level_1_container_updated[updatek] =
                    updatedNexusDocument[updatek];
                }
            }
            break;
          case 2:
            level_1_container_updated = await createUpdatedContainer(
              level_1_container,
              level_2_container_index,
              level_2_container,
              updatedNexusDocument
            );

            break;
          case 3:
            level_2_container_updated = await createUpdatedContainer(
              level_2_container,
              level_3_container_index,
              level_3_container,
              updatedNexusDocument
            );

            break;
          case 4:
            level_3_container_updated = await createUpdatedContainer(
              level_3_container,
              level_4_container_index,
              level_4_container,
              updatedNexusDocument
            );
            //     break;
            // }
            break;
          case 5:
            level_4_container_updated = await createUpdatedContainer(
              level_4_container,
              level_5_container_index,
              level_5_container,
              updatedNexusDocument
            );

            break;
          case 6:
            level_5_container_updated = await createUpdatedContainer(
              level_5_container,
              level_6_container_index,
              level_6_container,
              updatedNexusDocument
            );

            break;
          case 7:
            level_6_container_updated = await createUpdatedContainer(
              level_6_container,
              level_7_container_index,
              level_7_container,
              updatedNexusDocument
            );

            break;
          case 8:
            level_7_container_updated = await createUpdatedContainer(
              level_7_container,
              level_8_container_index,
              level_8_container,
              updatedNexusDocument
            );

            break;
          case 9:
            level_8_container_updated = await createUpdatedContainer(
              level_8_container,
              level_9_container_index,
              level_9_container,
              updatedNexusDocument
            );

            break;
          case 10:
            level_9_container_updated = await createUpdatedContainer(
              level_9_container,
              level_10_container_index,
              level_10_container,
              updatedNexusDocument
            );

            break;

          case 11:
            level_10_container_updated = await createUpdatedContainer(
              level_10_container,
              level_11_container_index,
              level_11_container,
              updatedNexusDocument
            );

            break;

          case 12:
            level_11_container_updated = await createUpdatedContainer(
              level_11_container,
              level_12_container_index,
              level_12_container,
              updatedNexusDocument
            );

            break;
        }

        if (level > 12) {
          level_10_container.nexusDocuments.splice(
            level_11_container_index,
            1,
            level_11_container
          );
        }

        if (level === 12) {
          level_10_container.nexusDocuments.splice(
            level_11_container_index,
            1,
            level_11_container_updated
          );
        }

        if (level > 11) {
          level_9_container.nexusDocuments.splice(
            level_10_container_index,
            1,
            level_10_container
          );
        }

        if (level === 11) {
          level_9_container.nexusDocuments.splice(
            level_10_container_index,
            1,
            level_10_container_updated
          );
        }

        if (level > 10) {
          level_8_container.nexusDocuments.splice(
            level_9_container_index,
            1,
            level_9_container
          );
        }

        if (level === 10) {
          level_8_container.nexusDocuments.splice(
            level_9_container_index,
            1,
            level_9_container_updated
          );
        }

        if (level > 9) {
          level_7_container.nexusDocuments.splice(
            level_8_container_index,
            1,
            level_8_container
          );
        }

        if (level === 9) {
          level_7_container.nexusDocuments.splice(
            level_8_container_index,
            1,
            level_8_container_updated
          );
        }

        if (level > 8) {
          level_6_container.nexusDocuments.splice(
            level_7_container_index,
            1,
            level_7_container
          );
        }

        if (level === 8) {
          level_6_container.nexusDocuments.splice(
            level_7_container_index,
            1,
            level_7_container_updated
          );
        }

        if (level > 7) {
          level_5_container.nexusDocuments.splice(
            level_6_container_index,
            1,
            level_6_container
          );
        }

        if (level === 7) {
          level_5_container.nexusDocuments.splice(
            level_6_container_index,
            1,
            level_6_container_updated
          );
        }

        if (level > 6) {
          level_4_container.nexusDocuments.splice(
            level_5_container_index,
            1,
            level_5_container
          );
        }

        if (level === 6) {
          level_4_container.nexusDocuments.splice(
            level_5_container_index,
            1,
            level_5_container_updated
          );
        }

        if (level > 5) {
          level_3_container.nexusDocuments.splice(
            level_4_container_index,
            1,
            level_4_container
          );
        }

        if (level === 5) {
          level_3_container.nexusDocuments.splice(
            level_4_container_index,
            1,
            level_4_container_updated
          );
        }

        if (level > 4) {
          level_2_container.nexusDocuments.splice(
            level_3_container_index,
            1,
            level_3_container
          );
        }

        if (level === 4) {
          level_2_container.nexusDocuments.splice(
            level_3_container_index,
            1,
            level_3_container_updated
          );
        }

        if (level > 3) {
          level_1_container.nexusDocuments.splice(
            level_2_container_index,
            1,
            level_2_container
          );
        }

        if (level === 3) {
          const replacedDocumentLevel2 =
            level_1_container.nexusDocuments.splice(
              level_2_container_index,
              1,
              level_2_container_updated
            );
          if (replacedDocumentLevel2._id !== updatedNexusDocument.id) {
            return;
          }
        }

        // const copyOfData = { ...data };

        if (level > 2) {
          data.accounts.splice(level_1_container_index, 1, level_1_container);
        }

        if (level === 2) {
          data.accounts.splice(
            level_1_container_index,
            1,
            level_1_container_updated
          );
        }

        if (level === 1) {
          data.accounts.splice(
            level_1_container_index,
            1,
            level_1_container_updated
          );
        }

        // console.log('updatedDocument>copyOfData: ', copyOfData);

        // client.writeQuery({
        //   query: tableDataQuery2,
        //   data: copyOfData,
        //   variables
        // });

        // setUpdateAccountId(accountId);
        // setRerender(rerender + 1);

        // ##################################

        // get index of row.
        const rowIndex = listOfData.findIndex((row) => {
          return row._id === updatedNexusDocument._id;
        });

        const oldRow = listOfData.filter((row) => {
          return row._id === updatedNexusDocument._id;
        })[0];

        // get closest childrows

        // const updatedListOfData = [...listOfData];
        // console.log('updateKey: ', updateKey);

        if (['system_admin', 'debug'].includes(user.role)) {
          console.log(
            'debug>RoiTable>row 1919>updatedListOfData: ',
            updatedListOfData
          );
        }

        let updatedRow = { ...oldRow };

        // setUpdateAccountId(accountId);
        // setRerender(rerender + 1);
        const updateParentChildRows = (updatedRow, queryRow) => {
          for (let row1 of updatedListOfData) {
            const childIndex = row1.childRows.findIndex(
              (childRow) => String(childRow._id) === String(updatedRow._id)
            );

            if (childIndex >= 0) {
              row1.childRows[childIndex] = updatedRow;
            }
          }
        };

        for (let updateKey of updateKeys) {
          if (updateKey) {
            // updatekey will be null/undefined if updating multiple keys at the same time.
            switch (documentType) {
              case 'folder':
              case 'task':
              case 'subTask':
              case 'taxonomy':
                switch (updateKey) {
                  case 'ganttBarDisplaySetting':
                    setUpdateAccountId(accountId);
                    // setRerender(rerender + 1);
                    shouldRerender = true;
                    // return '';
                    break;

                  case 'name':
                    updatedRow = {
                      ...updatedRow,
                      [updateKey]: updatedNexusDocument[updateKey]
                    };

                    for (let pathItem of updatedRow.interactivePath) {
                      if (pathItem._id === updatedNexusDocument._id) {
                        pathItem.name = updatedNexusDocument.name;
                      }
                    }

                    // const updatedListOfData = [...listOfData];

                    updatedListOfData.splice(rowIndex, 1, updatedRow);
                    updateParentChildRows(updatedRow);
                    setListOfData(updatedListOfData);

                    break;

                  case 'invoiceStatus':
                  case 'invoiceOrderId':
                  case 'generalDescription':

                  case 'buyingTypeDescription':
                  case 'creativeDescription':
                  case 'formatDescription':
                  case 'channelDescription':
                  case 'audienceDataDescription':

                  case 'targetGroupDescription':
                  case 'placementDescription':
                  case 'ganttBarDisplaySetting':
                  case 'goalDescription':

                  case 'showSubTasksOnCard':
                  case 'allowMissingBudget':
                  case 'allowMissingClicks':
                  case 'allowAbnormallyHighCtr':
                  case 'allowMissingImpressionsYesterday':
                  case 'allowImpressionsBeforeStartDate':
                  case 'allowImpressionsAfterEndDate':

                  case 'budgetCurrency':

                  case 'comments':
                  case 'ganttBarColor':
                  case 'statusUpdatedAt':
                    // case 'status':
                    // case 'action01':
                    updatedRow = {
                      ...updatedRow,
                      [updateKey]: updatedNexusDocument[updateKey]
                    };

                    // const updatedListOfData = [...listOfData];
                    updatedListOfData.splice(rowIndex, 1, updatedRow);
                    updateParentChildRows(updatedRow);
                    setListOfData(updatedListOfData);

                    break;

                  case 'checkedAt':
                    // console.log('wooohoooo!');
                    updatedRow = {
                      ...updatedRow,
                      [updateKey]: updatedNexusDocument[updateKey]
                    };

                    updatedRow.daysSinceCheckedAt =
                      updatedRow?.checkedAt &&
                      differenceInDaysFloor(
                        new Date(updatedRow.checkedAt),
                        new Date()
                      );

                    // console.log('updatedRow: ', updatedRow);

                    // calc number of days.

                    updatedListOfData.splice(rowIndex, 1, updatedRow);
                    updateParentChildRows(updatedRow);
                    setListOfData(updatedListOfData);
                    // console.log('debug67>row2028>end of checkeAt update');
                    break;

                  case 'status':
                    if (
                      oldRow.status === 'REMOVED' ||
                      updatedNexusDocument.status === 'REMOVED'
                    ) {
                      setUpdateAccountId(accountId);
                      // setRerender(rerender + 1);
                      shouldRerender = true;
                      break;
                    }
                    // case 'action01':
                    updatedRow = {
                      ...updatedRow,
                      [updateKey]: updatedNexusDocument[updateKey]
                      // statusUpdatedAt: updatedNexusDocument.statusUpdatedAt
                    };

                    // if (updatedNexusDocument.status === 'ACTIVE') {
                    //   // console.log('tralalalalala');
                    //   updatedRow.checkedAt = updatedNexusDocument.checkedAt;

                    //   updatedRow.daysSinceCheckedAt =
                    //     updatedRow?.checkedAt &&
                    //     differenceInDaysFloor(
                    //       new Date(updatedRow.checkedAt),
                    //       new Date()
                    //     );
                    // }

                    // const updatedListOfData = [...listOfData];

                    const status = nexusDocumentStatuses?.filter(
                      (status) => status.string === updatedRow.status
                    )[0];
                    if (status) {
                      updatedRow.statusColor = status?.color;
                      updatedRow.statusName = status?.name;
                    }

                    updatedListOfData.splice(rowIndex, 1, updatedRow);
                    updateParentChildRows(updatedRow);
                    setListOfData(updatedListOfData);

                    // shouldRerender = true;
                    break;

                  case 'action01':
                  case 'action02':
                  case 'action03':
                  case 'members':
                  case 'labels':
                    // setRerender(rerender + 1);
                    shouldRerender = true;
                    // console.log('refetch 1400');
                    // refetch();
                    break;

                  case 'budgetZZZ':
                    updatedRow = {
                      ...updatedRow,
                      [updateKey]: updatedNexusDocument[updateKey]
                    };

                    const parentRowIndex = updatedListOfData.findIndex(
                      (row) => {
                        return (
                          row._id ===
                          updatedRow[
                            'level_' +
                              (updatedRow.level - 1).toString() +
                              '_container_id'
                          ]
                        );
                      }
                    );

                    const parentRow = updatedListOfData.filter((row) => {
                      return (
                        row._id ===
                        updatedRow[
                          'level_' +
                            (updatedRow.level - 1).toString() +
                            '_container_id'
                        ]
                      );
                    })[0];

                    const copyOfParentRow = { ...parentRow };
                    copyOfParentRow.budgetSumChild =
                      copyOfParentRow.budgetSumChild -
                      (updatedRow.budget || null) +
                      updatedRow.budget;

                    updatedListOfData.splice(
                      parentRowIndex,
                      1,
                      copyOfParentRow
                    );

                    try {
                      if (updatedRow.startDate && updatedRow.endDate) {
                        updatedRow.numberOfDays =
                          differenceInDays(
                            updatedRow.startDate,
                            updatedRow.endDate
                          ) + 1;

                        updatedRow.numberOfDaysLeft = numberOfDaysLeft(
                          updatedRow.startDate,
                          updatedRow.endDate
                        );

                        updatedRow.numberOfDays =
                          differenceInDays(
                            updatedRow.startDate,
                            updatedRow.endDate
                          ) + 1;
                      }

                      if (updatedRow.budget && updatedRow.numberOfDays) {
                        updatedRow.averageDailyBudget =
                          updatedRow.budget / updatedRow.numberOfDays;
                      }
                    } catch (error) {
                      console.log('debug67>row2148>error: ', error);
                    }

                    try {
                      if (
                        updatedRow.budget &&
                        updatedRow.startDate &&
                        updatedRow.endDate
                        // new Date(updatedRow.startDate) >=
                        //   new Date(selectedDateStart) &&
                        // new Date(updatedRow.endDate) <= new Date(selectedDateStop)
                        // ||
                        // new Date(selectedDateStop) >= new Date()
                      ) {
                        // updatedRow.pace = pace(
                        //   updatedRow,
                        //   updatedRow.startDate,
                        //   updatedRow.endDate,
                        //   updatedRow.budget,
                        //   updatedRow.cost || 0
                        // );

                        updatedRow.pace =
                          updatedRow.budgetUntillNow &&
                          updatedRow.cost &&
                          Math.round(
                            (updatedRow.cost / updatedRow.budgetUntillNow) * 100
                          );

                        updatedRow.calcDailyBudget = calcDailyBudget(
                          updatedRow.budget,
                          updatedRow.cost || 0,
                          updatedRow.numberOfDays,
                          updatedRow.numberOfDaysLeft
                        );
                      } else if (
                        updatedRow.budget &&
                        updatedRow.startDate &&
                        updatedRow.endDate
                      ) {
                        updatedRow.pace = updatedRow.budget ? '?' : '';
                        updatedRow.calcDailyBudget = '?';
                      }
                    } catch (error) {
                      console.log('error');
                    }
                    // update pace.
                    // update calculated budget.

                    // const updatedListOfData = [...listOfData];
                    updatedListOfData.splice(rowIndex, 1, updatedRow);
                    updateParentChildRows(updatedRow);
                    setListOfData(updatedListOfData);
                    break;
                  case 'startDateZZZ':
                  case 'endDateZZZ':
                    const newDate =
                      updatedNexusDocument[updateKey] === null
                        ? null
                        : new Date(parseInt(updatedNexusDocument[updateKey]))
                            .toISOString()
                            .split('T')[0];

                    updatedRow = { ...oldRow, [updateKey]: newDate };

                    try {
                      if (updatedRow.startDate && updatedRow.endDate) {
                        updatedRow.numberOfDays =
                          differenceInDays(
                            updatedRow.startDate,
                            updatedRow.endDate
                          ) + 1;

                        updatedRow.numberOfDaysLeft = numberOfDaysLeft(
                          updatedRow.startDate,
                          updatedRow.endDate
                        );

                        updatedRow.numberOfDays =
                          differenceInDays(
                            updatedRow.startDate,
                            updatedRow.endDate
                          ) + 1;
                      } else {
                        updatedRow.numberOfDays = null;
                      }

                      if (updatedRow.budget && updatedRow.numberOfDays) {
                        updatedRow.averageDailyBudget =
                          updatedRow.budget / updatedRow.numberOfDays;
                      } else {
                        updatedRow.averageDailyBudget = null;
                      }
                    } catch (error) {}

                    try {
                      if (
                        updatedRow.budget &&
                        updatedRow.startDate &&
                        updatedRow.endDate
                        // &&
                        // (new Date(updatedRow.startDate)>= new Date(selectedDateStart) && (new Date(updatedRow.endDate)<= new Date(selectedDateStop)))
                      ) {
                        if (
                          new Date(updatedRow.startDate) >=
                            new Date(selectedDateStart) &&
                          new Date(updatedRow.endDate) <=
                            new Date(selectedDateStop)
                        ) {
                          updatedRow.pace = pace(
                            updatedRow,
                            updatedRow.startDate,
                            updatedRow.endDate,
                            updatedRow.budget,
                            updatedRow.cost
                          );

                          updatedRow.calcDailyBudget = calcDailyBudget(
                            updatedRow.budget,
                            updatedRow.cost,
                            updatedRow.numberOfDays,
                            updatedRow.numberOfDaysLeft
                          );
                        } else {
                          updatedRow.pace = updatedRow.budget ? '?' : '';
                          updatedRow.calcDailyBudget = updatedRow.budget
                            ? '?'
                            : '';
                        }
                      } else {
                        updatedRow.pace = null;
                        updatedRow.calcDailyBudget = null;
                      }
                    } catch (error) {
                      console.log('debug67>row2268>error: ', error);
                    }

                    // update warnings.

                    // if startDate/endDate is outside datepicker then rerender.

                    // const updatedListOfData = [...listOfData];
                    updatedListOfData.splice(rowIndex, 1, updatedRow);
                    updateParentChildRows(updatedRow);
                    setListOfData(updatedListOfData);

                    if (['system_admin', 'debug'].includes(user.role)) {
                      console.log(
                        'debug67>RoiTable>row 2279>updatedListOfData: ',
                        updatedListOfData
                      );
                    }
                    break;
                  default:
                    setUpdateAccountId(accountId);
                    // setRerender(rerender + 1);
                    shouldRerender = true;
                    // return '';
                    break;
                }

                break;
              case 'facebookAds':
              case 'googleAds':
              case 'cm360':
              case 'dv360':
              case 'snapchatAds':
              case 'tikTokAds':
              case 'pinterestAds':
              case 'readpeak':
              case 'strossle':
              case 'amazonDsp':
              case 'taboolaAds':
              case 'microsoftAdvertising':
              case 'appleSearchAds':
              case 'adform':
              case 'linkedInAds':
              case 'manualDataEntry':
              case 'customForm':
                switch (updateKey) {
                  case 'members':
                  case 'labels':
                    // setRerender(rerender + 1);
                    shouldRerender = true;

                    break;

                  case 'invoiceStatus':
                  case 'invoiceOrderId':
                  case 'name':

                  case 'buyingTypeDescription':
                  case 'creativeDescription':
                  case 'formatDescription':
                  case 'channelDescription':
                  case 'audienceDataDescription':
                  case 'targetGroupDescription':
                  case 'placementDescription':
                  case 'ganttBarDisplaySetting':
                  case 'generalDescription':
                  case 'goalDescription':

                  case 'showSubTasksOnCard':
                  case 'allowMissingBudget':
                  case 'allowMissingClicks':
                  case 'allowAbnormallyHighCtr':
                  case 'allowMissingImpressionsYesterday':
                  case 'allowImpressionsBeforeStartDate':
                  case 'allowImpressionsAfterEndDate':

                  case 'budgetCurrency':
                  // case 'members':
                  case 'comments':
                  case 'ganttBarColor':
                    // case 'status':
                    // case 'action01':
                    updatedRow = {
                      ...oldRow,
                      [updateKey]: updatedNexusDocument[updateKey]
                    };

                    // const updatedListOfData = [...listOfData];
                    updatedListOfData.splice(rowIndex, 1, updatedRow);

                    // remove warning

                    // supRow.warnings
                    // supRow.subWarnings

                    // warningMessages.push({
                    //   documentId: subRow._id,
                    //   type: 'MISSING_START_OR_END_DATE',
                    //   warningLevel,
                    //   message: (
                    //     <span style={{ color: redWarningColor }}>{warningMessage}</span>
                    //   ),
                    //   rawMessage: warningMessage
                    // });
                    if (
                      [
                        'allowMissingBudget',
                        'allowMissingClicks',
                        'allowAbnormallyHighCtr',
                        'allowMissingImpressionsYesterday',
                        'allowImpressionsBeforeStartDate',
                        'allowImpressionsAfterEndDate'
                      ].includes(updateKey)
                    ) {
                      const warningTypeDict = {
                        allowMissingBudget: 'MISSING_BUDGET',
                        allowMissingClicks: 'MISSING_CLICKS',
                        allowAbnormallyHighCtr: 'ABNORMALLY_HIGH_CTR',
                        allowMissingImpressionsYesterday:
                          'MISSING_IMPRESSIONS_YESTERDAY',
                        allowImpressionsBeforeStartDate:
                          'IMPRESSIONS_BEFORE_START_DATE',
                        allowImpressionsAfterEndDate:
                          'IMPRESSIONS_AFTER_END_DATE'
                      };

                      for (let dataRow of updatedListOfData) {
                        // remove warnings
                        if (dataRow._id === updatedRow._id) {
                          for (let warning of dataRow.warnings || []) {
                            warning.messages =
                              warning?.messages?.filter(
                                (obj) => obj.type !== warningTypeDict[updateKey]
                              ) || [];
                          }
                          dataRow.warnings = dataRow?.warnings?.filter(
                            (warning) => Boolean(warning?.messages?.length)
                          );
                        }
                        // remove subWarnings
                        // for (let warning of dataRow.subWarnings || []) {
                        //   warning.messages =
                        //     warning?.messages?.filter(
                        //       (obj) =>
                        //         (obj.type !== warningTypeDict[updateKey] &&
                        //           obj._id === updatedRow._id) ||
                        //         obj._id !== updatedRow._id
                        //     ) || [];
                        // }
                        // dataRow.subWarnings = dataRow?.subWarnings?.filter(
                        //   (warning) => Boolean(warning?.messages?.length)
                        // );
                      }
                    }

                    // updateParentChildRows(updatedRow);
                    setListOfData(updatedListOfData);
                    break;

                  case 'checkedAt':
                    updatedRow = {
                      ...oldRow,
                      [updateKey]: updatedNexusDocument[updateKey]
                    };

                    updatedRow.daysSinceCheckedAt =
                      updatedRow?.checkedAt &&
                      differenceInDaysFloor(
                        new Date(updatedRow.checkedAt),
                        new Date()
                      );
                    // const updatedListOfData = [...listOfData];
                    updatedListOfData.splice(rowIndex, 1, updatedRow);
                    updateParentChildRows(updatedRow);
                    setListOfData(updatedListOfData);
                    break;

                  case 'status':
                    if (
                      oldRow.status === 'REMOVED' ||
                      updatedNexusDocument.status === 'REMOVED'
                    ) {
                      console.log('status>rerender: ', true);
                      setUpdateAccountId(accountId);
                      // setRerender(rerender + 1);
                      shouldRerender = true;
                      break;
                    }
                    // case 'action01':
                    updatedRow = {
                      ...oldRow,
                      [updateKey]: updatedNexusDocument[updateKey],
                      statusUpdatedAt: updatedNexusDocument.statusUpdatedAt
                    };

                    if (updatedNexusDocument.status === 'ACTIVE') {
                      // console.log('tralalalalala');
                      updatedRow.checkedAt = updatedNexusDocument.checkedAt;

                      updatedRow.daysSinceCheckedAt =
                        updatedRow?.checkedAt &&
                        differenceInDaysFloor(
                          new Date(updatedRow.checkedAt),
                          new Date()
                        );
                    }

                    const status = nexusDocumentStatuses?.filter(
                      (status) => status.string === updatedRow.status
                    )[0];
                    if (status) {
                      updatedRow.statusColor = status?.color;
                      updatedRow.statusName = status?.name;
                    }

                    // const updatedListOfData = [...listOfData];
                    updatedListOfData.splice(rowIndex, 1, updatedRow);
                    setListOfData(updatedListOfData);
                    break;

                  case 'budgetZZZ':
                    updatedRow = {
                      ...oldRow,
                      [updateKey]: updatedNexusDocument[updateKey]
                    };

                    const parentRowIndex = updatedListOfData.findIndex(
                      (row) => {
                        return (
                          row._id ===
                          updatedRow[
                            'level_' +
                              (updatedRow.level - 1).toString() +
                              '_container_id'
                          ]
                        );
                      }
                    );

                    const parentRow = updatedListOfData.filter((row) => {
                      return (
                        row._id ===
                        updatedRow[
                          'level_' +
                            (updatedRow.level - 1).toString() +
                            '_container_id'
                        ]
                      );
                    })[0];

                    const copyOfParentRow = { ...parentRow };
                    copyOfParentRow.budgetSumChild =
                      copyOfParentRow.budgetSumChild -
                      (oldRow.budget || null) +
                      updatedRow.budget;

                    updatedListOfData.splice(
                      parentRowIndex,
                      1,
                      copyOfParentRow
                    );

                    try {
                      if (updatedRow.startDate && updatedRow.endDate) {
                        updatedRow.numberOfDays =
                          differenceInDays(
                            updatedRow.startDate,
                            updatedRow.endDate
                          ) + 1;

                        updatedRow.numberOfDaysLeft = numberOfDaysLeft(
                          updatedRow.startDate,
                          updatedRow.endDate
                        );

                        updatedRow.numberOfDays =
                          differenceInDays(
                            updatedRow.startDate,
                            updatedRow.endDate
                          ) + 1;
                      }

                      if (updatedRow.budget && updatedRow.numberOfDays) {
                        updatedRow.averageDailyBudget =
                          updatedRow.budget / updatedRow.numberOfDays;
                      }
                    } catch (error) {}

                    try {
                      if (
                        updatedRow.budget &&
                        updatedRow.startDate &&
                        updatedRow.endDate &&
                        new Date(updatedRow.startDate) >=
                          new Date(selectedDateStart) &&
                        new Date(updatedRow.endDate) <=
                          new Date(selectedDateStop)
                      ) {
                        updatedRow.pace = pace(
                          updatedRow,
                          updatedRow.startDate,
                          updatedRow.endDate,
                          updatedRow.budget,
                          updatedRow.cost || 0
                        );

                        updatedRow.calcDailyBudget = calcDailyBudget(
                          updatedRow.budget,
                          updatedRow.cost || 0,
                          updatedRow.numberOfDays,
                          updatedRow.numberOfDaysLeft
                        );
                      } else if (
                        updatedRow.budget &&
                        updatedRow.startDate &&
                        updatedRow.endDate
                      ) {
                        updatedRow.pace = updatedRow.budget ? '?' : '';
                        updatedRow.calcDailyBudget = '?';
                      }
                    } catch (error) {}

                    updatedListOfData.splice(rowIndex, 1, updatedRow);
                    updateParentChildRows(updatedRow);
                    setListOfData(updatedListOfData);
                    break;

                  default:
                    setUpdateAccountId(accountId);
                    // setRerender(rerender + 1);
                    shouldRerender = true;
                    // return '';
                    break;
                }

                break;
              default:
                setUpdateAccountId(accountId);
                // setRerender(rerender + 1);
                shouldRerender = true;
              // return '';
            }
          } else {
            // console.log('debug67>row2529');
            setUpdateAccountId(accountId);
            // setRerender(rerender + 1);
            shouldRerender = true;
            // return '';
          }
        }
        // console.log('debug67>row2535> before shouldRerender: ', shouldRerender);
      }
      if (shouldRerender) {
        // console.log('debug67>row2538>shouldRerender: ', shouldRerender);
        setRerender(rerender + 1);
        return '';
      }
    } catch (error) {
      console.log('debug67>row2542>error: ', error);
    }
  };

  // #################################################################
  // #################################################################
  // #################################################################

  const differenceInDays = (start, stop) => {
    var date1 = new Date(start);
    var date2 = new Date(stop);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    return Difference_In_Days;
  };

  const differenceInDaysRound = (start, stop) => {
    // var date1 = new Date(start);
    // var date2 = new Date(stop);
    // var Difference_In_Time = date2.getTime() - date1.getTime();
    // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(start);
    const secondDate = new Date(stop);

    const diffDays = Math.round((secondDate - firstDate) / oneDay);

    return diffDays;
  };

  const differenceInDaysCeil = (start, stop) => {
    // var date1 = new Date(start);
    // var date2 = new Date(stop);
    // var Difference_In_Time = date2.getTime() - date1.getTime();
    // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(start);
    const secondDate = new Date(stop);

    const diffDays = Math.ceil((secondDate - firstDate) / oneDay);

    return diffDays;
  };

  const differenceInDaysFloor = (start, stop) => {
    // var date1 = new Date(start);
    // var date2 = new Date(stop);
    // var Difference_In_Time = date2.getTime() - date1.getTime();
    // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const firstDate = new Date(start);
    const secondDate = new Date(stop);

    const diffDays = Math.floor((secondDate - firstDate) / oneDay);

    return diffDays;
  };

  const pace = (item, start, stop, budget, totalCost) => {
    var date1 = new Date(start);
    var date2 = new Date(stop);
    date2.setHours(23, 59, 59, 0);
    var total_difference_in_time = date2.getTime() - date1.getTime();
    var totalNumberOfDays = total_difference_in_time / (1000 * 3600 * 24);

    var date1_ = new Date(start);
    var date2_ = new Date();

    // if (['cm360', 'dv360'].includes(item.documentType)) {

    //   date2_.setDate(date2_.getDate() - 1);
    // }
    var time_since_start = date2_.getTime() - date1_.getTime();
    var days_since_start = time_since_start / (1000 * 3600 * 24);

    const unitBudget = budget / total_difference_in_time;

    const should_have_spent =
      unitBudget *
      (time_since_start >= total_difference_in_time
        ? total_difference_in_time
        : time_since_start);
    const pace = totalCost / should_have_spent;

    return Math.round(pace * 100);
    // return Math.round((pace * 100 + Number.EPSILON) * 100) / 100;
  };

  const numberOfDaysLeft = (startDate, endDate) => {
    let start = new Date();
    if (new Date(startDate) > start) {
      start = new Date(startDate);
    }
    const numDaysLeft =
      differenceInDays(formatDateYYYYMMDD(start), endDate) + 1;
    return numDaysLeft > 0 ? numDaysLeft : 0;
  };

  const calcDailyBudget = (
    budget,
    totalCost,
    // numberOfDays,
    numberOfDaysLeft
  ) => {
    if (!budget || budget <= 0) {
      return '';
    }

    const cost = totalCost ? totalCost : null;
    // const daysLeft =
    //   numberOfDaysLeft > numberOfDays ? numberOfDays : numberOfDaysLeft;

    if (numberOfDaysLeft <= 0) {
      return '';
    }
    const dailyBudgetLeft = (budget - cost) / numberOfDaysLeft;

    return dailyBudgetLeft;
  };

  const rowCalculations = (supRow, subRow) => {
    const status = nexusDocumentStatuses?.filter(
      (status) => status.string === subRow.status
    )[0];
    if (status) {
      subRow.statusColor = status?.color;
      subRow.statusName = status?.name;
    }

    // console.log('subRow.statusColor: ', subRow.statusColor);
    // subRow.associatedDocumentTypes = [
    //   ...(subRow?.associatedDocumentTypes || []),
    //   subRow.documentType
    // ];
    // supRow.associatedDocumentTypes = [
    //   ...(supRow?.associatedDocumentTypes || []),
    //   ...(subRow?.associatedDocumentTypes || [])
    // ];
    // ################### calculate hours today #################

    let hoursToday = new Date().getHours();
    try {
      const d = new Date();
      const dArray = d
        .toLocaleString('ja', {
          timeZone: subRow?.accountTimeZone || 'Europe/Stockholm'
          // 'Europe/Stockholm'
        })
        .split(/[/\s:]/);
      if (dArray[3]) {
        hoursToday = Number(dArray[3]);
      }
    } catch (error) {}
    const shareOfTodaysHours = hoursToday / 24;

    // ############### end of calculate hours today ##############

    const warningMessages = [];

    let highestWarningLevel = 0;

    let warningLevel = 0;
    const fieldsToBeManuallyCalculated = [];
    const fieldNotToBeManuallyCalculated = [];

    const compiledExpressions = {};

    for (let field of allEvalFieldsExceptTotal) {
      // if (subRow[field + 'Formula']) {

      let expression = '0';

      const expressionField = subRow[field + 'Formula'] || '';

      if (expressionField !== '=' + field && expressionField[0] === '=') {
        try {
          compiledExpressions[field] = compile(expressionField.substring(1));
        } catch (error) {}
      } else {
        compiledExpressions[field] = false;
      }
    }
    // console.log('compiledExpressions3423: ', compiledExpressions);

    if (!subRow || (!supRow && subRow.type !== 'account')) {
      return;
    } else {
    }

    // add info for parent gantt bars

    if (subRow.ganttBarDisplaySetting === 'CHILDREN') {
      supRow.childRows = [...supRow.childRows, ...subRow.childRows];
    } else {
      const cleanedSubRow = {};
      for (let key in subRow) {
        if (
          ![
            'action01googleanalytics4data',
            'action02googleanalytics4data',
            'action03googleanalytics4data',
            'totalAction01googleanalytics4data',
            'totalAction02googleanalytics4data',
            'totalAction03googleanalytics4data',
            'facebookadsadsets',
            'snapchatadscampaigndata',
            'tiktokadscampaigndata',
            'pinterestadscampaigndata',
            'readpeakadreportbasicdata',
            'strossleadreportbasicdata',
            'amazondspadreportdata',
            'taboolaadscampaigndata',
            'microsoftadvertisingbasicdata',
            'applesearchadsdata',
            'googleadsadgroups',
            'googleadsadgroupconversions',
            'cm360basic',
            'cm360conversions',
            'dv360basic',
            'dv360conversions',
            'dv360lineiteminfo',
            'snapchatadsadsquadinfo',
            'tiktokadsadgroupinfo',
            'pinterestadsadgroupinfo',
            'readpeakadgroupinfo',
            'strossleadsetinfo',
            'amazondsplineiteminfo',
            'adformbasicdata',
            'adformconversiondata',
            'adformlineiteminfo',

            'linkedinadsbasicdata',
            'linkedinadsconversiondata',
            'linkedinadscampaigninfo',
            'nexusDocuments',
            'childRows',
            'total_list_of_propagation_data',
            'total_list_of_propagation_data_row'
          ].includes(key) &&
          !key.includes('Sparkline')
        ) {
          cleanedSubRow[key] = subRow[key];
        }
      }
      supRow.childRows.push(cleanedSubRow);
    }

    supRow.childDocumentIds = [
      ...supRow.childDocumentIds,
      subRow._id,
      ...subRow.childDocumentIds
    ];

    const snackbarWarnings = [];

    // ################################# handel total data ##################################################################

    // // ################################# manual data entry ##################################################################

    // ######################################## handle GA4 actions #################################################################

    const googleAnalytics4PropagationData = [];

    if (
      subRow?.action01?.dataSource === 'googleAnalytics4' &&
      subRow.action01googleanalytics4data
    ) {
      for (let ga4Object of subRow.action01googleanalytics4data) {
        googleAnalytics4PropagationData.push({
          eventName: ga4Object.eventName,
          source: ga4Object.source,
          date_start: ga4Object.date,
          action01Count: ga4Object.eventCount,
          action01Value: ga4Object.eventValue
        });
      }
    }

    if (
      subRow?.action02?.dataSource === 'googleAnalytics4' &&
      subRow.action02googleanalytics4data
    ) {
      for (let ga4Object of subRow.action02googleanalytics4data) {
        googleAnalytics4PropagationData.push({
          eventName: ga4Object.eventName,
          source: ga4Object.source,
          date_start: ga4Object.date,
          action02Count: ga4Object.eventCount,
          action02Value: ga4Object.eventValue
        });
      }
    }

    if (
      subRow?.action03?.dataSource === 'googleAnalytics4' &&
      subRow.action03googleanalytics4data
    ) {
      for (let ga4Object of subRow.action03googleanalytics4data) {
        googleAnalytics4PropagationData.push({
          eventName: ga4Object.eventName,
          source: ga4Object.source,
          date_start: ga4Object.date,
          action03Count: ga4Object.eventCount,
          action03Value: ga4Object.eventValue
        });
      }
    }

    if (
      ![
        'account',
        'folder',
        'facebook_ads_ad_set',
        'google_ads_ad_group',
        'tiktok_ads_ad_group',
        'pinterest_ads_ad_group',
        'readpeak_ad_group',
        'strossle_ad_set',
        'amazon_dsp_line_item',
        'snapchat_ads_ad_squad',
        'dv360_line_item',
        'adform_line_item',
        'linkedin_ads_campaign'
      ].includes(subRow.type)
    ) {
      subRow.total_list_of_propagation_data = [
        ...subRow.total_list_of_propagation_data,
        ...googleAnalytics4PropagationData
      ];
    }

    // #################################################### testcalc #########################################################################

    for (let formulaField of allEvalFields) {
      // let regExpression =
      //   '([0-9]+)[+]{1}(.+)([a-zA-Z]+[0-9]*[a-zA-Z]*)|([a-zA-Z]+[0-9]*[a-zA-Z]*)[+]{1}(.+)([0-9]+)|([0-9]+)[-]{1}(.+)([a-zA-Z]+[0-9]*[a-zA-Z]*)|([a-zA-Z]+[0-9]*[a-zA-Z]*)[-]{1}(.+)([0-9]+)|^[=]*[0-9]+$';

      // let regExpression =
      //   '([0-9]+)[+]{1}(.+)([a-zA-Z]+[0-9]*[a-zA-Z]*)|([a-zA-Z]+[0-9]*[a-zA-Z]*)[+]{1}(.+)([0-9]+)|([0-9]+)[-]{1}(.+)([a-zA-Z]+[0-9]*[a-zA-Z]*)|([a-zA-Z]+[0-9]*[a-zA-Z]*)[-]{1}(.+)([0-9]+)|^[=]*[0-9/*+-.()]+$';

      let regExpression =
        '([0-9()]+)[+]{1}(.+)([a-zA-Z()]+[0-9()]*[a-zA-Z()]*)|([a-zA-Z()]+[0-9()]*[a-zA-Z()]*)[+]{1}(.+)([0-9()]+)|([0-9()]+)[-]{1}(.+)([a-zA-Z()]+[0-9()]*[a-zA-Z()]*)|([a-zA-Z()]+[0-9]*[a-zA-Z()]*)[-]{1}(.+)([0-9()]+)|^[=]*[0-9/*+-.()]+$';

      // let regExpression =
      // '([0-9]+)[+]{1}(.+)([a-zA-Z]+[0-9]*[a-zA-Z]*)|([a-zA-Z]+[0-9]*[a-zA-Z]*)[+]{1}(.+)([0-9]+)|([0-9]+)[-]{1}(.+)([a-zA-Z]+[0-9]*[a-zA-Z]*)|([a-zA-Z]+[0-9]*[a-zA-Z]*)[-]{1}(.+)([0-9]+)|^[^a-z]+$|^[=]*[0-9/*+-.]+$';

      // '([0-9]+)[+]{1}(.+)([a-zA-Z]+[0-9]*[a-zA-Z]*)|([a-zA-Z]+[0-9]*[a-zA-Z]*)[+]{1}(.+)([0-9]+)|([0-9]+)[-]{1}(.+)([a-zA-Z]+[0-9]*[a-zA-Z]*)|([a-zA-Z]+[0-9]*[a-zA-Z]*)[-]{1}(.+)([0-9]+)|^[=]*[0-9]+$';

      const regExName = new RegExp(regExpression);
      const regExNameResult = regExName.exec(subRow[formulaField + 'Formula']);

      if (regExNameResult) {
        fieldsToBeManuallyCalculated.push(formulaField);
      } else {
        fieldNotToBeManuallyCalculated.push(formulaField);
      }
    }

    const dataObject = {};
    for (let field of allEvalFields) {
      dataObject[field] = null;
    }

    const dailyDataObject = {};
    for (let field of allEvalFields) {
      dailyDataObject[field] = null;
    }

    for (let object of subRow.total_list_of_propagation_data) {
      // console.log('object: ', object);

      for (let field of allEvalFields) {
        dailyDataObject[field] =
          dailyDataObject[field] + (object[field] || null);
      }
    }

    for (let field of allEvalFieldsExceptTotal) {
      dataObject[field] = dataObject[field] + (dailyDataObject[field] || null);
    }

    let dStartDStopUpUntillTodayRate = 0;
    let dStart = null;
    let dStop = null;
    let dStopUpUntillToday = null;

    // const shareOfTodaysHours = new Date().getHours() / 24;
    if (subRow.startDate && subRow.endDate) {
      subRow.numberOfDays =
        differenceInDays(subRow.startDate, subRow.endDate) + 1;

      subRow.numberOfDaysLeft = numberOfDaysLeft(
        subRow.startDate,
        subRow.endDate
      );

      subRow.daysLeft =
        new Date(subRow.startDate) < today &&
        new Date(subRow.endDate) > addDays(new Date(), -1)
          ? subRow.numberOfDaysLeft
          : null;

      if (fieldsToBeManuallyCalculated.length > 0) {
        dStart =
          new Date(subRow.startDate) >= new Date(selectedDateStart)
            ? subRow.startDate
            : selectedDateStart;
        dStop =
          new Date(subRow.endDate) <= new Date(selectedDateStop)
            ? subRow.endDate
            : selectedDateStop;

        dStopUpUntillToday = new Date(dStop) > new Date() ? new Date() : dStop;

        const dStartDStopUpUntillTodayRange = getDates(
          dStart,
          dStopUpUntillToday
        );

        // const dStartDStopRange = getDates(dStart, dStop);

        // let dStartDStopRate = differenceInDays(dStart, dStop) + 1;

        for (let date of dStartDStopUpUntillTodayRange) {
          if (
            new Date(date).toISOString().split('T')[0] ===
            new Date().toISOString().split('T')[0]
          ) {
            // const shareOfTodaysHours = new Date().getHours() / 24;
            dStartDStopUpUntillTodayRate =
              dStartDStopUpUntillTodayRate + shareOfTodaysHours;
          } else {
            dStartDStopUpUntillTodayRate = dStartDStopUpUntillTodayRate + 1;
          }
        }

        subRow.campaign_days_within_date_selected =
          differenceInDays(dStart, dStop) + 1;
      }
    }

    const generateScope = (formulaField) => {
      const newObj = {};
      for (let field of allEvalFieldsExceptTotal) {
        newObj[field] = dataObject[field] || 0;
      }

      for (let field of fieldsToBeManuallyCalculated) {
        if (!plannedEvalFields.includes(field)) {
          newObj[field] =
            ((dataObject[field] || 0) * dStartDStopUpUntillTodayRate) /
            subRow.numberOfDays;
        } else {
          // do something here
          newObj[field] =
            ((dataObject[field] || 0) *
              subRow.campaign_days_within_date_selected) /
            subRow.numberOfDays;
        }
      }
      switch (formulaField) {
        default:
          return newObj;
      }
    };

    const fieldsNotDoneCalculating = [...allEvalFieldsExceptTotal];

    const calcWarnings2 = { stackOverFlow: false, formulaErrors: [] };

    const calculatedFields = dataObject;

    calcFields(
      compiledExpressions,
      allEvalFieldsExceptTotal,
      calculatedFields,
      fieldsNotDoneCalculating,
      generateScope,
      subRow,
      [],
      calcWarnings2
    );

    for (let field of fieldNotToBeManuallyCalculated) {
      subRow[field] = calculatedFields[field];
      // supRow[field+'Raw'] = supRow[field] + subRow[field];
    }

    if (
      subRow.startDate &&
      subRow.endDate &&
      fieldsToBeManuallyCalculated.length > 0
    ) {
      for (let fieldToBeManuallyCalculated of fieldsToBeManuallyCalculated) {
        subRow[fieldToBeManuallyCalculated + 'Raw'] =
          calculatedFields[fieldToBeManuallyCalculated];

        let rate = 0;

        if (plannedEvalFields.includes(fieldToBeManuallyCalculated)) {
          rate = subRow.campaign_days_within_date_selected; //dStartDStopRate;
        } else {
          rate = dStartDStopUpUntillTodayRate;
        }
        subRow[fieldToBeManuallyCalculated] =
          (calculatedFields[fieldToBeManuallyCalculated] /
            subRow.numberOfDays) *
          rate;
        // supRow[fieldToBeManuallyCalculated] =
        //   (calculatedFields[fieldToBeManuallyCalculated] /
        //     subRow.numberOfDays) *
        //   rate;
      }

      // ################################# manual data entry ##################################################################

      // if (subRow.startDate && subRow.endDate) {

      const dataPerDay = {};

      for (let field of fieldsToBeManuallyCalculated) {
        dataPerDay[field] = subRow[field]
          ? subRow[field + 'Raw'] / subRow.numberOfDays
          : null;
      }

      const tempDateRange = getDates(dStart, dStop);
      const tempDateRangeUpUntillToday = getDates(dStart, dStopUpUntillToday);
      const dailyData = [];

      // up untill today

      const aggregatedObjectBefore = {
        cost: null,
        impressions: null,
        date_start: startDateForLastDaysDataMinusOneDay.getTime()
      };
      let pushAggregatedObjectBefore = false;

      const aggregatedObjectAfter = {
        cost: null,
        impressions: null,
        date_start: dayAfterTomorrow.getTime()
      };
      let pushAggregatedObjectAfter = false;

      let pushAggregatedObject = false;
      for (let field of allEvalFieldsExceptTotal) {
        aggregatedObjectBefore[field] = null;
        aggregatedObjectAfter[field] = null;
      }
      for (let date of tempDateRangeUpUntillToday) {
        if (new Date(date) < new Date(startDateForLastDaysData)) {
          pushAggregatedObjectBefore = true;

          // aggregatedObjectBefore.date_start = date.getTime();

          for (let field of fieldsToBeManuallyCalculated) {
            if ([...sumEvalFields, ...nonSumEvalFields].includes(field)) {
              aggregatedObjectBefore[field] =
                aggregatedObjectBefore[field] + dataPerDay[field];
            }
          }
        } else if (new Date(date) > new Date(tomorrow)) {
          pushAggregatedObjectAfter = true;

          // aggregatedObjectAfter.date_start = date.getTime();

          for (let field of fieldsToBeManuallyCalculated) {
            if ([...sumEvalFields, ...nonSumEvalFields].includes(field)) {
              aggregatedObjectAfter[field] =
                aggregatedObjectAfter[field] + dataPerDay[field];
            }
          }
        } else {
          // // const objToPush = {};
          // // for (let field of fieldsToBeManuallyCalculated) {
          // //   if ([...sumEvalFields, ...nonSumEvalFields].includes(field)) {
          // //     aggregatedObject[field] =
          // //       aggregatedObject[field] + dataPerDay[field];
          // //   }
          // // }
          // const dailyObectToPush = { date_start: date.getTime() };
          // for (let field of fieldsToBeManuallyCalculated) {
          //   if ([...sumEvalFields, ...nonSumEvalFields].includes(field)) {
          //     dailyObectToPush[field] = dataPerDay[field];
          //   }
          // }

          // dailyData.push(dailyObectToPush);

          const dailyObject = {
            date_start: date.getTime()
          };

          for (let field of allEvalFieldsExceptTotal) {
            dailyObject[field] = null;
          }

          if (
            new Date(date).toISOString().split('T')[0] ===
            new Date().toISOString().split('T')[0]
          ) {
            // const shareOfTodaysHours = new Date().getHours() / 24;

            for (let field of fieldsToBeManuallyCalculated) {
              if ([...sumEvalFields, ...nonSumEvalFields].includes(field)) {
                dailyObject[field] = dataPerDay[field] * shareOfTodaysHours;
              }
            }
          } else {
            for (let field of fieldsToBeManuallyCalculated) {
              if ([...sumEvalFields, ...nonSumEvalFields].includes(field)) {
                dailyObject[field] = dataPerDay[field];
              }
            }
          }

          dailyData.push(dailyObject);
        }

        // dailyData.push(dailyObject);
      }

      if (pushAggregatedObjectBefore) {
        dailyData.unshift(aggregatedObjectBefore);
      }
      if (pushAggregatedObjectAfter) {
        dailyData.push(aggregatedObjectAfter);
      }

      // all days (planned and budget)

      const aggregatedObject2Before = {
        cost: null,
        impressions: null,
        date_start: startDateForLastDaysDataMinusOneDay.getTime()
      };
      let pushAggregatedObject2Before = false;
      const aggregatedObject2After = {
        cost: null,
        impressions: null,
        date_start: dayAfterTomorrow.getTime()
      };
      let pushAggregatedObject2After = false;

      for (let field of allEvalFieldsExceptTotal) {
        aggregatedObject2Before[field] = null;
        aggregatedObject2After[field] = null;
      }

      for (let date of tempDateRange) {
        if (new Date(date) < new Date(startDateForLastDaysData)) {
          pushAggregatedObject2Before = true;
          // aggregatedObject2Before.date_start = date.getTime();

          // for (let field of allEvalFieldsExceptTotal) {
          //   aggregatedObject2[field] = aggregatedObject2[field] + null;
          // }

          for (let field of fieldsToBeManuallyCalculated) {
            if (plannedEvalFields.includes(field)) {
              aggregatedObject2Before[field] =
                aggregatedObject2Before[field] + dataPerDay[field];
            }
          }
        } else if (new Date(date) > new Date(tomorrow)) {
          pushAggregatedObject2After = true;
          // aggregatedObject2After.date_start = date.getTime();

          // for (let field of allEvalFieldsExceptTotal) {
          //   aggregatedObject2[field] = aggregatedObject2[field] + null;
          // }

          for (let field of fieldsToBeManuallyCalculated) {
            if (plannedEvalFields.includes(field)) {
              aggregatedObject2After[field] =
                aggregatedObject2After[field] + dataPerDay[field];
            }
          }
        } else {
          const dailyObjToPush = { date_start: date.getTime() };
          for (let field of fieldsToBeManuallyCalculated) {
            if (plannedEvalFields.includes(field)) {
              dailyObjToPush[field] = dataPerDay[field];
            }
          }
          dailyData.push(dailyObjToPush);
        }

        // dailyData.push(dailyObject);
      }
      if (pushAggregatedObject2Before) {
        dailyData.unshift(aggregatedObject2Before);
      }

      if (pushAggregatedObject2After) {
        dailyData.push(aggregatedObject2After);
      }
      // dailyData.push(aggregatedObject2);

      // subRow.total_list_of_propagation_data = dailyData;
      // subRow.total_list_of_propagation_data_row = dailyData;
      subRow.total_list_of_propagation_data = [
        ...(subRow.total_list_of_propagation_data || []).map((row) => {
          const newRow = { ...row };
          for (let field of fieldsToBeManuallyCalculated) {
            newRow[field] = null;
          }
          return newRow;
        }),
        ...dailyData
      ];
      subRow.total_list_of_propagation_data_row = [
        ...subRow.total_list_of_propagation_data_row,
        ...dailyData
      ];
    } else if (fieldsToBeManuallyCalculated.length > 0 && !subRow.template) {
      let warningMessage = 'WARNING MISSING START DATE OR END DATE!';
      warningLevel = 5;
      highestWarningLevel =
        highestWarningLevel < warningLevel ? warningLevel : highestWarningLevel;

      highestWarningLevel =
        highestWarningLevel < warningLevel ? warningLevel : highestWarningLevel;

      // warningMessage = `WARNING CIRCULAR DEPENDENCIES! DO NOT TRUST THE DATA!`;

      warningMessages.push({
        relatedFields: ['startDate', 'endDate'],
        documentId: subRow._id,
        type: 'MISSING_START_OR_END_DATE',
        warningLevel,
        warningColor: redWarningColor,
        message: (
          <span style={{ color: redWarningColor }}>{warningMessage}</span>
        ),
        rawMessage: warningMessage
      });

      snackbarWarnings.push({
        message: `${warningMessage} --> ${subRow.path}`,
        variant: 'error',
        warningLevel
      });
    }

    // ############################ handle metrics that are not TOTAL ##############################################################

    let propDataToCalculate_ = [];

    let totalDataArray = [];

    // propDataToCalculate = [...subRow.total_list_of_propagation_data];
    propDataToCalculate_ = subRow.total_list_of_propagation_data.map((obj) => ({
      ...obj
    }));

    let propDataToCalculate = [];
    let datesInPropData = propDataToCalculate_.map((obj) =>
      parseInt(obj.date_start)
    );
    let uniqueDatesInPropData = datesInPropData.filter(
      (item, i, ar) => ar.indexOf(item) === i
    );
    uniqueDatesInPropData.sort();
    for (let date of uniqueDatesInPropData) {
      const newObj = { date_start: date };
      for (let obj of propDataToCalculate_) {
        // console.log('debug94>date: ', date);
        // console.log('debug94>obj.date_start: ', obj.date_start);
        if (parseInt(obj.date_start) === date) {
          // console.log(
          //   'debug94>obj.date_start === date: ',
          //   obj.date_start === date
          // );
          for (let key of allEvalFields) {
            newObj[key] = (newObj[key] || 0) + (obj[key] || 0);
          }
        }
      }
      propDataToCalculate.push(newObj);
    }

    // ###############################################################################################################

    const generateScope1 = (formulaField, obj) => {
      const newObj = {};
      for (let field of allEvalFieldsExceptTotal) {
        newObj[field] = obj[field] || 0;
      }
      switch (formulaField) {
        default:
          // return {};
          return newObj;
      }
    };

    const calculatedPropList = [];
    const calcWarnings = { stackOverFlow: false, formulaErrors: [] };

    const fieldsNotDoneCalculating3 = [...allEvalFieldsExceptTotal];
    calcFields2(
      propDataToCalculate,
      calculatedPropList,
      compiledExpressions,
      allEvalFieldsExceptTotal,
      calculatedFields,
      fieldsNotDoneCalculating3,
      generateScope1,
      subRow,
      fieldsToBeManuallyCalculated,
      calcWarnings
    );

    if (calcWarnings.stackOverFlow || calcWarnings2.stackOverFlow) {
      let warningMessage =
        'WARNING CIRCULAR DEPENDENCIES! DO NOT TRUST THE DATA!';
      warningLevel = 5;
      highestWarningLevel =
        highestWarningLevel < warningLevel ? warningLevel : highestWarningLevel;

      highestWarningLevel =
        highestWarningLevel < warningLevel ? warningLevel : highestWarningLevel;

      warningMessage = `WARNING CIRCULAR DEPENDENCIES! DO NOT TRUST THE DATA!`;

      warningMessages.push({
        relatedFields: [],
        documentId: subRow._id,
        type: 'CIRCULAR_DEPENDENCIES',
        warningLevel,
        warningColor: redWarningColor,
        message: (
          <span style={{ color: redWarningColor }}>{warningMessage}</span>
        ),
        rawMessage: warningMessage
      });

      snackbarWarnings.push({
        message: `${warningMessage} --> ${subRow.path}`,
        variant: 'error',
        warningLevel
      });
    }

    if (calcWarnings2?.formulaErrors?.length > 0) {
      let warningMessage = 'Formula error:';
      for (let errorField of calcWarnings2?.formulaErrors || []) {
        warningMessage = warningMessage + ' ' + errorField;
      }
      warningLevel = 5;
      highestWarningLevel =
        highestWarningLevel < warningLevel ? warningLevel : highestWarningLevel;

      highestWarningLevel =
        highestWarningLevel < warningLevel ? warningLevel : highestWarningLevel;

      warningMessages.push({
        relatedFields: [],
        documentId: subRow._id,
        type: 'FORMULA_ERROR',
        warningLevel,
        warningColor: redWarningColor,
        message: (
          <span style={{ color: redWarningColor }}>{warningMessage}</span>
        ),
        rawMessage: warningMessage
      });

      snackbarWarnings.push({
        message: `${warningMessage} --> ${subRow.path}`,
        variant: 'error',
        warningLevel
      });
    }

    // ###############################################################################################################
    const aggregatedDailyDataToPropagate = [];

    const beginningOfToday = new Date();
    beginningOfToday.setHours(0, 0, 0, 0);

    const todayAsString = new Date().toISOString().split('T')[0];

    // shareOfTodaysHours

    let pushDailyDataObjectBeforeGraph = false;
    const dailyDataObjectBeforeGraph = {
      date_start: startDateForLastDaysDataMinusOneDay.getTime(),
      AAAgraphType: 'dailyDataObjectBeforeGraph'
      // date_start: Math.floor(new Date(date).getTime())
    };
    let pushDailyDataObjectAfterGraph = false;
    const dailyDataObjectAfterGraph = {
      date_start: dayAfterTomorrow.getTime(),
      AAAgraphType: 'dailyDataObjectAfterGraph'
      // date_start: Math.floor(new Date(date).getTime())
    };

    for (let field of allEvalFields) {
      dailyDataObjectBeforeGraph[field] = null;
      dailyDataObjectAfterGraph[field] = null;
    }
    const propDataDataForTheLastDays = [];

    for (let object of propDataToCalculate) {
      if (new Date(+object.date_start) < new Date(startDateForLastDaysData)) {
        pushDailyDataObjectBeforeGraph = true;

        // dailyDataObjectBeforeGraph.date_start = object.date_start;
        for (let field of allEvalFieldsExceptTotal) {
          dailyDataObjectBeforeGraph[field] =
            dailyDataObjectBeforeGraph[field] + (object[field] || null);
        }
      } else if (new Date(+object.date_start) > new Date(tomorrow)) {
        pushDailyDataObjectAfterGraph = true;
        // dailyDataObjectAfterGraph.date_start = object.date_start;
        for (let field of allEvalFieldsExceptTotal) {
          dailyDataObjectAfterGraph[field] =
            dailyDataObjectAfterGraph[field] + (object[field] || null);
        }
      } else {
        propDataDataForTheLastDays.push(object);
        aggregatedDailyDataToPropagate.push(object);
      }

      // if (date < beginningOfToday) {
      // subRow.budgetUntillNow = subRow.budgetUntillNow + object.budget;
      // } else if (todayAsString === dateAsString) {
      // subRow.budgetUntillNow =
      //   subRow.budgetUntillNow + object.budget * shareOfTodaysHours;
      // }
    }
    // const startDateForLastDaysDataMinus3Days = new Date(
    //   startDateForLastDaysData
    // );
    // startDateForLastDaysDataMinus3Days.setDate(
    //   new Date(startDateForLastDaysDataMinus3Days).getDate() - 3
    // );
    // const tomorrowPlus3Days = new Date(tomorrow);
    // tomorrowPlus3Days.setDate(new Date(tomorrowPlus3Days).getDate() + 3);
    for (let date of range2) {
      if (
        new Date(date) < startDateForLastDaysDataMinus3Days ||
        new Date(date) > tomorrowPlus3Days
      ) {
        continue;
      }

      const dateAsString = date;
      const dailyDataObject = {
        date_start: Math.floor(new Date(date).getTime())
      };

      for (let field of allEvalFields) {
        dailyDataObject[field] = null;
      }

      const propDataForTheLastDaysPlus = [...propDataDataForTheLastDays];
      if (pushDailyDataObjectBeforeGraph) {
        propDataForTheLastDaysPlus.unshift(dailyDataObjectBeforeGraph);
      }
      if (pushDailyDataObjectAfterGraph) {
        propDataForTheLastDaysPlus.push(dailyDataObjectAfterGraph);
      }

      for (let object of propDataForTheLastDaysPlus) {
        if (
          new Date(parseInt(object.date_start)).toISOString().split('T')[0] ===
          dateAsString
        ) {
          for (let field of allEvalFieldsExceptTotal) {
            dailyDataObject[field] =
              dailyDataObject[field] + (object[field] || null);
          }

          if (new Date(date) < beginningOfToday) {
            subRow.budgetUntillNow =
              (subRow.budgetUntillNow || 0) + (object.budget || 0);
          } else if (todayAsString === dateAsString) {
            subRow.budgetUntillNow =
              (subRow.budgetUntillNow || 0) +
              (object.budget || 0) * shareOfTodaysHours;
          }
        }
      }

      if (
        new Date(date) < new Date(startDateForLastDaysData) ||
        new Date(date) > new Date(tomorrow)
      ) {
        continue;
      }
      if (formatDateYYYYMMDD(date) > formatDateYYYYMMDD(today)) {
        continue;
      }
      for (let field of allEvalFieldsExceptTotal) {
        subRow[field + 'Sparkline'].push(dailyDataObject[field] || 0);
      }
    }

    // if (subRow.startDate && subRow.endDate && subRow.budget) {
    //   let startDate_ =
    //     new Date(subRow.startDate) > new Date(selectedDateStart)
    //       ? new Date(subRow.startDate)
    //       : new Date(selectedDateStart);

    //   let endDate_ =
    //     new Date(subRow.endDate) < new Date(selectedDateStop)
    //       ? new Date(subRow.endDate)
    //       : new Date(selectedDateStop);

    //   let endDateTillNow_ =
    //     new Date(endDate_) > new Date()
    //       ? new Date()
    //       : new Date(endDate_).setDate(endDate_.getDate() + 1);

    //   const hoursBetweenStartDateAndEndDate =
    //     Math.abs(endDate_ - startDate_) / 36e5 + 24;

    //   const hoursTillNow = Math.abs(endDateTillNow_ - startDate_) / 36e5;

    //   subRow.budgetUntillNow =
    //     (subRow.budget / hoursBetweenStartDateAndEndDate) * hoursTillNow;

    // }

    if (pushDailyDataObjectBeforeGraph) {
      aggregatedDailyDataToPropagate.unshift(dailyDataObjectBeforeGraph);
    }

    if (pushDailyDataObjectAfterGraph) {
      aggregatedDailyDataToPropagate.push(dailyDataObjectAfterGraph);
    }

    subRow.total_list_of_propagation_data = aggregatedDailyDataToPropagate;

    // const beginningOfToday = new Date();
    // beginningOfToday.setHours(0, 0, 0, 0);

    // const todayAsString = new Date().toISOString().split('T')[0];

    if (!subRow.template) {
      supRow.total_list_of_propagation_data = [
        ...supRow.total_list_of_propagation_data,
        // ...aggregatedDailyDataToPropagate
        ...subRow.total_list_of_propagation_data

        // ...googleAnalytics4PropagationData
      ];
    }
    const yesterday = new Date();

    yesterday.setDate(yesterday.getDate() - 1);
    for (let object of subRow.total_list_of_propagation_data) {
      if (
        object.date_start &&
        new Date(+object.date_start).toISOString().split('T')[0] ===
          formatDateYYYYMMDD(yesterday)
      ) {
        subRow.costYesterday =
          (subRow.costYesterday || null) + (object.cost || null);
        subRow.impressionsYesterday =
          (subRow.impressionsYesterday || null) + (object.impressions || null);

        subRow.budgetYesterday =
          (subRow.budgetYesterday || null) + (object.budget || null);
      }
      if (
        object.date_start &&
        new Date(+object.date_start).toISOString().split('T')[0] ===
          formatDateYYYYMMDD(new Date())
      ) {
        subRow.costToday = (subRow.costToday || null) + (object.cost || null);
        subRow.impressionsToday =
          (subRow.impressionsToday || null) + (object.impressions || null);

        subRow.budgetToday =
          (subRow.budgetToday || null) + (object.budget || null);
      }
    }

    // #############################################################################################################################

    // if (subRow.budget && subRow.numberOfDays) {
    //   subRow.averageDailyBudget = subRow.budget / subRow.numberOfDays;
    // }

    if (!subRow.template) {
      for (let itemKey of allEvalFieldsExceptTotal) {
        supRow[itemKey + 'SumChild'] =
          supRow[itemKey + 'SumChild'] +
          (subRow[itemKey] && typeof subRow[itemKey] === 'number'
            ? subRow[itemKey]
            : null);

        // supRow[itemKey] = supRow[itemKey + 'SumChild'];
      }
    }

    if (!subRow.isSetChild) {
      if (
        // new Date(subRow.startDate) >= new Date(selectedDateStart) &&
        // new Date(subRow.endDate) <= new Date(selectedDateStop) &&
        ![
          'account',
          'globalTasks',
          'task',
          'subTask',
          'taxonomy',
          'facebook_ads_ad_set',
          'google_ads_ad_group',
          'tiktok_ads_ad_group',
          'pinterest_ads_ad_group',
          'readpeak_ad_group',
          'strossle_ad_set',
          'amazon_dsp_line_item',
          'snapchat_ads_ad_squad',
          'dv360_line_item',
          'adform_line_item',
          'linkedin_ads_campaign'
        ].includes(subRow.type)
      ) {
        subRow.daysSinceStatusUpdate =
          subRow?.statusUpdatedAt &&
          differenceInDaysFloor(new Date(+subRow.statusUpdatedAt), new Date());

        subRow.daysSinceCheckedAt =
          subRow?.checkedAt &&
          differenceInDaysFloor(new Date(+subRow.checkedAt), new Date());

        subRow.linearPace =
          subRow.budget &&
          subRow.cost &&
          subRow.startDate &&
          subRow.endDate &&
          pace(
            subRow,
            subRow.startDate,
            subRow.endDate,
            subRow.budget,
            subRow.cost
          );

        if (
          subRow.budgetUntillNow &&
          // subRow.cost &&
          !subRow.isSetChild &&
          (!subRow.startDate ||
            !subRow.endDate ||
            ((subRow.startDate
              ? new Date(subRow.startDate) < new Date()
              : true) &&
              (subRow.endDate
                ? new Date(subRow.endDate) > new Date(yesterday)
                : true)))
        ) {
          subRow.pace =
            // subRow.budgetUntillNow &&
            // subRow.cost &&
            Math.round((subRow.cost / subRow.budgetUntillNow) * 100);
        } else {
          subRow.pace = null;
        }

        subRow.daysSinceStartDate = subRow.startDate
          ? differenceInDays(subRow.startDate, new Date())
          : 0;

        subRow.daysSinceEndDate = subRow.endDate
          ? differenceInDays(subRow.endDate, new Date())
          : 0;

        if (
          new Date(subRow.startDate) >= new Date(selectedDateStart) &&
          new Date(subRow.endDate) <= new Date(selectedDateStop)
        ) {
          subRow.calcDailyBudget =
            subRow.budget &&
            // subRow.cost &&
            calcDailyBudget(
              subRow.budget,
              subRow.cost - (subRow.costToday || 0),
              // subRow.numberOfDays,
              subRow.numberOfDaysLeft
            );

          subRow.numberOfDaysLeftTillEndDateYesterday =
            differenceInDaysRound(startOfToday, subRow.endDate) + 2;

          // const numberOfDaysLeftYesterday =
          //   numberOfDaysLeft(subRow.startDate, subRow.endDate) + 2;

          const numberOfDaysLeftYesterday =
            differenceInDaysRound(startOfToday, subRow.endDate) + 2;

          subRow.numberOfDaysLeftYesterday =
            numberOfDaysLeftYesterday > 0 ? numberOfDaysLeftYesterday : 0;

          // subRow.calcDailyBudgetYesterday = calcDailyBudget(
          //   subRow.budget,
          //   (subRow.cost || 0) -
          //     (subRow.costToday || 0) -
          //     (subRow.costYesterday || 0),
          //   // updatedRow.numberOfDays,
          //   // subRow.numberOfDaysLeft + 1
          //   subRow.numberOfDaysLeftYesterday
          // );
          // // subRow.calcDailyBudgetYesterday =
          // //   (subRow.budget -
          // //     (subRow.cost || 0) -
          // //     (subRow.costToday || 0) -
          // //     (subRow.costYesterday || 0)) /
          // //   subRow.numberOfDaysLeftYesterday;

          // subRow.calcPaceYesterday =
          //   subRow?.calcDailyBudgetYesterday && subRow?.costYesterday
          //     ? Math.round(
          //         (subRow?.costYesterday / subRow?.calcDailyBudgetYesterday) * 100
          //       )
          //     : '';

          // subRow.paceYesterday =
          //   subRow?.budgetYesterday && subRow?.costYesterday
          //     ? Math.round(
          //         (subRow?.costYesterday / subRow?.budgetYesterday) * 100
          //       )
          //     : '';

          if (
            new Date(addDays(subRow.startDate, 1)) <=
              new Date(formatDateYYYYMMDD(new Date())) &&
            new Date(addDays(subRow.endDate, 1)) > beginningOfToday
          ) {
            subRow.calcDailyBudgetYesterday = calcDailyBudget(
              subRow.budget,
              (subRow.cost || 0) -
                (subRow.costToday || 0) -
                (subRow.costYesterday || 0),
              // updatedRow.numberOfDays,
              // subRow.numberOfDaysLeft + 1
              subRow.numberOfDaysLeftYesterday
            );
            // subRow.calcDailyBudgetYesterday =
            //   (subRow.budget -
            //     (subRow.cost || 0) -
            //     (subRow.costToday || 0) -
            //     (subRow.costYesterday || 0)) /
            //   subRow.numberOfDaysLeftYesterday;

            subRow.calcPaceYesterday =
              subRow?.calcDailyBudgetYesterday && subRow?.costYesterday
                ? Math.round(
                    (subRow?.costYesterday / subRow?.calcDailyBudgetYesterday) *
                      100
                  )
                : null;

            subRow.paceYesterday =
              subRow?.budgetYesterday && subRow?.costYesterday
                ? Math.round(
                    (subRow?.costYesterday / subRow?.budgetYesterday) * 100
                  )
                : null;
          } else {
            subRow.calcDailyBudgetYesterday = null;
            subRow.calcPaceYesterday = null;
            subRow.paceYesterday = null;
          }

          if (
            subRow.numberOfDaysLeftTillEndDateYesterday < 4 &&
            subRow.endDate &&
            new Date(subRow.endDate).setHours(23, 59, 59, 59) >=
              new Date().setHours(23, 59, 59, 59) &&
            ['ACTIVE', 'DOUBLECHECK', 'BUDDYCHECK'].includes(subRow.status)
          ) {
            const costUntillYesterday =
              (subRow.cost || 0) -
              (subRow.costToday || 0) -
              (subRow.costYesterday || 0);

            const predictedEndCost =
              costUntillYesterday +
              subRow.costYesterday * subRow.numberOfDaysLeftYesterday;

            if (subRow.budget && predictedEndCost > subRow.budget + 100) {
              const roundedCostYesterday = (
                Math.round((subRow.costYesterday + Number.EPSILON) * 100) / 100
              ).toLocaleString();
              const predictedOverSpend = predictedEndCost - subRow.budget;
              const roundedPredictedOverSpend = (
                Math.round((predictedOverSpend + Number.EPSILON) * 100) / 100
              ).toLocaleString();
              let warningMessage = `If you continue to spend like yesteday (${roundedCostYesterday}) you will overspend by ${roundedPredictedOverSpend}`;
              warningLevel = 4;
              highestWarningLevel =
                highestWarningLevel < warningLevel
                  ? warningLevel
                  : highestWarningLevel;

              warningMessages.push({
                relatedFields: ['cost'],
                documentId: subRow._id,
                warningLevel,
                warningColor: redWarningColor,
                message: (
                  <span style={{ color: redWarningColor }}>
                    {warningMessage}
                  </span>
                ),
                rawMessage: warningMessage
              });
            }

            if (subRow.budget && predictedEndCost + 100 < subRow.budget) {
              const roundedCostYesterday = (
                Math.round((subRow.costYesterday + Number.EPSILON) * 100) / 100
              ).toLocaleString();
              const predictedUnderSpend = subRow.budget - predictedEndCost;
              const roundedPredictedUnderSpend = (
                Math.round((predictedUnderSpend + Number.EPSILON) * 100) / 100
              ).toLocaleString();
              let warningMessage = `If you continue to spend like yesteday (${roundedCostYesterday}) you will underspend by ${roundedPredictedUnderSpend}`;
              warningLevel = 4;
              highestWarningLevel =
                highestWarningLevel < warningLevel
                  ? warningLevel
                  : highestWarningLevel;

              warningMessages.push({
                relatedFields: ['cost'],
                documentId: subRow._id,
                warningLevel,
                warningColor: blueWarningColor,
                message: (
                  <span style={{ color: blueWarningColor }}>
                    {warningMessage}
                  </span>
                ),
                rawMessage: warningMessage
              });
            }
          }
        }
      } else if (
        ![
          'globalTasks',
          'account',
          'task',
          'subTask',
          'taxonomy',
          'facebook_ads_ad_set',
          'google_ads_ad_group',
          'tiktok_ads_ad_group',
          'pinterest_ads_ad_group',
          'readpeak_ad_group',
          'strossle_ad_set',
          'amazon_dsp_line_item',
          'snapchat_ads_ad_squad',
          'dv360_line_item',
          'adform_line_item',
          'linkedin_ads_campaign'
        ].includes(subRow.type)
      ) {
        for (let field of allEvalFieldsExceptTotal) {
          subRow[field + 'SumChild'] = '?';
        }
        // subRow.pace =
        //   subRow.budget && subRow.startDate && subRow.endDate ? '?' : '';
        subRow.calcDailyBudget =
          subRow.budget && subRow.startDate && subRow.endDate ? '?' : '';
      } else {
        for (let field of allEvalFieldsExceptTotal) {
          subRow[field + 'SumChild'] = '';
        }
        subRow.pace = '';
        subRow.calcDailyBudget = '';
      }

      if (
        ![
          // 'account',
          'globalTasks',
          'task',
          'subTask',
          'taxonomy',
          'facebook_ads_ad_set',
          'google_ads_ad_group',
          'tiktok_ads_ad_group',
          'pinterest_ads_ad_group',
          'readpeak_ad_group',
          'strossle_ad_set',
          'amazon_dsp_line_item',
          'snapchat_ads_ad_squad',
          'dv360_line_item',
          'adform_line_item',
          'linkedin_ads_campaign'
        ].includes(subRow.type)
      ) {
        if (
          typeof subRow.cost === 'number' ||
          typeof subRow.budget === 'number'
        ) {
          subRow.budgetLeft = (subRow.budget || 0) - (subRow.cost || 0);
        }
      }
    }

    // ########## warnings #############

    // const warningMessages = [];

    // let highestWarningLevel = 0;

    // let warningLevel = 0;
    // ######### start date passed and status not active ###########

    if (
      ([
        'folder',
        'task',
        'subTask',
        'taxonomy',
        'manualDataEntry',
        'facebook_ads_campaign',
        'google_ads_campaign',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'pinterest_ads_campaign',
        'readpeak_campaign',
        'strossle_campaign',
        'amazon_dsp_order',
        'taboola_ads_campaign',
        'microsoft_advertising_campaign',
        'apple_search_ads_campaign',
        'adform_order',
        'linkedin_ads_campaign_group'
      ].includes(subRow.type) ||
        subRow.isSet) &&
      !subRow.isSetChild
    ) {
      if (!['folder', 'task', 'subTask', 'taxonomy'].includes(subRow.type)) {
        if (
          (!subRow.budget || isNaN(subRow.budget)) &&
          !subRow.allowMissingBudget
        ) {
          let warningMessage = 'MISSING BUDGET';
          warningLevel = 4;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessage = `MISSING BUDGET`;

          warningMessages.push({
            relatedFields: ['budget'],
            documentId: subRow._id,
            type: 'MISSING_BUDGET',
            warningLevel,
            warningColor: redWarningColor,
            message: (
              <span style={{ color: redWarningColor }}>{warningMessage}</span>
            ),
            rawMessage: warningMessage
          });

          // snackbarWarnings.push({
          //   message: `${warningMessage} --> ${subRow.path}`,
          //   variant: 'error',
          //   warningLevel
          // });
        }

        if (
          subRow.impressionsBeforeStartDate &&
          !subRow.allowImpressionsBeforeStartDate
        ) {
          let warningMessage = 'IMPRESSIONS BEFORE START DATE';
          warningLevel = 4;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessage = `IMPRESSIONS BEFORE START DATE`;

          warningMessages.push({
            relatedFields: ['impressions'],
            documentId: subRow._id,
            type: 'IMPRESSIONS_BEFORE_START_DATE',
            warningLevel,
            warningColor: redWarningColor,
            message: (
              <span style={{ color: redWarningColor }}>{warningMessage}</span>
            ),
            rawMessage: warningMessage
          });

          // snackbarWarnings.push({
          //   message: `${warningMessage} --> ${subRow.path}`,
          //   variant: 'error',
          //   warningLevel
          // });
        }

        if (
          subRow.impressionsAfterEndDate &&
          !subRow.allowImpressionsAfterEndDate
        ) {
          let warningMessage = 'IMPRESSIONS AFTER END DATE';
          warningLevel = 4;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessage = `IMPRESSIONS AFTER END DATE`;

          warningMessages.push({
            relatedFields: ['impressions'],
            documentId: subRow._id,
            type: 'IMPRESSIONS_AFTER_END_DATE',
            warningLevel,
            warningColor: redWarningColor,
            message: (
              <span style={{ color: redWarningColor }}>{warningMessage}</span>
            ),
            rawMessage: warningMessage
          });

          // snackbarWarnings.push({
          //   message: `${warningMessage} --> ${subRow.path}`,
          //   variant: 'error',
          //   warningLevel
          // });
        }

        if (
          subRow.impressionsAfterStatusChange &&
          !subRow.allowImpressionsAfterStatusChange
        ) {
          let warningMessage =
            'IMPRESSIONS AFTER STATUS CHANGE TO PAUSED/COMPLETED';
          warningLevel = 4;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessage = `IMPRESSIONS AFTER STATUS CHANGE TO PAUSED/COMPLETED`;

          warningMessages.push({
            relatedFields: ['impressions'],
            documentId: subRow._id,
            type: 'IMPRESSIONS_AFTER_STATUS_CHANGE',
            warningLevel,
            warningColor: redWarningColor,
            message: (
              <span style={{ color: redWarningColor }}>{warningMessage}</span>
            ),
            rawMessage: warningMessage
          });

          // snackbarWarnings.push({
          //   message: `${warningMessage} --> ${subRow.path}`,
          //   variant: 'error',
          //   warningLevel
          // });
        }
      }

      // const roundedCalcDailyBudget = Math.round(subRow.calcDailyBudget);
      const roundedCalcDailyBudget = (
        Math.round((subRow.calcDailyBudget + Number.EPSILON) * 100) / 100
      ).toLocaleString();

      const roundedCalcDailyBudgetYesterday = (
        Math.round((subRow.calcDailyBudgetYesterday + Number.EPSILON) * 100) /
        100
      ).toLocaleString();

      const roundedBudgetYesterday = (
        Math.round((subRow.budgetYesterday + Number.EPSILON) * 100) / 100
      ).toLocaleString();

      const roundedBudgetUntillNow = (
        Math.round((subRow.budgetUntillNow + Number.EPSILON) * 100) / 100
      ).toLocaleString();

      const roundedCostYesterday = (
        Math.round((subRow.costYesterday + Number.EPSILON) * 100) / 100
      ).toLocaleString();
      const roundedCost = (
        Math.round((subRow.cost + Number.EPSILON) * 100) / 100
      ).toLocaleString();

      // Math.round(subRow.budgetUntillNow);
      // ############# PACE ####################
      if (
        !['CANCELED', 'COMPLETED'].includes(subRow.status) &&
        subRow.pace &&
        subRow.pace > 120
      ) {
        let warningMessage = `Overpace! ${subRow.pace}% (${roundedCost} / ${roundedBudgetUntillNow})`;
        warningLevel = 4;
        highestWarningLevel =
          highestWarningLevel < warningLevel
            ? warningLevel
            : highestWarningLevel;

        warningMessages.push({
          relatedFields: ['pace'],
          documentId: subRow._id,
          type: 'OVERPACE',
          warningLevel,
          warningColor: redWarningColor,
          message: (
            <span style={{ color: redWarningColor }}>{warningMessage}</span>
          ),
          rawMessage: warningMessage
        });
      }

      if (
        !['CANCELED', 'COMPLETED'].includes(subRow.status) &&
        subRow.pace &&
        subRow.pace > 200
      ) {
        let warningMessage = `OVERPACE! ${subRow.pace}% (${roundedCost} / ${roundedBudgetUntillNow})`;

        // warningMessages.push({ documentId: subRow._id,
        //   message: <span style={{ color: redWarningColor }}>{warningMessage}</span>
        // });

        // enqueueSnackbar(
        //   `${warningMessage} --> ${subRow.path}`,
        //   {
        //     variant: 'error',
        //     // persist: true
        //   }
        // );

        snackbarWarnings.push({
          message: `${warningMessage} --> ${subRow.path}`,
          variant: 'error',
          warningLevel
        });
      }

      if (
        ['ACTIVE', 'DOUBLECHECK', 'BUDDYCHECK'].includes(subRow.status) &&
        subRow.cost &&
        subRow.budget &&
        typeof subRow.pace === 'number' &&
        subRow.pace < 80
      ) {
        warningLevel = 4;
        let warningMessage = `Underpace! ${subRow.pace}% (${roundedCost} / ${roundedBudgetUntillNow})`;
        highestWarningLevel =
          highestWarningLevel < warningLevel
            ? warningLevel
            : highestWarningLevel;
        warningMessages.push({
          relatedFields: ['pace'],
          documentId: subRow._id,
          type: 'UNDERPACE',
          warningLevel,
          warningColor: blueWarningColor,
          message: (
            <span style={{ color: blueWarningColor }}>{warningMessage}</span>
          ),
          rawMessage: `Underpace! ${subRow.pace}%`
        });
      }

      // ################### CALCULATED PACE YESTERDAY ###########################

      if (
        new Date(subRow.startDate) >= new Date(selectedDateStart) &&
        new Date(subRow.endDate) <= new Date(selectedDateStop) &&
        subRow.budgetFormula !== '=budget'
      ) {
        if (
          !['CANCELED', 'COMPLETED'].includes(subRow.status) &&
          subRow.calcPaceYesterday &&
          subRow.calcPaceYesterday > 150 &&
          subRow.calcDailyBudgetYesterday > 0
        ) {
          let warningMessage = `Overpace yesterday! (calculated) ${subRow.calcPaceYesterday}% (${roundedCostYesterday} / ${roundedCalcDailyBudgetYesterday})`;
          warningLevel = 4;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessages.push({
            relatedFields: ['calcPaceYesterday'],
            documentId: subRow._id,
            type: 'OVERPACE_YESTERDAY',
            warningLevel,
            warningColor: redWarningColor,
            message: (
              <span style={{ color: redWarningColor }}>{warningMessage}</span>
            ),
            rawMessage: warningMessage
          });
        }

        if (
          !['CANCELED', 'COMPLETED'].includes(subRow.status) &&
          subRow.calcPaceYesterday &&
          subRow.calcPaceYesterday > 200
        ) {
          let warningMessage = `Overpace yesterday! (calculated) ${subRow.calcPaceYesterday}% (${roundedCostYesterday} / ${roundedCalcDailyBudgetYesterday})`;

          // warningMessages.push({ documentId: subRow._id,
          //   message: <span style={{ color: redWarningColor }}>{warningMessage}</span>
          // });

          // enqueueSnackbar(
          //   `${warningMessage} --> ${subRow.path}`,
          //   {
          //     variant: 'error',
          //     // persist: true
          //   }
          // );

          snackbarWarnings.push({
            type: 'OVERPACE_YESTERDAY',
            message: `${warningMessage} --> ${subRow.path}`,
            variant: 'error',
            warningLevel
          });
        }

        if (
          ['ACTIVE', 'DOUBLECHECK', 'BUDDYCHECK'].includes(subRow.status) &&
          subRow.costYesterday &&
          subRow.calcDailyBudget &&
          typeof subRow.calcPaceYesterday === 'number' &&
          subRow.calcPaceYesterday < 50 &&
          Math.round(subRow.calcDailyBudgetYesterday) > 0
        ) {
          warningLevel = 4;
          let warningMessage = `Underpace Yesterday (calculated) ${subRow.calcPaceYesterday}% (${roundedCostYesterday} / ${roundedCalcDailyBudgetYesterday})`;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;
          warningMessages.push({
            relatedFields: ['calcPaceYesterday'],
            documentId: subRow._id,
            type: 'UNDERPACE_YESTERDAY',
            warningLevel,
            warningColor: blueWarningColor,
            message: (
              <span style={{ color: blueWarningColor }}>{warningMessage}</span>
            ),
            rawMessage: `Underpace Yesterday (calculated) ${subRow.calcPaceYesterday}% (${roundedCostYesterday} / ${roundedCalcDailyBudgetYesterday})`
          });
        }
      } else {
        if (
          !['CANCELED', 'COMPLETED'].includes(subRow.status) &&
          subRow.paceYesterday &&
          subRow.paceYesterday > 150 &&
          subRow.budgetYesterday > 0
        ) {
          let warningMessage = `Overpace yesterday! ${subRow.paceYesterday}% (${roundedCostYesterday} / ${roundedBudgetYesterday})`;
          warningLevel = 4;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessages.push({
            relatedFields: ['paceYesterday'],
            documentId: subRow._id,
            type: 'OVERPACE_YESTERDAY',
            warningLevel,
            warningColor: redWarningColor,
            message: (
              <span style={{ color: redWarningColor }}>{warningMessage}</span>
            ),
            rawMessage: warningMessage
          });
        }

        if (
          !['CANCELED', 'COMPLETED'].includes(subRow.status) &&
          subRow.paceYesterday &&
          subRow.paceYesterday > 200
        ) {
          let warningMessage = `Overpace yesterday! ${subRow.paceYesterday}% (${roundedCostYesterday} / ${roundedBudgetYesterday})`;

          // warningMessages.push({ documentId: subRow._id,
          //   message: <span style={{ color: redWarningColor }}>{warningMessage}</span>
          // });

          // enqueueSnackbar(
          //   `${warningMessage} --> ${subRow.path}`,
          //   {
          //     variant: 'error',
          //     // persist: true
          //   }
          // );

          snackbarWarnings.push({
            message: `${warningMessage} --> ${subRow.path}`,
            variant: 'error',
            warningLevel
          });
        }

        if (
          ['ACTIVE', 'DOUBLECHECK', 'BUDDYCHECK'].includes(subRow.status) &&
          subRow.costYesterday &&
          subRow.calcDailyBudget &&
          typeof subRow.paceYesterday === 'number' &&
          subRow.paceYesterday < 50 &&
          subRow.calcDailyBudgetYesterday > 0
        ) {
          warningLevel = 4;
          let warningMessage = `Underpace Yesterday ${subRow.paceYesterday}% (${roundedCostYesterday} / ${roundedBudgetYesterday})`;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;
          warningMessages.push({
            relatedFields: ['paceYesterday'],
            documentId: subRow._id,
            type: 'UNDERPACE_YESTERDAY',
            warningLevel,
            warningColor: blueWarningColor,
            message: (
              <span style={{ color: blueWarningColor }}>{warningMessage}</span>
            ),
            rawMessage: `Underpace Yesterday ${subRow.paceYesterday}% (${roundedCostYesterday} / ${roundedBudgetYesterday})`
          });
        }
      }

      // Overcost
      if (
        new Date(subRow.startDate) >= new Date(selectedDateStart) &&
        new Date(subRow.endDate) <= new Date(selectedDateStop) &&
        !['CANCELED', 'COMPLETED'].includes(subRow.status) &&
        subRow.cost &&
        subRow.budget
        //  &&
        // Math.round(subRow.cost) > Math.round(subRow.budget)
      ) {
        let overSpendMargin = 10;

        if (subRow?.dataSourceCurrency && subRow.costFormula === '=cost') {
          switch (subRow?.dataSourceCurrency) {
            case 'EUR':
              break;
            case 'SEK':
            case 'DKK':
            case 'NOK':
              overSpendMargin = 100;
              break;
          }
        }

        if (
          Math.round(subRow.cost) > Math.round(subRow.budget + overSpendMargin)
        ) {
          let warningMessage = 'OVERSPEND!';
          warningLevel = 5;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessage = `OVERSPEND! ${subRow.cost.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })} of ${subRow.budget.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          })}`;

          warningMessages.push({
            relatedFields: [
              'cost',
              'pace',
              'calcPaceYesterday',
              'paceYesterday',
              'budget'
            ],
            documentId: subRow._id,
            type: 'OVERSPEND',
            warningLevel,
            warningColor: redWarningColor,
            message: (
              <span style={{ color: redWarningColor }}>{warningMessage}</span>
            ),
            rawMessage: warningMessage
          });

          snackbarWarnings.push({
            message: `${warningMessage} --> ${subRow.path}`,
            variant: 'error',
            warningLevel
          });

          // enqueueSnackbar(
          //   `${warningMessage} --> ${subRow.path}`,
          //   {
          //     variant: 'error',
          //     // persist: true
          //   }
          // );
        }
      }

      if (
        new Date(subRow.startDate) >= new Date(selectedDateStart) &&
        new Date(subRow.endDate) <= new Date(selectedDateStop)
      ) {
        // // Overcost
        // if (
        //   !['CANCELED',' COMPLETED'].includes(subRow.status) &&
        //   subRow.cost &&
        //   subRow.budget &&
        //   subRow.cost > subRow.budget
        // ) {
        //   let warningMessage = 'OVERSPEND!';
        //     warningMessage = `OVERSPEND! ${subRow.cost.toFixed(2)} of ${
        //       subRow.budget
        //     }`;
        //   warningMessages.push({ documentId: subRow._id,
        //     message: <span style={{ color: redWarningColor }}>{warningMessage}</span>
        //   });
        // }
        // // over pace
        // if (
        //   ['ACTIVE', 'DRAFT', 'PLANNED'].includes(subRow.status) &&
        //   (subRow.pace && subRow.pace>1.2)
        // ) {
        //   let warningMessage = 'OVERPACE!';
        //     warningMessage = `OVERPACE! ${subRow.pace}%`;
        //   warningMessages.push({ documentId: subRow._id,
        //     message: <span style={{ color: redWarningColor }}>{warningMessage}</span>
        //   });
        // }
      }

      // if ((subRow.startDate && subRow.startDate <= new Date().toISOString().split('T')[0]) && !['ACTIVE', 'PAUSED', 'CANCELED', 'COMPLETED'].includes(subRow.status) ) {
      //   warningMessages.push({ documentId: subRow._id,
      //     message:
      //       'Start date is today but status is set to "' + subRow.status + '".'
      //   });
      // }

      if (
        new Date(subRow.startDate) >= new Date(selectedDateStart) &&
        new Date(subRow.endDate) <= new Date(selectedDateStop) &&
        !['CANCELED', 'COMPLETED'].includes(subRow.status) &&
        subRow.budget &&
        subRow.budgetSumChild &&
        Math.round(subRow.budgetSumChild) > Math.round(subRow.budget)
      ) {
        let warningMessage = `The sum of the children's budgets exceeds the parent's budget (${(
          Math.round((subRow.budgetSumChild + Number.EPSILON) * 100) / 100
        ).toLocaleString()} / ${(
          Math.round((subRow.budget + Number.EPSILON) * 100) / 100
        ).toLocaleString()})`;

        warningLevel = 4;

        highestWarningLevel =
          highestWarningLevel < warningLevel
            ? warningLevel
            : highestWarningLevel;

        warningMessages.push({
          relatedFields: ['budget'],
          documentId: subRow._id,
          type: 'SUM_OF_CHILDREN_BUDGETS_EXCEEDS_PARENTS_BUDGET',
          warningLevel,
          warningColor: redWarningColor,
          message: warningMessage,
          rawMessage: warningMessage
        });

        snackbarWarnings.push({
          message: `${warningMessage} --> ${subRow.path}`,
          variant: 'error',
          warningLevel
        });
      }

      var date_start_plus_1 = new Date(subRow.startDate);
      date_start_plus_1.setDate(date_start_plus_1.getDate() + 1);

      if (
        subRow.startDate &&
        new Date(subRow.startDate).setHours(0, 0, 0, 0) <=
          new Date().setHours(0, 0, 0, 0)
      ) {
        if (
          ![
            'ACTIVE',
            'PAUSED',
            'CANCELED',
            'BUDDYCHECK',
            'DOUBLECHECK',
            'COMPLETED',
            'REMOVED'
          ].includes(subRow.status)
        ) {
          const warningMessage =
            'Start date passed but status is set to "' + subRow.status + '".';
          warningLevel = 2;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessages.push({
            relatedFields: ['status', 'startDate'],
            documentId: subRow._id,
            type: 'START_DATE_PASSED_WITH_STATUS_NOT_ACTIVE',
            warningLevel,
            warningColor: 'grey',
            message: warningMessage,
            rawMessage: warningMessage
          });

          // if more than 1 day passed since start date
          if (new Date() >= date_start_plus_1) {
            // snackbarWarnings.push({
            //   message: `${warningMessage} --> ${subRow.path}`,
            //   variant: 'standard',
            //   warningLevel
            // });
          }
        }
      }

      if (
        subRow.endDate &&
        new Date(subRow.endDate).setHours(23, 59, 59, 59) <
          new Date().setHours(23, 59, 59, 59)
      ) {
        if (
          !['PAUSED', 'CANCELED', 'COMPLETED', 'REMOVED'].includes(
            subRow.status
          )
        ) {
          const warningMessage =
            'End date passed but status is set to "' + subRow.status + '".';

          warningLevel = 2;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessages.push({
            relatedFields: ['status', 'endDate'],
            documentId: subRow._id,
            type: 'END_DATE_PASSED_WITH_STATUS_NOT_COMPLETED',
            warningLevel,
            warningColor: 'grey',
            message: warningMessage,
            rawMessage: warningMessage
          });
          // enqueueSnackbar(
          //   `${warningMessage} --> ${subRow.path}`,
          //   {
          //     // variant: 'error',
          //     // persist: true
          //   }
          // );
          // snackbarWarnings.push({
          //   message: `${warningMessage} --> ${subRow.path}`,
          //   variant: 'standard',
          //   warningLevel
          // });
        }
      }
      // if (subRow._id === '6666ed930e4bd30b2d27a8d6') {
      //   console.log('debug88>subRow: ', subRow);
      //   console.log(
      //     'debug88>subRow.numberOfDaysLeftYesterday: ',
      //     subRow.numberOfDaysLeftYesterday
      //   );
      // }
      // invoice warnings

      if (
        !isInvoiceWarningsOff &&
        ([
          // 'folder',
          // 'task',
          // 'subTask',
          // 'taxonomy',
          'manualDataEntry',
          'facebook_ads_campaign',
          'google_ads_campaign',
          'cm360_campaign',
          'dv360_insertion_order',
          'snapchat_ads_campaign',
          'tiktok_ads_campaign',
          'pinterest_ads_campaign',
          'readpeak_campaign',
          'strossle_campaign',
          'amazon_dsp_order',
          'taboola_ads_campaign',
          'microsoft_advertising_campaign',
          'apple_search_ads_campaign',
          'adform_order',
          'linkedin_ads_campaign_group'
        ].includes(subRow.type) ||
          subRow.isSet) &&
        !subRow.isSetChild
      ) {
        const invoiceStatus = nexusDocumentInvoiceStatuses?.filter(
          (invoiceStatus) =>
            invoiceStatus.string === subRow.invoiceStatus ||
            (invoiceStatus.string === 'UNDEFINED' && !subRow.invoiceStatus)
        )[0];
        // console.log(
        //   'nexusDocumentInvoiceStatuses: ',
        //   nexusDocumentInvoiceStatuses
        // );
        // console.log('invoiceStatus: ', invoiceStatus);
        const invoiceWarningRules = invoiceStatus?.warningRules || [];

        if (invoiceStatus && invoiceWarningRules?.length) {
          let pushInvoiceWarning = false;
          for (let rule of invoiceWarningRules) {
            let warningMessage = 'Invoice status warning';
            switch (true) {
              case rule?.rule === 'NOT_ALLOWED':
                warningMessage =
                  'Invoice status set to "' + subRow.invoiceStatus + '".';
                warningLevel = 2;
                pushInvoiceWarning = true;
                subRow.invoiceStatusButtonColor = 'red';
                subRow.showInvoiceButtonWarning = true;
                break;

              case rule?.rule === 'MISSING_INVOICE_ORDER_ID' &&
                !subRow?.invoiceOrderId:
                warningMessage =
                  'Missing invoice order ID"' + subRow.invoiceStatus + '".';
                warningLevel = 2;
                pushInvoiceWarning = true;
                subRow.invoiceStatusButtonColor = 'red';
                subRow.showInvoiceButtonWarning = true;
                break;

              case rule?.rule === 'PAST_START_DATE' &&
                subRow.startDate &&
                new Date(subRow.startDate).setHours(0, 0, 0, 0) <=
                  new Date().setHours(0, 0, 0, 0):
                warningMessage =
                  'Start date passed but invoice status is set to "' +
                  subRow.invoiceStatus +
                  '".';
                warningLevel = 2;
                pushInvoiceWarning = true;
                subRow.invoiceStatusButtonColor = 'red';
                subRow.showInvoiceButtonWarning = true;
                break;

              case rule?.rule === 'PAST_END_DATE' &&
                subRow.endDate &&
                new Date(subRow.endDate).setHours(23, 59, 59, 59) <
                  new Date().setHours(23, 59, 59, 59):
                //warning

                warningMessage =
                  'End date passed but invoice status is set to "' +
                  subRow.invoiceStatus +
                  '".';
                warningLevel = 2;
                pushInvoiceWarning = true;
                subRow.invoiceStatusButtonColor = 'red';
                subRow.showInvoiceButtonWarning = true;
                break;
            }

            if (pushInvoiceWarning) {
              highestWarningLevel =
                highestWarningLevel < warningLevel
                  ? warningLevel
                  : highestWarningLevel;

              warningMessages.push({
                relatedFields: ['invoiceStatus'],
                documentId: subRow._id,
                type: 'INVOICE_WARNING',
                warningLevel,
                warningColor: 'grey',
                message: warningMessage,
                rawMessage: warningMessage
              });
            }
          }
        }
      }

      // ######### day since start > 1 and no cost ###########

      // var date_start_plus_1 = new Date(subRow.startDate);
      // date_start_plus_1.setDate(date_start_plus_1.getDate() + 1);
      if (
        [
          // 'folder',
          // 'task',
          // 'subTask',
          // 'taxonomy',
          // 'manualDataEntry',
          'facebook_ads_campaign',
          'google_ads_campaign',
          'cm360_campaign',
          'dv360_insertion_order',
          'snapchat_ads_campaign',
          'tiktok_ads_campaign',
          'pinterest_ads_campaign',
          'readpeak_campaign',
          'strossle_campaign',
          'amazon_dsp_order',
          'taboola_ads_campaign',
          'microsoft_advertising_campaign',
          'apple_search_ads_campaign',
          'adform_order',
          'linkedin_ads_campaign_group'
        ].includes(subRow.type) &&
        new Date(selectedDateStart) <= new Date(subRow.startDate) &&
        new Date() >= date_start_plus_1 &&
        subRow.budget &&
        !subRow.cost &&
        !['PAUSED', 'CANCELED', 'COMPLETED', 'REMOVED'].includes(subRow.status)
      ) {
        const warningMessage =
          'Start date passed by one day with ' +
          subRow.cost +
          '/' +
          Math.round(subRow.budget) +
          ' cost.';

        warningLevel = 3;
        highestWarningLevel =
          highestWarningLevel < warningLevel
            ? warningLevel
            : highestWarningLevel;

        warningMessages.push({
          relatedFields: ['cost', 'startDate'],
          documentId: subRow._id,
          type: 'START_DATE_PASSED_BY_ONE_DAY_BYT_NO_COST',
          warningLevel,
          warningColor: blueWarningColor,
          message: warningMessage,
          rawMessage: warningMessage
        });

        // if (
        //   [
        //     // 'folder',
        //     // 'manualDataEntry',
        //     'facebook_ads_campaign',
        //     'google_ads_campaign',
        //     'cm360_campaign',
        //     'dv360_insertion_order',
        //     'snapchat_ads_campaign',
        //     'tiktok_ads_campaign',
        //     'pinterest_ads_campaign',
        //      'readpeak_campaign',
        //     'strossle_campaign',
        //     'amazon_dsp_order',
        //     'taboola_ads_campaign',
        //     'microsoft_advertising_campaign',
        //     'apple_search_ads_campaign',
        //     'adform_order',
        //     'linkedin_ads_campaign_group'
        //   ].includes(subRow.type)
        // ) {
        //   // enqueueSnackbar(
        //   //   `${warningMessage} --> ${subRow.path}`,
        //   //   {
        //   //     // variant: 'error',
        //   //     // persist: true
        //   //   }
        //   // );
        //   // snackbarWarnings.push({
        //   //   message: `${warningMessage} --> ${subRow.path}`,
        //   //   variant: 'standard',
        //   //   warningLevel
        //   // });
        // }
      }

      if (
        [
          // 'folder',
          // 'manualDataEntry',
          'facebook_ads_campaign',
          'google_ads_campaign',
          'cm360_campaign',
          'dv360_insertion_order',
          'snapchat_ads_campaign',
          'tiktok_ads_campaign',
          'pinterest_ads_campaign',
          'readpeak_campaign',
          'strossle_campaign',
          'amazon_dsp_order',
          'taboola_ads_campaign',
          'microsoft_advertising_campaign',
          'apple_search_ads_campaign',
          'adform_order',
          'linkedin_ads_campaign_group'
        ].includes(subRow.type) &&
        ['ACTIVE', 'DOUBLECHECK', 'BUDDYCHECK'].includes(subRow.status)
      ) {
        // if (!subRow.costYesterday) {
        //   const warningMessage = 'No cost yesterday';

        //   warningLevel = 3;
        //   highestWarningLevel =
        //     highestWarningLevel < warningLevel
        //       ? warningLevel
        //       : highestWarningLevel;

        //   warningMessages.push({ documentId: subRow._id,
        //     warningLevel,
        //     message: warningMessage,
        //     rawMessage: warningMessage
        //   });
        // }

        // if (!subRow.costToday) {
        //   const warningMessage = 'No cost today';

        //   warningLevel = 3;
        //   highestWarningLevel =
        //     highestWarningLevel < warningLevel
        //       ? warningLevel
        //       : highestWarningLevel;

        //   warningMessages.push({ documentId: subRow._id,
        //     warningLevel,
        //     message: warningMessage,
        //     rawMessage: warningMessage
        //   });
        // }

        const yesterday_ = new Date();

        yesterday_.setDate(yesterday_.getDate() - 1);

        if (
          !subRow.impressionsYesterday &&
          !subRow.allowMissingImpressionsYesterday &&
          new Date(selectedDateStart).toISOString().split('T')[0] <=
            yesterday_.toISOString().split('T')[0] &&
          yesterday_.toISOString().split('T')[0] <=
            new Date(selectedDateStop).toISOString().split('T')[0] &&
          (!subRow.startDate ||
            new Date(subRow.startDate).toISOString().split('T')[0] <=
              yesterday_.toISOString().split('T')[0]) &&
          (!subRow.endDate ||
            new Date(subRow.endDate).toISOString().split('T')[0] >=
              yesterday_.toISOString().split('T')[0])
        ) {
          subRow.missingImpressionsYesterday = true;
          const warningMessage = 'No impressions yesterday';

          warningLevel = 3;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessages.push({
            relatedFields: ['impressions'],
            type: 'MISSING_IMPRESSIONS_YESTERDAY',
            documentId: subRow._id,
            warningLevel,
            warningColor: redWarningColor,
            message: (
              <span style={{ color: redWarningColor }}>{warningMessage}</span>
            ),
            rawMessage: warningMessage
          });
        }

        const today_ = new Date();

        if (
          !subRow.impressionsToday &&
          new Date(selectedDateStart).toISOString().split('T')[0] <=
            today_.toISOString().split('T')[0] &&
          today_.toISOString().split('T')[0] <=
            new Date(selectedDateStop).toISOString().split('T')[0] &&
          (!subRow.startDate ||
            new Date(subRow.startDate).toISOString().split('T')[0] <=
              today_.toISOString().split('T')[0]) &&
          (!subRow.endDate ||
            new Date(subRow.endDate).toISOString().split('T')[0] >=
              today_.toISOString().split('T')[0])
        ) {
          if (
            (['dv360_insertion_order', 'cm360_campaign'].includes(
              subRow.type
            ) &&
              today_.getUTCHours() > 11) ||
            !['dv360_insertion_order', 'cm360_campaign'].includes(subRow.type)
          ) {
            const warningMessage = 'No impressions today';

            warningLevel = 3;
            highestWarningLevel =
              highestWarningLevel < warningLevel
                ? warningLevel
                : highestWarningLevel;

            warningMessages.push({
              relatedFields: ['impressions'],
              type: 'MISSING_IMPRESSIONS_TODAY',
              documentId: subRow._id,
              warningLevel,
              warningColor: redWarningColor,
              message: (
                <span style={{ color: redWarningColor }}>{warningMessage}</span>
              ),
              rawMessage: warningMessage
            });
          }
        }

        if (
          (subRow.cost > 1000 || subRow.impressions > 10000) &&
          !subRow.clicks &&
          !subRow.allowMissingClicks
        ) {
          const warningMessage = 'Impressions/cost but no clicks';

          warningLevel = 3;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessages.push({
            relatedFields: ['clicks'],
            documentId: subRow._id,
            type: 'IMPRESSIONS_OR_COST_BUT_NO_CLICKS',
            warningLevel,
            warningColor: redWarningColor,
            message: (
              <span style={{ color: redWarningColor }}>{warningMessage}</span>
            ),
            rawMessage: warningMessage
          });
        }
      }

      // ################################################################################################

      if (
        [
          // 'folder',
          // 'manualDataEntry',
          // 'facebook_ads_campaign',
          // 'google_ads_campaign',
          'cm360_campaign',
          // 'dv360_insertion_order',
          // 'snapchat_ads_campaign',
          // 'tiktok_ads_campaign',
          // 'pinterest_ads_campaign',
          // 'readpeak_campaign',
          // 'strossle_campaign',
          // 'amazon_dsp_order',
          // 'taboola_ads_campaign',
          // 'microsoft_advertising_campaign',
          // 'apple_search_ads_campaign',
          'adform_order'
          // 'linkedin_ads_campaign_group'
        ].includes(subRow.type) &&
        ['ACTIVE', 'DOUBLECHECK', 'BUDDYCHECK'].includes(subRow.status)
      ) {
        if (subRow.impressions > 1000 && subRow.ctr > 0.2) {
          subRow.abormallyHighCtr = true;
          const warningMessage =
            'Abnormally high CTR. Are your tracking scripts implemented correctly?';

          warningLevel = 4;
          highestWarningLevel =
            highestWarningLevel < warningLevel
              ? warningLevel
              : highestWarningLevel;

          warningMessages.push({
            relatedFields: ['ctr'],
            documentId: subRow._id,
            type: 'ABNORMALLY_HIGH_CTR',
            warningLevel,
            warningColor: redWarningColor,
            message: (
              <span style={{ color: redWarningColor }}>{warningMessage}</span>
            ),
            rawMessage: warningMessage
          });
        }
      }
    }

    if (
      subRow.type === 'manualDataEntry' &&
      (subRow.cost ||
        subRow.trp ||
        subRow.spots ||
        subRow.impressions ||
        subRow.viewableImpressions ||
        subRow.measurableImpressions ||
        subRow.eligibleImpressions ||
        subRow.clicks ||
        subRow.action01Count ||
        subRow.action02Count ||
        subRow.action03Count ||
        subRow.action01Value ||
        subRow.action02Value ||
        subRow.action03Value)
    ) {
      if (!subRow.startDate || !subRow.endDate) {
        warningLevel = 3;
        highestWarningLevel =
          highestWarningLevel < warningLevel
            ? warningLevel
            : highestWarningLevel;

        const warningMessage = `Start date and/or end date is not set. This might cause manual data input to not count the values correctly.`;

        warningMessages.push({
          relatedFields: ['startDate', 'endDate'],
          documentId: subRow._id,
          warningLevel,
          warningColor: redWarningColor,
          message: warningMessage,
          rawMessage: warningMessage
        });
      }
    }

    // ######################## soft warnings ##################

    if (
      daysToGraph >= 15 &&
      !isKPIWarningsOff &&
      !['PAUSED', 'REMOVED', 'COMPLETED'].includes(subRow.status) &&
      [
        // 'folder',
        // 'manualDataEntry',
        'facebook_ads_campaign',
        'google_ads_campaign',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'pinterest_ads_campaign',
        'readpeak_campaign',
        'strossle_campaign',
        'amazon_dsp_order',
        'taboola_ads_campaign',
        'microsoft_advertising_campaign',
        'apple_search_ads_campaign',
        'adform_order',
        'linkedin_ads_campaign_group'
      ].includes(subRow.type) &&
      (subRow.daysSinceStartDate > 14 || !subRow.startDate) &&
      (new Date(subRow.endDate) > new Date(today) || !subRow.endDate) &&
      new Date(selectedDateStart) < new Date(startDateForLastDaysData) &&
      subRow.impressions &&
      subRow?.impressionsSparkline?.length > 14
    ) {
      const comparisonPeriod = {};

      const referencePeriod = {};

      for (let key of [
        'cost',
        'impressions',
        'clicks',
        'viewableImpressions',
        'measurableImpressions',
        'eligibleImpressions',
        'action01Count',
        'action02Count',
        'action03Count',
        'action01Value',
        'action02Value',
        'action03Value'
      ]) {
        for (let val of subRow[key + 'Sparkline'].slice(-8, -1)) {
          comparisonPeriod[key] =
            (comparisonPeriod[key] || null) + (val || null);
        }
        for (let val of subRow[key + 'Sparkline'].slice(-15, -8)) {
          referencePeriod[key] = (referencePeriod[key] || null) + (val || null);
        }
      }

      for (let obj of [comparisonPeriod, referencePeriod]) {
        obj.ctr = obj.impressions && obj.clicks && obj.clicks / obj.impressions;
        obj.cpm =
          obj.impressions && obj.cost && obj.cost / (obj.impressions / 1000);
        obj.cpc = obj.clicks && obj.cost && obj.cost / obj.clicks;
        obj.viewableRate =
          obj.viewableImpressions &&
          obj.measurableImpressions &&
          obj.viewableImpressions / obj.measurableImpressions;
        obj.searchImpressionShare =
          obj.impressions &&
          obj.eligibleImpressions &&
          obj.impressions / obj.eligibleImpressions;
        obj.action01Cpa =
          obj.cost && obj.action01Count && obj.cost / obj.action01Count;
        obj.action02Cpa =
          obj.cost && obj.action02Count && obj.cost / obj.action02Count;
        obj.action03Cpa =
          obj.cost && obj.action03Count && obj.cost / obj.action03Count;

        obj.action01Roas =
          obj.action01Value && obj.cost && obj.action01Value / obj.cost;
        obj.action02Roas =
          obj.action02Value && obj.cost && obj.action02Value / obj.cost;
        obj.action03Roas =
          obj.action03Value && obj.cost && obj.action03Value / obj.cost;
      }
      // console.log('debug79>subRow.type: ', subRow.type);

      // console.log('debug79>comparisonPeriod: ', comparisonPeriod);
      // console.log('debug79>referencePeriod: ', referencePeriod);
      // console.log(
      //   'debug79>comparisonPeriod.cpm &&referencePeriod.cpm && comparisonPeriod.cpm / referencePeriod.cpm: ',
      //   comparisonPeriod.cpm &&
      //     referencePeriod.cpm &&
      //     comparisonPeriod.cpm / referencePeriod.cpm
      // );

      // if (
      //   comparisonPeriod.cpm &&
      //   referencePeriod.cpm &&
      //   comparisonPeriod.cpm / referencePeriod.cpm > 1.3
      // ) {
      //   const warningMessage = `CPM last 7 days was ${Math.round(
      //     (comparisonPeriod.cpm / referencePeriod.cpm - 1) * 100
      //   )}% higher than the preceding 7 days.`;

      //   warningLevel = 3;
      //   highestWarningLevel =
      //     highestWarningLevel < warningLevel
      //       ? warningLevel
      //       : highestWarningLevel;

      //   warningMessages.push({ documentId: subRow._id,
      //     warningLevel,
      //     message: <span style={{ color: 'orange' }}>{warningMessage}</span>,
      //     rawMessage: warningMessage
      //   });
      // }

      // if (
      //   comparisonPeriod.ctr &&
      //   referencePeriod.ctr &&
      //   comparisonPeriod.ctr / referencePeriod.ctr < 0.7
      // ) {
      //   const warningMessage = `CTR last 7 days was ${Math.round(
      //     (comparisonPeriod.ctr / referencePeriod.ctr - 1) * 100 * -1
      //   )}% lower than the preceding 7 days.`;

      //   warningLevel = 3;
      //   highestWarningLevel =
      //     highestWarningLevel < warningLevel
      //       ? warningLevel
      //       : highestWarningLevel;

      //   warningMessages.push({ documentId: subRow._id,
      //     warningLevel,
      //     message: <span style={{ color: 'orange' }}>{warningMessage}</span>,
      //     rawMessage: warningMessage
      //   });
      // }
      // if (
      //   ['dv360_insertion_order'].includes(subRow.type) &&
      //   comparisonPeriod.viewableRate &&
      //   referencePeriod.viewableRate &&
      //   comparisonPeriod.viewableRate / referencePeriod.viewableRate < 0.7
      // ) {
      //   const warningMessage = `Viewable Rate last 7 days was ${Math.round(
      //     (comparisonPeriod.viewableRate / referencePeriod.viewableRate - 1) *
      //       100 *
      //       -1
      //   )}% lower than the preceding 7 days.`;

      //   warningLevel = 3;
      //   highestWarningLevel =
      //     highestWarningLevel < warningLevel
      //       ? warningLevel
      //       : highestWarningLevel;

      //   warningMessages.push({ documentId: subRow._id,
      //     warningLevel,
      //     message: <span style={{ color: 'orange' }}>{warningMessage}</span>,
      //     rawMessage: warningMessage
      //   });
      // }
      // if (
      //   ['google_ads_campaign', 'googleAds'].includes(subRow.type) &&
      //   comparisonPeriod.searchImpressionShare &&
      //   referencePeriod.searchImpressionShare &&
      //   comparisonPeriod.searchImpressionShare /
      //     referencePeriod.searchImpressionShare <
      //     0.7
      // ) {
      //   const warningMessage = `Search Impression Share last 7 days was ${Math.round(
      //     (comparisonPeriod.searchImpressionShare /
      //       referencePeriod.searchImpressionShare -
      //       1) *
      //       100 *
      //       -1
      //   )}% lower than the preceding 7 days.`;

      //   warningLevel = 3;
      //   highestWarningLevel =
      //     highestWarningLevel < warningLevel
      //       ? warningLevel
      //       : highestWarningLevel;

      //   warningMessages.push({ documentId: subRow._id,
      //     warningLevel,
      //     message: <span style={{ color: 'orange' }}>{warningMessage}</span>,
      //     rawMessage: warningMessage
      //   });
      // }

      let costThresholdForWarnings = 100;

      if (subRow?.dataSourceCurrency && subRow.cost) {
        switch (subRow?.dataSourceCurrency) {
          case 'EUR':
            break;
          case 'SEK':
          case 'DKK':
          case 'NOK':
            costThresholdForWarnings = 1000;
            break;
        }
      }

      if (
        // referencePeriod.impressions > 3000 ||
        referencePeriod.cost > costThresholdForWarnings
      ) {
        for (let key of [
          'cpm',
          // 'cpc',
          ...(referencePeriod.clicks > 50 ? ['cpc'] : []),
          ...(referencePeriod.action01Count > 20 ? ['action01Cpa'] : []),
          ...(referencePeriod.action02Count > 20 ? ['action02Cpa'] : []),
          ...(referencePeriod.action03Count > 20 ? ['action03Cpa'] : [])

          // 'action01Roas',
          // 'action02Roas',
          // 'action03Roas'
        ]) {
          if (
            // comparisonPeriod?.[key] &&
            referencePeriod?.[key] &&
            comparisonPeriod?.[key] / referencePeriod?.[key] > 1.2
          ) {
            const warningMessage = `${key} ${Math.round(
              (comparisonPeriod?.[key] / referencePeriod?.[key] - 1) * 100
            )}% higher the last 7 days compared to the preceding 7 days.`;

            warningLevel = 3;
            highestWarningLevel =
              highestWarningLevel < warningLevel
                ? warningLevel
                : highestWarningLevel;

            warningMessages.push({
              relatedFields: [key],
              documentId: subRow._id,
              type: 'PERFORMANCE_DIP',
              warningLevel,
              warningColor: 'orange',
              message: (
                <span style={{ color: 'orange' }}>{warningMessage}</span>
              ),
              rawMessage: warningMessage
            });
          } else if (
            referencePeriod?.[key] &&
            comparisonPeriod?.[key] / referencePeriod?.[key] < 0.8
          ) {
            const warningMessage = `${key} ${Math.round(
              (comparisonPeriod?.[key] / referencePeriod?.[key] - 1) * 100 * -1
            )}% lower the last 7 days compared to the preceding 7 days.`;

            warningLevel = -1;
            highestWarningLevel =
              highestWarningLevel < warningLevel
                ? warningLevel
                : highestWarningLevel;

            warningMessages.push({
              relatedFields: [key],
              documentId: subRow._id,
              type: 'PERFORMANCE_RISE',
              warningLevel,
              warningColor: greenWarningColor,
              message: (
                <span style={{ color: greenWarningColor }}>
                  {warningMessage}
                </span>
              ),
              rawMessage: warningMessage
            });
          }
        }

        for (let key of [
          ...(referencePeriod.clicks > 50 ? ['ctr'] : []),
          ...(['google_ads_campaign', 'googleAds'].includes(subRow.type)
            ? ['searchImpressionShare']
            : []),
          ...(['dv360_insertion_order'].includes(subRow.type)
            ? ['viewableRate']
            : []),
          // 'action01Cpa',
          // 'action02Cpa',
          // 'action03Cpa',
          ...(referencePeriod.action01Count > 20 ? ['action01Roas'] : []),
          ...(referencePeriod.action02Count > 20 ? ['action02Roas'] : []),
          ...(referencePeriod.action03Count > 20 ? ['action03Roas'] : [])
        ]) {
          if (
            // comparisonPeriod?.[key] &&
            referencePeriod?.[key] &&
            comparisonPeriod?.[key] / referencePeriod?.[key] < 0.8
          ) {
            const warningMessage = `${key} ${Math.round(
              (comparisonPeriod?.[key] / referencePeriod?.[key] - 1) * 100 * -1
            )}% lower the last 7 days compared to the preceding 7 days.`;

            warningLevel = 3;
            highestWarningLevel =
              highestWarningLevel < warningLevel
                ? warningLevel
                : highestWarningLevel;

            warningMessages.push({
              relatedFields: [key],
              documentId: subRow._id,
              type: 'PERFORMANCE_DIP',
              warningLevel,
              warningColor: 'orange',
              message: (
                <span style={{ color: 'orange' }}>{warningMessage}</span>
              ),
              rawMessage: warningMessage
            });
          } else if (
            referencePeriod?.[key] &&
            comparisonPeriod?.[key] / referencePeriod?.[key] > 1.2
          ) {
            const warningMessage = `${key} ${Math.round(
              (comparisonPeriod?.[key] / referencePeriod?.[key] - 1) * 100
            )}% higher the last 7 days compared to the preceding 7 days.`;

            warningLevel = -1;
            highestWarningLevel =
              highestWarningLevel < warningLevel
                ? warningLevel
                : highestWarningLevel;

            warningMessages.push({
              relatedFields: [key],
              documentId: subRow._id,
              type: 'PERFORMANCE_RISE',
              warningLevel,
              warningColor: greenWarningColor,
              message: (
                <span style={{ color: greenWarningColor }}>
                  {warningMessage}
                </span>
              ),
              rawMessage: warningMessage
            });
          }
        }
      }
    }

    // #########################################################

    subRow.highestWarningLevel = highestWarningLevel;

    const warningFieldColorDictionary = {};

    if (warningMessages.length > 0) {
      warningMessages?.sort((a, b) =>
        a.highestWarningLevel < b.highestWarningLevel
          ? -1
          : b.highestWarningLevel < a.highestWarningLevel
          ? 1
          : 0
      );

      for (let warningMessage of warningMessages) {
        for (let field of warningMessage?.relatedFields || []) {
          warningFieldColorDictionary[field] = warningMessage.warningColor;
        }
      }

      subRow.warningFieldColorDictionary = warningFieldColorDictionary;

      subRow.warnings = [
        ...subRow.warnings,
        {
          path: subRow.path,
          name: subRow.name,
          messages: warningMessages,
          highestWarningLevel
        }
      ];

      const relatedWarningFields = [];
      for (let warningMessage of warningMessages) {
        for (let warningField of warningMessage?.relatedFields || []) {
          if (!relatedWarningFields.includes(warningField)) {
            relatedWarningFields.push(warningField);
          }
        }
      }

      subRow.relatedWarningFields = relatedWarningFields;
    }

    // ######### subWarnings ##########

    supRow.snackbarWarnings = [
      ...supRow.snackbarWarnings,
      ...subRow.snackbarWarnings,
      ...snackbarWarnings
    ];

    supRow.subWarnings = [
      ...supRow.subWarnings,
      ...subRow.warnings,
      ...subRow.subWarnings
    ];

    // subRow.warnings = [...subRow.warnings, ...warningMessages];
  };

  // ######################################## checkboxes ########################################################

  // const [checkedBoxes, setCheckedBoxes] = useState([]);

  useEffect(() => {
    setCheckedBoxes([]);
  }, [currentAccountIds]);

  // ######################################### v2 ################################################################

  // const [listOfData, setListOfData] = useState([]);

  function unique(arr) {
    var u = {},
      a = [];
    for (var i = 0, l = arr.length; i < l; ++i) {
      if (!u.hasOwnProperty(arr[i])) {
        a.push(arr[i]);
        u[arr[i]] = 1;
      }
    }
    return a;
  }
  const [dataSourceNames, setDataSourceNames] = useState([]);

  useEffect(() => {
    // names
    const allNames = listOfData.map((row) => row.name);
    const uniqueNames = unique(allNames);
    setNames(uniqueNames);

    const allInvoiceOrderIds = listOfData.map((row) => row.invoiceOrderId);
    const uniqueInvoiceOrderids = unique(allInvoiceOrderIds);
    const filteredUniqueInvoiceOrderids = uniqueInvoiceOrderids.filter(
      (obj) => obj
    );
    setInvoiceOrderIds(filteredUniqueInvoiceOrderids);

    //dataSourceNames

    // const allDataSourceNames = listOfData.map(row=>{row.name});

    var reduced = listOfData.reduce(function (filtered, row) {
      if (
        [
          'facebook_ads_campaign',
          'google_ads_campaign',
          'cm360_campaign',
          'dv360_insertion_order',
          'snapchat_ads_campaign',
          'tiktok_ads_campaign',
          'pinterest_ads_campaign',
          'readpeak_campaign',
          'strossle_campaign',
          'amazon_dsp_order',
          'taboola_ads_campaign',
          'microsoft_advertising_campaign',
          'apple_search_ads_campaign',
          'adform_order',
          'linkedin_ads_campaign_group'
        ].includes(row.type)
      ) {
        filtered.push(row.name);
      }
      return filtered;
    }, []);

    const uniqueDataSourceNames = unique(reduced);
    setDataSourceNames(uniqueDataSourceNames);
  }, [listOfData]);

  // let total_list_of_propagation_data = [];

  // ###################################################################################################

  const prepareContainerIdsAndNames = (
    prepParentContainerRow,
    nexusDocument,
    level,
    id,
    name,
    type,
    style
  ) => {
    // #### actions ####

    for (let itemKey of ['action01', 'action02', 'action03']) {
      const action = nexusDocument?.[itemKey];
      // const nexusDocument = overrideValues;

      let actionName = '';
      switch (true) {
        // case nexusDocument?.[action].dataSource === 'googleAnalytics4':
        //   nexusDocument[action].name =
        //     nexusDocument?.[action]?.googleAnalytics4?.eventName;
        //   break;

        case action?.dataSource === 'facebookAds':
          actionName = action?.[action?.dataSource]?.name;
          break;

        case [
          'adform',
          'appleSearchAds',
          'googleAds',
          'snapchatAds',
          'tikTokAds',
          'pinterestAds',
          'readpeak',
          'strossle',
          'amazonDsp',
          'taboolaAds',
          'microsoftAdvertising',
          'linkedInAds'
        ].includes(action?.dataSource):
          actionName = action?.[action?.dataSource]?.name;
          break;

        case action?.dataSource === 'cm360':
        case action?.dataSource === 'dv360':
          actionName = action?.[action?.dataSource]?.activityName;
          break;
      }
      if (action && actionName) {
        nexusDocument[itemKey].name = actionName;
      }

      // switch (true) {
      //   // case nexusDocument?.[action].dataSource === 'googleAnalytics4':
      //   //   nexusDocument[action].name =
      //   //     nexusDocument?.[action]?.googleAnalytics4?.eventName;
      //   //   break;

      //   case nexusDocument.documentType === 'facebookAds':
      //     nexusDocument[action].name =
      //       nexusDocument?.[action]?.[nexusDocument.documentType]?.name;
      //     break;

      //   case [
      //     'adform',
      //     'appleSearchAds',
      //     'googleAds',
      //     'snapchatAds',
      //     'tikTokAds',
      //     'pinterestAds',
      //     'readpeak',
      //     'strossle',
      //     'amazonDsp',
      //     'taboolaAds',
      //     'microsoftAdvertising',
      //     'linkedInAds'
      //   ].includes(nexusDocument.documentType):
      //     nexusDocument[action].name =
      //       nexusDocument[action]?.[nexusDocument.documentType]?.name;
      //     break;

      //   case nexusDocument.documentType === 'cm360':
      //   case nexusDocument.documentType === 'dv360':
      //     nexusDocument[action].name =
      //       nexusDocument?.[action]?.[nexusDocument.documentType]?.activityName;
      //     break;
      // }
    }

    const prepContainerRow = {
      _id: id,
      level,
      isSetChild: Boolean(
        prepParentContainerRow.isSet || prepParentContainerRow.isSetChild
      ),
      accountName: prepParentContainerRow?.accountName,
      accountId: prepParentContainerRow?.accountId,
      accountColor: prepParentContainerRow?.accountColor,
      accountTimeZone: prepParentContainerRow?.accountTimeZone,
      name: nexusDocument.name,
      totalData: prepParentContainerRow.totalData,
      action01googleanalytics4data:
        nexusDocument.action01googleanalytics4data || [],
      action02googleanalytics4data:
        nexusDocument.action02googleanalytics4data || [],
      action03googleanalytics4data:
        nexusDocument.action03googleanalytics4data || [],
      comments: nexusDocument.comments,

      action01Type: nexusDocument?.action01?.type,
      action02Type: nexusDocument?.action02?.type,
      action03Type: nexusDocument?.action03?.type,

      action01Name: nexusDocument?.action01?.name,
      action02Name: nexusDocument?.action02?.name,
      action03Name: nexusDocument?.action03?.name,

      _members: [
        ...(prepParentContainerRow._members || []),
        ...(nexusDocument.members || [])
      ],

      _labels: [
        ...(prepParentContainerRow._labels || []),
        ...(nexusDocument.labels || [])
      ]
    };

    const rawIntRange = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
    const intRange = [];

    for (let num of rawIntRange) {
      if (num <= level) {
        intRange.push(num);
      }
    }

    for (let num of intRange) {
      prepContainerRow['level_' + num + '_container_id'] =
        prepParentContainerRow['level_' + num + '_container_id'];
      prepContainerRow['level_' + num + '_container_name'] =
        prepParentContainerRow['level_' + num + '_container_name'];
    }

    prepContainerRow['level_' + level + '_container_id'] = id;
    prepContainerRow['level_' + level + '_container_name'] = name;

    // ############# generate path #############
    let path = ' / ';
    let interactivePath = [];

    prepContainerRow.interactivePath = [
      ...prepParentContainerRow.interactivePath,
      {
        type: prepContainerRow.type,
        documentType: prepContainerRow.documentType,
        _id: prepContainerRow._id,
        name: prepContainerRow.name,
        level: prepContainerRow.level,
        accountId: prepContainerRow.accountId,
        level_1_container_id: prepContainerRow.level_1_container_id,
        level_2_container_id: prepContainerRow.level_2_container_id,
        level_3_container_id: prepContainerRow.level_3_container_id,
        level_4_container_id: prepContainerRow.level_4_container_id,
        level_5_container_id: prepContainerRow.level_5_container_id,
        level_6_container_id: prepContainerRow.level_6_container_id,
        level_7_container_id: prepContainerRow.level_7_container_id,
        level_8_container_id: prepContainerRow.level_8_container_id,
        level_9_container_id: prepContainerRow.level_9_container_id,
        level_10_container_id: prepContainerRow.level_10_container_id,
        level_11_container_id: prepContainerRow.level_11_container_id,
        level_12_container_id: prepContainerRow.level_12_container_id,
        level_13_container_id: prepContainerRow.level_13_container_id,
        level_14_container_id: prepContainerRow.level_14_container_id
      }
    ];
    for (let num of intRange) {
      const addToPath = prepContainerRow['level_' + num + '_container_name']
        ? prepContainerRow['level_' + num + '_container_name'] + ' / '
        : 'Data source' + ' / ';

      // not add path to ad groups and adsets. This will be added when parsing the data.
      if (
        [
          'facebook_ads_ad_set',
          'google_ads_ad_group',
          'dv360_line_item',
          'snapchat_ads_ad_squad'
        ].includes(type) &&
        level === num
      ) {
        path = path;
      } else {
        path = path + addToPath;
      }
    }

    prepContainerRow.path = path;
    prepContainerRow.style = style;

    return prepContainerRow;
  };

  const prepareDataSourceNexusDocument = (nexusDocument) => {
    const preparedNexusDocument = { ...nexusDocument };

    return preparedNexusDocument;
  };

  const prepareDataList = (
    prepGrandParentContainerRow,
    parentNexusDocument,
    level,
    accountName
  ) => {
    if (parentNexusDocument.status !== 'REMOVED' || statusFilter === 'All') {
      // console.log('parentNexusDocument: ', parentNexusDocument);
      const rowBaseKVs = rowBaseKV(
        parentNexusDocument,
        parentNexusDocument.documentType,
        ''
      );

      const preparedContainerAndIds = prepareContainerIdsAndNames(
        prepGrandParentContainerRow,
        parentNexusDocument,
        level,
        parentNexusDocument._id,
        parentNexusDocument.name,
        parentNexusDocument.documentType,
        // 'folder',
        parentNexusDocument.style
      );
      let prepParentContainerRow = {
        ...rowBaseKVs,
        ...preparedContainerAndIds
      };

      //if facebook

      if (parentNexusDocument.nexusDocuments) {
        for (let nexusDocument of parentNexusDocument.nexusDocuments) {
          if (nexusDocument.status !== 'REMOVED' || statusFilter === 'All') {
            let campaignBaseValues;
            let adsetBaseValues;

            const documentType = nexusDocument.documentType;
            const isSet = nexusDocument.isSet;
            switch (true) {
              case documentType === 'totalActionsDocument':
                const totalActionsDocumentKVs = rowBaseKV(
                  nexusDocument,
                  'manualDataEntry',
                  'manual_input'
                );
                // manualDataEntryKV(nexusDocument);

                const totalActionsDocumentPreparedDocument =
                  prepareContainerIdsAndNames(
                    prepParentContainerRow,
                    nexusDocument,
                    level + 1,
                    nexusDocument._id,
                    nexusDocument.name,
                    'totalActionsDocument',
                    nexusDocument.style
                  );

                const totalActionsDocumentManualDataEntry = {
                  ...totalActionsDocumentKVs,
                  ...totalActionsDocumentPreparedDocument
                };

                // rowCalculations(
                //   prepContainerRow,
                //   totalActionsDocumentManualDataEntry
                // );

                listOfData2.push(totalActionsDocumentManualDataEntry);
                break;
              case documentType === 'folder':
              case documentType === 'task':
              case documentType === 'subTask':
              case documentType === 'taxonomy':
              case isSet === true:
                const prepChildContainerRow = prepareDataList(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1
                );

                rowCalculations(prepParentContainerRow, prepChildContainerRow);

                break;
              case documentType === 'facebookAds':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'facebook_ads_campaign',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'facebook_ads_ad_set'
                );

                if (dataLevel === 'campaign') {
                  const {
                    prepared_campaign: prepared_campaign_fb
                    // prepared_listOfAdsets: prepared_listOfAdsets_fb
                  } = parse_fb_campaign_data_aggregated(
                    rowBaseKV(nexusDocument, 'facebookAds', 'facebook_ads'),
                    campaignBaseValues,
                    selectedDateStart,
                    selectedDateStop,
                    adsetBaseValues,
                    range2,
                    startDateForLastDaysData,
                    startDateForLastDaysDataMinusOneDay,
                    tomorrow,
                    dayAfterTomorrow
                  );

                  listOfData2.push(prepared_campaign_fb);
                  // listOfData2 = [...listOfData2, ...prepared_listOfAdsets_fb];

                  rowCalculations(prepParentContainerRow, prepared_campaign_fb);
                } else {
                  const {
                    prepared_campaign: prepared_campaign_fb,
                    prepared_listOfAdsets: prepared_listOfAdsets_fb
                  } = parse_fb_data(
                    rowBaseKV(nexusDocument, 'facebookAds', 'facebook_ads'),
                    campaignBaseValues,
                    selectedDateStart,
                    selectedDateStop,
                    adsetBaseValues,
                    range2
                  );

                  listOfData2.push(prepared_campaign_fb);
                  listOfData2 = [...listOfData2, ...prepared_listOfAdsets_fb];

                  rowCalculations(prepParentContainerRow, prepared_campaign_fb);
                }

                break;
              case documentType === 'googleAds':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  prepareDataSourceNexusDocument(nexusDocument),
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'google_ads_campaign',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'google_ads_ad_group',
                  nexusDocument.style
                );

                // console.log('before parse_google_ads_data');

                if (
                  dataLevel === 'adset' &&
                  ['SEARCH', 'SHOPPING', 'VIDEO'].includes(
                    nexusDocument.campaignType
                  )
                ) {
                  console.log('dataLevel: ', dataLevel);
                  const {
                    prepared_campaign: prepared_campaign_google_ads,
                    prepared_listOfAdsets: prepared_listOfAdsets_google_ads
                  } = parse_google_ads_data(
                    rowBaseKV(nexusDocument, 'googleAds', 'google_ads'),
                    campaignBaseValues,
                    selectedDateStart,
                    selectedDateStop,
                    adsetBaseValues,
                    range2,
                    startDateForLastDaysData,
                    startDateForLastDaysDataMinusOneDay,
                    tomorrow,
                    dayAfterTomorrow
                  );

                  listOfData2.push(prepared_campaign_google_ads);
                  listOfData2 = [
                    ...listOfData2,
                    ...prepared_listOfAdsets_google_ads
                  ];
                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_google_ads
                  );
                } else {
                  const { prepared_campaign: prepared_campaign_google_ads } =
                    parse_google_ads_campaign_data_aggregated(
                      rowBaseKV(nexusDocument, 'googleAds', 'google_ads'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2,
                      startDateForLastDaysData,
                      startDateForLastDaysDataMinusOneDay,
                      tomorrow,
                      dayAfterTomorrow
                    );

                  listOfData2.push(prepared_campaign_google_ads);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_google_ads
                  // ];
                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_google_ads
                  );
                }

                break;

              case documentType === 'cm360':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'cm360_campaign',
                  nexusDocument.style
                );

                // adsetBaseValues = prepareContainerIdsAndNames(
                //   campaignBaseValues,
                //   nexusDocument,
                //   level + 2,
                //   nexusDocument._id,
                //   nexusDocument.name,
                //   'google_ads_ad_group',
                //   nexusDocument.style
                // );

                if (true) {
                  const { prepared_campaign: prepared_campaign_cm360 } =
                    parse_cm360_campaign_data_aggregated(
                      rowBaseKV(nexusDocument, 'cm360', 'cm360'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2,
                      startDateForLastDaysData,
                      startDateForLastDaysDataMinusOneDay,
                      tomorrow,
                      dayAfterTomorrow
                    );

                  listOfData2.push(prepared_campaign_cm360);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_google_ads
                  // ];
                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_cm360
                  );
                } else {
                  // const {
                  //   prepared_campaign: prepared_campaign_google_ads,
                  //   prepared_listOfAdsets: prepared_listOfAdsets_google_ads
                  // } = parse_google_ads_data(
                  //   nexusDocument,
                  //   campaignBaseValues,
                  //   selectedDateStart,
                  //   selectedDateStop,
                  //   adsetBaseValues,
                  //   range2
                  // );
                  // listOfData2.push(prepared_campaign_google_ads);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_google_ads
                  // ];
                  // rowCalculations(
                  //   prepParentContainerRow,
                  //   prepared_campaign_google_ads
                  // );
                }

                break;

              case documentType === 'dv360':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'dv360_insertion_order',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'dv360_line_item',
                  nexusDocument.style
                );

                if (dataLevel === 'campaign') {
                  const { prepared_campaign: prepared_campaign_dv360 } =
                    parse_dv360_insertion_order_data_aggregated(
                      rowBaseKV(nexusDocument, 'dv360', 'dv360'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2,
                      startDateForLastDaysData,
                      startDateForLastDaysDataMinusOneDay,
                      tomorrow,
                      dayAfterTomorrow
                    );

                  listOfData2.push(prepared_campaign_dv360);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_google_ads
                  // ];
                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_dv360
                  );
                } else {
                  const {
                    prepared_campaign: prepared_campaign_dv360,
                    prepared_listOfAdsets: prepared_listOfAdsets_dv360
                  } = parse_dv360_line_item_data(
                    rowBaseKV(nexusDocument, 'dv360', 'dv360'),
                    campaignBaseValues,
                    selectedDateStart,
                    selectedDateStop,
                    adsetBaseValues,
                    range2
                  );

                  listOfData2.push(prepared_campaign_dv360);
                  listOfData2 = [
                    ...listOfData2,
                    ...prepared_listOfAdsets_dv360
                  ];

                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_dv360
                  );
                }

                break;

              case documentType === 'snapchatAds':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'snapchat_ads_campaign',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'snapchat_ads_ad_squad',
                  nexusDocument.style
                );

                if (
                  // dataLevel === 'campaign'
                  true
                ) {
                  const { prepared_campaign: prepared_campaign_snapchat_ads } =
                    parse_snapchat_ads_campaign_data_aggregated(
                      rowBaseKV(nexusDocument, 'snapchatAds', 'snapchatAds'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2,
                      startDateForLastDaysData,
                      startDateForLastDaysDataMinusOneDay,
                      tomorrow,
                      dayAfterTomorrow
                    );

                  listOfData2.push(prepared_campaign_snapchat_ads);

                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_snapchat_ads
                  );
                } else {
                  // const {
                  //   prepared_campaign: prepared_campaign_snapchat_ads,
                  //   prepared_listOfAdsets: prepared_listOfAdsets_snapchat_ads
                  // } = parse_snapchat_ads_ad_squad_data(
                  //   rowBaseKV(nexusDocument, 'snapchatAds', 'snapchatAds'),
                  //   campaignBaseValues,
                  //   selectedDateStart,
                  //   selectedDateStop,
                  //   adsetBaseValues,
                  //   range2
                  // );
                  // listOfData2.push(prepared_campaign_snapchat_ads);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_snapchat_ads
                  // ];
                  // rowCalculations(
                  //   prepParentContainerRow,
                  //   prepared_campaign_snapchat_ads
                  // );
                }

                break;

              case documentType === 'tikTokAds':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'tiktok_ads_campaign',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'tiktok_ads_ad_group',
                  nexusDocument.style
                );

                if (
                  // dataLevel === 'campaign'
                  true
                ) {
                  const { prepared_campaign: prepared_campaign_tiktok_ads } =
                    parse_tiktok_ads_campaign_data_aggregated(
                      rowBaseKV(nexusDocument, 'tikTokAds', 'tikTokAds'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2,
                      startDateForLastDaysData,
                      startDateForLastDaysDataMinusOneDay,
                      tomorrow,
                      dayAfterTomorrow
                    );

                  listOfData2.push(prepared_campaign_tiktok_ads);

                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_tiktok_ads
                  );
                } else {
                  // const {
                  //   prepared_campaign: prepared_campaign_tiktok_ads,
                  //   prepared_listOfAdsets: prepared_listOfAdsets_tiktok_ads
                  // } = parse_tiktok_ads_ad_squad_data(
                  //   rowBaseKV(nexusDocument, 'tiktokAds', 'tiktokAds'),
                  //   campaignBaseValues,
                  //   selectedDateStart,
                  //   selectedDateStop,
                  //   adsetBaseValues,
                  //   range2
                  // );
                  // listOfData2.push(prepared_campaign_tiktok_ads);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_tiktok_ads
                  // ];
                  // rowCalculations(
                  //   prepParentContainerRow,
                  //   prepared_campaign_tiktok_ads
                  // );
                }

                break;

              case documentType === 'pinterestAds':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'pinterest_ads_campaign',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'pinterest_ads_ad_group',
                  nexusDocument.style
                );

                if (
                  // dataLevel === 'campaign'
                  true
                ) {
                  const { prepared_campaign: prepared_campaign_pinterest_ads } =
                    parse_pinterest_ads_campaign_data_aggregated(
                      rowBaseKV(nexusDocument, 'pinterestAds', 'pinterestAds'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2,
                      startDateForLastDaysData,
                      startDateForLastDaysDataMinusOneDay,
                      tomorrow,
                      dayAfterTomorrow
                    );

                  listOfData2.push(prepared_campaign_pinterest_ads);

                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_pinterest_ads
                  );
                } else {
                  // const {
                  //   prepared_campaign: prepared_campaign_pinterest_ads,
                  //   prepared_listOfAdsets: prepared_listOfAdsets_pinterest_ads
                  // } = parse_pinterest_ads_ad_squad_data(
                  //   rowBaseKV(nexusDocument, 'pinterestAds', 'pinterestAds'),
                  //   campaignBaseValues,
                  //   selectedDateStart,
                  //   selectedDateStop,
                  //   adsetBaseValues,
                  //   range2
                  // );
                  // listOfData2.push(prepared_campaign_pinterest_ads);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_pinterest_ads
                  // ];
                  // rowCalculations(
                  //   prepParentContainerRow,
                  //   prepared_campaign_pinterest_ads
                  // );
                }

                break;

              case documentType === 'readpeak':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'readpeak_campaign',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'readpeak_ad_group',
                  nexusDocument.style
                );

                if (
                  // dataLevel === 'campaign'
                  true
                ) {
                  const { prepared_campaign: prepared_campaign_readpeak } =
                    parse_readpeak_campaign_data_aggregated(
                      rowBaseKV(nexusDocument, 'readpeak', 'readpeak'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2,
                      startDateForLastDaysData,
                      startDateForLastDaysDataMinusOneDay,
                      tomorrow,
                      dayAfterTomorrow
                    );

                  listOfData2.push(prepared_campaign_readpeak);

                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_readpeak
                  );
                } else {
                  // const {
                  //   prepared_campaign: prepared_campaign_readpeak,
                  //   prepared_listOfAdsets: prepared_listOfAdsets_readpeak
                  // } = parse_readpeak_ad_squad_data(
                  //   rowBaseKV(nexusDocument, 'readpeak', 'readpeak'),
                  //   campaignBaseValues,
                  //   selectedDateStart,
                  //   selectedDateStop,
                  //   adsetBaseValues,
                  //   range2
                  // );
                  // listOfData2.push(prepared_campaign_readpeak);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_readpeak
                  // ];
                  // rowCalculations(
                  //   prepParentContainerRow,
                  //   prepared_campaign_readpeak
                  // );
                }

                break;

              case documentType === 'strossle':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'strossle_campaign',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'strossle_ad_set',
                  nexusDocument.style
                );

                if (
                  // dataLevel === 'campaign'
                  true
                ) {
                  const { prepared_campaign: prepared_campaign_strossle } =
                    parse_strossle_campaign_data_aggregated(
                      rowBaseKV(nexusDocument, 'strossle', 'strossle'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2,
                      startDateForLastDaysData,
                      startDateForLastDaysDataMinusOneDay,
                      tomorrow,
                      dayAfterTomorrow
                    );

                  listOfData2.push(prepared_campaign_strossle);

                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_strossle
                  );
                } else {
                  // const {
                  //   prepared_campaign: prepared_campaign_rstrossle,
                  //   prepared_listOfAdsets: prepared_listOfAdsets_strossle
                  // } = parse_strossle_ad_squad_data(
                  //   rowBaseKV(nexusDocument, 'strossle', 'strossle'),
                  //   campaignBaseValues,
                  //   selectedDateStart,
                  //   selectedDateStop,
                  //   adsetBaseValues,
                  //   range2
                  // );
                  // listOfData2.push(prepared_campaign_strossle);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_strossle
                  // ];
                  // rowCalculations(
                  //   prepParentContainerRow,
                  //   prepared_campaign_strossle
                  // );
                }

                break;

              case documentType === 'amazonDsp':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'amazon_dsp_order',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'amazon_dsp_line_item',
                  nexusDocument.style
                );

                if (
                  // dataLevel === 'campaign'
                  true
                ) {
                  const { prepared_campaign: prepared_order_amazon_dsp } =
                    parse_amazon_dsp_order_data_aggregated(
                      rowBaseKV(nexusDocument, 'amazonDsp', 'amazonDsp'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2,
                      startDateForLastDaysData,
                      startDateForLastDaysDataMinusOneDay,
                      tomorrow,
                      dayAfterTomorrow
                    );

                  listOfData2.push(prepared_order_amazon_dsp);

                  rowCalculations(
                    prepParentContainerRow,
                    prepared_order_amazon_dsp
                  );
                } else {
                }

                break;

              case documentType === 'taboolaAds':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'taboola_ads_campaign',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'taboola_ads_ad_group',
                  nexusDocument.style
                );

                if (
                  // dataLevel === 'campaign'
                  true
                ) {
                  const { prepared_campaign: prepared_campaign_taboola_ads } =
                    parse_taboola_ads_campaign_data_aggregated(
                      rowBaseKV(nexusDocument, 'taboolaAds', 'taboolaAds'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2,
                      startDateForLastDaysData,
                      startDateForLastDaysDataMinusOneDay,
                      tomorrow,
                      dayAfterTomorrow
                    );

                  listOfData2.push(prepared_campaign_taboola_ads);

                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_taboola_ads
                  );
                } else {
                  // const {
                  //   prepared_campaign: prepared_campaign_taboola_ads,
                  //   prepared_listOfAdsets: prepared_listOfAdsets_taboola_ads
                  // } = parse_taboola_ads_ad_squad_data(
                  //   rowBaseKV(nexusDocument, 'taboolaAds', 'taboolaAds'),
                  //   campaignBaseValues,
                  //   selectedDateStart,
                  //   selectedDateStop,
                  //   adsetBaseValues,
                  //   range2
                  // );
                  // listOfData2.push(prepared_campaign_taboola_ads);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_taboola_ads
                  // ];
                  // rowCalculations(
                  //   prepParentContainerRow,
                  //   prepared_campaign_taboola_ads
                  // );
                }

                break;

              case documentType === 'microsoftAdvertising':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'microsoft_advertising_campaign',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'microsoft_advertising_ad_group',
                  nexusDocument.style
                );

                if (
                  // dataLevel === 'campaign'
                  true
                ) {
                  const {
                    prepared_campaign: prepared_campaign_microsoft_advertising
                  } = parse_microsoft_advertising_campaign_data_aggregated(
                    rowBaseKV(
                      nexusDocument,
                      'microsoftAdvertising',
                      'microsoftAdvertising'
                    ),
                    campaignBaseValues,
                    selectedDateStart,
                    selectedDateStop,
                    adsetBaseValues,
                    range2,
                    startDateForLastDaysData,
                    startDateForLastDaysDataMinusOneDay,
                    tomorrow,
                    dayAfterTomorrow
                  );

                  listOfData2.push(prepared_campaign_microsoft_advertising);

                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_microsoft_advertising
                  );
                } else {
                  // const {
                  //   prepared_campaign: prepared_campaign_microsoft_advertising,
                  //   prepared_listOfAdsets: prepared_listOfAdsets_microsoft_advertising
                  // } = parse_microsoft_advertising_ad_squad_data(
                  //   rowBaseKV(nexusDocument, 'microsoftAdvertising', 'microsoftAdvertising'),
                  //   campaignBaseValues,
                  //   selectedDateStart,
                  //   selectedDateStop,
                  //   adsetBaseValues,
                  //   range2
                  // );
                  // listOfData2.push(prepared_campaign_microsoft_advertising);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_microsoft_advertising
                  // ];
                  // rowCalculations(
                  //   prepParentContainerRow,
                  //   prepared_campaign_microsoft_advertising
                  // );
                }

                break;

              case documentType === 'appleSearchAds':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'apple_search_ads_campaign',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'apple_search_ads_ad_group',
                  nexusDocument.style
                );

                if (
                  // dataLevel === 'campaign'
                  true
                ) {
                  const {
                    prepared_campaign: prepared_campaign_apple_search_ads
                  } = parse_apple_search_ads_campaign_data_aggregated(
                    rowBaseKV(
                      nexusDocument,
                      'appleSearchAds',
                      'appleSearchAds'
                    ),
                    campaignBaseValues,
                    selectedDateStart,
                    selectedDateStop,
                    adsetBaseValues,
                    range2,
                    startDateForLastDaysData,
                    startDateForLastDaysDataMinusOneDay,
                    tomorrow,
                    dayAfterTomorrow
                  );

                  listOfData2.push(prepared_campaign_apple_search_ads);

                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_apple_search_ads
                  );
                } else {
                  // const {
                  //   prepared_campaign: prepared_campaign_apple_search_ads,
                  //   prepared_listOfAdsets: prepared_listOfAdsets_apple_search_ads
                  // } = parse_apple_search_ads_ad_squad_data(
                  //   rowBaseKV(nexusDocument, 'appleSearchAds', 'appleSearchAds'),
                  //   campaignBaseValues,
                  //   selectedDateStart,
                  //   selectedDateStop,
                  //   adsetBaseValues,
                  //   range2
                  // );
                  // listOfData2.push(prepared_campaign_apple_search_ads);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_apple_search_ads
                  // ];
                  // rowCalculations(
                  //   prepParentContainerRow,
                  //   prepared_campaign_apple_search_ads
                  // );
                }

                break;

              case documentType === 'adform':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'adform_order',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'adform_line_item',
                  nexusDocument.style
                );

                if (
                  // dataLevel === 'campaign'
                  true
                ) {
                  const { prepared_campaign: prepared_campaign_adform } =
                    parse_adform_order_data_aggregated(
                      rowBaseKV(nexusDocument, 'adform', 'adform'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2,
                      startDateForLastDaysData,
                      startDateForLastDaysDataMinusOneDay,
                      tomorrow,
                      dayAfterTomorrow
                    );

                  listOfData2.push(prepared_campaign_adform);

                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_adform
                  );
                } else {
                  // const {
                  //   prepared_campaign: prepared_campaign_snapchat_ads,
                  //   prepared_listOfAdsets: prepared_listOfAdsets_snapchat_ads
                  // } = parse_snapchat_ads_ad_squad_data(
                  //   rowBaseKV(nexusDocument, 'snapchatAds', 'snapchatAds'),
                  //   campaignBaseValues,
                  //   selectedDateStart,
                  //   selectedDateStop,
                  //   adsetBaseValues,
                  //   range2
                  // );
                  // listOfData2.push(prepared_campaign_snapchat_ads);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_snapchat_ads
                  // ];
                  // rowCalculations(
                  //   prepParentContainerRow,
                  //   prepared_campaign_snapchat_ads
                  // );
                }

                break;
              case documentType === 'linkedInAds':
                campaignBaseValues = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'linkedin_ads_campaign_group',
                  nexusDocument.style
                );

                adsetBaseValues = prepareContainerIdsAndNames(
                  campaignBaseValues,
                  nexusDocument,
                  level + 2,
                  nexusDocument._id,
                  nexusDocument.name,
                  'linkedin_ads_campaign',
                  nexusDocument.style
                );

                if (
                  // dataLevel === 'campaign'
                  true
                ) {
                  const { prepared_campaign: prepared_campaign_adform } =
                    parse_linkedin_ads_campaign_group_data_aggregated(
                      rowBaseKV(nexusDocument, 'linkedInAds', 'linkedInAds'),
                      campaignBaseValues,
                      selectedDateStart,
                      selectedDateStop,
                      adsetBaseValues,
                      range2,
                      startDateForLastDaysData,
                      startDateForLastDaysDataMinusOneDay,
                      tomorrow,
                      dayAfterTomorrow
                    );

                  listOfData2.push(prepared_campaign_adform);

                  rowCalculations(
                    prepParentContainerRow,
                    prepared_campaign_adform
                  );
                } else {
                  // const {
                  //   prepared_campaign: prepared_campaign_snapchat_ads,
                  //   prepared_listOfAdsets: prepared_listOfAdsets_snapchat_ads
                  // } = parse_snapchat_ads_ad_squad_data(
                  //   rowBaseKV(nexusDocument, 'snapchatAds', 'snapchatAds'),
                  //   campaignBaseValues,
                  //   selectedDateStart,
                  //   selectedDateStop,
                  //   adsetBaseValues,
                  //   range2
                  // );
                  // listOfData2.push(prepared_campaign_snapchat_ads);
                  // listOfData2 = [
                  //   ...listOfData2,
                  //   ...prepared_listOfAdsets_snapchat_ads
                  // ];
                  // rowCalculations(
                  //   prepParentContainerRow,
                  //   prepared_campaign_snapchat_ads
                  // );
                }

                break;
              case documentType === 'manualDataEntry':
                const manualDataEntryKVs = rowBaseKV(
                  nexusDocument,
                  'manualDataEntry',
                  'manual_input'
                );
                // manualDataEntryKV(nexusDocument);

                const preparedDocument = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'manualDataEntry',
                  nexusDocument.style
                );

                const manualDataEntry = {
                  ...manualDataEntryKVs,
                  ...preparedDocument,
                  dataSourceData: true
                };

                rowCalculations(prepParentContainerRow, manualDataEntry);

                listOfData2.push(manualDataEntry);
                break;

              case documentType === 'customForm':
                const customFormKVs = rowBaseKV(
                  nexusDocument,
                  'customForm',
                  'customForm'
                );

                const preparedCustomFormDocument = prepareContainerIdsAndNames(
                  prepParentContainerRow,
                  nexusDocument,
                  level + 1,
                  nexusDocument._id,
                  nexusDocument.name,
                  'customForm',
                  nexusDocument.style
                );

                const customForm = {
                  ...customFormKVs,
                  ...preparedCustomFormDocument,
                  dataSourceData: true
                };

                rowCalculations(prepParentContainerRow, customForm);

                listOfData2.push(customForm);
                break;
            }
          }
        }
      }

      listOfData2.push(prepParentContainerRow);
      return prepParentContainerRow;
      // }
    }
  };

  // #################################################################################################
  let listOfData2 = [];
  useEffect(() => {
    // const t0 = performance.now();
    // console.log(`Performance time start.`);
    // console.log('running calculations>data: ', data);
    listOfData2 = [];

    const listOfAccounts = [];
    const listOfTotalData = [];
    let allTotalActionsDocuments = [];
    if (data) {
      const t0 = performance.now();
      console.log(`Performance time start.`);
      // console.log('running calculations>data2: ', data);
      toggleDoneCalculating(false);

      let accounts = [];
      // console.log('RoiTable>row4626>updateAccountId: ', updateAccountId);
      if (updateAccountId) {
        accounts = (data?.accounts || []).filter((account) => {
          return account._id === updateAccountId;
        });
      } else {
        accounts = data?.accounts || [];
      }

      for (let account of accounts) {
        const formulaObjectToSpread = {};

        for (let formulaKey of allEvalFields) {
          formulaObjectToSpread[formulaKey + 'Formula'] = account[formulaKey];
          formulaObjectToSpread[formulaKey + 'Sparkline'] = [];
          formulaObjectToSpread[formulaKey] = null;
          formulaObjectToSpread[formulaKey + 'Today'] = null;
          formulaObjectToSpread[formulaKey + 'Yesterday'] = null;
          formulaObjectToSpread[formulaKey + 'SumChild'] = null;
        }

        // const spreadObject = {};
        // for (let key of Object.keys(account)) {
        //   if (!allEvalFields.includes(key)) {
        //     spreadObject[key] = account[key];
        //   } else {
        //     spreadObject[key] = null;
        //   }
        // }

        const spreadObject = {};
        for (let key of Object.keys(account)) {
          if (['nexusDocuments'].includes(key)) {
            continue;
          } else if (!allEvalFields.includes(key)) {
            spreadObject[key] = account[key];
          } else {
            spreadObject[key] = null;
          }
        }

        const accountRow = {
          ...formulaObjectToSpread,
          ...spreadObject,
          accountColor:
            account?.style?.gantt?.bar?.backgroundColor || 'rgb(49, 80, 161)',
          accountTimeZone: account?.timeZone,
          level: 1,
          warnings: [],
          accountAccessLevel: account.accountAccessLevel,
          level_1_container_id: account._id,
          level_1_container_name: account.name,
          _id: account._id,
          childDocumentIds: [],
          accountId: account._id,
          accountName: account.name,
          name: account.name,
          status: account.status,
          snackbarWarnings: [],
          warning: [],
          subWarnings: [],
          budgetUntillNow: null,

          childRows: [],
          documentType: 'account',
          type: 'account',
          budget: null,
          action01: account.action01,
          action02: account.action02,
          action03: account.action03,
          totalAction01: account.totalAction01,
          totalAction02: account.totalAction02,
          totalAction03: account.totalAction03,
          style: account.style,

          visible: true,
          path: ' / ' + account.name + ' / ',
          interactivePath: [
            {
              _id: account._id,
              level_1_container_id: account._id,
              name: account.name,
              level_1_container_name: account.name,
              level: 1
            }
          ],
          total_list_of_propagation_data: []
        };

        // calculate total actions

        const totalActionsDocuments = account.nexusDocuments.filter(
          (nexusDocument) =>
            nexusDocument.documentType === 'totalActionsDocument'
        );

        const totalData = [];

        // console.log('totalActionsDocuments: ', totalActionsDocuments);

        for (let totalActionsDocument of totalActionsDocuments) {
          allTotalActionsDocuments.push({
            ...totalActionsDocument,
            accountId: account._id
          });
          let totalDataObject = {};

          let totalAction01 = {};
          let totalAction02 = {};
          let totalAction03 = {};

          let totalAction01DataSource =
            totalActionsDocument?.totalAction01?.dataSource;
          let totalAction02DataSource =
            totalActionsDocument?.totalAction02?.dataSource;
          let totalAction03DataSource =
            totalActionsDocument?.totalAction03?.dataSource;

          totalDataObject = {
            totalActionsDocumentId: totalActionsDocument._id,
            totalData: [],
            accountId: account._id
          };

          let testCount = 0;
          let testValue = 0;

          const googleAnalytics4PropagationData = [];

          if (
            totalActionsDocument?.totalAction01?.dataSource ===
              'googleAnalytics4' &&
            totalActionsDocument.totalAction01googleanalytics4data
          ) {
            for (let ga4Object of totalActionsDocument.totalAction01googleanalytics4data) {
              totalDataObject.totalData.push({
                eventName: ga4Object.eventName,
                source: ga4Object.source,
                date_start: ga4Object.date,
                totalAction01Count: ga4Object.eventCount,
                totalAction01Value: ga4Object.eventValue
              });
            }
          }

          if (
            totalActionsDocument?.totalAction02?.dataSource ===
              'googleAnalytics4' &&
            totalActionsDocument.totalAction02googleanalytics4data
          ) {
            for (let ga4Object of totalActionsDocument.totalAction02googleanalytics4data) {
              totalDataObject.totalData.push({
                eventName: ga4Object.eventName,
                source: ga4Object.source,
                date_start: ga4Object.date,
                totalAction02Count: ga4Object.eventCount,
                totalAction02Value: ga4Object.eventValue
              });
            }
          }

          if (
            totalActionsDocument?.totalAction03?.dataSource ===
              'googleAnalytics4' &&
            totalActionsDocument.totalAction03googleanalytics4data
          ) {
            for (let ga4Object of totalActionsDocument.totalAction03googleanalytics4data) {
              totalDataObject.totalData.push({
                accountId: account._id,
                eventName: ga4Object.eventName,
                source: ga4Object.source,
                date_start: ga4Object.date,
                totalAction03Count: ga4Object.eventCount,
                totalAction03Value: ga4Object.eventValue
              });
            }
          }

          listOfTotalData.push(totalDataObject);
          totalData.push(totalDataObject);
        }

        if (account.nexusDocuments) {
          for (let nexusDocument of account.nexusDocuments) {
            if (nexusDocument.status !== 'REMOVED' || statusFilter === 'All') {
              let prepChildContainerRow = prepareDataList(
                accountRow,
                nexusDocument,
                2,
                account.name
              );

              rowCalculations(accountRow, prepChildContainerRow);
            }
          }
        }

        rowCalculations(
          {
            total_list_of_propagation_data: [],
            totalData,
            subWarnings: [],
            snackbarWarnings: [],
            childDocumentIds: [],
            childRows: []
          },
          accountRow
        );

        listOfAccounts.push(accountRow);
        listOfData2.push(accountRow);
      }

      let totalSnackbarWarnings = [];

      let summary_total_list_of_propagation_data = [];
      let basicTotalDataTotal = [];
      for (let account of listOfAccounts) {
        totalSnackbarWarnings = [
          ...totalSnackbarWarnings,
          ...account.snackbarWarnings
        ];
        const basicTotalData = [];
        const enrichedAccount = {
          // clicksSparkline: [],
          // ctrSparkline: [],
          // cpcSparkline: [],
          // costSparkline: [],
          // impressionsSparkline: [],

          totalClicksSparkline: [],
          totalCtrSparkline: [],
          totalCpcSparkline: [],
          totalCostSparkline: [],
          totaltrpSparkline: [],
          totalSpotsSparkline: [],
          totalImpressionsSparkline: [],

          totalViewableImpressionsSparkline: [],
          totalMeasurableImpressionsSparkline: [],
          totalEligibleImpressionsSparkline: [],

          totalAction01CountSparkline: [],
          totalAction01ValueSparkline: [],
          totalAction01CpaSparkline: [],
          totalAction01RoasSparkline: [],
          totalaction01CvrSparkline: [],
          totalAction01VpaSparkline: [],

          totalAction02CountSparkline: [],
          totalAction02ValueSparkline: [],
          totalAction02CpaSparkline: [],
          totalAction02RoasSparkline: [],
          totalaction02CvrSparkline: [],
          totalAction02VpaSparkline: [],

          totalAction03CountSparkline: [],
          totalAction03ValueSparkline: [],
          totalAction03CpaSparkline: [],
          totalAction03RoasSparkline: [],
          totalaction03CvrSparkline: [],
          totalAction03VpaSparkline: [],

          cpmSparkline: [],
          vCpmSparkline: [],

          // totalCost: account.cost,
          totalCost: null,
          totaltrp: null,
          totalSpots: null,
          totalImpressions: null,
          totalClicks: null,

          cost: account.cost,
          totalAction01Count: null,
          totalAction02Count: null,
          totalAction03Count: null,

          totalAction01Value: null,
          totalAction02Value: null,
          totalAction03Value: null,

          propData: []
        };

        // #################################

        let cost = null;
        let trp = null;
        let spots = null;
        let impressions = null;
        let viewableImpressions = null;
        let measurableImpressions = null;
        let eligibleImpressions = null;
        let clicks = null;
        let action01Count = null;
        let action02Count = null;
        let action03Count = null;
        let action01Value = null;
        let action02Value = null;
        let action03Value = null;

        let totalAction01Count = null;
        let totalAction02Count = null;
        let totalAction03Count = null;
        let totalAction01Value = null;
        let totalAction02Value = null;
        let totalAction03Value = null;

        // for (let object of subRow.total_list_of_propagation_data) {
        for (let object of [
          // ...totalData.totalData,
          ...account.total_list_of_propagation_data
        ]) {
          // console.log('object: ', object);

          cost = cost + (object.cost || null);
          trp = trp + (object.trp || null);

          spots = spots + (object.spots || null);

          impressions = impressions + (object.impressions || null);
          viewableImpressions =
            viewableImpressions + (object.viewableImpressions || null);
          measurableImpressions =
            measurableImpressions + (object.measurableImpressions || null);
          eligibleImpressions =
            eligibleImpressions + (object.eligibleImpressions || null);

          clicks = clicks + (object.clicks || null);
        }

        if (
          cost ||
          trp ||
          spots ||
          impressions ||
          viewableImpressions ||
          measurableImpressions ||
          eligibleImpressions ||
          clicks
        ) {
          basicTotalData.push({
            accountId: account._id,
            // date_start: date.getTime(),
            totalCost: cost || null,
            totaltrp: trp || null,

            totalSpots: spots || null,

            totalImpressions: impressions || null,

            totalViewableImpressions: viewableImpressions || null,
            totalMeasurableImpressions: measurableImpressions || null,
            totalEligibleImpressions: eligibleImpressions || null,

            totalClicks: clicks || null
          });

          basicTotalDataTotal.push({
            accountId: account._id,
            // date_start: date.getTime(),
            totalCost: cost || null,
            totaltrp: trp || null,

            totalSpots: spots || null,

            totalImpressions: impressions || null,
            totalViewableImpressions: viewableImpressions || null,
            totalMeasurableImpressions: measurableImpressions || null,
            totalEligibleImpressions: eligibleImpressions || null,

            totalClicks: clicks || null
          });
        }

        // ########################

        // let totalAction01Count = 0;
        // let enrichedAccount = {};
        for (let totalActionsDoc of allTotalActionsDocuments) {
          if (totalActionsDoc.accountId === account._id) {
            for (let totalData of listOfTotalData) {
              if (totalActionsDoc._id === totalData.totalActionsDocumentId) {
                enrichedAccount.propData = [
                  ...enrichedAccount.propData,
                  ...totalData.totalData
                ];
                // for (let tData of totalData.totalData) {
                //   console.log('tData: ', tData);

                //   enrichedAccount.totalAction01Count =
                //     enrichedAccount.totalAction01Count +
                //     tData.totalAction01Count;

                // }

                // ######################################################################
                // ######################################################################
                // ######################################################################
                // ######################################################################

                let cost = null;
                let trp = null;
                let spots = null;
                let impressions = null;
                let viewableImpressions = null;
                let measurableImpressions = null;
                let eligibleImpressions = null;
                let clicks = null;
                let action01Count = null;
                let action02Count = null;
                let action03Count = null;
                let action01Value = null;
                let action02Value = null;
                let action03Value = null;

                let totalAction01Count = null;
                let totalAction02Count = null;
                let totalAction03Count = null;
                let totalAction01Value = null;
                let totalAction02Value = null;
                let totalAction03Value = null;

                // for (let object of subRow.total_list_of_propagation_data) {
                for (let object of [
                  ...totalData.totalData,
                  ...account.total_list_of_propagation_data
                ]) {
                  // console.log('object: ', object);

                  // if (
                  //   new Date(parseInt(object.date_start))
                  //     .toISOString()
                  //     .split('T')[0] === date.toISOString().split('T')[0]
                  // ) {
                  cost = cost + (object.cost || null);
                  trp = trp + (object.trp || null);

                  spots = spots + (object.spots || null);

                  impressions = impressions + (object.impressions || null);
                  viewableImpressions =
                    viewableImpressions + (object.viewableImpressions || null);
                  measurableImpressions =
                    measurableImpressions +
                    (object.measurableImpressions || null);
                  eligibleImpressions =
                    eligibleImpressions + (object.eligibleImpressions || null);

                  clicks = clicks + (object.clicks || null);

                  totalAction01Count =
                    totalAction01Count + (object.totalAction01Count || null);

                  totalAction02Count =
                    totalAction02Count + (object.totalAction02Count || null);

                  totalAction03Count =
                    totalAction03Count + (object.totalAction03Count || null);

                  totalAction01Value =
                    totalAction01Value + (object.totalAction01Value || null);

                  totalAction02Value =
                    totalAction02Value + (object.totalAction02Value || null);

                  totalAction03Value =
                    totalAction03Value + (object.totalAction03Value || null);

                  // totalAction01Count =
                  //   totalAction01Count + (object.totalAction01Count || 0);
                  // }
                }

                // basicTotalData.push({
                //   accountId: account._id,
                //   date_start: date.getTime(),
                //   totalCost: cost || null,
                //   totalImpressions: impressions || null,
                //   totalClicks: clicks || null
                // });

                // basicTotalDataTotal.push({
                //   accountId: account._id,
                //   date_start: date.getTime(),
                //   totalCost: cost || null,
                //   totalImpressions: impressions || null,
                //   totalClicks: clicks || null
                // });
                // enrichedAccount.clicksSparkline.push(clicks);
                // enrichedAccount.ctrSparkline.push(
                //   impressions && clicks ? clicks / impressions : 0
                // );
                enrichedAccount.totalaction01CvrSparkline.push(
                  clicks && totalAction01Count ? totalAction01Count / clicks : 0
                );
                enrichedAccount.totalaction02CvrSparkline.push(
                  clicks && totalAction02Count ? totalAction02Count / clicks : 0
                );
                enrichedAccount.totalaction03CvrSparkline.push(
                  clicks && totalAction03Count ? totalAction03Count / clicks : 0
                );

                // enrichedAccount.cpcSparkline.push(
                //   cost && clicks ? cost / clicks.toFixed(2) : 0
                // );

                enrichedAccount.totalCostSparkline.push(cost);
                enrichedAccount.totaltrpSparkline.push(trp);

                enrichedAccount.totalSpotsSparkline.push(spots);

                enrichedAccount.totalImpressionsSparkline.push(impressions);
                enrichedAccount.totalViewableImpressionsSparkline.push(
                  viewableImpressions
                );
                enrichedAccount.totalMeasurableImpressionsSparkline.push(
                  measurableImpressions
                );

                enrichedAccount.totalEligibleImpressionsSparkline.push(
                  eligibleImpressions
                );

                enrichedAccount.totalClicksSparkline.push(clicks);
                // enrichedAccount.impressionsSparkline.push(impressions);
                // enrichedAccount.cpmSparkline.push(
                //   impressions ? (cost || 0) / (impressions / 1000) : 0
                // );

                enrichedAccount.totalAction01CountSparkline.push(
                  totalAction01Count
                );
                enrichedAccount.totalAction01ValueSparkline.push(
                  totalAction01Value
                );
                enrichedAccount.totalAction01VpaSparkline.push(
                  totalAction01Count && totalAction01Value
                    ? totalAction01Value / totalAction01Count
                    : 0
                );

                enrichedAccount.totalAction02CountSparkline.push(
                  totalAction02Count
                );
                enrichedAccount.totalAction02ValueSparkline.push(
                  totalAction02Value
                );
                enrichedAccount.totalAction02VpaSparkline.push(
                  totalAction02Count && totalAction02Value
                    ? totalAction02Value / totalAction02Count
                    : 0
                );

                enrichedAccount.totalAction03CountSparkline.push(
                  totalAction03Count
                );
                enrichedAccount.totalAction03ValueSparkline.push(
                  totalAction03Value
                );
                enrichedAccount.totalAction03VpaSparkline.push(
                  totalAction03Count && totalAction03Value
                    ? totalAction03Value / totalAction03Count
                    : 0
                );

                enrichedAccount.totalAction01CpaSparkline.push(
                  totalAction01Count ? cost / totalAction01Count : 0
                );

                enrichedAccount.totalAction02CpaSparkline.push(
                  totalAction02Count ? cost / totalAction02Count : 0
                );

                enrichedAccount.totalAction03CpaSparkline.push(
                  totalAction03Count ? cost / totalAction03Count : 0
                );

                enrichedAccount.totalAction01RoasSparkline.push(
                  cost ? totalAction01Value / cost : 0
                );

                enrichedAccount.totalAction02RoasSparkline.push(
                  cost ? totalAction02Value / cost : 0
                );

                enrichedAccount.totalAction03RoasSparkline.push(
                  cost ? totalAction03Value / cost : 0
                );

                // enrichedAccount.clicks = enrichedAccount.clicks + clicks;
                enrichedAccount.totalCost =
                  enrichedAccount.totalCost + (cost || null);

                enrichedAccount.totaltrp =
                  enrichedAccount.totaltrp + (trp || null);

                enrichedAccount.totalSpots =
                  enrichedAccount.totalSpots + (spots || null);

                enrichedAccount.totalImpressions =
                  enrichedAccount.totalImpressions + (impressions || null);

                enrichedAccount.totalViewableImpressions =
                  enrichedAccount.totalViewableImpressions +
                  (viewableImpressions || null);
                enrichedAccount.totalMeasurableImpressions =
                  enrichedAccount.totalMeasurableImpressions +
                  (measurableImpressions || null);

                enrichedAccount.totalEligibleImpressions =
                  enrichedAccount.totalEligibleImpressions +
                  (eligibleImpressions || null);

                enrichedAccount.totalClicks =
                  enrichedAccount.totalClicks + (clicks || null);

                enrichedAccount.totalAction01Count =
                  enrichedAccount.totalAction01Count + totalAction01Count;
                enrichedAccount.totalAction02Count =
                  enrichedAccount.totalAction02Count + totalAction02Count;
                enrichedAccount.totalAction03Count =
                  enrichedAccount.totalAction03Count + totalAction03Count;
                enrichedAccount.totalAction01Value =
                  enrichedAccount.totalAction01Value + totalAction01Value;
                enrichedAccount.totalAction02Value =
                  enrichedAccount.totalAction02Value + totalAction02Value;
                enrichedAccount.totalAction03Value =
                  enrichedAccount.totalAction03Value + totalAction03Value;

                // enrichedAccount.totalAction01Count =
                //   enrichedAccount.totalAction01Count + totalAction01Count;
                // enrichedAccount.totalAction02Count =
                //   enrichedAccount.totalAction02Count + totalAction02Count;
                // enrichedAccount.totalAction03Count =
                //   enrichedAccount.totalAction03Count + totalAction03Count;
                // enrichedAccount.totalAction01Value =
                //   enrichedAccount.totalAction01Value + totalAction01Value;
                // enrichedAccount.totalAction02Value =
                //   enrichedAccount.totalAction02Value + totalAction02Value;
                // enrichedAccount.totalAction03Value =
                //   enrichedAccount.totalAction03Value + totalAction03Value;
                // }

                // ######################################################################
                // ######################################################################
                // ######################################################################
                // ######################################################################
              }

              // console.log('hejhopp totalData: ', totalData);
            }
          }
          // console.log('totalActionsDoc: ', totalActionsDoc);
        }

        enrichedAccount.totalAction01Roas = enrichedAccount.totalCost
          ? (enrichedAccount.totalAction01Value || 0) /
            enrichedAccount.totalCost
          : null;

        enrichedAccount.totalAction02Roas = enrichedAccount.totalCost
          ? (enrichedAccount.totalAction02Value || 0) /
            enrichedAccount.totalCost
          : null;

        enrichedAccount.totalAction03Roas = enrichedAccount.totalCost
          ? (enrichedAccount.totalA03Value || 0) / enrichedAccount.totalCost
          : null;

        enrichedAccount.totalAction01Cpa =
          enrichedAccount.totalCost && enrichedAccount.totalAction01Count
            ? enrichedAccount.totalCost / enrichedAccount.totalAction01Count
            : null;

        enrichedAccount.totalAction02Cpa =
          enrichedAccount.totalCost && enrichedAccount.totalAction02Count
            ? enrichedAccount.totalCost / enrichedAccount.totalAction02Count
            : null;

        enrichedAccount.totalAction03Cpa =
          enrichedAccount.totalCost && enrichedAccount.totalAction03Count
            ? enrichedAccount.totalCost / enrichedAccount.totalAction03Count
            : null;

        enrichedAccount.totalAction01Vpa =
          enrichedAccount.totalAction01Count &&
          enrichedAccount.totalAction01Value
            ? enrichedAccount.totalAction01Value /
              enrichedAccount.totalAction01Count
            : null;

        enrichedAccount.totalAction02Vpa =
          enrichedAccount.totalAction02Count &&
          enrichedAccount.totalAction02Value
            ? enrichedAccount.totalAction02Value /
              enrichedAccount.totalAction02Count
            : null;

        enrichedAccount.totalAction03Vpa =
          enrichedAccount.totalAction03Count &&
          enrichedAccount.totalAction03Value
            ? enrichedAccount.totalAction03Value /
              enrichedAccount.totalAction03Count
            : null;

        // Round

        enrichedAccount.totalCost = enrichedAccount.totalCost
          ? Math.round((enrichedAccount.totalCost + Number.EPSILON) * 100) / 100
          : null;

        enrichedAccount.totaltrp = enrichedAccount.totaltrp
          ? Math.round((enrichedAccount.totaltrp + Number.EPSILON) * 100) / 100
          : null;

        enrichedAccount.totalSpots = enrichedAccount.totalSpots
          ? Math.round((enrichedAccount.totalSpots + Number.EPSILON) * 100) /
            100
          : null;

        enrichedAccount.totalImpressions = enrichedAccount.totalImpressions
          ? Math.round(
              (enrichedAccount.totalImpressions + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalClicks = enrichedAccount.totalClicks
          ? Math.round((enrichedAccount.totalClicks + Number.EPSILON) * 100) /
            100
          : null;

        enrichedAccount.totalAction01Count = enrichedAccount.totalAction01Count
          ? Math.round(
              (enrichedAccount.totalAction01Count + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction02Count = enrichedAccount.totalAction02Count
          ? Math.round(
              (enrichedAccount.totalAction02Count + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction03Count = enrichedAccount.totalAction03Count
          ? Math.round(
              (enrichedAccount.totalAction03Count + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction01Value = enrichedAccount.totalAction01Value
          ? Math.round(
              (enrichedAccount.totalAction01Value + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction02Value = enrichedAccount.totalAction02Value
          ? Math.round(
              (enrichedAccount.totalAction02Value + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction03Value = enrichedAccount.totalAction03Value
          ? Math.round(
              (enrichedAccount.totalAction03Value + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction01Roas = enrichedAccount.totalAction01Roas
          ? Math.round(
              (enrichedAccount.totalAction01Roas + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction02Roas = enrichedAccount.totalAction02Roas
          ? Math.round(
              (enrichedAccount.totalAction02Roas + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction03Roas = enrichedAccount.totalAction03Roas
          ? Math.round(
              (enrichedAccount.totalAction03Roas + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction01Cpa = enrichedAccount.totalAction01Cpa
          ? Math.round(
              (enrichedAccount.totalAction01Cpa + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction02Cpa = enrichedAccount.totalAction02Cpa
          ? Math.round(
              (enrichedAccount.totalAction02Cpa + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction03Cpa = enrichedAccount.totalAction03Cpa
          ? Math.round(
              (enrichedAccount.totalAction03Cpa + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction01Vpa = enrichedAccount.totalAction01Vpa
          ? Math.round(
              (enrichedAccount.totalAction01Vpa + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction02Vpa = enrichedAccount.totalAction02Vpa
          ? Math.round(
              (enrichedAccount.totalAction02Vpa + Number.EPSILON) * 100
            ) / 100
          : null;

        enrichedAccount.totalAction03Vpa = enrichedAccount.totalAction03Vpa
          ? Math.round(
              (enrichedAccount.totalAction03Vpa + Number.EPSILON) * 100
            ) / 100
          : null;

        summary_total_list_of_propagation_data = [
          ...summary_total_list_of_propagation_data,
          ...enrichedAccount.propData
          // ...basicTotalData
        ];

        const newPropDataArray = [
          ...enrichedAccount.propData,
          ...basicTotalData
        ];

        const cleaned_subRow_total_list_of_propagation_data = [];

        for (let date of range2) {
          const dateObject = { date_start: new Date(date).getTime() };

          for (let object of newPropDataArray) {
            if (
              object.date_start &&
              new Date(parseInt(object.date_start))
                .toISOString()
                .split('T')[0] === date
            ) {
              for (let key in object) {
                if (
                  !['accountId', 'date_start', 'eventName', 'source'].includes(
                    key
                  )
                ) {
                  dateObject[key] = (dateObject[key] || 0) + (object[key] || 0);
                }
              }
            }
          }
          cleaned_subRow_total_list_of_propagation_data.push(dateObject);
        }

        // console.log('enrichedAccount: ', enrichedAccount);
        for (let row of listOfData2) {
          if (
            row.accountId === account._id &&
            row.documentType !== 'totalActionsDocument'
          ) {
            row.totalAction01 = account.totalAction01;
            row.totalAction02 = account.totalAction02;
            row.totalAction03 = account.totalAction03;

            row.totalCost = enrichedAccount.totalCost;

            row.totaltrp = enrichedAccount.totaltrp;

            row.totalSpots = enrichedAccount.totalSpots;

            row.totalImpressions = enrichedAccount.totalImpressions;
            row.totalClicks = enrichedAccount.totalClicks;

            row.totalAction01Count = enrichedAccount.totalAction01Count;
            row.totalAction02Count = enrichedAccount.totalAction02Count;
            row.totalAction03Count = enrichedAccount.totalAction03Count;

            row.totalAction01Value = enrichedAccount.totalAction01Value;
            row.totalAction02Value = enrichedAccount.totalAction02Value;
            row.totalAction03Value = enrichedAccount.totalAction03Value;

            row.totalAction01Roas = enrichedAccount.totalAction01Roas;
            row.totalAction02Roas = enrichedAccount.totalAction02Roas;
            row.totalAction03Roas = enrichedAccount.totalAction03Roas;

            row.totalAction01Cpa = enrichedAccount.totalAction01Cpa;
            row.totalAction02Cpa = enrichedAccount.totalAction02Cpa;
            row.totalAction03Cpa = enrichedAccount.totalAction03Cpa;

            row.totalAction01Vpa = enrichedAccount.totalAction01Vpa;
            row.totalAction02Vpa = enrichedAccount.totalAction02Vpa;
            row.totalAction03Vpa = enrichedAccount.totalAction03Vpa;

            row.totalCostSparkline = enrichedAccount.totalCostSparkline;

            row.totaltrpSparkline = enrichedAccount.totaltrpSparkline;

            row.totalSpotsSparkline = enrichedAccount.totalSpotsSparkline;

            row.totalImpressionsSparkline =
              enrichedAccount.totalImpressionsSparkline;
            row.totalClicksSparkline = enrichedAccount.totalClicksSparkline;

            row.totalAction01CountSparkline =
              enrichedAccount.totalAction01CountSparkline;

            row.totalAction02CountSparkline =
              enrichedAccount.totalAction02CountSparkline;

            row.totalAction03CountSparkline =
              enrichedAccount.totalAction03CountSparkline;

            row.totalAction01ValueSparkline =
              enrichedAccount.totalAction01ValueSparkline;

            row.totalAction02ValueSparkline =
              enrichedAccount.totalAction02ValueSparkline;

            row.totalAction03ValueSparkline =
              enrichedAccount.totalAction03ValueSparkline;

            row.totalAction01CpaSparkline =
              enrichedAccount.totalAction01CpaSparkline;
            row.totalAction02CpaSparkline =
              enrichedAccount.totalAction02CpaSparkline;
            row.totalAction03CpaSparkline =
              enrichedAccount.totalAction03CpaSparkline;

            row.totalAction01RoasSparkline =
              enrichedAccount.totalAction01RoasSparkline;
            row.totalAction02RoasSparkline =
              enrichedAccount.totalAction02RoasSparkline;
            row.totalAction03RoasSparkline =
              enrichedAccount.totalAction03RoasSparkline;

            row.total_list_of_propagation_data_row = [
              ...row.total_list_of_propagation_data
              // ...basicTotalData
            ];
            row.total_list_of_propagation_data = [
              ...row.total_list_of_propagation_data,
              ...cleaned_subRow_total_list_of_propagation_data
              // ...enrichedAccount.propData,
              // ...basicTotalData
            ];
          }
        }
      }

      // console.log('totalSnackbarWarnings: ', totalSnackbarWarnings);
      if (allSnackbarWarnings.length === 0) {
        setAllSnackbarWarnings(totalSnackbarWarnings);
      }
      setTotalListOfPropagationData(summary_total_list_of_propagation_data);
      setTotalListOfPropagationBasicData(basicTotalDataTotal);
      // console.log('basicTotalDataTotal: ', basicTotalDataTotal);

      if (updateAccountId && data && !loading) {
        const newListOfData = [...listOfData];
        const indexOfUpdateAccount = newListOfData.findIndex((row) => {
          return row.accountId === updateAccountId;
        });
        const filterednewListOfData = newListOfData.filter((row) => {
          return row.accountId !== updateAccountId;
        });

        filterednewListOfData.splice(indexOfUpdateAccount, 0, ...listOfData2);

        // if (['system_admin', 'debug'].includes(user.role)) {
        //   console.log(
        //     'debug>RoiTable>row 5607>filterednewListOfData: ',
        //     filterednewListOfData
        //   );
        // }

        // if (['system_admin', 'debug'].includes(user.role)) {
        //   console.log(
        //     'debug>RoiTable>row 5611>updateAccountId: ',
        //     updateAccountId
        //   );
        // }

        setListOfData([...filterednewListOfData]);

        setUpdateAccountId('');

        toggleDoneCalculating(true);
        setShowSpinner(false);
      } else if (data && !loading) {
        if (['system_admin', 'debug'].includes(user.role)) {
          console.log(
            'debug>RoiTable>row 5623>updateAccountId: ',
            updateAccountId
          );
          console.log('debug>RoiTable>row 5627>listOfData2: ', listOfData2);
        }

        setListOfData(listOfData2);
        toggleDoneCalculating(true);
        setShowSpinner(false);
      }
      const t1 = performance.now();

      console.log(`Performance time end t1 - t0: ${t1 - t0} milliseconds.`);
    }

    // const t1 = performance.now();

    // console.log(`Performance time end t1 - t0: ${t1 - t0} milliseconds.`);
    return;
  }, [data, rerender, statusFilter, daysToGraph]);

  // #############################################################

  // const classes = useStyles();

  const [openSimpleModal, setOpenSimpleModal] = React.useState(false);

  const [right, setRight] = React.useState([
    // 'type',
    // 'name',
    'warnings',
    'status',
    // 'notifications',
    'path',
    'comments',
    'members',
    'labels',
    'startDate',
    'endDate',
    'numberOfDays',
    // 'plannedGross',
    // 'plannedNet',
    // 'plannedNetNet',
    // 'plannedCtc',
    // 'plannedTrp',
    // 'plannedTechFee',
    // 'plannedAdOpsFee',
    // 'plannedAdServingCost',
    // 'plannedFeePercent',
    'generalDescription',
    // "buyingTypeDescription",
    // "creativeDescription",
    // "formatDescription",
    // "channelDescription",
    // "audienceDataDescription",
    // 'targetGroupDescription',
    // 'placementDescription',
    'goalDescription',
    'budget',
    'budgetCurrency',
    'calcDailyBudget',
    'cost',
    // 'costYesterday',
    'pace',
    // 'pace_bar',
    // 'trp',
    'impressions',
    // 'viewableImpressions',
    // 'measurableImpressions',
    // 'eligibleImpressions',
    'cpm',
    'vCpm',
    'clicks',
    'ctr',
    'viewableRate',
    'searchImpressionShare',
    'cpc',
    'action01',
    'action01Count',
    'action01Cvr',
    'action01Cpa',
    'action01Value',
    'action01Roas',
    'action01Vpa',

    // 'action02',
    // 'action02Count',
    // 'action02Cvr',
    // 'action02Cpa',
    // 'action02Value',
    // 'action02Roas',
    // 'action02Vpa',

    // 'action03',
    // 'action03Count',
    // 'action03Cvr',
    // 'action03Cpa',
    // 'action03Value',
    // 'action03Roas',
    // 'action03Vpa',

    'ganttBarColor',
    'gantt'
  ]);

  const [left, setLeft] = React.useState(
    allColumns.filter((col) => !right.includes(col)).sort()
  );

  const onClickRefreshHandler = () => {
    console.log('refetch');
    refetch();
  };

  const onClickFreezeHandler = () => {
    console.log('refetch');

    if (subscriptionUpdatesFrozen) {
      enqueueSnackbar('Unfreeze and refetching data', {
        variant: 'default'
        // persist: true
      });
      console.log('unfreeze andrefetch');
      refetch();
    } else {
      enqueueSnackbar('Live updates frozen', {
        variant: 'default'
        // persist: true
      });
    }
    setSubscriptionUpdatesFrozen(!subscriptionUpdatesFrozen);
  };

  const onClickEditHandler = (e) => {
    setEditDialogOpen(true);
  };

  // if (!data) {
  //   return (
  //     <div
  //       style={{ position: 'absolut', textAlign: 'center', marginTop: '15%' }}
  //     >
  //       <div style={{ padding: '50px' }}>
  //         <Spinner />
  //       </div>
  //       <span>Loading Data...</span>
  //     </div>
  //   );
  // }
  // if (!doneCalculating) {
  //   return (
  //     <div
  //       style={{ position: 'absolut', textAlign: 'center', marginTop: '15%' }}
  //     >
  //       <div style={{ padding: '50px' }}>
  //         <Spinner />
  //       </div>
  //       <span>Processing Data...</span>
  //     </div>
  //   );
  // }

  // if (!data || !doneCalculating) {
  //   setShowSpinner(true);
  // }
  useEffect(() => {
    if (['system_admin', 'debug'].includes(user.role)) {
      console.log(
        'debug>RoiTable>row5901>listOfData.length: ',
        listOfData.length
      );
      console.log('debug>RoiTable>row 5953>listOfData: ', listOfData);
    }
  }, [listOfData]);

  return (
    <div
      style={{
        padding: '0 15px 0 15px',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <div
        // className="underlined"
        style={{
          height: '60px',
          marginTop: '5px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          borderRadius: '5px'
        }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'row', columnGap: '0px' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',

              justifyContent: 'center',
              alignItems: 'center',
              gridColumnStart: 1,
              gridColumnEnd: 1,

              marginRight: '15px'
            }}
          >
            <QuickCreate
              selectedDateStart={selectedDateStart}
              selectedDateStop={selectedDateStop}
              addDocument={addDocument}
              checkedBoxes={checkedBoxes}
              currentManagerAccountId={currentManagerAccount._id}
            />
          </div>
          {
            // advancedMode
            true && (
              <>
                {/* <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    //   border: '1px solid rgba(200, 200, 200, 0.5)',
                    //   height: '53px',
                    borderRadius: '5px',
                    // width: '200px',
                    gridColumnStart: 2,
                    gridColumnEnd: 2

                    // textAlign: 'right',
                    // padding: '1rem',
                    // marginTop: 'auto',
                    // marginBottom: 'auto'
                    // marginLeft: '15px',
                    // justifyContent: 'center'
                    // backgroundColor: 'blue'
                  }}
                > */}
                {/* <EditMenu
                    setEditDialogOpen={setEditDialogOpen}
                    copiedDocumentIds={copiedDocumentIds}
                    setCopiedDocumentIds={setCopiedDocumentIds}
                    copiedDocumentsAccountId={copiedDocumentsAccountId}
                    setCopiedDocumentsAccountId={setCopiedDocumentsAccountId}
                    enqueueSnackbar={enqueueSnackbar}
                    checkedBoxes={checkedBoxes}
                    selectedDateStart={selectedDateStart}
                    selectedDateStop={selectedDateStop}
                  /> */}
                {/* <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '40px',
                    minHeight: 0,
                    minWidth: 0
                    // backgroundColor: '#3e8ed0'
                  }}
                  onClick={onClickEditHandler}
                >
                  <EditIcon
                  // style={{ color: '#FFFFFF' }}
                  // className="hover-grey"
                  // style={{ cursor: 'pointer' }}
                  // onClick={onClickRefreshHandler}
                  />
                </Button> */}

                {/* {editDialogOpen && (
                    <EditDialog
                      checkedBoxes={checkedBoxes}
                      currentManagerAccountId={currentManagerAccount._id}
                      // open={editModalOpen}
                      // setOpen={(e) => setEditModalOpen(e)}
                    />
                  )}

                  {copyDialogOpen && (
                    <CopyDialog
                      checkedBoxes={checkedBoxes}
                      currentManagerAccountId={currentManagerAccount._id}
                      copiedDocumentIds={copiedDocumentIds}
                      setCopiedDocumentIds={setCopiedDocumentIds}
                      copiedDocumentsAccountId={copiedDocumentsAccountId}
                      setCopiedDocumentsAccountId={setCopiedDocumentsAccountId}
                      // open={editModalOpen}
                      // setOpen={(e) => setEditModalOpen(e)}
                    />
                  )} */}
                {/* </div> */}

                <div
                  style={{
                    display: 'grid',
                    justifyContent: 'center',
                    //   border: '1px solid rgba(200, 200, 200, 0.5)',
                    //   height: '53px',
                    borderRadius: '5px',
                    // width: '200px',
                    gridColumnStart: 2,
                    gridColumnEnd: 2,

                    textAlign: 'right',
                    // padding: '1rem',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    // marginLeft: '15px',
                    justifyContent: 'center'
                    // backgroundColor: 'blue'
                  }}
                >
                  <Tooltip title="Email poke">
                    <Button
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '40px',
                        minHeight: '36px',
                        minWidth: 0,
                        cursor: 'pointer'
                        // backgroundColor: '#3e8ed0'
                      }}
                      onClick={() => setEmailPokeDialogOpen(true)}
                    >
                      <i
                        style={{ fontSize: '20px' }}
                        className="fas fa-hand-point-right"
                      ></i>
                      {/* <EmailIcon
                  // style={{ color: '#FFFFFF' }}
                  // className="hover-grey"
                  // style={{ cursor: 'pointer' }}
                  // onClick={onClickRefreshHandler}
                  /> */}
                    </Button>
                  </Tooltip>

                  {emailPokeDialogOpen && (
                    <EmailPokeDialog
                      checkedBoxes={checkedBoxes}
                      currentManagerAccountId={currentManagerAccount._id}
                      // open={editModalOpen}
                      // setOpen={(e) => setEditModalOpen(e)}
                    />
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',

                    justifyContent: 'center',
                    alignItems: 'center',

                    gridColumnStart: 3,
                    gridColumnEnd: 3,

                    textAlign: 'left'
                  }}
                >
                  <StatusFilterMenu
                    statusFilter={statusFilter}
                    setStatusFilter={setStatusFilter}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gridColumnStart: 4,
                    gridColumnEnd: 4,

                    textAlign: 'left'
                  }}
                >
                  <DisplayMenu view="gantt" />
                </div>
              </>
            )
          }
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gridColumnStart: 5,
              gridColumnEnd: 5,

              textAlign: 'left'
            }}
          >
            <FlightViewButton
              // setFilterBySelection={setFilterBySelection}
              // checkedBoxes={checkedBoxes}
              // setCheckedBoxes={setCheckedBoxes}
              // setFilters={setFilters}
              // filters={filters}
              enqueueSnackbar={enqueueSnackbar}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gridColumnStart: 6,
              gridColumnEnd: 6,
              // backgroundColor: 'blue',
              textAlign: 'left'
              // padding: '1rem 0px'
            }}
          >
            <PerformancePopper
              setFilterBySelection={setFilterBySelection}
              checkedBoxes={checkedBoxes}
              setCheckedBoxes={setCheckedBoxes}
              setFilters={setFilters}
              filters={filters}
              enqueueSnackbar={enqueueSnackbar}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gridColumnStart: 6,
              gridColumnEnd: 6,
              // backgroundColor: 'blue',
              textAlign: 'left'
              // padding: '1rem 0px'
            }}
          >
            <FilterMenu
              setFilterBySelection={setFilterBySelection}
              checkedBoxes={checkedBoxes}
              setCheckedBoxes={setCheckedBoxes}
              setFilters={setFilters}
              filters={filters}
              enqueueSnackbar={enqueueSnackbar}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gridColumnStart: 7,
              gridColumnEnd: 7,
              // backgroundColor: 'blue',
              textAlign: 'left',
              // padding: '0.91rem',
              justifyContent: 'left'
            }}
          >
            <FilterComponent
              setCheckedBoxes={setCheckedBoxes}
              // setFilters={setFilters}
              // setFilterBySelection={setFilterBySelection}
              // setFilterByDataSource={setFilterByDataSource}
              // filterByDataSource={filterByDataSource}
              names={names}
              invoiceOrderIds={invoiceOrderIds}
              dataSourceNames={dataSourceNames}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {/* {advancedMode && (
            <div
              style={{
                display: 'grid',
                //   border: '1px solid rgba(200, 200, 200, 0.5)',
                //   height: '53px',
                borderRadius: '5px',
                // width: '200px',
                gridColumnStart: 8,
                gridColumnEnd: 8,

                textAlign: 'right',
                // padding: '1.4rem 1rem 1rem 1rem',
                marginTop: 'auto',
                marginBottom: 'auto',
                justifyContent: 'center'
                // backgroundColor: 'blue'
              }}
            >
              <DataLevelSwitch
                dataLevel={dataLevel}
                setDataLevel={setDataLevel}
                currentAccountIds={currentAccountIds}
              />
            </div>
          )} */}
          <div
            style={{
              display: 'grid',
              //   border: '1px solid rgba(200, 200, 200, 0.5)',
              //   height: '53px',
              borderRadius: '5px',
              // width: '200px',
              gridColumnStart: 9,
              gridColumnEnd: 9,

              textAlign: 'right',
              // padding: '1.4rem 1rem 1rem 1rem',
              marginTop: 'auto',
              marginBottom: 'auto',
              justifyContent: 'center'
              // backgroundColor: 'blue'
            }}
          >
            {/* uncomment */}
            <ExportMenu
              className="hover-grey"
              columns={right}
              arrayOfData={arrayOfData}
              selectedDateStart={selectedDateStart}
              selectedDateStop={selectedDateStop}
              summaryRow={summaryRow}
              setSummaryRow={setSummaryRow}
              range2={range2}
              // style={{ cursor: 'pointer' }}
              // onClick={onClickRefreshHandler}
            />
          </div>
          {['system_admin', 'debug'].includes(user.role) && (
            <div
              style={{
                display: 'grid',
                //   border: '1px solid rgba(200, 200, 200, 0.5)',
                //   height: '53px',
                borderRadius: '5px',
                // width: '200px',
                gridColumnStart: 10,
                gridColumnEnd: 10,

                textAlign: 'right',
                // padding: '1rem',
                marginTop: 'auto',
                marginBottom: 'auto',
                justifyContent: 'center'
                // backgroundColor: 'blue'
              }}
            >
              <Tooltip title="Report Builder">
                <Button
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '40px',
                    minHeight: 0,
                    minWidth: 0,
                    backgroundColor: subscriptionUpdatesFrozen
                      ? '#3e8ed0'
                      : null
                  }}
                  onClick={() => setReportBuilderDialogOpen(true)}
                >
                  <AssessmentIcon
                    style={{
                      color: subscriptionUpdatesFrozen ? '#FFFFFF' : null
                    }}
                    // className="hover-grey"
                    // style={{ cursor: 'pointer' }}
                    // onClick={onClickRefreshHandler}
                  />
                </Button>
              </Tooltip>
            </div>
          )}
          {advancedMode && (
            <>
              <div
                style={{
                  display: 'grid',
                  //   border: '1px solid rgba(200, 200, 200, 0.5)',
                  //   height: '53px',
                  borderRadius: '5px',
                  // width: '200px',
                  gridColumnStart: 10,
                  gridColumnEnd: 10,

                  textAlign: 'right',
                  // padding: '1rem',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  justifyContent: 'center'
                  // backgroundColor: 'blue'
                }}
              >
                <Tooltip title="Freeze real time updates">
                  <Button
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '40px',
                      minHeight: 0,
                      minWidth: 0,
                      backgroundColor: subscriptionUpdatesFrozen
                        ? '#3e8ed0'
                        : null
                    }}
                    onClick={onClickFreezeHandler}
                  >
                    <AcUnitIcon
                      style={{
                        color: subscriptionUpdatesFrozen ? '#FFFFFF' : null
                      }}
                      // className="hover-grey"
                      // style={{ cursor: 'pointer' }}
                      // onClick={onClickRefreshHandler}
                    />
                  </Button>
                </Tooltip>
              </div>
              <div
                style={{
                  display: 'grid',
                  //   border: '1px solid rgba(200, 200, 200, 0.5)',
                  //   height: '53px',
                  borderRadius: '5px',
                  // width: '200px',
                  gridColumnStart: 11,
                  gridColumnEnd: 11,

                  textAlign: 'right',
                  // padding: '1rem',
                  marginTop: 'auto',
                  marginBottom: 'auto',
                  justifyContent: 'center'
                  // backgroundColor: 'blue'
                }}
              >
                <Tooltip title="Refresh data">
                  <Button
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '40px',
                      minHeight: 0,
                      minWidth: 0
                    }}
                    onClick={onClickRefreshHandler}
                  >
                    <RefreshRoundedIcon
                    // className="hover-grey"
                    // style={{ cursor: 'pointer' }}
                    // onClick={onClickRefreshHandler}
                    />
                  </Button>
                </Tooltip>
              </div>
            </>
          )}
          <div
            style={{
              display: 'flex',

              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '5px',

              textAlign: 'right',

              marginTop: 'auto',
              marginBottom: 'auto'
            }}
          >
            <Tooltip title="Kanban view">
              <Button
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '40px',
                  minHeight: 0,
                  minWidth: 0,
                  cursor: 'pointer'
                }}
                onClick={() => setViewDialogOpen(true, 'kanban')}
              >
                <ViewKanbanIcon />
              </Button>
            </Tooltip>
          </div>
          <div
            style={{
              display: 'flex',

              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '5px',

              textAlign: 'right',

              marginTop: 'auto',
              marginBottom: 'auto'
            }}
          >
            <Tooltip title="Calendar view">
              <Button
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '40px',
                  minHeight: 0,
                  minWidth: 0,
                  cursor: 'pointer'
                }}
                onClick={() => setViewDialogOpen(true, 'calendar')}
              >
                <CalendarViewWeekIcon />
              </Button>
            </Tooltip>
          </div>
          <div
            style={{
              display: 'flex',

              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '5px',

              textAlign: 'right',

              marginTop: 'auto',
              marginBottom: 'auto'
            }}
          >
            <Tooltip title="List view">
              <Button
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '40px',
                  minHeight: 0,
                  minWidth: 0,
                  cursor: 'pointer'
                }}
                onClick={() => setViewDialogOpen(true, 'list')}
              >
                <TableRowsIcon />
              </Button>
            </Tooltip>
          </div>

          <div
            style={{
              display: 'grid',
              //   border: '1px solid rgba(200, 200, 200, 0.5)',
              //   height: '53px',
              borderRadius: '5px',
              // width: '200px',
              gridColumnStart: 12,
              gridColumnEnd: 12,

              textAlign: 'right',
              // padding: '1rem',
              marginTop: 'auto',
              marginBottom: 'auto'
              // backgroundColor: 'blue'
            }}
          >
            {/* uncomment */}
            <ColumnMenu
              currentManagerAccountId={currentManagerAccount._id}
              left={left}
              setLeft={setLeft}
              right={right}
              setRight={setRight}
              setOpenSimpleModal={setOpenSimpleModal}
              user={user}
              // columnData={columnData}
            />
            {columnDialogOpen && <ColumnDialog />}

            {/* uncomment */}
            {/* <DraggableDialog
            currentManagerAccountId={currentManagerAccount._id}
            open={openSimpleModal}
            setOpen={setOpenSimpleModal}
            style={{ zIndex: '2000' }}
            left={left}
            setLeft={setLeft}
            right={right}
            setRight={setRight}
          /> */}
          </div>
          {/* <div
          style={{
            display: 'grid',
            //   border: '1px solid rgba(200, 200, 200, 0.5)',
            //   height: '53px',
            borderRadius: '5px',
            // width: '200px',
            gridColumnStart: 22,
            gridColumnEnd: 23,

            textAlign: 'right',
            // padding: '1rem',
            marginTop: 'auto',
            marginBottom: 'auto'
            // backgroundColor: 'blue'
          }}
        >
          <DatePicker
            stylesStoredInRedux={{ stylesStoredInRedux }}
            selectedDate={selectedDateStart}
            handleDateChange={handleDateStartChange}
          />
        </div> */}
          <div
            style={{
              display: 'grid',
              //   border: '1px solid rgba(200, 200, 200, 0.5)',
              //   height: '53px',
              borderRadius: '5px',
              // width: '200px',
              gridColumnStart: 13,
              gridColumnEnd: 13,

              textAlign: 'center',
              // padding: '1rem',
              marginTop: 'auto',
              marginBottom: 'auto'
              // backgroundColor: 'blue'
            }}
          >
            <ReactDateRange
              stylesStoredInRedux={stylesStoredInRedux}
              selectedDateStart={selectedDateStart}
              selectedDateStop={selectedDateStop}
              setSelectedDateStart={setSelectedDateStart}
              setSelectedDateStop={setSelectedDateStop}
              currentAccountIds={currentAccountIds}
              enqueueSnackbar={enqueueSnackbar}
              setShowSpinner={setShowSpinner}
            />
            {/* <DatePicker
            stylesStoredInRedux={{ stylesStoredInRedux }}
            selectedDate={selectedDateStop}
            handleDateChange={handleDateStopChange}
          /> */}
          </div>
        </div>
      </div>

      {/* uncomment */}

      {doneCalculating ? (
        <Table
          aggregatedMode={true}
          dataLevel={dataLevel}
          summaryRow={summaryRow}
          totalListOfPropagationData={totalListOfPropagationData}
          totalListOfPropagationBasicData={totalListOfPropagationBasicData}
          setSummaryRow={setSummaryRow}
          arrayOfData={arrayOfData}
          setArrayOfData={setArrayOfData}
          visColumns={visColumns}
          setVisColumns={setVisColumns}
          enqueueSnackbar={enqueueSnackbar}
          // filterBySelection={filterBySelection}
          // setFilterBySelection={setFilterBySelection}
          addDocument={addDocument}
          updateDocument={updateDocument}
          currentManagerAccountId={currentManagerAccount._id}
          // changeTableData={changeTableData}
          stylesStoredInRedux={stylesStoredInRedux}
          // openRows={openRows}
          // setOpenRows={setOpenRows}
          range={range}
          range2={range2}
          rawArrayOfData={listOfData}
          columns={right}
          setColumns={setRight}
          selectedDateStart={selectedDateStart}
          selectedDateStop={selectedDateStop}
          // checkedBoxes={checkedBoxes}
          // setCheckedBoxes={setCheckedBoxes}
          // filters={filters}
          // setFilters={setFilters}
          // filterByMemberId={filterByMemberId}
          // filterByName={filterByName}
          // filterByDataSourceName={filterByDataSourceName}
          // filterByDataSource={filterByDataSource}
          refetch={refetch}
          setSubscriptionCallStack={setSubscriptionCallStack}
          currentAccountIds={currentAccountIds}
          names={names}
          dataSourceNames={dataSourceNames}
          subscriptionSkip={subscriptionSkip}
          copiedDocumentIds={copiedDocumentIds}
          setCopiedDocumentIds={setCopiedDocumentIds}
          copiedDocumentsAccountId={copiedDocumentsAccountId}
          setCopiedDocumentsAccountId={setCopiedDocumentsAccountId}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100vh - 142px)'
          }}
        >
          LOADING... PLEASE TRY TO HARD REFRESH{' '}
          {navigator?.platform?.toUpperCase()?.indexOf('MAC') >= 0
            ? '( Shift + Command + R )'
            : '( Ctrl + F5 )'}{' '}
          IF THE LOADING NEVER FINISH.
        </div>
      )}
      {(currentManagerAccount?.isTimeboxingOn ||
        ['system_admin', 'debug'].includes(user?.role) ||
        [
          '6167f2a56a44185b71fd0bcc',
          '61a917e9d86ba532fcee25a9',
          '63ea0160f4ab796775dbe0da',
          '640b425a1b1064af10bc2918',
          '640b3de89db4bd2b100bdf58'
        ].includes(user?._id)) && <BottomRightCornerMenu />}

      <Timeboxer />
      <TimeboxerViewDialog />
      {['system_admin', 'debug'].includes(user?.role) && <ReportBuilder2 />}

      {/* <AlertDialog /> */}
      {(loading || showSpinner || !doneCalculating || !data) && (
        <div
          style={{
            display: 'block',
            position: 'fixed',
            // backgroundColor: 'red',
            height: '100px',
            width: '100px',
            zIndex: 3000,
            bottom: 0,
            right: '30px'
          }}
        >
          <Spinner />
        </div>
      )}
    </div>
  );
};

RoiTable.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  // currentManagerAccount: PropTypes.object.isRequired,
  // style: PropTypes.object.isRequired
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  stylesStoredInRedux: state.style
  // user: state.auth.user
});

export default connect(mapStateToProps, {})(RoiTable);
