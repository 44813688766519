import React, { useContext } from 'react';
import ReactExport from 'react-data-export';
import GetAppIcon from '@mui/icons-material/GetApp';
// import './App.css';
import MenuItem from '@mui/material/MenuItem';

import JSZip from 'jszip';
import * as XLSX from 'xlsx';

import { ColumnContext } from './../../../context/ColumnContext';
import { DataContext } from '../../../context/DataContext';

import { columnDictionary } from '../column-menu/columns';

import formatDateYYYYMMDD from './../roitable_functions/formatDateYYYYMMDD';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function rgba2hex(rgba) {
  rgba = rgba.match(
    /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
  );
  return rgba && rgba.length === 4
    ? 'FF' +
        ('0' + parseInt(rgba[1], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgba[2], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgba[3], 10).toString(16)).slice(-2)
    : '';
}

const allColumns = [
  'name',
  'status',
  'path',
  // 'comments',
  // 'members',
  'startDate',
  'endDate',
  'numberOfDays',
  'generalDescription',
  'buyingTypeDescription',
  'creativeDescription',
  'formatDescription',
  'channelDescription',
  'audienceDataDescription',
  'targetGroupDescription',
  'placementDescription',
  'goalDescription',
  'budget',
  'budgetCurrency',
  'calcDailyBudget',
  'plannedGross',
  'plannedNet',
  'plannedNetNet',
  'plannedCtc',

  'plannedTrp',
  'plannedCpp',
  'plannedTechFee',
  'plannedAdOpsFee',
  'plannedAdServingCost',
  'plannedFeePercent',

  'kpi',
  'plannedImpressions',
  'plannedClicks',
  'plannedCtr',
  'plannedCpc',
  'plannedReach',
  'plannedCpm',
  'plannedFrequency',
  'plannedActionCount',
  'plannedActionValue',
  'plannedActionRoas',
  'plannedCpa',
  'cost',
  'pace',
  // 'pace_bar',
  'universe',
  'trp',
  'spots',
  'impressions',
  'viewableImpressions',
  'measurableImpressions',
  'eligibleImpressions',
  'cpm',
  'vCpm',
  'reach',
  'clicks',
  'ctr',
  'viewableRate',
  'searchImpressionShare',
  'cpc',
  'action01',
  'action01Count',
  'action01Cvr',
  'action01Cpa',
  'action01Value',
  'action01Roas',
  'action01Vpa',
  'action02',
  'action02Count',
  'action02Cvr',
  'action02Cpa',
  'action02Value',
  'action02Roas',
  'action02Vpa',
  'action03',
  'action03Count',
  'action03Cvr',
  'action03Cpa',
  'action03Value',
  'action03Roas',
  'action03Vpa',

  'totalCost',
  'totaltrp',
  'totalSpots',
  'totalImpressions',
  'totalCpm',
  'totalClicks',
  'totalCtr',
  'totalCpc',

  'totalAction01',
  'totalAction01Count',
  'totalaction01Cvr',
  'totalAction01Cpa',
  'totalAction01Value',
  'totalAction01Roas',
  'totalAction01Vpa',
  'totalAction02',
  'totalAction02Count',
  'totalaction02Cvr',
  'totalAction02Cpa',
  'totalAction02Value',
  'totalAction02Roas',
  'totalAction02Vpa',
  'totalAction03',
  'totalAction03Count',
  'totalaction03Cvr',
  'totalAction03Cpa',
  'totalAction03Value',
  'totalAction03Roas',
  'totalAction03Vpa',

  // 'ganttBarColor',
  'gantt'
];

const ExportExcel = ({
  range2,
  columns: customColumns,
  // arrayOfData,
  handleClose,
  selectedDateStart,
  selectedDateStop,
  summaryRow,
  setSummaryRow
}) => {
  const { arrayOfData } = useContext(DataContext);
  const { columns: columnsFromContext } = useContext(ColumnContext);

  const columns = customColumns || columnsFromContext || [];

  const multiDataSetPage1 = {
    columns: [],
    data: []
  };

  const visColumns = ['name', ...columns];

  if (visColumns && arrayOfData) {
    // column headers
    for (let column of visColumns) {
      const BORDER_STYLE = 'thin';
      const COLOR_SPEC = { rgb: '00000000' };

      if (allColumns.includes(column)) {
        switch (column) {
          case 'name':
            multiDataSetPage1.columns.push({
              title: columnDictionary[column],
              width: { wpx: 150 },
              style: {
                font: { sz: '14', bold: true },
                border: { bottom: { style: BORDER_STYLE, color: COLOR_SPEC } }
              }
            });
            break;
          case 'path':
            multiDataSetPage1.columns.push({
              title: columnDictionary[column],
              width: { wpx: 150 },
              style: {
                font: { sz: '14', bold: true },
                alignment: { horizontal: 'center' },
                border: { bottom: { style: BORDER_STYLE, color: COLOR_SPEC } }
              }
            });
            break;
          case 'status':
            multiDataSetPage1.columns.push({
              title: columnDictionary[column],
              width: { wpx: 80 },
              style: {
                font: { sz: '14', bold: true },
                alignment: { horizontal: 'center' },
                border: { bottom: { style: BORDER_STYLE, color: COLOR_SPEC } }
              }
            });
            break;
          case 'startDate':
          case 'endDate':
            multiDataSetPage1.columns.push({
              title: columnDictionary[column],
              width: { wpx: 100 },
              style: {
                font: { sz: '14', bold: true },
                alignment: { horizontal: 'center' },
                border: { bottom: { style: BORDER_STYLE, color: COLOR_SPEC } }
              }
            });
            break;
          case 'gantt':
            for (let date of range2) {
              const colObject = {
                title: date, //date.toISOString().split('T')[0],
                width: { wpx: 20 },
                style: {
                  font: { sz: '14', bold: true },
                  alignment: { horizontal: 'center', textRotation: 180 },
                  border: { bottom: { style: BORDER_STYLE, color: COLOR_SPEC } }
                }
              };

              const day_number = new Date(date).getDay();
              if (day_number === 6 || day_number === 0) {
                colObject.style.border = {
                  right: { style: 'thin', color: { rgb: 'FFFFFFFF' } },
                  left: { style: 'thin', color: { rgb: 'FFFFFFFF' } },
                  bottom: { style: BORDER_STYLE, color: COLOR_SPEC }
                };
                colObject.style.fill = {
                  patternType: 'solid',
                  fgColor: {
                    rgb: 'FFE0E0E0'
                  }
                };
              }
              multiDataSetPage1.columns.push(colObject);
            }
            break;
          default:
            multiDataSetPage1.columns.push({
              title: columnDictionary[column],
              width: { wpx: 100 },
              style: {
                font: { sz: '14', bold: true },
                alignment: { horizontal: 'center', wrapText: true },
                border: { bottom: { style: BORDER_STYLE, color: COLOR_SPEC } }
              }
            });
        }
      }
    }

    for (let item of arrayOfData) {
      const row = [];
      for (let column of visColumns) {
        if (allColumns.includes(column)) {
          switch (column) {
            case 'name':
              let indent = '';
              for (let i = 0; i < item.level - 1; i++) {
                indent = indent + '  ';
              }

              row.push({
                value: indent + item[column],
                style: { font: { sz: '12', bold: false } }
              });
              break;
            case 'action01':
            case 'action02':
            case 'action03':
            case 'totalAction01':
            case 'totalAction02':
            case 'totalAction03':
              row.push({
                value:
                  item[column] && item[column].name ? item[column].name : '',
                style: { font: { sz: '12', bold: false } }
              });
              break;
            case 'cpm':
            case 'vCpm':
            case 'cpc':
            case 'cost':

            case 'totalCpm':
            case 'totalCpc':
            case 'totalCost':

            case 'action01Cpa':
            case 'action02Cpa':
            case 'action03Cpa':

            case 'action01Count':
            case 'action02Count':
            case 'action03Count':

            case 'action01Value':
            case 'action02Value':
            case 'action03Value':

            case 'action01Vpa':
            case 'action02Vpa':
            case 'action03Vpa':
            case 'action01Roas':
            case 'action02Roas':
            case 'action03Roas':
            case 'totalAction01Cpa':
            case 'totalAction02Cpa':
            case 'totalAction03Cpa':
            case 'totalAction01Vpa':
            case 'totalAction02Vpa':
            case 'totalAction03Vpa':
            case 'totalAction01Roas':
            case 'totalAction02Roas':
            case 'totalAction03Roas':
              // case 'calcDailyBudget':
              row.push({
                value: item[column]
                  ? Math.round((item[column] + Number.EPSILON) * 100) / 100
                  : '',
                style: { font: { sz: '12', bold: false } }
              });
              break;

            case 'calcDailyBudget':
              if (!item[column] || item[column] === '?') {
                row.push({
                  value: '?',
                  style: {
                    font: { sz: '12', bold: false },
                    alignment: { horizontal: 'center' }
                  }
                });
              } else {
                row.push({
                  value: item[column]
                    ? Math.round((item[column] + Number.EPSILON) * 100) / 100
                    : '',
                  style: { font: { sz: '12', bold: false } }
                });
              }
              break;
            case 'pace':
              if (!item[column] || item[column] === '?') {
                row.push({
                  value: '?',
                  style: {
                    font: { sz: '12', bold: false },
                    alignment: { horizontal: 'center' }
                  }
                });
              } else {
                row.push({
                  value: item[column]
                    ? Math.round((item[column] + Number.EPSILON) * 100) / 100
                    : // +
                      //   '%'
                      '',
                  style: {
                    font: { sz: '12', bold: false },
                    alignment: { horizontal: 'center' }
                  }
                });
              }
              break;

            case 'ctr':
            case 'totalCtr':

            case 'viewableRate':
            case 'totalViewableRate':
            case 'searchImpressionShare':
            case 'totalSearchImpressionShare':

            case 'action01Cvr':
            case 'action02Cvr':
            case 'action03Cvr':
            case 'totalaction01Cvr':
            case 'totalaction02Cvr':
            case 'totalaction03Cvr':
              row.push({
                value:
                  item[column] && item[column].toFixed(2)
                    ? (item[column] * 100).toFixed(2)
                    : // + '%'
                      '',
                style: {
                  font: { sz: '12', bold: false },
                  alignment: { horizontal: 'right' }
                }
              });
              break;
            case 'status':
            case 'startDate':
            case 'endDate':
            case 'numberOfDays':
              row.push({
                value: item[column] ? item[column] : '',
                style: {
                  font: { sz: '12', bold: false },
                  alignment: { horizontal: 'center' }
                }
              });
              break;

            case 'gantt':
              for (let date of range2) {
                let rowObj = {};
                if (
                  (item.startDate &&
                    !item.endDate &&
                    new Date(date) >=
                      new Date(formatDateYYYYMMDD(item.startDate))) ||
                  (item.endDate &&
                    !item.startDate &&
                    new Date(date) <=
                      new Date(formatDateYYYYMMDD(item.endDate))) ||
                  (item.startDate &&
                    item.endDate &&
                    new Date(date) >=
                      new Date(formatDateYYYYMMDD(item.startDate)) &&
                    new Date(date) <=
                      new Date(formatDateYYYYMMDD(item.endDate)))
                ) {
                  const ganttBarBackgroundColor =
                    item &&
                    item.style &&
                    item.style.gantt &&
                    item.style.gantt.bar &&
                    item.style.gantt.bar.backgroundColor;

                  rowObj = {
                    value: ' ',
                    style: {
                      fill: {
                        patternType: 'solid',
                        fgColor: {
                          rgb: ganttBarBackgroundColor
                            ? rgba2hex(ganttBarBackgroundColor)
                            : 'FFB24393'
                        }
                      },

                      border: {
                        top: { style: 'thin', color: { rgb: 'FFFFFFFF' } },
                        bottom: { style: 'thin', color: { rgb: 'FFFFFFFF' } }
                      }
                      // fgColor: { rgb: 'FFFF0000' }
                    }

                    // style: {
                    //   // font: { sz: '14', bold: true }
                    //   // alignment: { horizontal: 'center', textRotation: 180 }
                    //   // border: {
                    //   //   bottom: { style: BORDER_STYLE, color: COLOR_SPEC }
                    //   // }
                    // }
                  };

                  const day_number = new Date(date).getDay();

                  if (day_number === 0) {
                    rowObj.style.border.right = {
                      style: 'thin',
                      color: { rgb: 'AAFFFFFF' }
                    };
                  }
                } else {
                  rowObj = {
                    value: ' '
                    // style: {
                    //   fill: 'none',
                    //   // bgColor: { rgb: 'FFFFFF00' }
                    //   // fgColor: { rgb: 'FFFF0000' }
                    // }

                    // style: {
                    //   // font: { sz: '14', bold: true }
                    //   // alignment: { horizontal: 'center', textRotation: 180 }
                    //   // border: {
                    //   //   bottom: { style: BORDER_STYLE, color: COLOR_SPEC }
                    //   // }
                    // }
                  };
                }

                row.push(rowObj);
              }

              break;
            default:
              row.push({
                value: item[column] ? item[column] : '',
                style: { font: { sz: '12', bold: false } }
              });
          }
        }
      }
      multiDataSetPage1.data.push(row);
    }
    const row = [];

    const BORDER_STYLE = 'thin';
    const COLOR_SPEC = { rgb: '00000000' };

    for (let column of visColumns) {
      if (allColumns.includes(column)) {
        switch (column) {
          case 'name':
            // let indent = '';
            // for (let i = 0; i < summaryRow.level - 1; i++) {
            //   indent = indent + '  ';
            // }

            row.push({
              value: 'Summary',
              style: {
                font: { sz: '12', bold: true },
                border: { top: { style: BORDER_STYLE, color: COLOR_SPEC } }
              }
            });
            break;
          // case 'action01':
          // case 'action02':
          // case 'action03':
          //   row.push({
          //     value:
          //       summaryRow[column] && summaryRow[column].name
          //         ? summaryRow[column].name
          //         : '',
          //     style: {
          //       font: { sz: '12', bold: true },
          //       border: { top: { style: BORDER_STYLE, color: COLOR_SPEC } }
          //     }
          //   });
          //   break;
          // case 'cpm':
          // case 'cpc':
          // case 'action01Cpa':
          // case 'action02Cpa':
          // case 'action03Cpa':
          //   row.push({
          //     value: summaryRow[column]
          //       ? Math.round((summaryRow[column] + Number.EPSILON) * 100) / 100
          //       : '',
          //     style: {
          //       font: { sz: '12', bold: true },
          //       border: { top: { style: BORDER_STYLE, color: COLOR_SPEC } }
          //     }
          //   });
          //   break;
          case 'ctr':
          case 'viewableRate':
          case 'searchImpressionShare':
          case 'action01Cvr':
          case 'action02Cvr':
          case 'action03Cvr':
          case 'totalaction01Cvr':
          case 'totalaction02Cvr':
          case 'totalaction03Cvr':
            row.push({
              value: summaryRow[column]
                ? summaryRow[column]
                : // + '%'
                  '',
              style: {
                font: { sz: '12', bold: true },
                alignment: { horizontal: 'right' },
                border: { top: { style: BORDER_STYLE, color: COLOR_SPEC } }
              }
            });
            break;
          // case 'status':
          // case 'startDate':
          // case 'endDate':
          // case 'numberOfDays':
          //   row.push({
          //     value: summaryRow[column] ? summaryRow[column] : '',
          //     style: {
          //       font: { sz: '12', bold: true },
          //       alignment: { horizontal: 'center' },
          //       border: { top: { style: BORDER_STYLE, color: COLOR_SPEC } }
          //     }
          //   });
          //   break;
          case 'gantt':
            for (let date of range2) {
              row.push({
                value: ' ',
                style: {
                  font: { sz: '12', bold: true },
                  border: { top: { style: BORDER_STYLE, color: COLOR_SPEC } }
                }
              });
            }
            break;
          default:
            row.push({
              value: summaryRow[column] ? summaryRow[column] : '',
              style: {
                font: { sz: '12', bold: true },
                border: { top: { style: BORDER_STYLE, color: COLOR_SPEC } }
              }
            });
        }
      }
    }
    multiDataSetPage1.data.push(row);
  }

  const multiDataSet = [multiDataSetPage1];

  return (
    <div>
      <ExcelFile
        filename={`NexusTable Export ${selectedDateStart} - ${selectedDateStop} Created ${new Date()}`}
        element={
          // <GetAppIcon className="hover-grey" style={{ cursor: 'pointer' }} />
          <MenuItem onClick={handleClose}>Excel</MenuItem>
        }
      >
        <ExcelSheet dataSet={multiDataSet} name="Export" />
      </ExcelFile>
    </div>
  );
};

export default ExportExcel;
