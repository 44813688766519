import React, { useState, useEffect, useContext } from 'react';
// import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

import { useLazyQuery, useMutation } from '@apollo/client';

import { AccountContext } from '../../../../../context/AccountContext';

import { MutationContext } from '../../../../../context/MutationContext';

import {
  accountUsersQuery,
  updateDocumentMutation
} from './../../../../../graphql/queries';

import { useSnackbar } from 'notistack';

// import SimpleTooltip from './SimpleTootip';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(0),
//       width: '100%'
//     }
//   }
// }));

function StringItem({ item, itemKey, itemValue, keyForMap }) {
  const { enqueueSnackbar } = useSnackbar();

  const { currentManagerAccountId } = useContext(AccountContext);

  // const [updateDocument] = useMutation(updateDocumentMutation);
  const { updateDocument } = useContext(MutationContext);

  // ################### update #######################

  // const classes = useStyles();
  const [showTextField, toggleShowTextField] = useState(false);
  const onClickHandler = () => {
    toggleShowTextField(true);
  };

  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue(item[itemKey + 'Formula']);
  }, [showTextField, toggleShowTextField]);

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (
      [
        'facebook_ads_campaign',
        // 'facebook_ads_ad_set'
        'google_ads_campaign',
        'cm360_campaign'
        // 'google_ads_ad_group',
        // 'folder'
      ].includes(item.type) &&
      [
        'cost',
        'impressions',
        'viewableImpressions',
        'measurableImpressions',
        'eligibleImpressions',
        'clicks',
        'action01Count',
        'action02Count',
        'action03Count',
        'action01Value',
        'action02Value',
        'action03Value'
      ].includes(itemKey)
    ) {
      let regExpression = '^replace[/\\*()]?[0-9.]*$';
      const replacedReExpression = regExpression.replace(
        'replace',
        `=${itemKey}`
      );

      const regExName = new RegExp(replacedReExpression);
      const regExNameResult = regExName.exec(inputValue);
      if (!regExNameResult) {
        if (itemKey === 'cost' && inputValue.includes(`=impressions/1000*`)) {
        } else {
          enqueueSnackbar(
            `Only =${itemKey}, =${itemKey}*value and =${itemKey}/value are allowed.`,
            {
              variant: 'error'
            }
          );

          return console.log('Unallowed characters were used.');
        }
      }
      // RegExpression = RegExpression.
    }

    const regExName = new RegExp('^[a-zA-Z0-9åäöÅÄÖæøÆØ _,.+-/\\*()=]*$');
    const regExNameResult = regExName.exec(inputValue);
    if (!regExNameResult) {
      enqueueSnackbar(
        'Unallowed characters were used. Only a-zA-Z0-9åäöÅÄÖæøÆØ _.- are allowed.',
        {
          variant: 'error'
        }
      );

      return console.log('Unallowed characters were used.');
    }

    if (inputValue && inputValue.length > 100) {
      enqueueSnackbar('Name too long. ' + inputValue.length + '/100 used', {
        variant: 'error'
      });

      return console.log('name too long.');
    }

    if (inputValue === item[itemKey + 'Formula']) {
      toggleShowTextField(false);
      return '';
    }

    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          [itemKey]: inputValue
        }
      ],
      updateKeys: [itemKey]
    };

    console.log('documentInput: ', documentInput);

    updateDocument({ variables: { input: { ...documentInput } } });
    toggleShowTextField(false);
  };
  const onBlurHandler = (e) => {
    // e.preventDefault();
    toggleShowTextField(false);
  };
  const onKeyDownHandler = (e) => {
    if (e.key === 'Escape') {
      toggleShowTextField(false);
    }
  };

  const onChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  let numberOfDecimals = 0;

  switch (itemKey) {
    case 'impressions':
    case 'viewableImpressions':
    case 'measurableImpressions':
    case 'eligibleImpressions':
    case 'clicks':
    case 'universe':
      numberOfDecimals = 0;
      break;
    default:
      numberOfDecimals = 2;
  }

  const textField = (
    <form
      //   style={{ padding: '0px 14px' }}
      onSubmit={onSubmitHandler}
      onBlur={onSubmitHandler}
      // className={classes.root}
      noValidate
      autoComplete="off"
    >
      <TextField
        variant="standard"
        autoFocus
        id="standard-basic"
        value={inputValue}
        inputProps={{
          min: 0,
          style: { textAlign: 'left', padding: '0px', fontSize: '12px' }
        }}
        onBlur={onBlurHandler}
        onKeyDown={onKeyDownHandler}
        onChange={onChangeHandler}
      />
      {/* <TextField id="filled-basic" label="Filled" variant="filled" />
    <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
    </form>
  );

  // let subValue = '';

  // switch (itemKey) {
  //   default:
  //     subValue = item[itemKey + 'SumChild'] ? item[itemKey + 'SumChild'] : '-';
  //     if (typeof subValue === 'number') {
  //       subValue = parseFloat(subValue).toLocaleString(undefined, {
  //         minimumFractionDigits: numberOfDecimals,
  //         maximumFractionDigits: numberOfDecimals
  //       });
  //     }

  //     break;
  // }

  if (
    item.sum_child_budgets &&
    item.sum_child_budgets !== 0 &&
    item.sum_child_budgets !== undefined
  ) {
    // child_sum =
    //   'sum. child.' + parseInt(item.sum_child_budgets).toLocaleString();
  }

  // ##################################################

  let spanStyle = {
    margin: 'auto',
    width: '100%'
  };
  if (true) {
    spanStyle = { textAlign: 'right' };
  }

  let displayItemValue = null;
  let displayValueColor = null;

  if (
    itemValue === null ||
    itemValue === undefined ||
    itemValue === '' ||
    itemValue == '0'
  ) {
    displayItemValue = '-';
    displayValueColor = 'grey';
  } else if (isNaN(itemValue)) {
    displayItemValue = itemValue;
  } else {
    displayItemValue = (
      Math.round((itemValue + Number.EPSILON) * 100) / 100
    ).toLocaleString(undefined, {
      minimumFractionDigits: numberOfDecimals,
      maximumFractionDigits: numberOfDecimals
    });
  }

  if (
    ([
      'manualDataEntry',
      // 'folder',
      'facebook_ads_campaign',
      'google_ads_campaign',
      'cm360_campaign'
      // 'task',
      // 'subTask'
    ].includes(item.type) &&
      [
        'cost',
        'universe',
        'trp',
        'spots',
        'impressions',
        'viewableImpressions',
        'measurableImpressions',
        'eligibleImpressions',
        'reach',
        'clicks',
        'action01Count',
        'action02Count',
        'action03Count',

        // 'action01Value',
        // 'action02Value',
        // 'action03Value',

        'plannedGross',
        'plannedNet',
        'plannedNetNet',
        'plannedCtc',

        'plannedTrp',
        'plannedCpp',
        'plannedTechFee',
        'plannedAdOpsFee',
        'plannedAdServingCost',
        'plannedFeePercent',

        'kpi',
        'plannedImpressions',
        'plannedClicks',
        'plannedCtr',
        'plannedCpc',
        'plannedReach',
        'plannedCpm',
        'plannedFrequency',
        'plannedActionCount',
        'plannedActionValue',
        'plannedActionRoas',
        'plannedCpa',
        'budget'
      ].includes(itemKey)) ||
    ([
      'manualDataEntry',
      'folder',
      // 'facebook_ads_campaign',
      // 'google_ads_campaign',
      // 'cm360_campaign',
      'task',
      'subTask'
      // 'taxonomy'
    ].includes(item.type) &&
      [
        // 'cost',
        // 'impressions',
        //  'viewableImpressions',
        // 'measurableImpressions',
        // 'eligibleImpressions',
        // 'reach',
        // 'clicks',
        // 'action01Count',
        // 'action02Count',
        // 'action03Count',

        // 'action01Value',
        // 'action02Value',
        // 'action03Value',
        'goalDescription',
        'universe',
        'plannedGross',
        'plannedNet',
        'plannedNetNet',
        'plannedCtc',

        'plannedTrp',
        'plannedCpp',
        'plannedTechFee',
        'plannedAdOpsFee',
        'plannedAdServingCost',
        'plannedFeePercent',

        'kpi',
        'plannedImpressions',
        'plannedClicks',
        'plannedCtr',
        'plannedCpc',
        'plannedReach',
        'plannedCpm',
        'plannedFrequency',
        'plannedActionCount',
        'plannedActionValue',
        'plannedActionRoas',
        'plannedCpa',
        'budget'
      ].includes(itemKey))
  ) {
    return (
      <div
        key={keyForMap}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          //   padding: '10px 7px',
          // padding: '10px 14px 9px 14px',
          padding: '0px 14px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <div
          style={{
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {showTextField ? (
            <span style={{ textAlign: 'left', width: '100%' }}>
              {textField}
            </span>
          ) : (
            <p
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                // minWidth: '200px',
                width: '100%'
              }}
            >
              {/* <span
                style={{
                  textAlign: isNaN(itemValue) ? 'left' : 'right',
                  width: '100%'
                }}
              > */}
              <span
                style={{
                  textAlign: isNaN(itemValue) ? 'left' : 'right',
                  width: '100%',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: displayValueColor
                }}
              >
                {displayItemValue}
                {/* {isNaN(itemValue) ? itemValue : itemValue} */}
                {/* </span> */}
              </span>
              <span>
                <span>
                  <i
                    className={
                      'fas fa-pen ' +
                      ([
                        'cost',
                        'trp',
                        'spots',
                        'impressions',
                        'viewableImpressions',
                        'measurableImpressions',
                        'eligibleImpressions',
                        'clicks',
                        'action01Count',
                        'action02Count',
                        'action03Count'
                      ].includes(itemKey)
                        ? 'transparent-hover-grey'
                        : 'hover-grey')
                    }
                    style={{
                      fontSize: '8px',
                      // color: 'grey',
                      padding: '4px 0 4px 2px',
                      cursor: 'pointer'
                    }}
                    onClick={onClickHandler}
                  ></i>
                </span>
              </span>
            </p>
          )}
          {/* <p
            style={{
              fontSize: '8px',
              color: '#bcbcbc',
              textAlign: 'right',
              marginRight: '11px'
              // visibility: 'hidden'
            }}
          > */}
          {/* {' '} */}
          {/* {subValue && <SimpleTooltip tooltipMessage={subValue} />} */}
          {/* </p> */}
        </div>
      </div>
    );
  } else {
    return (
      <div
        key={keyForMap}
        style={{
          maxHeight: '46px',

          // fontSize: '12px',

          left: '53px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
          //   padding: '10px 7px',
          // padding: '10px 14px',
          padding: '0px 14px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <div
          style={{
            textAlign: isNaN(itemValue) ? 'left' : 'right',
            // width: '100%'
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          <p
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              // minWidth: '200px',
              width: '100%'
            }}
          >
            <span
              style={{
                textAlign: isNaN(itemValue) ? 'left' : 'right',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: displayValueColor
              }}
            >
              {/* {itemValue && parseFloat(itemValue.toFixed(2)).toLocaleString()} */}
              {[
                'account',
                'folder',
                'facebook_ads_campaign',
                'facebook_ads_ad_set',
                'google_ads_campaign',
                'google_ads_ad_group',
                'cm360_campaign',
                'dv360_insertion_order',
                'dv360_line_item'
              ].includes(item.type) && displayItemValue}

              <i
                className={'fas fa-pen '}
                style={{
                  fontSize: '8px',
                  // color: 'grey',
                  padding: '4px 0 4px 2px',
                  cursor: 'pointer',
                  visibility: 'hidden'
                }}
                // onClick={onClickHandler}
              ></i>
              {/* {isNaN(itemValue) ? itemValue : itemValue.toFixed(2)} */}
            </span>
          </p>
          {/* <p
            style={{
              fontSize: '8px',
              color: '#bcbcbc',
              visibility: 'hidden'
            }}
          >
            {' '}
            test
          </p> */}
        </div>
      </div>
    );
  }
}

export default React.memo(StringItem);
