import React, { useEffect, useContext } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';

import { useInView } from 'react-intersection-observer';
import { InterfaceContext } from '../../../../context/InterfaceContext';

import { FilterContext } from '../../../../context/FilterContext';

export default function Checkboxes({
  item,
  maxHeight,
  displayArrayOfData,
  checkedBoxes,
  setCheckedBoxes
}) {
  // const { checkedBoxes, setCheckedBoxes } = useContext(FilterContext);
  const [checked, setChecked] = React.useState(false);

  const { exportMode, advancedMode } = useContext(InterfaceContext);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  useEffect(() => {
    const currentBoxInCheckedBoxes = checkedBoxes.filter((obj) => {
      return obj._id === item._id;
    });
    if (currentBoxInCheckedBoxes.length > 0) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  });

  const onClickHandler = (e) => {
    // console.log('test44>displayArrayOfData: ', displayArrayOfData);
    // console.log('test44>checkedBoxes: ', checkedBoxes);
    // console.log('event:', e);
    // e.stopPropagation();
    setChecked(e.target.checked);
    const currentBoxInCheckedBoxes = checkedBoxes.filter((obj) => {
      return obj._id === item._id;
    });

    // console.log('currentBoxInCheckedBoxes: ', currentBoxInCheckedBoxes);
    // console.log(
    //   'displayArrayOfData.length > 0: ',
    //   displayArrayOfData.length > 0
    // );
    // In that case, event.ctrlKey does the trick.
    if (
      e.shiftKey &&
      displayArrayOfData.length > 0 &&
      currentBoxInCheckedBoxes.length < 1
    ) {
      console.log('shift!');

      const startRow = checkedBoxes[checkedBoxes.length - 1];

      // console.log('checkedBoxes: ', checkedBoxes);
      // console.log('startRow: ', startRow);

      const startRowIndex = displayArrayOfData.findIndex(
        (obj) => obj._id === startRow._id
      );
      // console.log('test44>startRowIndex: ', startRowIndex);

      // console.log('checkedBoxes: ', checkedBoxes);
      // console.log('startRow: ', startRow);

      const endRowIndex = displayArrayOfData.findIndex(
        (obj) => obj._id === item._id
      );
      // console.log('test44>endRowIndex: ', endRowIndex);

      const newCheckedBoxes = [...displayArrayOfData];

      // console.log(
      //   'startRowIndex < endRowIndex ? startRowIndex : endRowIndex: ',
      //   startRowIndex < endRowIndex ? startRowIndex : endRowIndex
      // );
      // console.log(
      //   'startRowIndex < endRowIndex ? endRowIndex : startRowIndex: ',
      //   startRowIndex < endRowIndex ? endRowIndex : startRowIndex
      // );
      setCheckedBoxes(
        newCheckedBoxes.slice(
          startRowIndex < endRowIndex ? startRowIndex : endRowIndex,
          (startRowIndex < endRowIndex ? endRowIndex : startRowIndex) + 1
        )
      );

      // console.debug('Shift+click has just happened!');
    } else {
      // setChecked(e.target.checked);
      // const currentBoxInCheckedBoxes = checkedBoxes.filter((obj) => {
      //   return obj._id === item._id;
      // });

      if (currentBoxInCheckedBoxes.length < 1) {
        // console.log('test44>currentBoxInCheckedBoxes.length < 1');
        setCheckedBoxes([...checkedBoxes, item]);
      }

      if (currentBoxInCheckedBoxes.length > 0) {
        setCheckedBoxes(
          checkedBoxes.filter((obj) => {
            return obj._id !== item._id;
          })
        );
      }
    }
    // console.log('test44>checkedBoxes: ', checkedBoxes);
  };

  const handleChange = (e) => {
    // setChecked(e.target.checked);
    // const currentBoxInCheckedBoxes = checkedBoxes.filter((obj) => {
    //   return obj._id === item._id;
    // });
    // if (currentBoxInCheckedBoxes.length < 1) {
    //   setCheckedBoxes([...checkedBoxes, item]);
    // }
    // if (currentBoxInCheckedBoxes.length > 0) {
    //   setCheckedBoxes(
    //     checkedBoxes.filter((obj) => {
    //       return obj._id !== item._id;
    //     })
    //   );
    // }
  };

  const onDivClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      style={{ maxHeight, maxWidth: maxHeight }}
      onClick={(e) => onDivClick(e)}
      ref={ref}
    >
      {inView || exportMode ? (
        <Checkbox
          size="small"
          style={{ maxHeight, maxWidth: maxHeight }}
          checked={checked}
          // onChange={handleChange}
          inputProps={{ 'aria-label': 'primary checkbox' }}
          // onMouseDown={(e) => handleOnMouseDown(e)}
          onClick={onClickHandler}
        />
      ) : (
        <div style={{ height: maxHeight, width: maxHeight }}></div>
      )}
      {/* <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        name="checkedI"
      /> */}
    </div>
  );
}
