import React, { useContext, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { AccountContext } from '../../../../context/AccountContext';
import { DialogContext } from './../../../../context/DialogContext';

import GeneralDescriptionField from './GeneralDescriptionField';
import SubTasks from './subtasks/SubTasks';

// import CommentDialogContent from './../../item-components/body/comment/CommentDialogContent';
import NewsFeedDialogContent from '../newsfeed/NewsFeedDialogContent';
//import TextInput from './TextInput';

import TextInput2 from './TextInput2';

import CloseIcon from '@mui/icons-material/Close';

import Members from './Members';

// import Labels from './Labels';
import Labels from './../../item-components/body/labelItem/Labels';

import Personalcheckbox from './PersonalCheckbox';

import DateItemContent from '../../item-components/body/dateItem/DateItemContent';

import StatusMenu from '../../item-components/body/StatusMenu';

import FieldMenu from './field-menu/FieldMenu';

import { InterfaceContext } from './../../../../context/InterfaceContext';

import LineChart from './components/line-chart/LineChart';
import MathEvalItem from './components/MathEvalItem';

import {
  fieldDictionary as _fieldDictionary,
  fieldDictionary
} from './field-menu/fields';

import { columnArray } from '../../column-menu/columns';

import prepDataFunction from '../prepDataFunction';

import ExportIMG from './ExportIMG';

import { AuthContext } from '../../../../context/AuthContext';

import HistorySwitch from './../../../news-feed/HistorySwitch';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper style={{ width: '900px', maxWidth: '100vw' }} {...props} />
    </Draggable>
  );
}

const InfoModal = ({ rawArrayOfData, stylesStoredInRedux, aggregatedMode }) => {
  const { user } = useContext(AuthContext);
  const { currentManagerAccount } = useContext(AccountContext);

  const [includeHistory, setIncludeHistory] = useState(false);
  const [listOfCustomFields, setListOfCustomFields] = useState([]);
  const [scrollTop, setScrollTop] = useState(0);
  const {
    infoModalDocumentOpen: open,
    setInfoModalDocumentOpen: setOpen,
    itemId,
    setItemId
  } = useContext(DialogContext);

  const {
    fields,
    setFields,
    overrideDefaultFields,
    setOverrideDefaultFields,
    range2
  } = useContext(InterfaceContext);
  // const [open, setOpen] = React.useState(false);

  const item = rawArrayOfData.filter((obj) => obj._id === itemId)[0];
  const time = 'day';
  const columns = columnArray;

  const fieldDictionary = {
    ..._fieldDictionary,
    action01Count: item?.action01?.name
      ? item?.action01?.name + ' count'
      : 'Action 01 actions',
    action02Count: item?.action02?.name
      ? item?.action02?.name + ' count'
      : 'Action 02 actions',
    action03Count: item?.action03?.name
      ? item?.action03?.name + ' count'
      : 'Action 03 actions',
    action01Value: item?.action01?.name
      ? item?.action01?.name + ' value'
      : 'Action 01 value',
    action02Value: item?.action02?.name
      ? item?.action02?.name + ' value'
      : 'Action 02 value',
    action03Value: item?.action03?.name
      ? item?.action03?.name + ' value'
      : 'Action 03 value',

    action01Roas: item?.action01?.name
      ? item?.action01?.name + ' roas'
      : 'Action 01 roas',
    action02Roas: item?.action02?.name
      ? item?.action02?.name + ' roas'
      : 'Action 02 roas',
    action03Roas: item?.action03?.name
      ? item?.action03?.name + ' roas'
      : 'Action 03 roas',

    action01Cpa: item?.action01?.name
      ? item?.action01?.name + ' cpa'
      : 'Action 01 cpa',
    action02Cpa: item?.action02?.name
      ? item?.action02?.name + ' cpa'
      : 'Action 02 cpa',
    action03Cpa: item?.action03?.name
      ? item?.action03?.name + ' cpa'
      : 'Action 03 cpa'
  };

  const [prepared_data, setPrepared_data] = useState([]);

  useEffect(() => {
    if (!aggregatedMode) {
      let prepared_data = prepDataFunction(
        item,
        range2,
        time,
        columns,
        fieldDictionary
      );
    }
    setPrepared_data(prepared_data);
  });

  useEffect(() => {
    let fallbackFields = [];

    switch (item.type) {
      case 'task':
        fallbackFields = [
          { name: 'generalDescription', string: 'generalDescription' },
          { name: 'status', string: 'status' },
          { name: 'dates', string: 'dates' },
          { name: 'labels', string: 'labels' },
          { name: 'members', string: 'members' },
          { name: 'isPersonal', string: 'isPersonal' },
          { name: 'subTasks', string: 'subTasks' },
          { name: 'comments', string: 'comments' }
        ];
        break;
      default:
        fallbackFields = [
          { name: 'generalDescription', string: 'generalDescription' },
          { name: 'status', string: 'status' },
          { name: 'dates', string: 'dates' },
          { name: 'labels', string: 'labels' },
          { name: 'members', string: 'members' },
          { name: 'comments', string: 'comments' }
        ];
    }
    if (!overrideDefaultFields) {
      if (item?.defaultNexusBoardId) {
        // filter through saved sets.

        const filteredListOFCustomFields = listOfCustomFields.filter(
          (fieldSet) => fieldSet._id === item?.defaultNexusBoardId
        );
        if (filteredListOFCustomFields.length === 1) {
          setOverrideDefaultFields(true);
          setFields(filteredListOFCustomFields[0].fields);
        } else {
          setFields(fallbackFields);
        }
      } else {
        setFields(fallbackFields);
      }
    }
  }, [item, listOfCustomFields]);

  if (!item) {
    setOpen(false);
    return <div></div>;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFields([]);
    setOverrideDefaultFields(false);
    setOpen(false);
  };

  const body = (
    <div
      id="nexustable-info-modal"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {fields &&
        fields.map((field, index) => {
          switch (field.string) {
            // case 'generalDescription':
            case 'buyingTypeDescription':
            case 'creativeDescription':
            case 'formatDescription':
            case 'channelDescription':
            case 'audienceDataDescription':
            case 'targetGroupDescription':
            case 'placementDescription':
            case 'goalDescription':
            case 'budgetCurrency':
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    backgroundColor: 'rgba(150, 150, 150, 0.05)',
                    margin: '10px 25px 10px 25px',
                    padding: '10px 0px 10px 20px',
                    width: '50%',
                    fontSize: '14px'
                  }}
                >
                  <div style={{ fontWeight: 'bold', minWidth: '150px' }}>
                    {fieldDictionary[field.field] + ':'}
                  </div>
                  <MathEvalItem
                    ketForMap={index}
                    item={item}
                    itemValue={item[field.field]}
                    itemKey={field.field}
                  />
                </div>
              );
              break;
            case 'cost':
            case 'impressions':
            case 'viewableImpressions':
            case 'measurableImpressions':
            case 'eligibleImpressions':
            case 'cpm':
            case 'vCpm':
            case 'clicks':
            case 'cpc':
            case 'ctr':
            case 'viewableRate':
            case 'searchImpressionShare':
            case 'action01Count':
            case 'action02Count':
            case 'action03Count':
            case 'action01Value':
            case 'action02Value':
            case 'action03Value':
            case 'action01Roas':
            case 'action02Roas':
            case 'action03Roas':
            case 'action01Cpa':
            case 'action02Cpa':
            case 'action03Cpa':
            case 'action01Cvr':
            case 'action02Cvr':
            case 'action03Cvr':
            case 'action01Vpa':
            case 'action02Vpa':
            case 'action03Vpa':

            case 'plannedGross':
            case 'plannedNet':
            case 'plannedNetNet':
            case 'plannedCtc':

            case 'plannedTrp':
            case 'plannedCpp':
            case 'plannedTechFee':
            case 'plannedAdOpsFee':
            case 'plannedAdServingCost':
            case 'plannedFeePercent':

            case 'kpi':
            case 'plannedImpressions':
            case 'plannedClicks':
            case 'plannedCtr':
            case 'plannedCpc':
            case 'plannedReach':
            case 'plannedCpm':
            case 'plannedFrequency':
            case 'plannedActionCount':
            case 'plannedActionValue':
            case 'plannedActionRoas':
            case 'plannedCpa':
            case 'budget':
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    backgroundColor: 'rgba(150, 150, 150, 0.05)',
                    margin: '10px 25px 10px 25px',
                    padding: '10px 0px 10px 20px',
                    width: '35%',
                    fontSize: '14px'
                  }}
                >
                  <div style={{ fontWeight: 'bold', minWidth: '150px' }}>
                    {fieldDictionary[field.string] + ':'}
                  </div>
                  <MathEvalItem
                    ketForMap={index}
                    item={item}
                    itemValue={item[field.string]}
                    itemKey={field.string}
                  />
                </div>
              );
              break;
            case 'costLineChart':
            case 'trpLineChart':
            case 'spotsLineChart':
            case 'impressionsLineChart':
            case 'viewableImpressionsLineChart':
            case 'measurableImpressionsLineChart':
            case 'eligibleImpressionsLineChart':
            case 'cpmLineChart':
            case 'vCpmLineChart':
            case 'clicksLineChart':
            case 'ctrLineChart':
            case 'viewableRateLineChart':
            case 'searchImpressionShareLineChart':
            case 'cpcLineChart':
            case 'action01CountLineChart':
            case 'action02CountLineChart':
            case 'action03CountLineChart':
            case 'action01ValueLineChart':
            case 'action02ValueLineChart':
            case 'action03ValueLineChart':
            case 'action01RoasLineChart':
            case 'action02RoasLineChart':
            case 'action03RoasLineChart':
            case 'action01CpaLineChart':
            case 'action02CpaLineChart':
            case 'action03CpaLineChart':
            case 'action01CvrLineChart':
            case 'action02CvrLineChart':
            case 'action03CvrLineChart':
            case 'action01VpaLineChart':
            case 'action02VpaLineChart':
            case 'action03VpaLineChart':

            case 'totalCostLineChart':
            case 'totaltrpLineChart':
            case 'totalSpotsLineChart':
            case 'totalImpressionsLineChart':
            case 'totalClicksLineChart':
            case 'totalAction01CountLineChart':
            case 'totalAction02CountLineChart':
            case 'totalAction03CountLineChart':
            case 'totalAction01ValueLineChart':
            case 'totalAction02ValueLineChart':
            case 'totalAction03ValueLineChart':
            case 'totalAction01RoasLineChart':
            case 'totalAction02RoasLineChart':
            case 'totalAction03RoasLineChart':
            case 'totalAction01CpaLineChart':
            case 'totalAction02CpaLineChart':
            case 'totalAction03CpaLineChart':
            case 'totalaction01CvrLineChart':
            case 'totalaction02CvrLineChart':
            case 'totalaction03CvrLineChart':
            case 'totalAction01VpaLineChart':
            case 'totalAction02VpaLineChart':
            case 'totalAction03VpaLineChart':
            case 'LINECHART':
              // const fieldMetric = field.string.split('LineChart')[0];
              // const tempDataArray = item[fieldMetric + 'Sparkline'];

              // const copyOfRange = [...range2];
              // const prepared_data = [];
              // for (let i = 0; i < range2.length; i++) {
              //   prepared_data.push({
              //     [fieldMetric]: tempDataArray[i]
              //       ? parseFloat(tempDataArray[i].toFixed(2))
              //       : 0,
              //     [fieldDictionary[fieldMetric]]: tempDataArray[i]
              //       ? parseFloat(tempDataArray[i].toFixed(2))
              //       : 0,
              //     id: new Date(range2[i]).toISOString().split('T')[0],
              //     date_start: new Date(range2[i]).toISOString().split('T')[0],
              //     date: new Date(range2[i]).toISOString().split('T')[0]
              //   });
              // }

              // const leftYAxisMetrics =
              //   field?.leftYAxisMetrics?.map((metric) => metric.string) || [];

              // const rightYAxisMetrics =
              //   field?.rightYAxisMetrics?.map((metric) => metric.string) || [];
              // // const time = 'day';
              const isExpanded = false;

              return (
                <div style={{ margin: '30px 0px' }}>
                  <LineChart
                    componentIndex={index}
                    prepared_data={prepared_data}
                    // leftYAxisMetrics={leftYAxisMetrics}
                    // rightYAxisMetrics={rightYAxisMetrics}
                    time={time}
                    isExpanded={isExpanded}
                    columnDictionary={fieldDictionary}
                    field={field}
                    fields={fields}
                    setFields={setFields}
                  />
                </div>
              );
            case 'comments':
              return (
                <>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div
                      style={{
                        marginLeft: '25px',
                        fontWeight: 'bold',
                        fontSize: '14px'
                      }}
                    >
                      Comments
                    </div>

                    <div>
                      <HistorySwitch
                        includeHistory={includeHistory}
                        setIncludeHistory={setIncludeHistory}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      // display: 'flex',
                      // alignItems: 'flex-end',
                      // width: '90%',
                      // maxHeight: '500px',
                      height: '750px',
                      // height: 'auto',
                      // padding: '10px',
                      // margin: '20px 25px 25px 25px',
                      borderRadius: '5px',
                      border: '1px solid rgba(150, 150, 150, 0.3)'
                      // overflowY: 'scroll'
                      // backgroundColor: 'rgba(150, 150, 150, 0.1)'
                    }}
                  >
                    <NewsFeedDialogContent
                      item={item}
                      currentManagerAccountId={currentManagerAccount._id}
                      rawArrayOfData={rawArrayOfData}
                      includeHistory={includeHistory}
                    />
                  </div>
                </>
              );
            case 'dates':
              return (
                <div
                  style={{
                    margin: '15px 25px',
                    fontSize: '12px'
                    // marginBottom: item.type !== 'task' && '60px'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      fontSize: '14px'
                    }}
                  >
                    <div style={{ width: '120px' }}>
                      <div
                        style={{
                          fontWeight: 'bold',
                          marginBottom: '10px',
                          fontSize: '14px'
                        }}
                      >
                        Start Date
                      </div>
                      <DateItemContent item={item} itemKey={'startDate'} />
                    </div>
                    <div style={{ width: '120px', marginLeft: '50px' }}>
                      <div
                        style={{
                          fontWeight: 'bold',
                          marginBottom: '10px',
                          fontSize: '14px'
                        }}
                      >
                        End Date
                      </div>
                      <DateItemContent item={item} itemKey={'endDate'} />
                    </div>
                  </div>
                </div>
              );
            case 'generalDescription':
              return (
                <div style={{ margin: '15px 25px' }}>
                  <GeneralDescriptionField
                    currentManagerAccount={currentManagerAccount}
                    item={item}
                    stylesStoredInRedux={stylesStoredInRedux}
                  />
                </div>
              );
            case 'labels':
              return (
                <div
                  style={{
                    margin: '15px 25px',
                    fontSize: '12px'
                    // marginBottom: item.type !== 'task' && '60px'
                  }}
                >
                  <div
                    style={{
                      fontWeight: 'bold',
                      marginBottom: '10px',
                      fontSize: '14px'
                    }}
                  >
                    Labels
                  </div>
                  <Labels item={item} />
                </div>
              );
            case 'members':
              return (
                <div
                  style={{
                    margin: '15px 25px',
                    marginBottom: item?.type !== 'task' && '60px'
                  }}
                >
                  <Members item={item} />
                </div>
              );
            case 'isPersonal':
              return (
                <div
                  style={{
                    margin: '10px 25px 45px 25px',
                    width: 'fit-content'
                  }}
                >
                  <Personalcheckbox
                    currentManagerAccountId={currentManagerAccount?._id}
                    item={item}
                  />
                </div>
              );
            case 'status':
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: '14px',
                    marginLeft: '25px',
                    marginBottom: '30px'
                  }}
                >
                  <StatusMenu
                    currentManagerAccountId={currentManagerAccount._id}
                    item={item}
                    showText={true}
                  />
                </div>
              );
            case 'subTasks':
              return (
                <SubTasks
                  currentManagerAccountId={currentManagerAccount?._id}
                  item={item}
                  rawArrayOfData={rawArrayOfData}
                />
              );
            default:
              return <></>;
          }
        })}
    </div>
  );

  const onScrollHandler = (e) => {
    setScrollTop(e.target.scrollTop);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open draggable dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            {['folder', 'task'].includes(item.type) ? (
              <TextInput2
                currentManagerAccount={currentManagerAccount}
                itemKey={'name'}
                item={item}
                stylesStoredInRedux={stylesStoredInRedux}
              />
            ) : (
              <div>{item?.name || ''}</div>
            )}
            {/* <span>{item.name}</span> */}
            {/* <span style={{ fontSize: '12px' }}>{item.path}</span> */}
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <ExportIMG item={item} />
              </div>
              {['system_admin'].includes(user.role) && (
                <div>
                  <FieldMenu
                    listOfCustomFields={listOfCustomFields}
                    setListOfCustomFields={setListOfCustomFields}
                    item={item}
                  />
                </div>
              )}

              <div
                style={{
                  cursor: 'pointer',
                  right: '0px',
                  textAlign: 'right',
                  padding: '5px'
                  // paddingRight: '25px'
                }}
              >
                <span style={{ width: '50px' }} onClick={handleClose}>
                  <CloseIcon className="hover-light-grey" />
                </span>
              </div>
            </div>
          </div>
          <div style={{ fontSize: '12px', padding: '0px 5px' }}>
            {item.path}
          </div>
        </DialogTitle>
        <DialogContent
          sx={{ overflowX: 'hidden' }}
          onScroll={(e) => onScrollHandler(e)}
        >
          {body}
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleClose}>Subscribe</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
};

InfoModal.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  // currentManagerAccount: PropTypes.object.isRequired
  // style: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  stylesStoredInRedux: state.style
  // user: state.auth.user
});

export default connect(mapStateToProps, {})(InfoModal);
