import React, { useState, useEffect, useContext } from 'react';
// import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

import { useSnackbar } from 'notistack';
import Checkbox from '@mui/material/Checkbox';

import { useInView } from 'react-intersection-observer';
import { InterfaceContext } from '../../../../context/InterfaceContext';

import { MutationContext } from '../../../../context/MutationContext';
import Tooltip from '@mui/material/Tooltip';

import { columnDictionary } from './../../column-menu/columns';
// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(0),
//       width: '100%'
//     }
//   }
// }));

function BooleanCheckboxItem({
  currentManagerAccountId,
  // updateDocument,
  itemStyle,
  keyForMap,
  item,
  itemKey,
  itemValue,
  rowNumber,
  showItemsHandler,
  openRows,
  checked
}) {
  const { enqueueSnackbar } = useSnackbar();

  const { exportMode, advancedMode } = useContext(InterfaceContext);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const { updateDocument } = useContext(MutationContext);

  // ################### update #######################

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          [itemKey]: !item[itemKey]
        }
      ],
      updateKeys: [itemKey]
    };
    // console.log('item[itemKey]: ', item[itemKey]);
    // console.log('itemKey', itemKey);

    // updateDocument({ variables: { input: { ...documentInput } } });

    try {
      const res = await updateDocument({
        variables: { input: { ...documentInput } }
      });
      // console.log('res: ', res);
      // console.log(
      //   'res?.data?.updateDocument?.response: ',
      //   res?.data?.updateDocument?.response
      // );
      if (res?.data?.updateDocument?.response !== 'Success') {
        throw new Error();
      }
    } catch (error) {
      enqueueSnackbar('ERROR! PLEASE RELOAD THE PAGE AND TRY AGAIN!', {
        variant: 'error'
      });
    }
  };

  // ##################################################

  let spanStyle = {
    margin: 'auto'
  };
  if (true) {
    spanStyle = { textAlign: 'right' };
  }
  const label = { inputProps: { 'aria-label': 'Template' } };

  if (
    [
      'manualDataEntry',
      'folder',
      'facebook_ads_campaign',
      'google_ads_campaign',
      'cm360_campaign',
      'dv360_insertion_order',
      'snapchat_ads_campaign',
      'tiktok_ads_campaign',
      'pinterest_ads_campaign',
      'readpeak_campaign',
      'strossle_campaign',
      'amazon_dsp_order',
      'taboola_ads_campaign',
      'microsoft_advertising_campaign',
      'apple_search_ads_campaign',
      'adform_order',
      'linkedin_ads_campaign_group',
      'task',
      'subTask',
      'taxonomy'
    ].includes(item.type) ||
    !item.isSetChild
  ) {
    return (
      <div
        ref={ref}
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          borderStyle: 'solid',
          borderWidth: '0px 1px 1px 0px',
          borderColor: itemStyle['borderColor'],
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          left: '53px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          //   padding: '10px 7px',
          // padding: '10px 14px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        <Tooltip followCursor title={columnDictionary[itemKey]}>
          <div
            style={{ maxHeight: '46px', maxWidth: '46px' }}
            // onClick={(e) => onDivClick(e)}
          >
            {(inView || exportMode) && (
              <Checkbox
                {...label}
                checked={item[itemKey]}
                onChange={onSubmitHandler}
                size="small"
              />
            )}
          </div>
        </Tooltip>
      </div>
    );
  } else {
    return (
      <div
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          borderRight: itemStyle['border'],
          borderBottom: itemStyle['border'],
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          display: 'grid',
          height: '100%',
          width: '100%',
          padding: '12px 15px',
          whiteSpace: 'nowrap'
        }}
      >
        <span style={spanStyle}>
          <p>
            {/* {itemValue && parseFloat(itemValue.toFixed(2)).toLocaleString()} */}
            {itemValue && itemValue}
          </p>
          <p
            style={{
              fontSize: '8px',
              color: '#bcbcbc',
              visibility: 'hidden'
            }}
          >
            {' '}
            test
          </p>
        </span>
      </div>
    );
  }
}

export default React.memo(BooleanCheckboxItem);
