import React, { useContext, useEffect, useRef, useState } from 'react';
import Card from './card/Card';
import { useInView } from 'react-intersection-observer';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { useMutation } from '@apollo/client';
import { updateDocumentMutation } from '../../../../../graphql/queries';

import { AccountContext } from '../../../../../context/AccountContext';

import { MutationContext } from '../../../../../context/MutationContext';

import CreateTodoButton from '../create-todo-column-button/CreateTodoButton';

import { DialogContext } from '../../../../../context/DialogContext';

function Column({
  isExpanded,
  // currentManagerAccount,
  column,
  filteredColumns,
  rawArrayOfData,
  stylesStoredInRedux,
  account,
  setAccount,
  hideSelected
}) {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const { viewDialogOpen } = useContext(DialogContext);
  const { currentManagerAccount, nexusDocumentStatuses } =
    useContext(AccountContext);
  const [testRender, setTestrender] = useState(0);
  // console.log('column: ', column);

  const columnRef = useRef(0);
  useEffect(() => {
    // console.log('columnRef: ', columnRef);
    // if (column.string === new Date().toISOString().split('T')[0]) {
    //   columnRef?.current.scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'nearest',
    //     inline: 'center'
    //   });
    // }
    setTestrender(testRender + 1);
  }, []);

  useEffect(() => {
    // console.log('columnRef: ', columnRef);
    if (column.string === new Date().toISOString().split('T')[0]) {
      columnRef?.current.scrollIntoView({
        behavior: 'instant',
        block: 'nearest',
        inline: 'center'
      });
    }
  }, [testRender]);
  // let fiberManualRecordIconColor = '#FFFFFF';

  const getStatusColor = (status) => {
    // console.log('getStatusColor>status: ', status);
    let fiberManualRecordIconColor = 'black';
    switch (status) {
      case 'ACTIVE':
        // case 'DONE':
        fiberManualRecordIconColor = 'green';
        break;
      case 'DONE':
      case 'COMPLETED':
        fiberManualRecordIconColor = 'rgba(0,128,0,0.4)';
        break;
      case 'REMOVED':
        fiberManualRecordIconColor = 'red';
        break;

      case 'DRAFT':
        fiberManualRecordIconColor = '#bcbcbc';
        break;
      case 'PAUSED':
        fiberManualRecordIconColor = 'rgb(62, 142, 208)';
        break;
      default:
        fiberManualRecordIconColor = 'orange';
        break;
    }
    return fiberManualRecordIconColor;
  };

  // const [updateDocument] = useMutation(updateDocumentMutation);

  const { updateDocument } = useContext(MutationContext);

  const drop = (e) => {
    console.log('e: ', e);
    e.preventDefault();
    const droppedItemString = e.dataTransfer.getData('droppedItem');
    const droppedItem = JSON.parse(droppedItemString);

    const droppedItemElement = document.getElementById(droppedItem.id);

    if (
      ![
        'folder',
        'manualDataEntry',
        'customForm',
        'facebook_ads_campaign',
        'google_ads_campaign',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'pinterest_ads_campaign',
        'readpeak_campaign',
        'strossle_campaign',
        'amazon_dsp_order',
        'taboola_ads_campaign',
        'microsoft_advertising_campaign',
        'apple_search_ads_campaign',
        'adform_order',
        'linkedin_ads_campaign_group',
        'task',
        'subTask',
        'taxonomy'
      ].includes(droppedItem.type)
    ) {
      droppedItemElement.style.display = 'block';
      console.log('Current item does not support drag and drop.');
      return;
    }

    if (column.string === droppedItem.status) {
      droppedItemElement.style.display = 'block';
      return;
    }

    droppedItemElement.style.display = 'block';

    // const documentInput = {
    //   managerAccountId: currentManagerAccount._id,
    //   accountId: droppedItem.accountId,
    //   documentId: droppedItem.id,
    //   // checkedDocumentId: item._id,
    //   document: { status: name },
    //   updateKey: 'status',
    //   action: 'UPDATE_DOCUMENT',

    //   documentType: droppedItem.documentType,
    //   level: droppedItem.level
    // };

    const documentInput = {
      managerAccountId: currentManagerAccount._id,
      documents: [
        {
          accountId: droppedItem.accountId,
          documentId: droppedItem.id,
          documentType: droppedItem.documentType,
          status: column.string
        }
      ],
      updateKeys: ['status']
    };

    updateDocument({ variables: { input: { ...documentInput } } });

    // moveDocument({ variables: { input } });
  };

  const dragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // const dragEnd = (e, value) => {
  //   console.log('dragEnd');
  //   const target = e.target;

  //   setTimeout(() => {
  //     target.style.display = 'block';
  //   }, 0);
  // };

  // ####################################################

  const orderedColumns = [...filteredColumns];

  switch (column.string) {
    case 'ACTIVE':
      orderedColumns.sort(function (a, b) {
        // if (a?.warnings.length > 0) {
        //   return -1;
        // }

        if (!a.startDate) {
          return 1;
        }

        if (!b.startDate) {
          return -1;
        }
        var dateA = new Date(a.startDate || null); // ignore upper and lowercase
        var dateB = new Date(b.startDate || null); // ignore upper and lowercase
        if (dateA > dateB) {
          return -1;
        }
        if (dateA < dateB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
      break;

    default:
      orderedColumns.sort(function (a, b) {
        // if (a?.warnings.length > 0) {
        //   return -1;
        // }
        if (!a.startDate) {
          return 1;
        }

        if (!b.startDate) {
          return -1;
        }

        var dateA = new Date(a.startDate || null); // ignore upper and lowercase
        var dateB = new Date(b.startDate || null); // ignore upper and lowercase
        if (dateA < dateB) {
          return -1;
        }
        if (dateA > dateB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
  }

  orderedColumns.sort(function (a, b) {
    if (!a?.highestWarningLevel) {
      return 1;
    }
    if (!b.highestWarningLevel) {
      return -1;
    }

    var highestWarningLevelA = a.highestWarningLevel || null; // ignore upper and lowercase
    var highestWarningLevelB = b.highestWarningLevel || null; // ignore upper and lowercase
    if (highestWarningLevelA > highestWarningLevelB) {
      return -1;
    }
    if (highestWarningLevelA < highestWarningLevelB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
  console.log('nexusDocumentStatuses: ', nexusDocumentStatuses);
  const displayColumn = [];
  for (let status of nexusDocumentStatuses) {
    console.log('status.status: ', status.status);
    const newStatusObject = {
      status,
      items: orderedColumns.filter((obj) => obj.status === status.string)
    };
    displayColumn.push(newStatusObject);
  }

  console.log('displayColumn: ', displayColumn);

  const columnStyle = {};
  switch (true) {
    case column.string === new Date().toISOString().split('T')[0] &&
      stylesStoredInRedux.styleName === 'light':
      columnStyle.backgroundColor = 'rgba(255,255,255,.3)';
      columnStyle.border = '2px solid #2a52a3aa';
      break;

    case column.string === new Date().toISOString().split('T')[0] &&
      stylesStoredInRedux.styleName === 'dark':
      columnStyle.backgroundColor = 'rgba(255,255,255,0.1)';
      columnStyle.border = '1px solid rgba(255,255,255,0.7';
      break;
    case stylesStoredInRedux.styleName === 'light':
      columnStyle.backgroundColor = 'rgba(255,255,255,.3)';
      break;

    case stylesStoredInRedux.styleName === 'dark':
      columnStyle.backgroundColor = 'rgba(255,255,255,0.1)';
      break;

    default:
  }

  return (
    <div
      ref={columnRef}
      onDrop={drop}
      onDragOver={dragOver}
      style={{ maxWidth: '285px', minWidth: '285px' }}
    >
      <div ref={ref}>
        {!inView ? (
          <div style={{ minHeight: '100px', width: '275px' }}></div>
        ) : (
          <div
            onDrop={drop}
            onDragOver={dragOver}
            // draggable
            className="kanbanColumn"
            style={{
              backdropFilter: 'blur(10px)',
              // backgroundColor:
              //   stylesStoredInRedux.styleName === 'light'
              //     ? 'rgba(255,255,255,.3)'
              //     : 'rgba(255,255,255,0.1)',
              // maxWidth: '270px',
              // minWidth: '270px',
              minHeight: '100px',
              height: 'fit-content',
              maxHeight: '100%',
              // height: 'calc(100vh - 200px)',
              // padding: '10px 3px 3px 10px',
              paddingRight: '3px',
              // margin: '10px',
              margin: '5px',
              borderRadius: '5px',
              boxShadow:
                '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
              ...columnStyle
            }}
          >
            <div
              style={{
                padding: '10px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
                // margin: '0px 0px 10px 0px'
              }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                {/* <span
              style={{
                height: '10px',
                width: '10px',
                backgroundColor: fiberManualRecordIconColor,
                borderRadius: '50%',
                display: 'inline-block',
                border: '1px solid white',
                marginRight: '5px'
              }}
            ></span> */}
                <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                  {column.dayOfWeek}
                </span>
                <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                  {column.name}
                </span>
                <span style={{}}>wk {column.weekNumber}</span>
              </div>
              <div>
                {/* <CreateTodoButton
              stylesStoredInRedux={stylesStoredInRedux}
              status={column.string}
              account={account}
              setAccount={setAccount}
            /> */}
              </div>
            </div>
            <div
              onDrop={drop}
              onDragOver={dragOver}
              style={{
                overflowY: 'scroll',
                maxHeight: isExpanded
                  ? 'calc(100vh - 205px)'
                  : 'calc(90vh - 205px)',
                paddingRight: '3px'
              }}
            >
              {displayColumn.map((obj) => {
                if (obj.items.length) {
                  return (
                    <>
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          fontSize: '12px',
                          fontWeight: 'bold',
                          padding: '3px'
                        }}
                      >
                        <span
                          style={{
                            height: '10px',
                            width: '10px',
                            backgroundColor: getStatusColor(obj.status.string),
                            borderRadius: '50%',
                            display: 'inline-block',
                            border: '1px solid white',
                            marginRight: '5px'
                          }}
                        ></span>
                        <span>{obj.status.name}</span>
                      </div>
                      {obj.items.map((item) => (
                        <Card
                          item={item}
                          rawArrayOfData={rawArrayOfData}
                          hideSelected={hideSelected}
                          column={column}
                        />
                      ))}
                    </>
                  );
                }
              })}
              {/* {orderedColumns.map((item) => (
            <Card
              item={item}
              rawArrayOfData={rawArrayOfData}
              hideSelected={hideSelected}
              column={column}
            />
          ))} */}
              {/* <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card />
        <Card /> */}
            </div>
            {/* <div style={{ cursor: 'pointer', padding: '10px' }}>
          <span> + Add a card</span>
        </div> */}
          </div>
        )}
      </div>
    </div>
  );
}

// export default Column;

Column.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  // currentManagerAccount: PropTypes.object.isRequired
  // style: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  stylesStoredInRedux: state.style
  // user: state.auth.user
});

export default connect(mapStateToProps, {})(React.memo(Column));
