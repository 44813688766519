import React, { useState, useEffect, useContext } from 'react';

import DateItemContent from './DateItemContent';

import { useInView } from 'react-intersection-observer';

import { InterfaceContext } from '../../../../../context/InterfaceContext';

import { MutationContext } from '../../../../../context/MutationContext';

function DateItem({
  currentManagerAccountId,
  // updateDocument,
  itemStyle,
  keyForMap,
  item,
  itemKey,
  itemValue,
  rowNumber,
  stylesStoredInRedux,
  checked
}) {
  const { updateDocument } = useContext(MutationContext);
  const { exportMode } = useContext(InterfaceContext);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });
  // ################### update #######################

  // ##################################################
  if (
    ([
      'folder',
      'manualDataEntry',
      'customForm',
      'facebook_ads_campaign',
      'google_ads_campaign',
      'cm360_campaign',
      'dv360_insertion_order',
      'snapchat_ads_campaign',
      'tiktok_ads_campaign',
      'pinterest_ads_campaign',
      'readpeak_campaign',
      'strossle_campaign',
      'amazon_dsp_order',
      'taboola_ads_campaign',
      'microsoft_advertising_campaign',
      'apple_search_ads_campaign',
      'adform_order',
      'linkedin_ads_campaign_group',
      'task',
      'subTask',
      'taxonomy'
    ].includes(item.type) &&
      !item.isSetChild) ||
    item.isSet
    // && !item.template
  ) {
    return (
      <div
        ref={ref}
        key={keyForMap}
        style={{
          maxHeight: '46px',

          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          borderStyle: 'solid',
          borderWidth: '0px 1px 1px 0px',

          borderColor: itemStyle['borderColor'],

          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,

          left: '53px',
          display: 'grid',
          height: '100%',
          width: '100%',

          padding: '10px 7px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {(inView || exportMode) && (
          <DateItemContent item={item} itemKey={itemKey} />
        )}
      </div>
    );
  }

  return (
    <div
      key={keyForMap}
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        maxHeight: '46px',

        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],
        color: itemStyle['color'],
        borderStyle: 'solid',
        borderWidth: '0px 1px 1px 0px',

        borderColor: itemStyle['borderColor'],

        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,

        left: '53px',
        display: 'grid',
        height: '100%',
        width: '100%',

        padding: '10px 7px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: '25px'
      }}
    >
      {item.isSetChild && item[itemKey]}
    </div>
  );
}

export default React.memo(DateItem);
