import React from 'react';

import { styled, useTheme } from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import ReportBuilderWidgetList from './ReportBuilderWidgetList';

function ReportBuilderWidgetListWrapper({
  setWidgetListOpen,
  widgetListOpen,
  isDraggingWidget,
  setIsDraggingWidget
}) {
  const theme = useTheme();
  return (
    <div
      style={{
        top: '65px',
        marginTop: '5px',
        position: 'absolute',
        width: widgetListOpen ? '300px' : '20px',
        right: '0px',
        height: 'calc(80vh - 10px)',
        // right: widgetListOpen ? '-140px' : '-400px',
        display: 'flex',
        flexDirection: 'row',
        background:
          theme?.palette?.mode === 'dark'
            ? 'rgba(255,255,255,0.1)'
            : 'rgba(255,255,255,0.6)',
        borderRadius: '5px 0 0 5px',
        overflow: 'hidden',
        backdropFilter: 'blur(10px)',
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px',
        zIndex: '1000'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '20px',
          // height: '600px',
          // background: 'rgba(255,255,255,0.6)'
          background:
            theme?.palette?.mode === 'dark'
              ? 'rgba(255,255,255,0.1)'
              : 'rgba(255,255,255,0.6)',
          cursor: 'pointer'
        }}
        onClick={() => setWidgetListOpen(!widgetListOpen)}
      >
        <span style={{ cursor: 'pointer', padding: '5px' }}>
          {widgetListOpen ? (
            <CloseIcon
              style={{ fontSize: '15px' }}
              // onClick={() => setWidgetListOpen(false)}
            />
          ) : (
            <MenuIcon
              style={{ padding: '5px', cursor: 'pointer' }}
              // onClick={() => setWidgetListOpen(!widgetListOpen)}
            />
          )}
        </span>
        <span
          style={{
            fontSize: '12px',
            padding: '5px',
            transform: 'rotate(-90deg)'
          }}
        >
          Widgets
        </span>
        <span></span>
      </div>
      <div
        style={{
          // height: '600px',
          width: '400px'
          // gridRow: 0,
          // gridColumn: 1

          // position: 'absolute'
        }}
      >
        <div>
          <ReportBuilderWidgetList
            setIsDraggingWidget={setIsDraggingWidget}
            isDraggingWidget={isDraggingWidget}
          />
        </div>
      </div>
    </div>
  );
}

export default ReportBuilderWidgetListWrapper;
