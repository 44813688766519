import React, { useContext } from 'react';

import StatusMenu from './StatusMenu';

import { useInView } from 'react-intersection-observer';

import { InterfaceContext } from '../../../../context/InterfaceContext';

import { MutationContext } from '../../../../context/MutationContext';

function DefaultItem({
  currentManagerAccountId,
  item,
  itemKey,
  itemValue,
  rowNumber,
  keyForMap,
  itemStyle,
  // updateDocument,
  checked
}) {
  const { updateDocument } = useContext(MutationContext);
  const { exportMode } = useContext(InterfaceContext);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });
  return (
    <div
      ref={ref}
      key={keyForMap}
      style={{
        maxHeight: '46px',
        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],
        color: itemStyle['color'],
        // backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRight: itemStyle['border'],
        borderBottom: itemStyle['border'],
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        // display: 'grid',
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'flex-start',
        justifyContent: 'center',
        // justifyContent: 'center',
        height: '100%',
        width: '100%',
        padding: '10px 12px',
        whiteSpace: 'nowrap'
      }}
    >
      {(inView || exportMode) && (
        <span
          style={
            {
              // margin: 'auto'
            }
          }
        >
          {/* <p>{itemValue}</p> */}
          {(([
            'account',
            'totalActionsDocument',
            'folder',
            'manualDataEntry',
            'customForm',
            'facebook_ads_campaign',
            'google_ads_campaign',
            'cm360_campaign',
            'dv360_insertion_order',
            'snapchat_ads_campaign',
            'tiktok_ads_campaign',
            'pinterest_ads_campaign',
            'readpeak_campaign',
            'strossle_campaign',
            'amazon_dsp_order',
            'taboola_ads_campaign',
            'microsoft_advertising_campaign',
            'apple_search_ads_campaign',
            'adform_order',
            'linkedin_ads_campaign_group',
            'task',
            'subTask',
            'taxonomy'
          ].includes(item.type) &&
            !item.isSetChild) ||
            item.isSet) && (
            <StatusMenu
              item={item}
              currentManagerAccountId={currentManagerAccountId}
              updateDocument={updateDocument}
              itemKey={itemKey}
              showText={true}
            />
          )}
          <p
            style={{
              fontSize: '8px',
              color: '#bcbcbc',
              visibility: 'hidden'
            }}
          >
            {' '}
            test
          </p>
        </span>
      )}
    </div>
  );
}

export default React.memo(DefaultItem);
