import React, { useState, useEffect, useContext } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

import { useTheme } from '@mui/material';

import { AccountContext } from '../../../../../context/AccountContext';

function LogoWidget({
  widget,
  // displayMode,
  updateWidget,
  widgetIndex,
  // setMouseDownWidgetIndex,
  // // editMode,
  // dropOnWidget,
  dragOver,
  // dragStart,
  // dragEnd,
  // isDraggingWidget,
  // setIsDraggingWidget,
  // onMouseDownHandler,
  onMouseUpHandler
  // widgetType
}) {
  const { currentManagerAccount } = useContext(AccountContext);
  const theme = useTheme();

  const logoUrl =
    currentManagerAccount?.logo ||
    'https://nexustable-dev.herokuapp.com/static/media/nexuslogo.7d069a28e8fddb231627.png';

  console.log('logoUrl: ', logoUrl);

  return (
    <div
      onMouseUpHandler={onMouseUpHandler}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <img
        draggable={false}
        src={logoUrl}
        alt="Logo"
        style={{
          width: '100%'
          // alignSelf: 'baseline',
          // margin: '0px 3px',
          // marginTop: '12px'
        }}
      />
    </div>
  );
}

export default LogoWidget;
