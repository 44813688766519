export const typeToNameDict = {
  facebook_ads_campaign: 'Meta',
  google_ads_campaign: 'Google Ads',
  cm360_campaign: 'CM360',
  dv360_insertion_order: 'DV360',
  snapchat_ads_campaign: 'Snapchat Ads',
  tiktok_ads_campaign: 'TikTok Ads',
  pinterest_ads_campaign: 'Pinterest Ads',
  readpeak_campaign: 'ReadPeak Ads',
  strossle_campaign: 'Strossle',
  amazon_dsp_order: 'Amazon DSP',
  taboola_ads_campaign: 'Taboola Ads',
  microsoft_advertising_campaign: 'Microsoft Ads',
  apple_search_ads_campaign: 'Apple Search Ads',
  adform_order: 'Adform',
  linkedin_ads_campaign_group: 'LinkedIn Ads'
};
export const documentTypeToNameDict = {
  adform: 'Adform',
  facebookAds: 'Facebook Ads',
  googleAds: 'Google Ads',
  linkedInAds: 'LinkedIn Ads',
  dv360: 'Display & Video 360',
  cm360: 'Campaign Manager 360',
  snapchatAds: 'Snapchat Ads',
  tikTokAds: 'TikTok Ads',
  pinterestAds: 'Pinterest Ads',
  readpeak: 'Readpeak',
  strossle: 'Strossle',
  amazonDsp: 'Amazon DSP',
  taboolaAds: 'Taboola Ads',
  microsoftAdvertising: 'Microsoft Advertising',
  appleSearchAds: 'Apple Search Ads'
};
