import React, { useContext } from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Cloud from '@mui/icons-material/Cloud';

// import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';

import EditIcon from '@mui/icons-material/Edit';

import { useMutation } from '@apollo/client';
import { AccountContext } from '../../../context/AccountContext';
import { AuthContext } from '../../../context/AuthContext';

import { copyDocumentMutation } from './../../../graphql/queries';

import { DialogContext } from './../../../context/DialogContext';

import { Tooltip } from '@mui/material';

import { DataContext } from '../../../context/DataContext';

function hasDuplicates(array) {
  return new Set(array).size !== array.length;
}

export default function BasicMenu({
  // setEditDialogOpen,
  copiedDocumentIds,
  setCopiedDocumentIds,
  copiedDocumentsAccountId,
  setCopiedDocumentsAccountId,
  enqueueSnackbar,
  checkedBoxes,
  selectedDateStart,
  selectedDateStop
}) {
  const { user } = useContext(AuthContext);

  const [copyDocument] = useMutation(copyDocumentMutation);

  const { currentManagerAccountId } = useContext(AccountContext);

  const { listOfData } = useContext(DataContext);

  const { editDialogOpen, setEditDialogOpen, setCopyDialogOpen } =
    useContext(DialogContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickBulkEditMenuItem = () => {
    setEditDialogOpen(true);
    handleClose();
  };

  const onClickCopyMenuItem = () => {
    let accountId = null;
    for (let checkedBox of checkedBoxes) {
      if (!accountId) {
        accountId = checkedBox.accountId;
      } else if (accountId !== checkedBox.accountId) {
        enqueueSnackbar('You can only copy documents from the same account.', {
          variant: 'default'
          // persist: true
        });
        return;
      }
      if (!accountId) {
        enqueueSnackbar('Issue with accountId.', {
          variant: 'default'
          // persist: true
        });
        return;
      }

      if (
        ![
          'folder',
          'task',
          'subTask',
          'taxonomy',
          'manualDataEntry',
          'facebook_ads_campaign',
          'google_ads_campaign',
          'cm360_campaign',
          'dv360_insertion_order',
          'snapchat_ads_campaign',
          'tiktok_ads_campaign',
          'pinterest_ads_campaign',
          'readpeak_campaign',
          'strossle_campaign',
          'amazon_dsp_order',
          'taboola_ads_campaign',
          'microsoft_advertising_campaign',
          'apple_search_ads_campaign',
          'adform_order',
          'linkedin_ads_campaign_group',
          'googleAds'
        ].includes(checkedBox.type)
      ) {
        enqueueSnackbar(`Copy ${checkedBox.type} is not supported.`, {
          variant: 'default'
          // persist: true
        });
        return;
      }
    }
    const docIds = [...checkedBoxes.map((obj) => obj._id)];
    if (hasDuplicates(docIds)) {
      enqueueSnackbar('Error: Includes duplicates', {
        variant: 'default'
        // persist: true
      });
      return;
    }
    setCopiedDocumentsAccountId(accountId);

    setCopiedDocumentIds(docIds);
    enqueueSnackbar('Documents Copied', {
      variant: 'default'
      // persist: true
    });
    // alert('CTRL+C Pressed');
    handleClose();
  };

  const onClickPasteMenuItem = () => {
    console.log('copiedDocumentIds: ', copiedDocumentIds);

    if (!copiedDocumentIds?.length) {
      enqueueSnackbar('Nothing to copy', {
        variant: 'default'
        // persist: true
      });
      return;
    }

    if (hasDuplicates(copiedDocumentIds)) {
      enqueueSnackbar('Error: Includes duplicates', {
        variant: 'default'
        // persist: true
      });
      return;
    }

    console.log('checkedBoxes: ', checkedBoxes);
    if (checkedBoxes.length !== 1) {
      enqueueSnackbar('Please select ONE folder in order to paste', {
        variant: 'default'
        // persist: true
      });
      return;
    }

    if (
      checkedBoxes[0].accountId !== copiedDocumentsAccountId &&
      !['system_admin'].includes(user.role)
    ) {
      enqueueSnackbar('You cannot paste documents into a different account', {
        variant: 'default'
        // persist: true
      });
      return;
    }

    if (copiedDocumentIds.includes(checkedBoxes[0]._id)) {
      enqueueSnackbar('You cannot paste a document into itself', {
        variant: 'default'
        // persist: true
      });
      return;
    }

    if (!['account', 'folder'].includes(checkedBoxes[0].documentType)) {
      enqueueSnackbar(
        'You can only paste documents into same account or folders from the same account',
        {
          variant: 'default'
          // persist: true
        }
      );
      return;
    }

    const documentsToBeCopied = listOfData.filter((obj) =>
      copiedDocumentIds.includes(obj._id)
    );
    const documentsToBeCopiedTypes = documentsToBeCopied.map((obj) => obj.type);
    console.log('documentsToBeCopiedTypes: ', documentsToBeCopiedTypes);
    if (
      checkedBoxes[0].documentType !== 'folder' &&
      documentsToBeCopiedTypes.filter((str) => str !== 'folder')?.length
    ) {
      enqueueSnackbar('You can only paste folders into an account.', {
        variant: 'default'
        // persist: true
      });
      return;
    }

    setCopyDialogOpen(true);
    handleClose();
    return;
    const copyDocumentInput = {
      managerAccountId: currentManagerAccountId,
      accountId: copiedDocumentsAccountId,
      checkedDocumentId: checkedBoxes[0]._id,
      documentIds: copiedDocumentIds,
      level: checkedBoxes[0].level,
      startDate: selectedDateStart,
      endDate: selectedDateStop
    };
    copyDocument({ variables: { input: { ...copyDocumentInput } } });
    enqueueSnackbar('Pasting documents', {
      variant: 'default'
      // persist: true
    });
    handleClose();
  };

  const getOs = () => {
    const os = ['Windows', 'Linux', 'Mac']; // add your OS values
    return os.find((v) => navigator.appVersion.indexOf(v) >= 0);
  };

  const os = getOs();

  // console.log('os: ', os);

  return (
    <div>
      {/* <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Dashboard
      </Button> */}

      <Tooltip title="Edit menu">
        <Button
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '40px',
            minHeight: 0,
            minWidth: 0
            // backgroundColor: '#3e8ed0'
          }}
          //   onClick={onClickEditHandler}
          onClick={handleClick}
        >
          <EditIcon
          // style={{ color: '#FFFFFF' }}
          // className="hover-grey"
          // style={{ cursor: 'pointer' }}
          // onClick={onClickRefreshHandler}
          />
        </Button>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {/* <MenuItem>
          <ListItemIcon>
            <ContentCut fontSize="small" />
          </ListItemIcon>
          <ListItemText>Cut</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘X
          </Typography>
        </MenuItem> */}

        <MenuItem onClick={onClickCopyMenuItem}>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy</ListItemText>
          <Typography variant="body2" color="text.secondary">
            {/* <span style={{ paddingLeft: '20px' }}>⌘C</span> */}
            <span style={{ paddingLeft: '20px' }}>
              {' '}
              {os === 'Mac' ? 'ctrl ⌘ C' : 'ctrl alt C'}
            </span>
          </Typography>
        </MenuItem>
        <MenuItem onClick={onClickPasteMenuItem}>
          <ListItemIcon>
            <ContentPaste fontSize="small" />
          </ListItemIcon>
          <ListItemText>Paste</ListItemText>
          <Typography variant="body2" color="text.secondary">
            <span style={{ paddingLeft: '20px' }}>
              {' '}
              {os === 'Mac' ? 'ctrl ⌘ V' : 'ctrl alt V'}
            </span>
          </Typography>
        </MenuItem>
        <Divider />

        <MenuItem onClick={onClickBulkEditMenuItem}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Bulk Edit</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
