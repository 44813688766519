import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';

import Button from '@mui/material/Button';

// import SelectCampaign from './SelectCampaign';
// import DisabledSimpleSelect from './../DisabledSimpleSelect';
// import DisabledDatePickerStart from './../DisabledDatePickerStart';
// import DisabledDatePickerStop from './../DisabledDatePickerStop';

import Checkbox from '@mui/material/Checkbox';
// import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import {
  ListDV360InsertionOrdersQuery,
  addDataSourceRelationMutation,
  listAddedDataSourceIdsQuery,
  AccountToDataSourceSettingsQuery
} from './../../../../graphql/queries';

import { MutationContext } from '../../../../context/MutationContext';
import { InterfaceContext } from '../../../../context/InterfaceContext';

import { makeStyles } from '@mui/styles';

import Checklist from './../CheckList';

import { useSnackbar } from 'notistack';

import SelectStatus from '../show-more-options/SelectStatus';

// import SelectCampaignType from './SelectCampaignType';
import BasicDatePicker from '../../utils/BasicDatePicker';

import ShowMoreOptions from '../show-more-options/ShowMoreOptions';

function DV360({
  dataSource,
  currentManagerAccountId,
  checkedBoxes,
  setOpen,
  setDataSource,
  default_date_start_first_date_of_month_as_string,
  default_date_stop_last_date_of_month_as_string
}) {
  const [showMore, setShowMore] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { setTypeItemToggledItem, setTypeItemAnchorRef } =
    useContext(InterfaceContext);
  // const useStyles = makeStyles((theme) => ({
  //   paper: {
  //     position: 'absolute',
  //     width: 760,
  //     // height: 800,
  //     height: '85%',
  //     border: '1px solid grey',
  //     // padding: '20px 50px',
  //     backgroundColor: theme.palette.background.paper,
  //     // border: '2px solid #000',
  //     // border: '1px solid rgba(200, 200, 200, 0.5)',
  //     // boxShadow: theme.shadows[5],
  //     //padding: '20px',
  //     // padding: theme.spacing(2, 4, 3),
  //     // padding: theme.spacing(4),
  //     // borderRadius: '5px',
  //     outline: 0
  //   },
  //   root: {
  //     '& > *': {
  //       margin: theme.spacing(1)
  //     }
  //   }
  // }));

  // const classes = useStyles();

  // const [dataSource, setDataSource] = useState({});

  const [campaign, setCampaign] = useState({});

  const [datePickerDisabled, toggleDatePickerDisabled] = useState(true);

  const [listOfCampaignsExAdded, setListOfCampaignsExAdded] = useState([]);

  // const [startDate, setStartDate] = useState(
  //   // new Date().toISOString().split('T')[0]
  //   default_date_start_first_date_of_month_as_string
  // );

  // add date_start + 1 month to date_stop
  // let todayPlus1Month = new Date();
  // todayPlus1Month.setMonth(todayPlus1Month.getMonth() + 1);
  // todayPlus1Month = todayPlus1Month.toISOString().split('T')[0];

  const [overrideFields, setOverrideFields] = useState([
    // 'allowImpressionsBeforeStartDate',
    // 'allowImpressionsAfterEndDate',
    // 'allowMissingBudget',
    // 'allowMissingClicks',
    // 'allowAbnormallyHighCtr',
    // 'allowMissingImpressionsYesterday'
  ]);
  // const [overrideValues, updateOverrideValues] = useState({
  //   status: 'READY',
  //   startDate: default_date_start_first_date_of_month_as_string,
  //   endDate: default_date_stop_last_date_of_month_as_string
  // });

  const essentialDefaultOverrideValues = {
    status: 'READY',
    startDate: default_date_start_first_date_of_month_as_string,
    endDate: default_date_stop_last_date_of_month_as_string
  };
  const [overrideValues, updateOverrideValues] = useState(
    essentialDefaultOverrideValues
  );

  const setOverrideValues = (newState) => {
    const copyOfOverrideValues = { ...newState };

    for (let itemKey of ['action01', 'action02', 'action03']) {
      delete copyOfOverrideValues[itemKey];
      // if (!document[itemKey]?.dataSource) {
      //   delete copyOfOverrideValues[itemKey];
      // }
      if (newState[itemKey]?.dataSource) {
        copyOfOverrideValues[itemKey] = {
          dataSource: newState[itemKey]?.dataSource,
          dataSourceRef: newState[itemKey]?.dataSourceRef,
          [newState[itemKey]?.dataSource]:
            newState[itemKey]?.[newState[itemKey]?.dataSource],
          // name: String,
          dataSourceId: newState[itemKey]?.dataSourceId,
          dataSourceAccountId: newState[itemKey]?.dataSourceAccountId,

          name: newState[itemKey]?.name
        };
      }
    }
    updateOverrideValues(copyOfOverrideValues);
  };

  // const [endDate, setEndDate] = useState(
  //   default_date_stop_last_date_of_month_as_string
  // );

  // const [addDataSourcerelation, { called }] = useMutation(
  //   addDataSourceRelationMutation
  // );

  const { addDocument } = useContext(MutationContext);

  useEffect(() => {
    campaign && campaign.campaign_id
      ? toggleDatePickerDisabled(false)
      : toggleDatePickerDisabled(true);
  }, [campaign]);

  const onSaveHandler = () => {
    const documentType = 'dv360';

    let dataSourceObjects = listOfCampaigns.filter((obj) =>
      checked.includes(obj.id)
    );
    dataSourceObjects = dataSourceObjects.map((obj) => ({
      documentType,
      dataSourceAccountId: dataSource.dataSourceAccountId,
      dataSourceCampaignId: obj.campaignId,
      dataSourceInsertionOrderId: obj.insertionOrderId,
      startDate: obj.startDate,
      endDate: obj.endDate,
      status: obj.status === 'ENTITY_STATUS_ACTIVE' ? 'ACTIVE' : 'DRAFT'
    }));

    for (let field of overrideFields) {
      switch (true) {
        case field === 'dates':
          for (let obj of dataSourceObjects) {
            if (overrideValues['startDate']) {
              if (
                (obj.startDate &&
                  new Date(obj.startDate) <
                    new Date(overrideValues['startDate'])) ||
                (!obj.startDate && overrideValues['startDate'])
              ) {
                obj.startDate = overrideValues['startDate'];
              }
            }
            if (overrideValues['endDate']) {
              if (
                (obj.endDate &&
                  new Date(obj.endDate) >
                    new Date(overrideValues['endDate'])) ||
                (!obj.endDate && overrideValues['endDate'])
              ) {
                obj.endDate = overrideValues['endDate'];
              }
            }
          }
          break;

        case field === 'status':
          for (let obj of dataSourceObjects) {
            obj.status = overrideValues['status'];
          }
          break;
        case [
          'allowImpressionsBeforeStartDate',
          'allowImpressionsAfterEndDate',
          'allowMissingBudget',
          'allowMissingClicks',
          'allowAbnormallyHighCtr',
          'allowMissingImpressionsYesterday'
        ].includes(field):
          for (let obj of dataSourceObjects) {
            obj[field] = Boolean(overrideValues[field]);
          }
          break;
        case ['action01', 'action02', 'action03'].includes(field):
          for (let obj of dataSourceObjects) {
            obj[field] = overrideValues[field];
          }
          break;
      }
    }

    let addDocumentInput = {
      managerAccountId: currentManagerAccountId,
      accountId: checkedBoxes?.[0]?.accountId,
      checkedDocumentId: checkedBoxes?.[0]?._id,
      level: checkedBoxes?.[0]?.level,
      newDocuments: dataSourceObjects,
      templateDescendant: checkedBoxes?.[0]?.templateDescendant
    };

    // for (let obj of dataSourceObjects) {
    //   if (limitDates && startDate) {
    //     if (
    //       (obj.startDate && new Date(obj.startDate) < new Date(startDate)) ||
    //       (!obj.startDate && startDate)
    //     ) {
    //       obj.startDate = startDate;
    //     }
    //   }
    //   if (limitDates && endDate) {
    //     if (
    //       (obj.endDate && new Date(obj.endDate) > new Date(endDate)) ||
    //       (!obj.endDate && endDate)
    //     ) {
    //       obj.endDate = endDate;
    //     }
    //   }

    //   if (overrideStatus) {
    //     obj.status = status;
    //   }
    // }

    if (checked.length === 0) {
      console.log('list too short');
      return;
    }

    if (
      limitDates &&
      overrideValues['startDate'] &&
      overrideValues['endDate'] &&
      new Date(overrideValues['startDate']) >
        new Date(overrideValues['endDate'])
    ) {
      enqueueSnackbar('End date must be later than or equal to start date', {
        variant: 'error'
      });
      console.log('End date must be later than or equal to start date');
      return;
    }

    addDocument({ variables: { input: addDocumentInput } });

    handleClose();
  };

  const handleClose = () => {
    setOverrideValues({});
    setOverrideFields([]);
    setOpen(false);
    setDataSource();
    setCampaign({});
    // setStartDate(new Date().toISOString().split('T')[0]);
    // setEndDate(new Date().toISOString().split('T')[0]);
    toggleDatePickerDisabled(true);
    setTypeItemToggledItem(null);
    setTypeItemAnchorRef(null);
  };

  const accountId =
    checkedBoxes && checkedBoxes[0] && checkedBoxes[0].accountId;

  const variables = {
    dataSourceInput: {
      managerAccountId: currentManagerAccountId,
      accountId,
      dataSourceId: dataSource._id,
      showMore
    }
  };

  const [skip, toggleSkip] = useState(false);

  const { data, refetch, error } = useQuery(ListDV360InsertionOrdersQuery, {
    variables,
    fetchPolicy: 'no-cache',
    skip
  });

  const [listOfCampaigns, setListOfCampaigns] = useState([]);

  useEffect(() => {
    try {
      const mappedListOfDataCampaigns = data?.listdv360insertionorders?.map(
        (obj) => {
          return {
            ...obj,
            id: obj.insertionOrderId,
            name: `${obj.displayName}`
            // startDate: obj.startDate ? new Date(obj.startDate) : null,
            // endDate: obj.startDate ? new Date(obj.endDate) : null
          };
        }
      );

      mappedListOfDataCampaigns.sort(function (a, b) {
        var nameA = a.id; // ignore upper and lowercase
        var nameB = b.id; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      setListOfCampaigns(mappedListOfDataCampaigns);

      if (mappedListOfDataCampaigns.length > 0) {
        setListOfCampaigns(mappedListOfDataCampaigns);

        // toggleSkip(true);
      }
    } catch (error) {}
  }, [data, dataSource]);

  const [isLoading, toggleIsLoading] = useState(true);
  const [checked, setChecked] = useState([]);

  const [overrideStatus, setOverrideStatus] = useState(false);
  const [status, setStatus] = useState('DRAFT');
  const [limitDates, setLimitDates] = useState(false);

  const [showMoreOptions, setShowMoreOptions] = useState(false);

  const [campaignType, setCampaignType] = useState();

  const clickShowMoreOptionsHandler = () => {
    setShowMoreOptions(!showMoreOptions);
  };

  const [unAddedDataSources30Days, setUnAddedDataSources30Days] =
    useState(true);

  const listAddedDataSouceVariables = {
    input: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccountId,
      accountId: checkedBoxes[0].accountId,
      dataSourceAccountId: dataSource?.dataSourceAccountId,
      dataSourceType: 'dv360'
    }
  };

  const [
    loadAddedDataSourceIds,
    { called: addedDataSourceIdsCalled, loading, data: addedDataSourceIdsData }
  ] = useLazyQuery(listAddedDataSourceIdsQuery, {
    variables: listAddedDataSouceVariables,
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    loadAddedDataSourceIds();
  }, [dataSource?.dataSourceAccountId]);

  useEffect(() => {
    if (
      unAddedDataSources30Days &&
      dataSource &&
      listOfCampaigns
      // !addedDataSourceIdsCalled
    ) {
      // loadAddedDataSourceIds();

      if (listOfCampaigns && addedDataSourceIdsData?.listAddedDataSourceIds) {
        var thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

        setListOfCampaignsExAdded(
          listOfCampaigns.filter(
            (campaign) =>
              !addedDataSourceIdsData?.listAddedDataSourceIds.includes(
                campaign.id
              ) && new Date(campaign.startDate) >= new Date(thirtyDaysAgo)
          )
        );
      }
    } else if (
      !unAddedDataSources30Days &&
      Boolean(listOfCampaignsExAdded.length)
    ) {
      setListOfCampaignsExAdded([]);
    }
  }, [
    unAddedDataSources30Days,
    dataSource,
    addedDataSourceIdsData?.listAddedDataSourceIds,
    listOfCampaigns
  ]);

  const [
    loadAccountToDataSourceSettings,
    {
      called: accountToDataSourceSettingsCalled,
      loading: loadingAccountToDataSourceSettings,
      data: accountToDataSourceSettingsData
    }
  ] = useLazyQuery(AccountToDataSourceSettingsQuery, {
    variables: {
      input: {
        managerAccountId: currentManagerAccountId,
        accountId: checkedBoxes?.[0]?.accountId,
        accountToDataSourceRelationId:
          dataSource?.accountToDataSourceRelationId,
        dataSourceId: dataSource?._id
      }
    },
    fetchPolicy: 'no-cache'
  });

  // const [acconuntToDataSourceSettings, setAccountToDataSourceSettings] =
  //   useState({});

  useEffect(() => {
    if (dataSource.accountToDataSourceRelationId) {
      loadAccountToDataSourceSettings();
    }
  }, [dataSource?.accountToDataSourceRelationId]);

  useEffect(() => {
    if (dataSource.accountToDataSourceRelationId) {
      // setAccountToDataSourceSettings(
      //   accountToDataSourceSettingsData?.accountToDataSourceSettings
      // );
      // setBudgetTypes(
      //   accountToDataSourceSettingsData?.accountToDataSourceSettings
      //     ?.defaultScanSettings?.budgetTypes
      // );
      // setCountries(
      //   accountToDataSourceSettingsData?.accountToDataSourceSettings
      //     ?.defaultScanSettings?.countries
      // );

      // ######################

      const defaultNexusDocumentValues =
        accountToDataSourceSettingsData?.accountToDataSourceSettings
          ?.defaultNexusDocumentValues;
      const fields =
        defaultNexusDocumentValues && Object.keys(defaultNexusDocumentValues);
      if (fields?.length) {
        const newOverrideFields = [];
        const newOverrideValues = {};
        for (let field of fields) {
          if (defaultNexusDocumentValues?.[field]) {
            newOverrideFields.push(field);
            newOverrideValues[field] = defaultNexusDocumentValues[field];
          }
        }
        setOverrideFields(newOverrideFields);
        setOverrideValues({
          ...essentialDefaultOverrideValues,
          ...newOverrideValues
        });
      }
    }
  }, [accountToDataSourceSettingsData, dataSource]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gridTemplateRows: '440px auto 100px'
      }}
    >
      {/* <div style={{ gridRow: 1, marginTop: '10px' }}>
        <SelectCampaignType
          campaignType={campaignType}
          setCampaignType={setCampaignType}
        />
      </div> */}
      <div style={{ display: 'flex', flexDirection: 'column', gridRow: 1 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '42px',
            alignItems: 'center'
          }}
        >
          <Checkbox
            checked={unAddedDataSources30Days}
            onChange={() =>
              setUnAddedDataSources30Days(!unAddedDataSources30Days)
            }
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <span>
            Only show data sources that aren't added to account yet, start date
            less than 30 days ago.
          </span>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '42px',
            alignItems: 'center'
          }}
        >
          <Checkbox
            checked={showMore}
            onChange={() => setShowMore(!showMore)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <span>Show more campaigns.</span>
        </div>

        {dataSource && dataSource._id && (
          <Checklist
            // list={listOfCampaigns}
            list={
              unAddedDataSources30Days
                ? listOfCampaignsExAdded
                : listOfCampaigns
            }
            checked={checked}
            setChecked={setChecked}
            isLoading={isLoading}
            toggleIsLoading={toggleIsLoading}
          />
        )}
      </div>
      <div style={{ gridRow: 2, minHeight: '90px' }}>
        <ShowMoreOptions
          overrideFields={overrideFields}
          setOverrideFields={setOverrideFields}
          overrideValues={overrideValues}
          setOverrideValues={setOverrideValues}
          managerAccountId={currentManagerAccountId}
          accountId={checkedBoxes?.[0]?.accountId}
          dataSource={dataSource}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'end',
          // gridTemplateColumns: '50px 200px 200px 310px',
          gridRowStart: 3,
          gridRowEnd: 3,
          paddingTop: '20px',
          paddingBottom: '20px'
        }}
      >
        <div
          // className={classes.root}
          style={{ gridColumn: 4, textAlign: 'center' }}
        >
          <Button
            variant="outlined"
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{ minWidth: '91px' }}
            variant="contained"
            color="primary"
            onClick={onSaveHandler}
          >
            Save
          </Button>
        </div>
      </div>

      {/* <div
        style={{
          display: 'grid',
          gridRowStart: 4,
          gridRowEnd: 4,
          alignItems: 'end',
          textAlignLast: 'end'
        }}
      >
        <div className={classes.root}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            style={{ minWidth: '91px' }}
            variant="contained"
            color="primary"
            onClick={onSaveHandler}
          >
            Save
          </Button>
        </div>
      </div> */}
    </div>
  );
}

export default DV360;
