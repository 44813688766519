import React, { useState, useEffect, useContext } from 'react';
// import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

import { useSnackbar } from 'notistack';

import Tooltip from '@mui/material/Tooltip';

import { useInView } from 'react-intersection-observer';
import { InterfaceContext } from '../../../../context/InterfaceContext';
import { MutationContext } from '../../../../context/MutationContext';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(0),
//       width: '100%'
//     }
//   }
// }));

function StringItem({
  currentManagerAccountId,
  // updateDocument,
  itemStyle,
  keyForMap,
  item,
  itemKey,
  itemValue,
  rowNumber,
  showItemsHandler,
  openRows,
  checked
}) {
  const { enqueueSnackbar } = useSnackbar();

  const { exportMode, advancedMode } = useContext(InterfaceContext);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const { updateDocument } = useContext(MutationContext);

  // console.log('test>itemValue: ', itemValue);

  let displayValue = itemValue ? itemValue : '';
  // ################### update #######################

  // const classes = useStyles();
  const [showTextField, toggleShowTextField] = useState(false);
  const onClickHandler = () => {
    if (showTextField) {
      return;
    }
    if (
      ([
        'account',
        // 'totalActionsDocument',
        'folder',
        'task',
        'subTask',
        'taxonomy',
        'manualDataEntry',
        'facebook_ads_campaign',
        'google_ads_campaign',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'pinterest_ads_campaign',
        'readpeak_campaign',
        'strossle_campaign',
        'amazon_dsp_order',
        'taboola_ads_campaign',
        'microsoft_advertising_campaign',
        'apple_search_ads_campaign',
        'adform_order',
        'linkedin_ads_campaign_group'
      ].includes(item.type) &&
        !item.isSetChild) ||
      item.isSet
    ) {
      toggleShowTextField(true);
    }
  };

  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue(item[itemKey]);
  }, [showTextField, toggleShowTextField]);

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    const regExName = new RegExp(
      '^[a-zA-Z0-9åäöÅÄÖæøÆØéÉúÚ -_.,+-/\\*()=:#%&:]*$'
    );
    const regExNameResult = regExName.exec(inputValue);
    if (!regExNameResult) {
      enqueueSnackbar(
        'Unallowed characters were used. Only a-zA-Z0-9åäöÅÄÖæøÆØúÚ _.- are allowed.',
        {
          variant: 'error'
        }
      );

      return console.log('Unallowed characters were used.');
    }

    // if (inputValue.length < 2) {
    //   enqueueSnackbar(
    //     'Name too short. Minimum length 2 characters. ' +
    //       inputValue.length +
    //       '/100 used',
    //     {
    //       variant: 'error'
    //     }
    //   );

    //   return console.log('name too short.');
    // }

    if (inputValue && inputValue.length > 500) {
      enqueueSnackbar('Name too long. ' + inputValue.length + '/500 used', {
        variant: 'error'
      });

      return console.log('name too long.');
    }

    if (inputValue === item[itemKey]) {
      toggleShowTextField(false);
      return '';
    }

    // const documentInput = {
    //   accountId: item.accountId,
    //   documentId: item._id,
    //   document: { [itemKey]: inputValue },
    //   updateKey: itemKey,
    //   action: 'UPDATE_DOCUMENT',
    //   managerAccountId: currentManagerAccountId,
    //   documentType: item.documentType,
    //   level: item.level
    // };

    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          [itemKey]: inputValue?.trim()
        }
      ],
      updateKeys: [itemKey]
    };

    // updateDocument({ variables: { input: { ...documentInput } } });

    try {
      const res = await updateDocument({
        variables: { input: { ...documentInput } }
      });
      // console.log('res: ', res);
      // console.log(
      //   'res?.data?.updateDocument?.response: ',
      //   res?.data?.updateDocument?.response
      // );
      if (res?.data?.updateDocument?.response !== 'Success') {
        throw new Error();
      }
      toggleShowTextField(false);
    } catch (error) {
      enqueueSnackbar('ERROR! PLEASE RELOAD THE PAGE AND TRY AGAIN!', {
        variant: 'error'
      });
    }
  };
  const onBlurHandler = (e) => {
    // e.preventDefault();
    toggleShowTextField(false);
  };
  const onKeyDownHandler = (e) => {
    if (e.key === 'Escape') {
      toggleShowTextField(false);
    }
  };

  const onChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  const textField = (
    <form
      id={item?._id + '_' + itemKey}
      //   style={{ padding: '0px 14px' }}
      onSubmit={onSubmitHandler}
      onBlur={onSubmitHandler}
      // className={classes.root}
      noValidate
      autoComplete="off"
    >
      <TextField
        variant="standard"
        autoFocus
        id="standard-basic"
        value={inputValue}
        inputProps={{
          min: 0,
          style: { textAlign: 'left', padding: '0px', fontSize: '12px' }
        }}
        onBlur={onBlurHandler}
        onKeyDown={onKeyDownHandler}
        onChange={onChangeHandler}
      />
      {/* <TextField id="filled-basic" label="Filled" variant="filled" />
    <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
    </form>
  );

  let subValue = '';

  switch (itemKey) {
    default:
      subValue = '';
      break;
  }

  if (
    item.sum_child_budgets &&
    item.sum_child_budgets !== 0 &&
    item.sum_child_budgets !== undefined
  ) {
    // child_sum =
    //   'sum. child.' + parseInt(item.sum_child_budgets).toLocaleString();
  }

  // ##################################################

  let spanStyle = {
    margin: 'auto',
    width: '100%'
  };
  if (true) {
    spanStyle = { textAlign: 'right' };
  }

  if (
    [
      'manualDataEntry',
      'folder',
      'facebook_ads_campaign',
      'google_ads_campaign',
      'cm360_campaign',
      'dv360_insertion_order',
      'snapchat_ads_campaign',
      'tiktok_ads_campaign',
      'pinterest_ads_campaign',
      'readpeak_campaign',
      'strossle_campaign',
      'amazon_dsp_order',
      'taboola_ads_campaign',
      'microsoft_advertising_campaign',
      'apple_search_ads_campaign',
      'adform_order',
      'linkedin_ads_campaign_group',
      'task',
      'subTask',
      'taxonomy'
    ].includes(item.type) &&
    !item.isSetChild
  ) {
    return (
      <div
        ref={ref}
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          borderStyle: 'solid',
          borderWidth: '0px 1px 1px 0px',
          borderColor: itemStyle['borderColor'],
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          left: '53px',
          display: 'flex',
          height: '100%',
          width: '100%',
          //   padding: '10px 7px',
          padding: '10px 14px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          cursor: 'text'
        }}
        onClick={onClickHandler}
      >
        {(inView || exportMode) && (
          <span style={{ textAlign: 'left', width: '100%' }}>
            {showTextField ? (
              textField
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <p
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    marginRight: '4px'
                  }}
                >
                  {/* {Boolean(displayValue) && (
                <Tooltip title={displayValue}>{displayValue}</Tooltip>
              )} */}
                  {displayValue && (
                    <Tooltip
                      title={
                        <span style={{ fontSize: '12px' }}>{displayValue}</span>
                      }
                      placement="bottom-start"
                    >
                      <span>{displayValue}</span>
                    </Tooltip>
                  )}
                </p>
                {/* <i
                  className="fas fa-pen hover-grey"
                  style={{
                    fontSize: '8px',
                    // color: 'grey',
                    padding: '4px 0 4px 2px',
                    cursor: 'pointer'
                  }}
                  onClick={onClickHandler}
                ></i> */}
              </div>
            )}
            <p
              style={{
                fontSize: '8px',
                color: '#bcbcbc'
                // visibility: 'hidden'
              }}
            >
              {' '}
              {subValue}
            </p>
          </span>
        )}
      </div>
    );
  } else {
    return (
      <div
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          borderRight: itemStyle['border'],
          borderBottom: itemStyle['border'],
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          display: 'flex',
          height: '100%',
          width: '100%',
          padding: '12px 15px',
          whiteSpace: 'nowrap'
        }}
      >
        <span style={spanStyle}>
          <p
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {displayValue && (
              <Tooltip title={displayValue} placement="bottom-start">
                <span style={{ fontSize: '12px' }}>{displayValue}</span>
              </Tooltip>
            )}
          </p>
          <p
            style={{
              fontSize: '8px',
              color: '#bcbcbc',
              visibility: 'hidden'
            }}
          >
            {' '}
            test
          </p>
        </span>
      </div>
    );
  }
}

export default React.memo(StringItem);
