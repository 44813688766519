// import { columnDictionary } from '../../column-menu/columns';

import { allEvalFields } from '../../fields';

import { period_on_period } from './period_on_period';

// const periodOnPeriodEligibleColumns = [
//   'cost',
//   'trp',
//   'spots',
//   'impressions',
//   'cpm',
//   'clicks',
//   'ctr',
//   'cpc',

//   'action01Count',
//   'action01Cvr',
//   'action01Cpa',
//   'action01Value',
//   'action01Roas',
//   'action01Vpa',

//   'totalAction01Count',
//   'totalaction01Cvr',
//   'totalAction01Cpa',
//   'totalAction01Value',
//   'totalAction01Roas',
//   'totalAction01Vpa',

//   'action02Count',
//   'action02Cvr',
//   'action02Cpa',
//   'action02Value',
//   'action02Roas',
//   'action02Vpa',

//   'totalAction02Count',
//   'totalaction02Cvr',
//   'totalAction02Cpa',
//   'totalAction02Value',
//   'totalAction02Roas',
//   'totalAction02Vpa',

//   'action03Count',
//   'action03Cvr',
//   'action03Cpa',
//   'action03Value',
//   'action03Roas',
//   'action03Vpa',

//   'totalAction03Count',
//   'totalaction03Cvr',
//   'totalAction03Cpa',
//   'totalAction03Value',
//   'totalAction03Roas',
//   'totalAction03Vpa'
// ];

export const prepareObject = (
  prepared_object,
  previous_prepared_object,
  i,
  columns,
  columnDictionary
) => {
  for (let field of allEvalFields) {
    prepared_object[field] =
      Math.round((prepared_object[field] + Number.EPSILON) * 100) / 100;
  }
  const cpc =
    prepared_object.clicks && prepared_object.cost
      ? (prepared_object.cost / prepared_object.clicks).toFixed(2)
      : 0;
  const ctr =
    prepared_object.clicks && prepared_object.impressions
      ? (prepared_object.clicks / prepared_object.impressions).toFixed(4) * 100
      : 0;

  const viewableRate =
    prepared_object.viewableImpressions && prepared_object.measurableImpressions
      ? (
          prepared_object.viewableImpressions /
          prepared_object.measurableImpressions
        ).toFixed(4) * 100
      : 0;

  const searchImpressionShare =
    prepared_object.impressions && prepared_object.eligibleImpressions
      ? (
          prepared_object.impressions / prepared_object.eligibleImpressions
        ).toFixed(4) * 100
      : 0;

  const action01Cvr =
    prepared_object.action01Count && prepared_object.clicks
      ? (prepared_object.action01Count / prepared_object.clicks).toFixed(4) *
        100
      : 0;
  const action02Cvr =
    prepared_object.action02Count && prepared_object.clicks
      ? (prepared_object.action02Count / prepared_object.clicks).toFixed(4) *
        100
      : 0;
  const action03Cvr =
    prepared_object.action03Count && prepared_object.clicks
      ? (prepared_object.action03Count / prepared_object.clicks).toFixed(4) *
        100
      : 0;

  const action01Cpa = prepared_object.action01Count
    ? prepared_object.cost / prepared_object.action01Count
    : 0;
  const action02Cpa = prepared_object.action02Count
    ? prepared_object.cost / prepared_object.action02Count
    : 0;
  const action03Cpa = prepared_object.action03Count
    ? prepared_object.cost / prepared_object.action03Count
    : 0;

  const totalAction01Cpa = prepared_object.totalAction01Count
    ? prepared_object.totalCost / prepared_object.totalAction01Count
    : 0;
  const totalAction02Cpa = prepared_object.totalAction02Count
    ? prepared_object.totalCost / prepared_object.totalAction02Count
    : 0;
  const totalAction03Cpa = prepared_object.totalAction03Count
    ? prepared_object.totalCost / prepared_object.totalAction03Count
    : 0;

  const cpm = prepared_object.impressions
    ? prepared_object.cost / (prepared_object.impressions / 1000)
    : 0;

  const vCpm = prepared_object.viewableImpressions
    ? prepared_object.cost / (prepared_object.viewableImpressions / 1000)
    : 0;

  const action01Roas = prepared_object.cost
    ? prepared_object.action01Value / prepared_object.cost
    : 0;
  const action02Roas = prepared_object.cost
    ? prepared_object.action02Value / prepared_object.cost
    : 0;
  const action03Roas = prepared_object.cost
    ? prepared_object.action03Value / prepared_object.cost
    : 0;

  const totalAction01Roas = prepared_object.totalCost
    ? prepared_object.totalAction01Value / prepared_object.totalCost
    : 0;
  const totalAction02Roas = prepared_object.totalCost
    ? prepared_object.totalAction02Value / prepared_object.totalCost
    : 0;
  const totalAction03Roas = prepared_object.totalCost
    ? prepared_object.totalAction03Value / prepared_object.totalCost
    : 0;

  const action01Vpa =
    prepared_object.action01Count && prepared_object.action01Value
      ? parseFloat(
          (
            prepared_object.action01Value / prepared_object.action01Count
          ).toFixed(2)
        )
      : 0;

  const action02Vpa =
    prepared_object.action02Count && prepared_object.action02Value
      ? parseFloat(
          (
            prepared_object.action02Value / prepared_object.action02Count
          ).toFixed(2)
        )
      : 0;

  const action03Vpa =
    prepared_object.action03Count && prepared_object.action03Value
      ? parseFloat(
          (
            prepared_object.action03Value / prepared_object.action03Count
          ).toFixed(2)
        )
      : 0;

  prepared_object.action01Vpa = parseFloat(action01Vpa).toFixed(2);
  prepared_object.action02Vpa = parseFloat(action02Vpa).toFixed(2);
  prepared_object.action03Vpa = parseFloat(action03Vpa).toFixed(2);

  prepared_object.cpc = parseFloat(cpc);
  prepared_object.ctr = parseFloat(ctr);
  prepared_object.viewableRate = parseFloat(viewableRate);
  prepared_object.searchImpressionShare = parseFloat(searchImpressionShare);
  prepared_object.action01Cvr = parseFloat(action01Cvr).toFixed(2);
  prepared_object.action02Cvr = parseFloat(action02Cvr).toFixed(2);
  prepared_object.action03Cvr = parseFloat(action03Cvr).toFixed(2);

  prepared_object.action01Cpa = parseFloat(action01Cpa.toFixed(2));
  prepared_object.action02Cpa = parseFloat(action02Cpa.toFixed(2));
  prepared_object.action03Cpa = parseFloat(action03Cpa.toFixed(2));

  prepared_object.action01Vpa = parseFloat(action01Vpa.toFixed(2));
  prepared_object.action02Vpa = parseFloat(action02Vpa.toFixed(2));
  prepared_object.action03Vpa = parseFloat(action03Vpa.toFixed(2));

  prepared_object.totalAction01Cpa = parseFloat(totalAction01Cpa.toFixed(2));
  prepared_object.totalAction02Cpa = parseFloat(totalAction02Cpa.toFixed(2));
  prepared_object.totalAction03Cpa = parseFloat(totalAction03Cpa.toFixed(2));
  prepared_object.cpm = parseFloat(cpm.toFixed(2));
  prepared_object.vCpm = parseFloat(vCpm.toFixed(2));
  prepared_object.action01Roas = parseFloat(action01Roas.toFixed(2));
  prepared_object.action02Roas = parseFloat(action02Roas.toFixed(2));
  prepared_object.action03Roas = parseFloat(action03Roas.toFixed(2));

  prepared_object.totalAction01Roas = parseFloat(totalAction01Roas.toFixed(2));
  prepared_object.totalAction02Roas = parseFloat(totalAction02Roas.toFixed(2));
  prepared_object.totalAction03Roas = parseFloat(totalAction03Roas.toFixed(2));

  // prepared_object.totalAction01Value = parseFloat(
  //   prepared_object.totalAction01Value.toFixed(2)
  // );
  // prepared_object.totalAction02Value = parseFloat(
  //   prepared_object.totalAction02Value.toFixed(2)
  // );
  // prepared_object.totalAction03Value = parseFloat(
  //   prepared_object.totalAction03Value.toFixed(2)
  // );

  // prepared_object.cost = Math.round(prepared_object.cost);
  // prepared_object.trp = Math.round(
  //   prepared_object.trp
  // );

  // prepared_object.spots = Math.round(prepared_object.spots);

  // prepared_object.impressions = Math.round(prepared_object.impressions);

  // prepared_object.action01Count =
  //   Math.round((prepared_object.action01Count + Number.EPSILON) * 100) / 100;
  // // .toFixed(2)
  // // .toLocaleString();

  // prepared_object.action02Count =
  //   Math.round((prepared_object.action02Count + Number.EPSILON) * 100) / 100;

  // prepared_object.action03Count =
  //   Math.round((prepared_object.action03Count + Number.EPSILON) * 100) / 100;

  // prepared_object.action01Values = Math.round(prepared_object.action01Value);
  // prepared_object.action02Values = Math.round(prepared_object.action02Value);
  // prepared_object.action03Values = Math.round(prepared_object.action03Value);

  if (i > 0) {
    for (let column of columns) {
      for (let eligibleColumn of [
        ...allEvalFields,
        'totalAction01Cpa',
        'totalAction02Cpa',
        'totalAction03Cpa',
        'totalAction01Roas',
        'totalAction02Roas',
        'totalAction03Roas'
      ]) {
        if (column === eligibleColumn) {
          prepared_object[column + 'PeriodOnPeriod'] = period_on_period(
            column,
            prepared_object,
            previous_prepared_object
          );
          continue;
        }
      }
    }
    // prepared_object.costPeriodOnPeriod = period_on_period(
    //   'cost',
    //   prepared_object,
    //   previous_prepared_object
    // );
    // prepared_object.impressionsPeriodOnPeriod = period_on_period(
    //   'impressions',
    //   prepared_object,
    //   previous_prepared_object
    // );
    // prepared_object.cpmPeriodOnPeriod = period_on_period(
    //   'cpm',
    //   prepared_object,
    //   previous_prepared_object
    // );

    // prepared_object.clicksPeriodOnPeriod = period_on_period(
    //   'clicks',
    //   prepared_object,
    //   previous_prepared_object
    // );

    // prepared_object.cpcPeriodOnPeriod = period_on_period(
    //   'cpc',
    //   prepared_object,
    //   previous_prepared_object
    // );

    // prepared_object.action01RoasPeriodOnPeriod = period_on_period(
    //   'action01Roas',
    //   prepared_object,
    //   previous_prepared_object
    // );
    // prepared_object.action02RoasPeriodOnPeriod = period_on_period(
    //   'action02Roas',
    //   prepared_object,
    //   previous_prepared_object
    // );
    // prepared_object.action03RoasPeriodOnPeriod = period_on_period(
    //   'action03Roas',
    //   prepared_object,
    //   previous_prepared_object
    // );

    // prepared_object.action01CountPeriodOnPeriod = period_on_period(
    //   'action01Count',
    //   prepared_object,
    //   previous_prepared_object
    // );
    // prepared_object.action02CountPeriodOnPeriod = period_on_period(
    //   'action02Count',
    //   prepared_object,
    //   previous_prepared_object
    // );
    // prepared_object.action03CountPeriodOnPeriod = period_on_period(
    //   'action03Count',
    //   prepared_object,
    //   previous_prepared_object
    // );

    // prepared_object.action01ValuePeriodOnPeriod = period_on_period(
    //   'action01Value',
    //   prepared_object,
    //   previous_prepared_object
    // );
    // prepared_object.action02ValuePeriodOnPeriod = period_on_period(
    //   'action02Value',
    //   prepared_object,
    //   previous_prepared_object
    // );
    // prepared_object.action03ValuePeriodOnPeriod = period_on_period(
    //   'action03Value',
    //   prepared_object,
    //   previous_prepared_object
    // );

    // prepared_object.action01CpaPeriodOnPeriod = period_on_period(
    //   'action01Cpa',
    //   prepared_object,
    //   previous_prepared_object
    // );
    // prepared_object.action02CpaPeriodOnPeriod = period_on_period(
    //   'action02Cpa',
    //   prepared_object,
    //   previous_prepared_object
    // );
    // prepared_object.action03CpaPeriodOnPeriod = period_on_period(
    //   'action03Cpa',
    //   prepared_object,
    //   previous_prepared_object
    // );
  }

  for (let objKey of Object.keys(prepared_object)) {
    if (columnDictionary[objKey]) {
      prepared_object[columnDictionary[objKey]] = prepared_object[objKey];
    }
  }

  return prepared_object;
};

// export default prepareObject;
