import React, { useState, useContext } from 'react';

import { AccountContext } from './../../../../../context/AccountContext';

import Labels from './Labels';

import { useInView } from 'react-intersection-observer';

import { InterfaceContext } from '../../../../../context/InterfaceContext';

import { MutationContext } from '../../../../../context/MutationContext';

function LabelItem({
  item,
  itemKey,
  itemValue,
  rowNumber,
  keyForMap,
  itemStyle,
  // updateDocument,
  currentManagerAccountId,
  checked
}) {
  const { updateDocument } = useContext(MutationContext);
  const { exportMode } = useContext(InterfaceContext);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (Boolean(anchorEl)) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };
  if (
    ([
      'folder',
      'task',
      'subTask',
      'taxonomy',
      'facebook_ads_campaign',
      'google_ads_campaign',
      'cm360_campaign',
      'dv360_insertion_order',
      'snapchat_ads_campaign',
      'tiktok_ads_campaign',
      'pinterest_ads_campaign',
      'readpeak_campaign',
      'strossle_campaign',
      'amazon_dsp_order',
      'taboola_ads_campaign',
      'microsoft_advertising_campaign',
      'apple_search_ads_campaign',
      'adform_order',
      'linkedin_ads_campaign_group',
      'manualDataEntry',
      'customForm'
    ].includes(item.type) ||
      item.isSet) &&
    !item.isSetChild
  ) {
    return (
      <div
        ref={ref}
        key={keyForMap}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'start',
          alignItems: 'center',
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRight: itemStyle['border'],
          borderBottom: itemStyle['border'],
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          // display: 'grid',
          // display: 'grid',
          height: '100%',
          width: '100%',
          padding: '6px 10px 6px 15px',
          whiteSpace: 'nowrap',
          cursor: 'pointer'
          // gridTemplateColumns: 'auto 20px'
        }}
        onClick={handleClick}
      >
        {(inView || exportMode) && (
          <Labels item={item} anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        )}
      </div>
    );
  } else {
    return (
      <div
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRight: itemStyle['border'],
          borderBottom: itemStyle['border'],
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          // display: 'grid',
          display: 'grid',
          height: '100%',
          width: '100%',
          padding: '6px 10px 6px 15px',
          whiteSpace: 'nowrap',
          gridTemplateColumns: 'auto 20px'
        }}
      >
        <div
          style={{
            display: 'grid',
            gridRowStart: 0,
            gridRowEnd: 0,
            gridColumnStart: 0,
            gridColumnEnd: 0
          }}
        ></div>
      </div>
    );
  }
}

export default React.memo(LabelItem);
