import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';

import { useQuery } from '@apollo/client';
import { DataSourcesQuery } from './../../../graphql/queries';

export default function Playground({
  currentManagerAccountId,
  checkedBoxes,
  dataSource,
  setDataSource
}) {
  // const [value, setValue] = React.useState(null);

  const accountId =
    checkedBoxes && checkedBoxes[0] && checkedBoxes[0].accountId;

  const variables = {
    dataSourcesInput: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccountId,
      accountId
    }
  };

  const { data } = useQuery(DataSourcesQuery, {
    variables,
    fetchPolicy: 'no-cache',
    skip: false
  });

  const [listOfDataSources, setListOfDataSources] = useState([]);
  console.log('listOfDataSources: ', listOfDataSources);

  useEffect(() => {
    let mappedListOfDataSoruces =
      data &&
      data.dataSources &&
      data.dataSources.map((obj) => {
        var dataSourceType = '';
        switch (obj.dataSourceRef) {
          case 'GoogleAdsDataSource':
            dataSourceType = 'Google Ads';
            break;
          case 'AppleSearchAdsDataSource':
            dataSourceType = 'Apple Search Ads';
            break;
          case 'CM360DataSource':
            dataSourceType = 'CM360';
            break;
          case 'DV360DataSource':
            dataSourceType = 'DV360';
            break;

          case 'AdformDataSource':
            dataSourceType = 'Adform';
            break;
          case 'FacebookAdsDataSource':
            dataSourceType = 'Facebook Ads';
            break;
          case 'SnapchatAdsDataSource':
            dataSourceType = 'Snapchat Ads';
            break;

          case 'TikTokAdsDataSource':
            dataSourceType = 'TikTok Ads';
            break;
          case 'PinterestAdsDataSource':
            dataSourceType = 'Pinterest Ads';
            break;

          case 'ReadpeakDataSource':
            dataSourceType = 'Readpeak';
            break;

          case 'StrossleDataSource':
            dataSourceType = 'Strossle';
            break;

          case 'AmazonDspDataSource':
            dataSourceType = 'Amazon DSP';
            break;

          case 'TaboolaAdsDataSource':
            dataSourceType = 'Taboola Ads';
            break;

          case 'MicrosoftAdvertisingDataSource':
            dataSourceType = 'Microsoft Advertising';
            break;

          case 'AppleSearchAdsDataSource':
            dataSourceType = 'Apple Search Ads';
            break;

          case 'LinkedInAdsDataSource':
            dataSourceType = 'LinkedIn Ads';
            break;
        }

        return {
          ...obj,
          title: `${dataSourceType} - ${obj.name} - ${obj.dataSourceAccountId}`
        };
      });

    mappedListOfDataSoruces =
      mappedListOfDataSoruces &&
      mappedListOfDataSoruces.filter(
        (obj) => obj.dataSourceRef !== 'GoogleAnalytics4DataSource'
      );

    if (mappedListOfDataSoruces) {
      setListOfDataSources(mappedListOfDataSoruces);
    }
  }, [data]);

  // const defaultProps = {
  //   options: top100Films,
  //   getOptionLabel: (option) => option.title
  // };

  // const flatProps = {
  //   options: top100Films.map((option) => option.title)
  // };

  const defaultProps = {
    options: listOfDataSources && listOfDataSources,
    getOptionLabel: (option) => option.title
  };
  return (
    <Stack spacing={1} sx={{ width: '100%' }}>
      <Autocomplete
        {...defaultProps}
        variant="standard"
        id="controlled-demo"
        value={dataSource}
        onChange={(event, newValue) => {
          setDataSource(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Data Source" variant="standard" />
        )}
      />
    </Stack>
  );
}
