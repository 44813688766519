import React, { useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';

import BalanceIcon from '@mui/icons-material/Balance';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined';
import TableRowsIcon from '@mui/icons-material/TableRows';

import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';

import './kanban.css';

// import Column from './kanban/Column';

import CreateTodoButton from './create-todo-button/CreateTodoButton';

import FilterMenu from '../../FilterMenu';
import FilterComponent from '../../FilterComponent';

import DisplayMenu from './DisplayMenu';

import Kanban from './kanban/Columns';

import Calendar from './calendar/Columns';

import List from './list/List';

import Charts from './charts/Charts';
import { InterfaceContext } from '../../../../context/InterfaceContext';

import { AccountContext } from '../../../../context/AccountContext';

import { DialogContext } from '../../../../context/DialogContext';

function ViewDialogContent({
  isExpanded,
  filteredArrayOfData,
  rawArrayOfData,
  stylesStoredInRedux,
  checkedBoxes,
  setCheckedBoxes,
  names,
  dataSourceNames
}) {
  const [account, setAccount] = React.useState(null);

  const { hideSelected, setHideSelected, advancedMode } =
    useContext(InterfaceContext);
  const { nexusDocumentStatuses } = useContext(AccountContext);

  const { setEmailPokeDialogOpen, viewDialogView, setViewDialogView } =
    useContext(DialogContext);

  // const [viewDialogView, setDisplayView] = useState('kanban');

  const onClickCharthandler = (value) => {
    setViewDialogView(value);
    // if (viewDialogView !== 'kanban') {
    //   setDisplayView('kanban');
    // } else {
    //   setDisplayView('charts');
    // }
  };

  // const columns = [
  //   'DRAFT',
  //   'PLANNED',
  //   'READY',
  //   'ACTIVE',
  //   'PAUSED',
  //   'COMPLETED',
  //   'CANCELED'
  // ];

  const columns = nexusDocumentStatuses;

  // const renderView = (
  //   <Columns
  //     columns={columns}
  //     filteredArrayOfData={filteredArrayOfData}
  //     isExpanded={isExpanded}
  //     rawArrayOfData={rawArrayOfData}
  //     account={account}
  //     setAccount={setAccount}
  //     hideSelected={hideSelected}
  //   />
  // );

  // if (viewDialogView === 'charts') {
  //   renderView = (<div>hej</div>);
  // }

  return (
    <div>
      <div
        style={{
          width: '100%',
          height: '50px',
          marginLeft: '30px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
          // backgroundColor:
          //   stylesStoredInRedux.styleName === 'light'
          //     ? 'red'
          //     : 'rgba(255,255,255,0)'
        }}
      >
        {/* Kanban */}
        {/* <div style={{ marginRight: '20px' }}>
          <CreateTodoButton />
        </div> */}

        <div style={{ marginRight: '5px' }}>
          <Button
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '40px',
              minHeight: '36px',
              minWidth: 0,
              cursor: 'pointer'
              // backgroundColor: '#3e8ed0'
            }}
            onClick={() => setEmailPokeDialogOpen(true)}
          >
            <i
              style={{ fontSize: '20px' }}
              className="fas fa-hand-point-right"
            ></i>
            {/* <EmailIcon
                  // style={{ color: '#FFFFFF' }}
                  // className="hover-grey"
                  // style={{ cursor: 'pointer' }}
                  // onClick={onClickRefreshHandler}
                  /> */}
          </Button>
        </div>
        <div style={{ marginRight: '5px' }}>
          <Button
            style={{
              minWidth: '40px',
              width: '40px',
              backgroundColor:
                viewDialogView === 'kanban' ? 'rgba(255,255,255,0.1)' : null
            }}
            onClick={() => onClickCharthandler('kanban')}
          >
            <ViewKanbanOutlinedIcon />
          </Button>
        </div>

        <div style={{ marginRight: '5px' }}>
          <Button
            style={{
              minWidth: '40px',
              width: '40px',
              backgroundColor:
                viewDialogView === 'calendar' ? 'rgba(255,255,255,0.1)' : null
            }}
            onClick={() => onClickCharthandler('calendar')}
          >
            <CalendarViewWeekIcon />
          </Button>
        </div>
        <div style={{ marginRight: '5px' }}>
          <Button
            style={{
              minWidth: '40px',
              width: '40px',
              backgroundColor:
                viewDialogView === 'list' ? 'rgba(255,255,255,0.1)' : null
            }}
            onClick={() => onClickCharthandler('list')}
          >
            <TableRowsIcon />
          </Button>
        </div>
        <div style={{ marginRight: '5px' }}>
          <Button
            style={{
              minWidth: '40px',
              width: '40px',
              backgroundColor:
                viewDialogView === 'charts' ? 'rgba(255,255,255,0.1)' : null
            }}
            onClick={() => onClickCharthandler('charts')}
          >
            <DonutLargeIcon />
          </Button>
        </div>

        {advancedMode && (
          <>
            <div style={{ marginRight: '5px' }}>
              <DisplayMenu
              // selected={hideSelected}
              // setSelected={setHideSelected}
              />
            </div>
          </>
        )}
        <div>
          <FilterMenu
            // setFilterBySelection={setFilterBySelection}
            checkedBoxes={checkedBoxes}
            setCheckedBoxes={setCheckedBoxes}
            // setFilters={setFilters}
            // filters={filters}
            // enqueueSnackbar={enqueueSnackbar}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            alignItems: 'center',
            marginLeft: '15px'
          }}
        >
          <FilterComponent
            setCheckedBoxes={setCheckedBoxes}
            // setFilters={setFilters}
            // setFilterBySelection={setFilterBySelection}
            // setFilterByDataSource={setFilterByDataSource}
            // filterByDataSource={filterByDataSource}
            names={names}
            dataSourceNames={dataSourceNames}
          />
        </div>
      </div>
      <div
        style={{
          // display: 'flex',
          // flexDirection: 'row',
          // padding: '10px 20px',
          // overflowX: 'scroll',
          overflowX: 'scroll',
          height: isExpanded ? 'calc(100vh - 116px)' : 'calc(90vh - 116px)',
          maxHeight: isExpanded ? 'calc(100vh - 116px)' : 'calc(90vh - 116px)'
        }}
      >
        {/* {renderView} */}
        {viewDialogView === 'kanban' && (
          <Kanban
            columns={columns}
            filteredArrayOfData={filteredArrayOfData}
            isExpanded={isExpanded}
            rawArrayOfData={rawArrayOfData}
            account={account}
            setAccount={setAccount}
            hideSelected={hideSelected}
          />
        )}

        {viewDialogView === 'list' && (
          <List
            columns={columns}
            filteredArrayOfData={filteredArrayOfData}
            isExpanded={isExpanded}
            rawArrayOfData={rawArrayOfData}
            account={account}
            setAccount={setAccount}
            hideSelected={hideSelected}
          />
        )}
        {viewDialogView === 'calendar' && (
          <Calendar
            columns={columns}
            filteredArrayOfData={filteredArrayOfData}
            isExpanded={isExpanded}
            rawArrayOfData={rawArrayOfData}
            account={account}
            setAccount={setAccount}
            hideSelected={hideSelected}
          />
        )}
        {viewDialogView === 'charts' && (
          <Charts
            columns={columns}
            filteredArrayOfData={filteredArrayOfData}
            isExpanded={isExpanded}
            rawArrayOfData={rawArrayOfData}
            account={account}
            setAccount={setAccount}
            hideSelected={hideSelected}
          />
        )}
        {/* {viewDialogView === 'charts' && <div style={{ width: '95vw' }}>Hej</div>} */}
        {/* {renderColumns} */}
        {/* <Column name="Draft" /> */}
        {/* <Column name="To Do" />
        <Column name="Doing" />
        <Column name="Done" />
        <Column name="Block" /> */}
      </div>
    </div>
  );
}

// export default Kanban;

ViewDialogContent.propTypes = {
  // getPosts: PropTypes.func.isRequired,
  // post: PropTypes.object.isRequired
  currentManagerAccount: PropTypes.object.isRequired
  // style: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  stylesStoredInRedux: state.style
  // user: state.auth.user
});

export default connect(mapStateToProps, {})(ViewDialogContent);
