export const fieldArray = [
  'warnings',
  'status',
  'path',
  'comments',
  'members',
  'labels',
  'startDate',
  'endDate',
  'numberOfDays',
  'generalDescription',
  'buyingTypeDescription',
  'creativeDescription',
  'formatDescription',
  'channelDescription',
  'audienceDataDescription',
  'targetGroupDescription',
  'placementDescription',
  'goalDescription',
  'budget',
  'budgetCurrency',
  'calcDailyBudget',
  'plannedGross',
  'plannedNet',
  'plannedNetNet',
  'plannedCtc',

  'plannedTrp',
  'plannedCpp',
  'plannedTechFee',
  'plannedAdOpsFee',
  'plannedAdServingCost',
  'plannedFeePercent',

  'kpi',
  'plannedImpressions',
  'plannedClicks',
  'plannedCtr',
  'plannedCpc',
  'plannedReach',
  'plannedCpm',
  'plannedFrequency',
  'plannedActionCount',
  'plannedActionValue',
  'plannedActionRoas',
  'plannedCpa',
  'cost',
  'pace',
  // 'pace_bar',
  'universe',
  'trp',
  'spots',
  'impressions',
  'viewableImpressions',
  'measurableImpressions',
  'eligibleImpressions',
  'cpm',
  'vCpm',
  'reach',
  'clicks',
  'ctr',
  'viewableRate',
  'searchImpressionShare',
  'cpc',
  'action01',
  'action01Count',
  'action01Cvr',
  'action01Cpa',
  'action01Value',
  'action01Roas',
  'action01Vpa',
  'action02',
  'action02Count',
  'action02Cvr',
  'action02Cpa',
  'action02Value',
  'action02Roas',
  'action02Vpa',
  'action03',
  'action03Count',
  'action03Cvr',
  'action03Cpa',
  'action03Value',
  'action03Roas',
  'action03Vpa',

  'totalCost',
  'totalImpressions',
  'totalCpm',
  'totalClicks',
  'totalCtr',
  'totalCpc',

  'totalAction01',
  'totalAction01Count',
  // 'totalaction01Cvr',
  'totalAction01Cpa',
  'totalAction01Value',
  'totalAction01Roas',
  'totalAction01Vpa',
  'totalAction02',
  'totalAction02Count',
  // 'totalaction02Cvr',
  'totalAction02Cpa',
  'totalAction02Value',
  'totalAction02Roas',
  'totalAction02Vpa',
  'totalAction03',
  'totalAction03Count',
  // 'totalaction03Cvr',
  'totalAction03Cpa',
  'totalAction03Value',
  'totalAction03Roas',
  'totalAction03Vpa',

  'ganttBarColor',
  'gantt',

  'impressionsToday',
  'impressionsYesterday',
  'impressionsLineChart',

  'viewableImpressionsToday',
  'viewableImpressionsYesterday',
  'viewableImpressionsLineChart',

  'measurableImpressionsToday',
  'measurableImpressionsYesterday',
  'measurableImpressionsLineChart',

  'eligibleImpressionsToday',
  'eligibleImpressionsYesterday',
  'eligibleImpressionsLineChart',

  'cpmToday',
  'cpmYesterday',
  'cpmLineChart',

  'vCpmToday',
  'vCpmYesterday',
  'vCpmLineChart',

  'costToday',
  'costYesterday',
  'costLineChart',

  'clicksToday',
  'clicksYesterday',
  'clicksLineChart',

  'ctrToday',
  'ctrYesterday',
  'ctrLineChart',

  'viewableRateToday',
  'viewableRateYesterday',
  'viewableRateLineChart',

  'searchImpressionShareToday',
  'searchImpressionShareYesterday',
  'searchImpressionShareLineChart',

  'cpcToday',
  'cpcLineChart',
  'cpcYesterday',

  'action01CountLineChart',
  'action01CvrLineChart',
  'action01CpaLineChart',
  'action01ValueLineChart',
  'action01RoasLineChart',
  'action01VpaLineChart',

  'action02CountLineChart',
  'action02CvrLineChart',
  'action02CpaLineChart',
  'action02ValueLineChart',
  'action02RoasLineChart',
  'action02VpaLineChart',

  'action03CountLineChart',
  'action03CvrLineChart',
  'action03CpaLineChart',
  'action03ValueLineChart',
  'action03RoasLineChart',
  'action03VpaLineChart',

  'action01CountToday',
  'action01CvrToday',
  'action01CpaToday',
  'action01ValueToday',
  'action01RoasToday',
  'action01VpaToday',

  'action02CountToday',
  'action02CvrToday',
  'action02CpaToday',
  'action02ValueToday',
  'action02RoasToday',
  'action02VpaToday',

  'action03CountToday',
  'action03CvrToday',
  'action03CpaToday',
  'action03ValueToday',
  'action03RoasToday',
  'action03VpaToday',

  'action01CountYesterday',
  'action01CvrYesterday',
  'action01CpaYesterday',
  'action01ValueYesterday',
  'action01RoasYesterday',
  'action01VpaYesterday',

  'action02CountYesterday',
  'action02CvrYesterday',
  'action02CpaYesterday',
  'action02ValueYesterday',
  'action02RoasYesterday',
  'action02VpaYesterday',

  'action03CountYesterday',
  'action03CvrYesterday',
  'action03CpaYesterday',
  'action03ValueYesterday',
  'action03RoasYesterday',
  'action03VpaYesterday'
];

// export const fieldArray = [
//   'warnings',
//   'status',
//   'path',
//   'comments',
//   'members',
//   'labels',
//   'startDate',
//   'endDate',
//   'numberOfDays',
//   'generalDescription',
// "buyingTypeDescription",
// "creativeDescription",
// "formatDescription",
// "channelDescription",
// "audienceDataDescription",
//   'targetGroupDescription',
//   'placementDescription',
//   'goalDescription',
//   'budget',
//   'budgetCurrency',
//   'calcDailyBudget',
//   'plannedGross',
//   'plannedNet',
//   'plannedNetNet',
//   'plannedCtc',

// "plannedTrp",
// "plannedTechFee",
// "plannedAdOpsFee",
// "plannedAdServingCost",
// "plannedFeePercent",

//   'kpi',
//   'plannedImpressions',
//   'plannedClicks',
//   'plannedCtr',
//   'plannedCpc',
//   'plannedReach',
//   'plannedCpm',
//   'plannedFrequency',
//   'plannedActionCount',
//   'plannedActionValue',
//   'plannedActionRoas',
//   'plannedCpa',
//   'cost',
//   'pace',
//   // 'pace_bar',
//   'universe',
//   'trp',
//   'spots',
//   'impressions',
//   'cpm',
//   'reach',
//   'clicks',
//   'ctr',
//   'viewableRate',
//   'searchImpressionShare',
//   'cpc',
//   'action01',
//   'action01Count',
//   'action01Cvr',
//   'action01Cpa',
//   'action01Value',
//   'action01Roas',
//   'action01Vpa',
//   'action02',
//   'action02Count',
//   'action02Cvr',
//   'action02Cpa',
//   'action02Value',
//   'action02Roas',
//   'action02Vpa',
//   'action03',
//   'action03Count',
//   'action03Cvr',
//   'action03Cpa',
//   'action03Value',
//   'action03Roas',
//   'action03Vpa',

//   'totalCost',
//   'totalImpressions',
//   'totalCpm',
//   'totalClicks',
//   'totalCtr',
//   'totalCpc',

//   'totalAction01',
//   'totalAction01Count',
//   // 'totalaction01Cvr',
//   'totalAction01Cpa',
//   'totalAction01Value',
//   'totalAction01Roas',
//   'totalAction01Vpa',
//   'totalAction02',
//   'totalAction02Count',
//   // 'totalaction02Cvr',
//   'totalAction02Cpa',
//   'totalAction02Value',
//   'totalAction02Roas',
//   'totalAction02Vpa',
//   'totalAction03',
//   'totalAction03Count',
//   // 'totalaction03Cvr',
//   'totalAction03Cpa',
//   'totalAction03Value',
//   'totalAction03Roas',
//   'totalAction03Vpa',

//   'ganttBarColor',
//   'gantt',

//   'impressionsToday',
//   'impressionsYesterday',
//   'impressionsLineChart',

//   'cpmToday',
//   'cpmYesterday',
//   'cpmLineChart',

//   'costToday',
//   'costYesterday',
//   'costLineChart',

//   'clicksToday',
//   'clicksYesterday',
//   'clicksLineChart',

//   'ctrToday',
//   'ctrYesterday',
//   'ctrLineChart',

//   'viewableRateToday',
//   'viewableRateYesterday',
//   'viewableRateLineChart',

//   'searchImpressionShareToday',
//   'searchImpressionShareYesterday',
//   'searchImpressionShareLineChart',

//   'cpcToday',
//   'cpcLineChart',
//   'cpcYesterday',

//   'action01CountLineChart',
//   'action01CvrLineChart',
//   'action01CpaLineChart',
//   'action01ValueLineChart',
//   'action01RoasLineChart',
//   'action01VpaLineChart',

//   'action02CountLineChart',
//   'action02CvrLineChart',
//   'action02CpaLineChart',
//   'action02ValueLineChart',
//   'action02RoasLineChart',
//   'action02VpaLineChart',

//   'action03CountLineChart',
//   'action03CvrLineChart',
//   'action03CpaLineChart',
//   'action03ValueLineChart',
//   'action03RoasLineChart',
//   'action03VpaLineChart',

//   'action01CountToday',
//   'action01CvrToday',
//   'action01CpaToday',
//   'action01ValueToday',
//   'action01RoasToday',
//   'action01VpaToday',

//   'action02CountToday',
//   'action02CvrToday',
//   'action02CpaToday',
//   'action02ValueToday',
//   'action02RoasToday',
//   'action02VpaToday',

//   'action03CountToday',
//   'action03CvrToday',
//   'action03CpaToday',
//   'action03ValueToday',
//   'action03RoasToday',
//   'action03VpaToday',

//   'action01CountYesterday',
//   'action01CvrYesterday',
//   'action01CpaYesterday',
//   'action01ValueYesterday',
//   'action01RoasYesterday',
//   'action01VpaYesterday',

//   'action02CountYesterday',
//   'action02CvrYesterday',
//   'action02CpaYesterday',
//   'action02ValueYesterday',
//   'action02RoasYesterday',
//   'action02VpaYesterday',

//   'action03CountYesterday',
//   'action03CvrYesterday',
//   'action03CpaYesterday',
//   'action03ValueYesterday',
//   'action03RoasYesterday',
//   'action03VpaYesterday'
// ];

export const defaultFieldSets = [
  {
    _id: 'standard',
    name: 'Standard',
    string: 'standard',
    fields: [
      { name: 'generalDescription', string: 'generalDescription' },
      { name: 'status', string: 'status' },
      { name: 'dates', string: 'dates' },
      { name: 'labels', string: 'labels' },
      { name: 'members', string: 'members' },
      { name: 'comments', string: 'comments' }
    ]
  },
  {
    _id: 'basic',
    name: 'Basic line charts',
    string: 'basic',
    fields: [
      { name: 'cost', string: 'cost' },
      {
        // componentType: 'LINECHART',
        name: 'Line Chart',
        string: 'LINECHART',
        type: 'LINECHART',
        metrics: [
          {
            name: 'Cost',
            type: 'monotone',
            dataKey: 'cost',
            stackId: '1',
            stroke: '#8884d8',
            fill: '#8884d8',
            yAxisOrientation: 'left',
            hideYAxis: false
          }
        ]
        // leftYAxisMetrics: [{ name: 'cost', string: 'cost' }],
        // rightYAxisMetrics: []
      }
      // { name: 'impressions', string: 'impressions' },
      // {
      //   // componentType: 'LINECHART',
      //   name: 'impressionsLineChart',
      //   string: 'impressionsLineChart',
      //   leftYAxisMetrics: [{ name: 'impressions', string: 'impressions' }],
      //   rightYAxisMetrics: []
      // },
      // { name: 'action01Count', string: 'action01Count' },
      // {
      //   // componentType: 'LINECHART',
      //   name: 'action01CountLineChart',
      //   string: 'action01CountLineChart',
      //   leftYAxisMetrics: [{ name: 'action01Count', string: 'action01Count' }],
      //   rightYAxisMetrics: []
      // }
    ]
  },
  {
    _id: 'comments',
    name: 'Comments',
    string: 'comments',
    fields: [{ name: 'comments', string: 'comments' }]
  }
];

export const defaultTaskFieldSets = [
  {
    _id: 'standard',
    name: 'Standard',
    fields: [
      { name: 'generalDescription', string: 'generalDescription' },
      { name: 'status', string: 'status' },
      { name: 'dates', string: 'dates' },
      { name: 'labels', string: 'labels' },
      { name: 'members', string: 'members' },
      { name: 'comments', string: 'comments' },
      { name: 'personal', string: 'isPersonal' },
      { name: 'subTask', field: 'subTasks' }
    ]
  }
  // {
  //   _id: 'comments',
  //   name: 'Comments',
  //   fields: [{ field: 'comments' }]
  // }
];

export const fieldDictionary = {
  isPersonal: 'Is Personal',
  subTasks: 'Subtasks',
  name: 'Name',
  warnings: 'Warnings',
  status: 'Status',
  path: 'Path',
  comments: 'Comments',
  members: 'Members',
  labels: 'Labels',
  dates: 'Dates',
  startDate: 'Start Date',
  endDate: 'End Date',
  numberOfDays: 'Number of Days',
  generalDescription: 'General Description',
  buyingTypeDescription: 'Buying Type Description',
  creativeDescription: 'Creative Description',
  formatDescription: 'Format Description',
  channelDescription: 'Channel Description',
  audienceDataDescription: 'Audience Data Description',
  targetGroupDescription: 'Target Group Description',
  placementDescription: 'Placement Description',
  goalDescription: 'Goal Description',
  budget: 'Budget',
  budgetCurrency: 'Budget Currency',
  calcDailyBudget: 'Calculated Daily Budget',
  plannedGross: 'Planned Gross',
  plannedNet: 'Planned Net',
  plannedNetNet: 'Planned Net Net',
  plannedCtc: 'Planned Cost To Client',

  plannedTrp: 'Planned Target Rating Points',
  plannedCpp: 'Planned Cost Per Point',
  plannedTechFee: 'Planned Tech Fee',
  plannedAdOpsFee: 'Planned Ad Ops Fee',
  plannedAdServingCost: 'Planned Ad Serving Cost',
  plannedFeePercent: 'Planned Fee Percent',

  kpi: 'KPI',
  plannedImpressions: 'Planned Impressions',
  plannedClicks: 'Planned Clicks',
  plannedCtr: 'Planned CTR',
  plannedCpc: 'Planned CPC',
  plannedReach: 'Planned Reach',
  plannedCpm: 'Planned CPM',
  plannedFrequency: 'Planned Frequency',
  plannedActionCount: 'Planned Actions',
  plannedActionValue: 'Planned Action Value',
  plannedActionRoas: 'Planned Action ROAS',
  plannedCpa: 'Planned CPA',
  cost: 'Cost',
  pace: 'Pace',
  pace_bar: 'Pace Bar',
  universe: 'Universe',
  trp: 'Target Rating Points',
  spots: 'Spots',
  impressions: 'Impressions',
  viewableImpressions: 'Viewable Impressions',
  measurableImpressions: 'Measurable Impressions',
  eligibleImpressions: 'Eligible Impressions',
  cpm: 'CPM',
  vCpm: 'vCPM',
  reach: 'Reach',
  clicks: 'Clicks',
  ctr: 'CTR',
  viewableRate: 'Viewable Rate',
  searchImpressionShare: 'Search Impression Share',
  cpc: 'CPC',
  action01: 'Action 01 Type',
  action01Count: 'Action 01 Count',
  action01Cvr: 'Action 01 Conversion Rate',
  action01Cpa: 'Action 01 CPA',
  action01Value: 'Action 01 Value',
  action01Roas: 'Action 01 ROAS',
  action01Vpa: 'Action 01 Average Value Per Action',
  action02: 'Action 02 Type',
  action02Count: 'Action 02 Count',
  action02Cvr: 'Action 02 Conversion Rate',
  action02Cpa: 'Action 02 CPA',
  action02Value: 'Action 02 Value',
  action02Roas: 'Action 02 ROAS',
  action02Vpa: 'Action 02 Average Value Per Action',
  action03: 'Action 03 Type',
  action03Count: 'Action 03 Count',
  action03Cvr: 'Action 03 Conversion Rate',
  action03Cpa: 'Action 03 CPA',
  action03Value: 'Action 03 Value',
  action03Roas: 'Action 03 ROAS',
  action03Vpa: 'Action 03 Average Value Per Action',

  totalCost: 'Total Cost',
  totalImpressions: 'Total Impressions',
  totalCpm: 'Total CPM',
  totalClicks: 'Total Clicks',
  totalCtr: 'Total CTR',
  totalCpc: 'Total CPC',

  totalAction01: 'Total Action 01',
  totalAction01Count: 'Total Action 01 Count',
  totalaction01Cvr: 'Total Action 01 Conversion Rate',
  totalAction01Cpa: 'Total Action 01 CPA',
  totalAction01Value: 'Total Action 01 Value',
  totalAction01Roas: 'Total Action 01 ROAS',
  totalAction01Vpa: 'Total Action 01 Average Value Per Action',
  totalAction02: 'Total Action 02',
  totalAction02Count: 'Total Action 02 Count',
  totalaction02Cvr: 'Total Action 02 Conversion Rate',
  totalAction02Cpa: 'Total Action 02 CPA',
  totalAction02Value: 'Total Action 02 Value',
  totalAction02Roas: 'Total Action 02 ROAS',
  totalAction02Vpa: 'Total Action 02 Average Value Per Action',
  totalAction03: 'Total Action 03',
  totalAction03Count: 'Total Action 03 Count',
  totalaction03Cvr: 'Total Action 03 Conversion Rate',
  totalAction03Cpa: 'Total Action 03 CPA',
  totalAction03Value: 'Total Action 03 Value',
  totalAction03Roas: 'Total Action 03 ROAS',
  totalAction03Vpa: 'Total Action 03 Average Value Per Action',

  totalAction01CountLineChart: 'Total Action 01 Count LineChart',
  totalaction01CvrLineChart: 'Total Action 01 Conversion Rate LineChart',
  totalAction01CpaLineChart: 'Total Action 01 CPA LineChart',
  totalAction01ValueLineChart: 'Total Action 01 Value LineChart',
  totalAction01RoasLineChart: 'Total Action 01 ROAS LineChart',
  totalAction01VpaLineChart:
    'Total Action 01 Average Value Per Action LineChart',

  totalAction02CountLineChart: 'Total Action 02 Count LineChart',
  totalaction02CvrLineChart: 'Total Action 02 Conversion Rate LineChart',
  totalAction02CpaLineChart: 'Total Action 02 CPA LineChart',
  totalAction02ValueLineChart: 'Total Action 02 Value LineChart',
  totalAction02RoasLineChart: 'Total Action 02 ROAS LineChart',
  totalAction02VpaLineChart:
    'Total Action 02 Average Value Per Action LineChart',

  totalAction03CountLineChart: 'Total Action 03 Count LineChart',
  totalaction03CvrLineChart: 'Total Action 03 Conversion Rate LineChart',
  totalAction03CpaLineChart: 'Total Action 03 CPA LineChart',
  totalAction03ValueLineChart: 'Total Action 03 Value LineChart',
  totalAction03RoasLineChart: 'Total Action 03 ROAS LineChart',
  totalAction03VpaLineChart:
    'Total Action 03 Average Value Per Action LineChart',

  ganttBarColor: 'Gantt Bar Color',
  gantt: 'Gantt',

  impressionsToday: 'Impressions Today',
  impressionsYesterday: 'Impressions Yesterday',
  impressionsLineChart: 'Impressions LineChart',

  viewableImpressionsToday: 'Viewable Impressions Today',
  viewableImpressionsYesterday: 'Viewable Impressions Yesterday',
  viewableImpressionsLineChart: 'Viewable Impressions LineChart',

  measurableImpressionsToday: 'Measurable Impressions Today',
  measurableImpressionsYesterday: 'Measurable Impressions Yesterday',
  measurableImpressionsLineChart: 'Measurable Impressions LineChart',

  eligibleImpressionsToday: 'Eligible Impressions Today',
  eligibleImpressionsYesterday: 'Eligible Impressions Yesterday',
  eligibleImpressionsLineChart: 'Eligible Impressions LineChart',

  cpmToday: 'CPM Today',
  cpmYesterday: 'CPM Yesterday',
  cpmLineChart: 'CPM LineChart',

  vCpmToday: 'vCPM Today',
  vCpmYesterday: 'vCPM Yesterday',
  vCpmLineChart: 'vCPM LineChart',

  costToday: 'Cost Today',
  costYesterday: 'Cost Yesterday',
  costLineChart: 'Cost LineChart',

  trpToday: 'Target Rating Points Today',
  trpYesterday: 'Target Rating Points Yesterday',
  trpLineChart: 'Target Rating Points LineChart',

  spotsToday: 'Spots Today',
  spotsYesterday: 'Spots Yesterday',
  spotsLineChart: 'Spots LineChart',

  clicksToday: 'Clicks Today',
  clicksYesterday: 'Clicks Yesterday',
  clicksLineChart: 'Clicks LineChart',

  ctrToday: 'CTR Today',
  ctrYesterday: 'CTR Yesterday',
  ctrLineChart: 'CTR LineChart',

  viewableRateToday: 'Viewable Rate Today',
  viewableRateYesterday: 'Viewable Rate Yesterday',
  viewableRateLineChart: 'Viewable Rate LineChart',

  searchImpressionShareToday: 'Search Impression Share Today',
  searchImpressionShareYesterday: 'Search Impression Share Yesterday',
  searchImpressionShareLineChart: 'Search Impression Share LineChart',

  cpcToday: 'CPC Today',
  cpcLineChart: 'CPC LineChart',
  cpcYesterday: 'CPC Yesterday',

  action01CountLineChart: 'Action 01 Count LineChart',
  action01CvrLineChart: 'Action 01 Conversion Rate LineChart',
  action01CpaLineChart: 'Action 01 CPA LineChart',
  action01ValueLineChart: 'Action 01 Value LineChart',
  action01RoasLineChart: 'Action 01 ROAS LineChart',
  action01VpaLineChart: 'Action 01 Average Value Per Action LineChart',

  action02CountLineChart: 'Action 02 Count LineChart',
  action02CvrLineChart: 'Action 02 Conversion Rate LineChart',
  action02CpaLineChart: 'Action 02 CPA LineChart',
  action02ValueLineChart: 'Action 02 Value LineChart',
  action02RoasLineChart: 'Action 02 ROAS LineChart',
  action02VpaLineChart: 'Action 02 Average Value Per Action LineChart',

  action03CountLineChart: 'Action 03 Count LineChart',
  action03CvrLineChart: 'Action 03 Conversion Rate LineChart',
  action03CpaLineChart: 'Action 03 CPA LineChart',
  action03ValueLineChart: 'Action 03 Value LineChart',
  action03RoasLineChart: 'Action 03 ROAS LineChart',
  action03VpaLineChart: 'Action 03 Average Value Per Action LineChart',

  action01CountToday: 'Action 01 Count Today',
  action01CvrToday: 'Action 01 Conversion Rate Today',
  action01CpaToday: 'Action 01 CPA Today',
  action01ValueToday: 'Action 01 Value Today',
  action01RoasToday: 'Action 01 ROAS Today',
  action01VpaToday: 'Action 01 Average Value Per Action Today',

  action02CountToday: 'Action 02 Count Today',
  action02CvrToday: 'Action 02 Conversion Rate Today',
  action02CpaToday: 'Action 02 CPA Today',
  action02ValueToday: 'Action 02 Value Today',
  action02RoasToday: 'Action 02 ROAS Today',
  action02VpaToday: 'Action 02 Average Value Per Action Today',

  action03CountToday: 'Action 03 Count Today',
  action03CvrToday: 'Action 03 Conversion Rate Today',
  action03CpaToday: 'Action 03 CPA Today',
  action03ValueToday: 'Action 03 Value Today',
  action03RoasToday: 'Action 03 ROAS Today',
  action03VpaToday: 'Action 03 Average Value Per Action Today',

  action01CountYesterday: 'Action 01 Count Yesterday',
  action01CvrYesterday: 'Action 01 Conversion Rate Yesterday',
  action01CpaYesterday: 'Action 01 CPA Yesterday',
  action01ValueYesterday: 'Action 01 Value Yesterday',
  action01RoasYesterday: 'Action 01 ROAS Yesterday',
  action01VpaYesterday: 'Action 01 Average Value Per Action Yesterday',

  action02CountYesterday: 'Action 02 Count Yesterday',
  action02CvrYesterday: 'Action 02 Conversion Rate Yesterday',
  action02CpaYesterday: 'Action 02 CPA Yesterday',
  action02ValueYesterday: 'Action 02 Value Yesterday',
  action02RoasYesterday: 'Action 02 ROAS Yesterday',
  action02VpaYesterday: 'Action 02 Average Value Per Action Yesterday',

  action03CountYesterday: 'Action 03 Count Yesterday',
  action03CvrYesterday: 'Action 03 Conversion Rate Yesterday',
  action03CpaYesterday: 'Action 03 CPA Yesterday',
  action03ValueYesterday: 'Action 03 Value Yesterday',
  action03RoasYesterday: 'Action 03 ROAS Yesterday',
  action03VpaYesterday: 'Action 03 Average Value Per Action Yesterday'
};
