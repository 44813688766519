import React, { useContext } from 'react';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';

import { useSnackbar } from 'notistack';
import { MutationContext } from '../../../../../../context/MutationContext';
import { AccountContext } from '../../../../../../context/AccountContext';

export default function DenseMenu({ item }) {
  const { enqueueSnackbar } = useSnackbar();

  const { currentManagerAccountId } = useContext(AccountContext);

  const { updateDocument } = useContext(MutationContext);

  // ################### update #######################

  const onSubmitHandler = async (e, itemKey) => {
    e.preventDefault();

    const documentInput = {
      managerAccountId: currentManagerAccountId,
      documents: [
        {
          accountId: item.accountId,
          documentId: item._id,
          documentType: item.documentType,
          [itemKey]: !item[itemKey]
        }
      ],
      updateKeys: [itemKey]
    };
    // console.log('item[itemKey]: ', item[itemKey]);
    // console.log('itemKey', itemKey);

    // updateDocument({ variables: { input: { ...documentInput } } });

    try {
      const res = await updateDocument({
        variables: { input: { ...documentInput } }
      });
      // console.log('res: ', res);
      // console.log(
      //   'res?.data?.updateDocument?.response: ',
      //   res?.data?.updateDocument?.response
      // );
      if (res?.data?.updateDocument?.response !== 'Success') {
        throw new Error();
      }
    } catch (error) {
      enqueueSnackbar('ERROR! PLEASE RELOAD THE PAGE AND TRY AGAIN!', {
        variant: 'error'
      });
    }
  };

  const menuItems = [
    {
      name: 'Allow Impressions Before Start Date',
      itemKey: 'allowImpressionsBeforeStartDate',
      warningType: 'IMPRESSIONS_BEFORE_START_DATE'
    },
    {
      name: 'Allow Impressions After End Date',
      itemKey: 'allowImpressionsAfterEndDate',
      warningType: 'IMPRESSIONS_AFTER_END_DATE'
    },
    {
      name: 'Allow Missing Budget',
      itemKey: 'allowMissingBudget',
      warningType: 'MISSING_BUDGET'
    },
    {
      name: 'Allow Missing Clicks',
      itemKey: 'allowMissingClicks',
      warningType: 'IMPRESSIONS_OR_COST_BUT_NO_CLICKS'
    },
    {
      name: 'Allow Abnormally High Ctr',
      itemKey: 'allowAbnormallyHighCtr',
      warningType: 'ABNORMALLY_HIGH_CTR'
    },
    {
      name: 'Allow Missing Impressions Yesterday',
      itemKey: 'allowMissingImpressionsYesterday',
      warningType: 'MISSING_IMPRESSIONS_YESTERDAY'
    }
  ];
  return (
    <Paper sx={{ width: 300 }}>
      <MenuList dense>
        {menuItems.map((obj) => (
          <MenuItem onClick={(e) => onSubmitHandler(e, obj.itemKey)}>
            {item[obj.itemKey] && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            {item[obj.itemKey] ? (
              obj.name
            ) : (
              <ListItemText inset>{obj.name}</ListItemText>
            )}
          </MenuItem>
        ))}

        {/* <MenuItem>
          <ListItemIcon>
            <Check />
          </ListItemIcon>
          Allow missing clicks
        </MenuItem> */}
        {/* <Divider /> */}
        {/* <MenuItem>
          <ListItemText>Add space before paragraph</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemText>Add space after paragraph</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemText>Custom spacing...</ListItemText>
        </MenuItem> */}
      </MenuList>
    </Paper>
  );
}
