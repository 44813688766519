import React, { useState, useContext, useEffect } from 'react';

import ReportBuilderWidgetList from './ReportBuilderWidgetList';
import { styled, useTheme } from '@mui/material/styles';

import EditIcon from '@mui/icons-material/Edit';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import ReportBuilderField from './ReportBuilderField';

import ForwardIcon from '@mui/icons-material/Forward';

import { Button } from '@mui/material';

import { DataContext } from '../../../../context/DataContext';
import { DialogContext } from '../../../../context/DialogContext';

import ReportBuilderWidgetListWrapper from './ReportBuilderWidgetListWrapper';

function ReportBuilderDialogContent() {
  // const [editMode, setEditMode] = useState(false);

  const { listOfData } = useContext(DataContext);
  const { itemId } = useContext(DialogContext);

  const item = listOfData.filter((obj) => obj._id === itemId)[0];

  console.log('TaxonomyDialogContent>item: ', item);

  const [taxonomyFieldIdsInEditMode, setTaxonomyFieldIdsInEditMode] = useState(
    []
  );
  const theme = useTheme();
  const [widgetListOpen, setWidgetListOpen] = useState(false);
  const [deleted, setDeleted] = useState(null);

  const [isDraggingWidget, setIsDraggingWidget] = useState(false);

  const [taxonomyString, setTaxonomyString] = useState('');

  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    if (!isEditMode && widgetListOpen) {
      setWidgetListOpen(false);
    }
    if (isEditMode && !widgetListOpen) {
      setWidgetListOpen(true);
    }
  }, [taxonomyFieldIdsInEditMode]);

  // const [grabbing, toggleGrabbing] = useState(false);

  return (
    <div
      style={{
        // width: '80vw',
        // height: '80vh',
        width: '100%',
        height: '100%',
        // height: 'calc(100% - 35px)',
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: !widgetListOpen ? 'center' : 'space-between',
        alignItems: 'center',
        overflow: 'hidden'
      }}
    >
      <div
        style={{
          // background: 'green',
          width: '100%',
          height: '100%',
          // padding: '10px',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'start',
          flexDirection: 'column',
          paddingRight: '20px'
          // paddingTop: '10px'
          // paddingTop: '135px'
          // rowGap: '10px'
          // paddingBottom: '90px'
        }}
      >
        <ReportBuilderField
          setDeleted={setDeleted}
          isDraggingWidget={isDraggingWidget}
          setIsDraggingWidget={setIsDraggingWidget}
        />
      </div>

      <ReportBuilderWidgetListWrapper
        widgetListOpen={widgetListOpen}
        setWidgetListOpen={setWidgetListOpen}
        isDraggingWidget={isDraggingWidget}
        setIsDraggingWidget={setIsDraggingWidget}
      />

      {/* )} */}
    </div>
  );
}

export default ReportBuilderDialogContent;
