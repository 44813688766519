import React, { useContext, useState, useEffect } from 'react';

import { DialogContext } from '../../../../context/DialogContext';

import { ColumnContext } from './../../../../context/ColumnContext';

import { prepareObject } from './preparedObject';

// ########

import IntervallSelect from './IntervallSelect';

import MultipleSelect from './MultipleSelect';

import LineChart from './LineChart';

import DataGrid from './DataGrid';

import prepDataFunction from '../prepDataFunction';

import TrendView from './TrendView';

import DashboardIcon from '@mui/icons-material/Dashboard';

import LineAxisIcon from '@mui/icons-material/LineAxis';

import { InterfaceContext } from '../../../../context/InterfaceContext';

import { DataContext } from '../../../../context/DataContext';

import {
  generateWeeks,
  getWeekNumber,
  getDayAsString,
  generateMonths
} from './../../roitable_functions/date_functions';

import { columnDictionary } from './../../column-menu/columns';

// import ExportMenu from './../../export/data-export/ExportMenu';

import getFastViewDataStartDate from './../../roitable_functions/getFastViewDataStartDate';

// ########

function GraphContent({
  isExpanded,
  range2,
  rawArrayOfData,
  selectedDateStart,
  selectedDateStop,
  item
}) {
  const { listOfData } = useContext(DataContext);
  const { daysToGraph } = useContext(InterfaceContext);
  const startDateForLastDaysData = getFastViewDataStartDate(
    selectedDateStart,
    selectedDateStop,
    daysToGraph
  );
  const startDateForLastDaysDataMinusOneDay = new Date(
    new Date().setDate(startDateForLastDaysData.getDate() - 1)
  );

  // console.log(
  //   'startDateForLastDaysDataMinusOneDay: ',
  //   startDateForLastDaysDataMinusOneDay
  // );
  const today = new Date();
  let tomorrow = new Date();
  tomorrow = tomorrow.setDate(tomorrow.getDate() + 1);
  let dayAfterTomorrow = new Date(
    new Date().setDate(new Date(tomorrow).getDate() + 1)
  );
  // const { itemId, itemKey } = useContext(DialogContext);

  //   const item = rawArrayOfData.filter((obj) => obj._id === itemId)[0] || {};

  const { columns } = useContext(ColumnContext);

  // #######################################################################################

  // let defaultKeyArray = [];

  // if (itemKey.includes('Sparkline')) {
  //   defaultKeyArray = [itemKey.split('Sparkline')[0]];
  // } else {
  //   defaultKeyArray = [itemKey];
  // }

  const [time, setTime] = React.useState('day');

  // const [leftYAxisMetrics, setLeftYAxisMetrics] = useState(defaultKeyArray);
  // const [rightYAxisMetrics, setRightYAxisMetrics] = useState([]);

  let prepared_data = prepDataFunction(
    item,
    range2,
    time,
    columns,
    columnDictionary,
    startDateForLastDaysData,
    tomorrow,
    listOfData
  );
  // console.log('prepared_data: ', prepared_data);

  const body = (
    <div
      //   className={classes.paper}
      style={{ overflowY: 'scroll', overflowX: 'hidden', width: '100%' }}
    >
      {/* <div style={{ width: '100%', height: '100px', background: 'red' }}></div> */}
      <TrendView
        time={time}
        setTime={setTime}
        prepared_data={prepared_data}
        item={item}
        range2={range2}
        columns={columns}
        selectedDateStart={selectedDateStart}
        selectedDateStop={selectedDateStop}
        isExpanded={isExpanded}
      />
    </div>
  );

  // #######################################################################################

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 20px',
        overflowX: 'scroll',
        // width: '90vw',
        height: isExpanded ? 'calc(100vh - 116px)' : 'calc(90vh - 116px)',
        maxHeight: isExpanded ? 'calc(100vh - 116px)' : 'calc(90vh - 116px)'
      }}
    >
      {/* <div style={{ width: '100%', height: '100px', background: 'red' }}></div> */}
      {body}
    </div>
  );
}

export default GraphContent;
