import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula';

const parse_dv360_insertion_order_data = (
  dv360InsertionOrder,
  campaignBaseValues,
  selectedDateStart,
  selectedDateStop,
  adsetBaseValues,
  range2,
  startDateForLastDaysData,
  startDateForLastDaysDataMinusOneDay,
  tomorrow,
  dayAfterTomorrow
) => {
  const action01DataSource = dv360InsertionOrder?.action01?.dataSource;
  const action02DataSource = dv360InsertionOrder?.action02?.dataSource;
  const action03DataSource = dv360InsertionOrder?.action03?.dataSource;

  const action01DataSourceIsDV360 = action01DataSource === 'dv360';
  const action02DataSourceIsDV360 = action02DataSource === 'dv360';
  const action03DataSourceIsDV360 = action03DataSource === 'dv360';

  const action01ActivityId = dv360InsertionOrder?.action01?.dv360?.activityId;
  const action02ActivityId = dv360InsertionOrder?.action02?.dv360?.activityId;
  const action03ActivityId = dv360InsertionOrder?.action03?.dv360?.activityId;

  const action01Type = dv360InsertionOrder?.action01?.dv360?.type;

  const action02Type = dv360InsertionOrder?.action02?.dv360?.type;

  const action03Type = dv360InsertionOrder?.action03?.dv360?.type;

  // const action01Name = dv360InsertionOrder?.action01?.dv360?.activityName;

  // const action02Name = dv360InsertionOrder?.action02?.dv360?.activityName;

  // const action03Name = dv360InsertionOrder?.action03?.dv360?.activityName;

  const action01AttributionType =
    dv360InsertionOrder?.action01?.dv360?.attributionType;
  const action02AttributionType =
    dv360InsertionOrder?.action02?.dv360?.attributionType;
  const action03AttributionType =
    dv360InsertionOrder?.action03?.dv360?.attributionType;

  const aggregatedObject = {
    date_start: startDateForLastDaysDataMinusOneDay.getTime(),
    cost: null,
    impressions: null,
    viewableImpressions: null,
    measurableImpressions: null,
    clicks: null,
    action01Count: null,
    action01Value: null,
    action02Count: null,
    action02Value: null,
    action03Count: null,
    action03Value: null
  };

  const campaign = {
    ...dv360InsertionOrder,
    ...campaignBaseValues,
    dataSourceData: true,
    type: 'dv360_insertion_order'
  };

  // campaign.action01.name = action01Name;
  // campaign.action02.name = action02Name;
  // campaign.action03.name = action03Name;

  // if (action01DataSourceIsDV360) {
  //   campaign.action01.name = action01Name;
  // }
  // if (action02DataSourceIsDV360) {
  //   campaign.action02.name = action02Name;
  // }

  // if (action03DataSourceIsDV360) {
  //   campaign.action03.name = action03Name;
  // }

  const runCostFormula = campaign.costFormula !== '=cost';

  const listOfAdsets = [];

  const listOfPropagationData = [];

  if (
    dv360InsertionOrder &&
    dv360InsertionOrder.dv360basic &&
    dv360InsertionOrder.dv360basic.length > 0
  ) {
    // get campaign metrics

    const listOfAdSetIds = [];

    for (let adgroup_object of dv360InsertionOrder.dv360basic) {
      // metrics total

      let action01Count = null;
      let action02Count = null;
      let action03Count = null;

      let action01Value = null;
      let action02Value = null;
      let action03Value = null;

      let outbound_clicks = null;

      if (action01DataSourceIsDV360) {
        switch (action01Type) {
          case 'conversions':
            action01Count =
              action01Count + (adgroup_object.totalConversions || null);
            action01Value =
              action01Value + (adgroup_object.metrics.conversionsValue || null);
            break;

          default:
            if (dv360InsertionOrder && dv360InsertionOrder.dv360conversions) {
              for (let adgroup_conversion_object of dv360InsertionOrder.dv360conversions) {
                if (
                  adgroup_conversion_object?.floodlightActivityId &&
                  adgroup_conversion_object?.floodlightActivityId ===
                    action01ActivityId &&
                  adgroup_conversion_object.date === adgroup_object.date
                ) {
                  switch (action01AttributionType) {
                    case 'default':
                      action01Count =
                        action01Count +
                        (adgroup_conversion_object.totalConversions || null);
                      action01Value =
                        action01Value +
                        (adgroup_conversion_object.cm360PostViewRevenue ||
                          null) +
                        (adgroup_conversion_object.cm360PostClickRevenue ||
                          null);
                      break;
                    case 'view':
                      action01Count =
                        action01Count +
                        (adgroup_conversion_object.postViewConversions || null);
                      action01Value =
                        action01Value +
                        (adgroup_conversion_object.cm360PostViewRevenue ||
                          null);
                      break;
                    case 'click':
                      action01Count =
                        action01Count +
                        (adgroup_conversion_object.postClickConversions ||
                          null);
                      action01Value =
                        action01Value +
                        (adgroup_conversion_object.cm360PostClickRevenue ||
                          null);
                      break;
                  }
                }
              }
            }
        }
      }

      if (action02DataSourceIsDV360) {
        switch (action02Type) {
          case 'conversions':
            action02Count =
              action02Count + (adgroup_object.metrics.conversions || null);
            action02Value =
              action02Value + (adgroup_object.metrics.conversionsValue || null);
            break;

          default:
            if (dv360InsertionOrder && dv360InsertionOrder.dv360conversions) {
              for (let adgroup_conversion_object of dv360InsertionOrder.dv360conversions) {
                if (
                  adgroup_conversion_object?.floodlightActivityId &&
                  adgroup_conversion_object?.floodlightActivityId ===
                    action02ActivityId &&
                  adgroup_conversion_object.date === adgroup_object.date
                ) {
                  switch (action02AttributionType) {
                    case 'default':
                      action02Count =
                        action02Count +
                        (adgroup_conversion_object.totalConversions || null);
                      action02Value =
                        action02Value +
                        (adgroup_conversion_object.cm360PostViewRevenue ||
                          null) +
                        (adgroup_conversion_object.cm360PostClickRevenue ||
                          null);
                      break;
                    case 'view':
                      action02Count =
                        action02Count +
                        (adgroup_conversion_object.postViewConversions || null);
                      action02Value =
                        action02Value +
                        (adgroup_conversion_object.cm360PostViewRevenue ||
                          null);
                      break;
                    case 'click':
                      action02Count =
                        action02Count +
                        (adgroup_conversion_object.postClickConversions ||
                          null);
                      action02Value =
                        action02Value +
                        (adgroup_conversion_object.cm360PostClickRevenue ||
                          null);
                      break;
                  }
                }
              }
            }
        }
      }

      if (action03DataSourceIsDV360) {
        switch (action03Type) {
          case 'conversions':
            action03Count =
              action03Count + (adgroup_object.metrics.conversions || null);
            action03Value =
              action03Value + (adgroup_object.metrics.conversionsValue || null);
            break;

          default:
            if (dv360InsertionOrder && dv360InsertionOrder.dv360conversions) {
              for (let adgroup_conversion_object of dv360InsertionOrder.dv360conversions) {
                if (
                  adgroup_conversion_object?.floodlightActivityId &&
                  adgroup_conversion_object?.floodlightActivityId ===
                    action03ActivityId &&
                  adgroup_conversion_object.date === adgroup_object.date
                ) {
                  switch (action03AttributionType) {
                    case 'default':
                      action03Count =
                        action03Count +
                        (adgroup_conversion_object.totalConversions || null);
                      action03Value =
                        action03Value +
                        (adgroup_conversion_object.cm360PostViewRevenue ||
                          null) +
                        (adgroup_conversion_object.cm360PostClickRevenue ||
                          null);
                      break;
                    case 'view':
                      action03Count =
                        action03Count +
                        (adgroup_conversion_object.postViewConversions || null);
                      action03Value =
                        action03Value +
                        (adgroup_conversion_object.cm360PostViewRevenue ||
                          null);
                      break;
                    case 'click':
                      action03Count =
                        action03Count +
                        (adgroup_conversion_object.postClickConversions ||
                          null);
                      action03Value =
                        action03Value +
                        (adgroup_conversion_object.cm360PostClickRevenue ||
                          null);
                      break;
                  }
                }
              }
            }
        }
      }

      // const costScope = {
      //   cost: adgroup_object.dv360Cost || 0,
      //   impressions: adgroup_object.impressions || 0
      // };

      // const cost = runCostFormula
      //   ? evaluateFormula(campaign.costFormula, costScope)
      //   : adgroup_object.dv360Cost || null;

      const formulaFields = [
        // 'plannedGross',
        // 'plannedNet',
        // 'plannedNetNet',
        // 'plannedCtc',

        'plannedTrp',
        'plannedCpp',
        'plannedTechFee',
        'plannedAdOpsFee',
        'plannedAdServingCost',
        'plannedFeePercent',

        // 'budget',
        // 'kpi',
        // 'plannedImpressions',
        // 'plannedClicks',
        // 'plannedCtr',
        // 'plannedCpc',
        // 'plannedReach',
        // 'plannedCpm',
        // 'plannedFrequency',
        // 'plannedActionCount',
        // 'plannedActionValue',
        // 'plannedActionRoas',
        // 'plannedCpa',
        'reach',
        'cost',
        'impressions',
        'viewableImpressions',
        'measurableImpressions',
        'clicks',
        'action01Count',
        'action02Count',
        'action03Count',
        'action01Value',
        'action02Value',
        'action03Value'
      ];

      // const generateScope = (formulaField, calcObject) => {
      //   switch (formulaField) {
      //     case 'cost':
      //       return {
      //         cost: adgroup_object.dv360Cost || 0,
      //         impressions: adgroup_object.impressions || 0
      //       };

      //     case 'impressions':
      //       return {
      //         impressions: calcObject.impressions || 0
      //       };
      //     case 'clicks':
      //       return {
      //         clicks: calcObject.clicks || 0
      //       };
      //     case 'action01Count':
      //     case 'action02Count':
      //     case 'action03Count':
      //     case 'action01Value':
      //     case 'action02Value':
      //     case 'action03Value':
      //       return {
      //         [formulaField]: calcObject[formulaField] || 0
      //       };

      //     default:
      //       return {};
      //   }
      // };

      // const calculatedFields = {};

      // // for (let formulaField of formulaFields) {
      // //   calculatedFields[formulaField] = evaluateFormula(
      // //     campaign[formulaField + 'Formula'],
      // //     generateScope(formulaField, adgroup_object)
      // //   );
      // // }

      // for (let formulaField of formulaFields) {
      //   calculatedFields[formulaField] = evaluateFormula(
      //     '=' + formulaField,
      //     generateScope(formulaField, adgroup_object)
      //   );
      // }

      if (
        new Date(+adgroup_object.date).toISOString().split('T')[0] >=
        new Date(startDateForLastDaysData).toISOString().split('T')[0]
      ) {
        campaign.total_list_of_propagation_data.push({
          cost: adgroup_object.revenueAdvertiserCurrency || 0,
          impressions: adgroup_object.impressions || null,

          viewableImpressions:
            adgroup_object.activeViewViewableImpressions || null,

          measurableImpressions:
            adgroup_object.activeViewMeasurableImpressions || null,

          clicks: adgroup_object.clicks || null,

          date_start: adgroup_object.date,
          date_stop: adgroup_object.date,
          action01Type: action01Type,
          action01Count: action01Count,
          action01Value: action01Value,

          action02Type: action02Type,
          action02Count: action02Count,
          action02Value: action02Value,

          action03Type: action03Type,
          action03Count: action03Count,
          action03Value: action03Value
        });
      } else {
        aggregatedObject.cost =
          aggregatedObject.cost +
          (adgroup_object.revenueAdvertiserCurrency || 0);
        aggregatedObject.impressions =
          aggregatedObject.impressions + (adgroup_object.impressions || null);

        aggregatedObject.viewableImpressions =
          aggregatedObject.viewableImpressions +
          (adgroup_object.activeViewViewableImpressions || null);

        aggregatedObject.measurableImpressions =
          aggregatedObject.measurableImpressions +
          (adgroup_object.activeViewMeasurableImpressions || null);

        aggregatedObject.clicks =
          aggregatedObject.clicks + (adgroup_object.clicks || null);

        // aggregatedObject.date_start = adgroup_object.date_start;
        // aggregatedObject.date_stop = adgroup_object.date_stop;
        aggregatedObject.action01Type = action01Type;
        aggregatedObject.action01Count =
          aggregatedObject.action01Count + action01Count;
        aggregatedObject.action01Value =
          aggregatedObject.action01Value + action01Value;

        aggregatedObject.action02Type = action02Type;
        aggregatedObject.action02Count =
          aggregatedObject.action02Count + action02Count;
        aggregatedObject.action02Value =
          aggregatedObject.action02Value + action02Value;

        aggregatedObject.action03Type = action03Type;
        aggregatedObject.action03Count =
          aggregatedObject.action03Count + action03Count;
        aggregatedObject.action03Value =
          aggregatedObject.action03Value + action03Value;
      }

      // listOfPropagationData.push({
      //   ...adgroup_object,
      //   // ...calculatedFields,
      //   date_start: adgroup_object.date,
      //   date_stop: adgroup_object.date,
      //   cost: adgroup_object.revenueAdvertiserCurrency || 0,
      //   impressions: adgroup_object.impressions || null,
      //   clicks: adgroup_object.clicks || null,
      //   action01Type,
      //   action01Count,
      //   action01Value,

      //   action02Type,
      //   action02Count,
      //   action02Value,

      //   action03Type,
      //   action03Count,
      //   action03Value,
      //   // outbound_clicks,
      //   // clicks: adgroup_object.clicks,
      //   accountId: campaignBaseValues.accountId
      // });
    }
  }

  campaign.total_list_of_propagation_data.unshift(aggregatedObject);

  return {
    prepared_campaign: campaign
    // prepared_listOfAdsets: listOfAdsets
  };
};

export default parse_dv360_insertion_order_data;
