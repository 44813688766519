import React, { Fragment, useEffect, useState, useContext } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';

import nexustablelogo from './../../img/nexustableicon.png';

import NexusTableChangelog from '../nexustable-changelogs/NexusTableChangelog';

import { AccountContext } from '../../context/AccountContext';

function getChromeVersion() {
  var raw = navigator?.userAgent?.match(/Chrom(e|ium)\/([0-9]+)\./);

  return raw ? parseInt(raw[2], 10) : false;
}

const getBrowser = () => {
  const { userAgent } = navigator;
  let match =
    userAgent?.match(
      /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
    ) || [];
  let temp;

  if (/trident/i.test(match[1])) {
    temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];

    return `IE ${temp[1] || ''}`;
  }

  if (match[1] === 'Chrome') {
    temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/);

    if (temp !== null) {
      return temp.slice(1).join(' ').replace('OPR', 'Opera');
    }

    temp = userAgent.match(/\b(Edg)\/(\d+)/);

    if (temp !== null) {
      return temp.slice(1).join(' ').replace('Edg', 'Edge (Chromium)');
    }
  }

  match = match[2]
    ? [match[1], match[2]]
    : [navigator.appName, navigator.appVersion, '-?'];
  temp = userAgent.match(/version\/(\d+)/i);

  if (temp !== null) {
    match.splice(1, 1, temp[1]);
  }

  return match.join(' ');
};

const Dashboard = ({
  getCurrentProfile,
  deleteAccount,
  auth: { user },
  profile: { profile, loading },
  stylesStoredInRedux
}) => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const { currentManagerAccountId } = useContext(AccountContext);

  useEffect(() => {
    console.log('currentManagerAccountId: ', currentManagerAccountId);
    if (!currentManagerAccountId) {
      console.log('no manager account id');
      return navigate('/choose-manager-account');
      // return (
      //   <Navigate
      //     to={
      //       (state?.path || '') + (state?.search || '') ||
      //       '/choose-manager-account'
      //     }
      //   />
      // );
    }
  });

  const [browerWarning, setBrowserWarning] = useState('');

  useEffect(() => {
    const browser = getBrowser();
    const googleChromeVersion = getChromeVersion();
    if (browser.includes('Chrome')) {
      if (googleChromeVersion < 112) {
        setBrowserWarning(
          'You are running on an old version of Chrome. Please update to latest version.'
        );
      }
    } else {
      setBrowserWarning(
        'Attention! NexusTable is optimized for the latest version of Chrome. Please switch to Chrome.'
      );
    }
    console.log('browser: ', browser);

    console.log('googleChromeVersion: ', googleChromeVersion);
  }, []);

  // useEffect(() => {
  //   getCurrentProfile();
  // }, [getCurrentProfile]);

  // return loading && profile === null ? (
  return false ? (
    <Spinner />
  ) : (
    <Fragment>
      <div
        style={{
          // marginTop: '20%',
          // height: '100%',
          height: 'calc(100vh - 64px)',
          width: '100%',
          display: 'flex',
          flex: 1,
          // justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          overflowY: 'scroll'
        }}
      >
        {/* <h1>Welcome to NexusTable</h1> */}
        <div style={{ marginTop: '35vh' }}>
          <img
            style={{ height: '100px', width: 'auto' }}
            src={nexustablelogo}
          />
        </div>
        <div style={{ marginTop: '15px', fontWeight: 'bold' }}>
          Welcome to NexusTable
        </div>
        {browerWarning && (
          <div style={{ padding: '40px 20px 0px 20px' }}>
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              {browerWarning}
            </span>
          </div>
        )}

        <div
          style={{
            marginTop: '100px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {/* <div
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px',
              border: '2px solid red',
              background: 'red',
              borderRadius: '5px',
              color: 'white',
              marginBottom: '40px',
              textAlign: 'center',
              width: '500px'
            }}
          >
            GOOGLE ADS DATA AND NEXUSASSISTANT ARE TEMPORARY DOWN DUE TO
            MIGRATION. MIGRATION ESTIMATED TO BE COMPLETED SUNDAY AFTERNOON.
            UNTIL THEN GOOGLE DATA WILL IN MOST CASES SHOW ZERO.
          </div> */}
          <NexusTableChangelog />
        </div>
      </div>

      {/* <div
        style={{
          backgroundImage: stylesStoredInRedux.card.backgroundImage,
          padding: '3%',
          margin: '50px auto',
          maxWidth: '1200px',
          height: '1200px',
          border: '0px solid grey',
          borderRadius: '5px',
          boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
        }}
      >
        <h1 className="large text-primary">Welcome</h1>
        <p className="lead">
          <i className="fas fa-user" /> Welcome {user && user.name}
        </p>
      </div> */}
    </Fragment>
  );
};

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  stylesStoredInRedux: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  stylesStoredInRedux: state.style
});

export default connect(mapStateToProps, {})(Dashboard);
