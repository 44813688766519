import React, { useContext, useState, useEffect, useRef } from 'react';
import { DialogContext } from '../../../../context/DialogContext';

import { AuthContext } from '../../../../context/AuthContext';
import { AccountContext } from '../../../../context/AccountContext';
import { MutationContext } from '../../../../context/MutationContext';
import { InterfaceContext } from '../../../../context/InterfaceContext';

import { DataContext } from '../../../../context/DataContext';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { Button } from '@mui/material';

import Timebox from './Timebox';

import { v4 as uuidv4 } from 'uuid';

import { useTheme } from '@emotion/react';

import formatDateYYYYMMDD from '../../roitable_functions/formatDateYYYYMMDD';

import TimeboxerMenu from '../menu/TimeboxerMenu';

import AssessmentIcon from '@mui/icons-material/Assessment';

// import TimeboxerViewDialog from './report/TimeboxerViewDialog';

// import UserSelectPopover from './user-select-menu/UserSelectPopover';
import TimeboxContextMenu from '../context-menu/TimeboxContextMenu';

import TimeboxListSelectPopover from './timebox-list-select-menu/TimeboxListSelectPopover';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import QueueIcon from '@mui/icons-material/Queue';
import CloseIcon from '@mui/icons-material/Close';

import {
  useQuery,
  useLazyQuery,
  useMutation,
  useSubscription
} from '@apollo/client';
import { timeboxListTimeboxesQuery } from '../../../../graphql/queries';

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}

function TimeboxList({ selectedUser, numDays, right }) {
  const pollTime = 5 * 60 * 1000;

  const [isDragging, setIsDragging] = useState(false);

  const {
    selectedTimeboxList,
    setSelectedTimeboxList
    // userTimeboxLists,
    // setUserTimeboxLists
  } = useContext(DataContext);

  const {
    timeboxListTimeboxes: timeboxes,
    setTimeboxListTimeboxes: setTimeboxes
  } = useContext(DataContext);
  // console.log('TimeboxList>timeboxes: ', timeboxes);
  // const [selectedTimeboxList, setSelectedTimeboxList] = useState(null);
  // const [timeboxListScale, setTimeboxListScale] = useState(1);
  const [contextMenuAnchorPosition, setContextMenuAnchorPosition] =
    React.useState(null);

  const [
    timeboxIdThatTriggeredContextMenu,
    setTimeboxIdThatTriggeredContextMenu
  ] = useState(null);

  // const [isTimeboxViewDialogOpen, setIsTimeboxViewDialogOpen] = useState(false);
  const ref = useRef();

  // const [selectedUser, setSelectedUser] = useState(null);

  const timeboxerRef = useRef();
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const {
    isReportBuilderOpen: isTimeboxerOpen,
    isReportBuilderListOpen: isTimeboxListOpen,
    setIsReportBuilderListOpen: setIsTimeboxListOpen,
    timeboxListScale,
    setTimeboxListScale
  } = useContext(DialogContext);
  const { currentManagerAccount } = useContext(AccountContext);

  const {
    selectedDateStartTimebox,
    setSelectedDateStartTimebox,
    selectedDateStopTimebox,
    setSelectedDateStopTimebox
  } = useContext(InterfaceContext);

  const [isTimeboxesMoved, setIsTimeboxesMoved] = useState(false);

  const [displayTimebox, setDisplayTimebox] = useState({});

  const {
    addTimeboxes,
    updateTimeboxes,
    refetchTimeboxesCounter,
    setRefetchTimeboxesCounter
  } = useContext(MutationContext);
  // const [timeboxes, setTimeboxes] = useState([]);

  const [refetchTimeboxesCounter2, setRefetchTimeboxesCounter2] = useState(0);

  // useEffect(() => {
  //   if (!selectedUser) {
  //     setSelectedUser(user);
  //   }
  // }, [user]);

  // ################## data #######################

  const variables = {
    input: {
      managerAccountId: currentManagerAccount._id,
      // userIds: [selectedUser?._id],
      timeboxListId: selectedTimeboxList?._id
      // selectedDateStartTimebox: new Date(selectedDateStartTimebox),
      // selectedDateStopTimebox: new Date(selectedDateStopTimebox)
    }
  };
  const [
    getData,
    { loading, error, data, refetch, client, startPolling, stopPolling }
  ] = useLazyQuery(timeboxListTimeboxesQuery, {
    variables,

    fetchPolicy: 'no-cache',

    onCompleted: () => {
      console.log('RoiTable>Query completed.');
    },
    onError: (error) => {
      console.log('error: ', error);
    }
  });

  // useEffect(() => {
  //   console.log('data: ', data);
  // }, [data]);

  useEffect(() => {
    if (refetchTimeboxesCounter) {
      refetch();
    }
  }, [refetchTimeboxesCounter]);

  useEffect(() => {
    if (currentManagerAccount._id && isTimeboxListOpen && selectedTimeboxList) {
      // console.log('TimeboxList>getData');
      getData();
      startPolling(pollTime);
    }
  }, [currentManagerAccount._id, selectedTimeboxList, isTimeboxListOpen]);

  useEffect(() => {
    // console.log('debug49>test>data: ', data);
    if (data?.timeboxListTimeboxes && selectedTimeboxList) {
      // console.log('debug49>test1');
      const timeboxes_ = data?.timeboxListTimeboxes?.map((timebox) => ({
        ...timebox,
        startDateTime: new Date(+timebox.startDateTime),
        endDateTime: new Date(+timebox.endDateTime),
        deadline: timebox.deadline && new Date(+timebox.deadline),
        duration: (timebox?.endDateTime - timebox?.startDateTime) / 60000
      }));

      const copyOfTimeboxListTimeboxes = [...(timeboxes_ || [])];

      const copyOfTimeboxListTimeboxesWithDeadlines =
        copyOfTimeboxListTimeboxes?.filter((obj) => Boolean(obj?.deadline));

      const copyOfTimeboxListTimeboxesWithoutDeadlines =
        copyOfTimeboxListTimeboxes?.filter((obj) => !Boolean(obj?.deadline));

      const sortedDeadlines = copyOfTimeboxListTimeboxesWithDeadlines?.sort(
        function (a, b) {
          var deadlineA = a?.deadline; // ignore upper and lowercase
          var deadlineB = b?.deadline; // ignore upper and lowercase

          if (deadlineA < deadlineB) {
            return -1;
          }
          if (deadlineA > deadlineB) {
            return 1;
          }

          // names must be equal
          return 0;
        }
      );

      setTimeboxes([
        ...copyOfTimeboxListTimeboxesWithDeadlines,
        ...copyOfTimeboxListTimeboxesWithoutDeadlines
      ]);
      // console.log('Timeboxer>timeboxes>data: ', data);
    }
  }, [data, refetchTimeboxesCounter2, selectedTimeboxList]);
  // console.log('Timeboxer>timeboxes: ', timeboxes);
  // ###############################################

  let zoom = 2.5;

  let timeSteps = 5;
  let minimumDuration = 5;

  const today = new Date();

  const hours = [
    '00:00',
    '00:30',
    '1:00',
    '1:30',
    '2:00',
    '2:30',
    '3:00',
    '3:30',
    '4:00',
    '4:30',
    '5:00',
    '5:30',
    '6:00',
    '6:30',
    '7:00',
    '7:30',
    '8:00',
    '8:30',
    '9:00',
    '9:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30'
  ];

  const onPressDateArrowHandler = (days) => {
    let startDateTime = new Date(selectedDateStartTimebox);
    startDateTime = addDays(startDateTime, days);

    setSelectedDateStartTimebox(startDateTime);

    let endDateTime = new Date(selectedDateStartTimebox);
    endDateTime = addDays(endDateTime, days + 1);

    setSelectedDateStopTimebox(endDateTime);
  };

  useEffect(() => {
    setMouseDownTimeboxIds([]);
  }, [selectedDateStartTimebox, selectedDateStartTimebox]);
  // ###################### on drop ############################

  const onDropHandler = async (e) => {
    // console.log('debug94>Timeboxer>onDrop>e: ', e);
    e.preventDefault();
    const droppedItemString = e.dataTransfer.getData('droppedItem');
    let droppedItem = {};
    try {
      droppedItem = JSON.parse(droppedItemString);
    } catch (error) {
      setIsMouseDown(false);
      return;
    }

    const backupOfTimeboxes = timeboxes.map((tb) => ({ ...tb }));

    var offsetY = e.nativeEvent.offsetY;

    // console.log('debug94>row243>droppedItem: ', droppedItem);
    switch (true) {
      case droppedItem.documentType === 'timebox':
      case droppedItem.documentType === 'timebox' &&
        droppedItem.status === 'TIMEBOX_LIST':
        // console.log('debug94>row247>droppedItem: ', droppedItem);
        // var offsetY = e.nativeEvent.offsetY;

        const duration =
          (new Date(droppedItem?.endDateTime) -
            new Date(droppedItem?.startDateTime)) /
          60000;
        // console.log('duration: ', duration);

        var minutes = 0;
        minutes = (offsetY - droppedItem.eNativeEventOffsetY) / zoom;

        minutes = Math.round(minutes / 5) * 5;
        minutes =
          minimumDuration > Math.abs(minutes) ? minimumDuration : minutes;
        // console.log('debug94>minutes: ', minutes);

        var startDate = addMinutes(
          new Date(formatDateYYYYMMDD(new Date(selectedDateStartTimebox))),
          minutes + new Date(selectedDateStartTimebox).getTimezoneOffset()
        );
        var endDate = addMinutes(new Date(startDate), duration);

        // newTimeboxes = newTimeboxes.map((timebox) => {
        //   let copyOfTimebox = { ...timebox };
        //   delete copyOfTimebox.userId;
        //   delete copyOfTimebox.nexusDocuments;
        //   delete copyOfTimebox.accountName;
        //   delete copyOfTimebox.accountColor;
        //   return copyOfTimebox;
        // });

        const timeboxUpdate = {
          status: 'TIMEBOX_LIST',
          _id: droppedItem.id
          // startDateTime: startDate,
          // endDateTime: endDate
        };

        let input1 = {
          managerAccountId: currentManagerAccount._id,
          selectedUserId: selectedUser?._id,
          updateKeys: ['status'],
          timeboxes: [timeboxUpdate],
          selectedTimeboxListId: selectedTimeboxList._id
        };

        // console.log('update!!!');

        try {
          // console.log('String(droppedItem.id): ', String(droppedItem.id));
          // console.log('timeboxes: ', timeboxes);
          // const newTimeboxIndex = timeboxes.findIndex(
          //   (tb) => String(tb._id) === String(droppedItem.id)
          // );

          // if (newTimeboxIndex > -1) {
          //   console.log('newTimeboxIndex: ', newTimeboxIndex);
          //   const copyOfCurrentTimebox = {
          //     ...timeboxes[newTimeboxIndex],
          //     ...timeboxUpdate
          //   };
          //   const copyOfTimeboxes = [...timeboxes];
          //   copyOfTimeboxes[newTimeboxIndex] = copyOfCurrentTimebox;
          //   setTimeboxes(copyOfTimeboxes);
          // } else {
          //   setTimeboxes(
          //     timeboxes.filter(
          //       (tb) => String(tb._id) !== String(droppedItem.id)
          //     )
          //   );
          // }

          // setTimeboxes(
          //   timeboxes.filter((tb) => String(tb._id) !== String(droppedItem.id))
          // );
          await updateTimeboxes({ variables: { input: input1 } });
          // setRefetchTimeboxesCounter2(refetchTimeboxesCounter2);
        } catch (error) {
          // console.log('debug367: ', error);
          setTimeboxes(backupOfTimeboxes);
          setRefetchTimeboxesCounter(refetchTimeboxesCounter);
          setRefetchTimeboxesCounter2(refetchTimeboxesCounter2);
        }

        // await updateTimeboxes({ variables: { input: input1 } });

        break;

      case droppedItem.documentType === 'timebox':
        break;

      default:
        // console.log('debug94>Timeboxer>droppedItem: ', droppedItem);

        // console.log('debug94>Timeboxer>droppedItem.name: ', droppedItem.name);

        // var offsetY = e.nativeEvent.offsetY;

        var minutes = 0;
        minutes = offsetY / zoom;

        minutes = Math.round(minutes / 5) * 5;
        minutes =
          minimumDuration > Math.abs(minutes) ? minimumDuration : minutes;
        // console.log('debug94>minutes: ', minutes);

        var startDate = addMinutes(
          new Date(formatDateYYYYMMDD(new Date(selectedDateStartTimebox))),
          minutes + new Date(selectedDateStartTimebox).getTimezoneOffset()
        );
        var endDate = addMinutes(new Date(startDate), 15);
        // console.log('endDate: ', endDate);
        // console.log('debug34>endDate>row271: ', endDate);
        // timeboxId = uuidv4();
        // console.log(
        //   'debug34>createNewTimeboxStartDate: ',
        //   createNewTimeboxStartDate
        // );
        // console.log('createNewTimeboxStartDate: ', createNewTimeboxStartDate);
        const newTimebox = {
          // _id: timeboxId,
          status: 'TIMEBOX_LIST',
          accountId: droppedItem.accountId,
          nexusDocumentIds:
            droppedItem.type !== 'account' ? [droppedItem.id] : [],
          // text: 'new timebox',
          startDateTime: startDate,
          endDateTime: endDate
          // text: `Work on ${droppedItem.name}`
        };

        // console.log('debug94>newTimebox: ', newTimebox);

        newTimebox.startDate = formatDateYYYYMMDD(newTimebox.startDateTime);
        newTimebox.endDate = formatDateYYYYMMDD(newTimebox.endDateTime);

        const input = {
          managerAccountId: currentManagerAccount._id,
          selectedUserId: selectedUser?._id,
          timeboxes: [
            newTimebox
            // {
            //   text: 'String',
            //   nexusDocumentIds: [],
            //   startDateTime: '2023-01-18',
            //   endDateTime: '2023-01-18',
            //   startDate: '2023-01-18',
            //   endDate: '2023-01-18'
            // }
          ],
          selectedTimeboxListId: selectedTimeboxList._id
        };

        await addTimeboxes({ variables: { input } });
    }
  };

  const onDragOverHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // ###########################################################

  //   console.log('timeboxes: ', timeboxes);

  // useEffect(() => {
  //   if (!isTimeboxerOpen) {
  //     return;
  //   }
  //   // console.log('ref.current.scrollTop: ', ref.current.scrollTop);
  //   const now = new Date();
  //   ref.current.scrollTop =
  //     (Number(now.getHours()) * 60 + Number(now.getMinutes())) * zoom - 300;
  // }, [isTimeboxerOpen]);
  // useEffect(() => {
  //   setTimeboxes([
  //     //   {
  //     //     _id: 'aksmdflsd',
  //     //     text: 'ansjkdfak sjdnfa kjsdnfkj as',
  //     //     startDateTime: new Date(
  //     //       'Sun Dec 31 2023 16:12:06 GMT+0100 (Central European Standard Time'
  //     //     ),
  //     //     endDateTime: new Date(
  //     //       'Sun Dec 31 2023 17:12:06 GMT+0100 (Central European Standard Time'
  //     //     )
  //     //     // duration: () => this.endDateTime - this.startDateTime
  //     //   },
  //     //   {
  //     //     _id: 'asiofkgsdjk',
  //     //     text: 'ansjkdfak sjdnfa kjsdnfkj as',
  //     //     startDateTime: new Date(
  //     //       'Sun Dec 31 2023 14:12:06 GMT+0100 (Central European Standard Time'
  //     //     ),
  //     //     endDateTime: new Date(
  //     //       'Sun Dec 31 2023 15:12:06 GMT+0100 (Central European Standard Time'
  //     //     )
  //     //     // duration: 15
  //     //   }
  //   ]);
  // }, []);
  // ############################

  const [isMouseDown, setIsMouseDown] = useState(false);

  const [mousePosition, setMousePosition] = React.useState({
    x: null,
    y: null
  });

  const [mouseDownPosition, setMouseDownPosition] = React.useState({
    x: null,
    y: null
  });

  const [onMouseDownPosition, setOnMouseDownPosition] = React.useState({
    x: null,
    y: null
  });

  const [onMouseUpPosition, setOnMouseUpPosition] = React.useState({
    x: null,
    y: null
  });

  const [mouseDownTimeboxIds, setMouseDownTimeboxIds] = useState([]);
  const [isShiftSelected, setIsShiftSelected] = useState(false);
  const [mouseDownAction, setMouseDownAction] = useState();

  const [createNewTimeboxStartDate, setCreateNewTimeboxStartDate] = useState(
    new Date()
  );

  //   useEffect(()=>{
  // setCreateNewTimeboxStartDate(selectedDateStartTimebox);
  //   },[selectedDateStartTimebox])

  const [displayDateRange, setDisplayDateRange] = useState('');
  const [displayDuration, setDisplayDuration] = useState(null);

  const updateMousePosition = (ev) => {
    if (isMouseDown) {
      setMouseDownPosition({ x: ev.clientX, y: ev.clientY });
    }

    setMousePosition({ x: ev.clientX, y: ev.clientY });
    // }
  };
  //   console.log('mousePosition: ', mousePosition);

  useEffect(() => {
    // console.log('debug31>mousePosition: ', mousePosition);
  }, [mousePosition]);

  const onMouseDownHandler = (e, timeboxId, action) => {
    e.stopPropagation();

    const timebox = timeboxes?.filter((tbox) => tbox._id === timeboxId)[0];

    if (timebox?._id && !timebox?.isMutable) {
      return;
    }

    // if (action === 'create') {
    //   return;
    // }
    updateMousePosition(e);
    if (!isMouseDown && !action) {
      setOnMouseDownPosition({ ...mousePosition });
    }

    // let timeboxId = null;
    if (!isMouseDown && action) {
      switch (action) {
        case 'create':
          var start = new Date(selectedDateStartTimebox);
          start.setUTCHours(0, 0, 0, 0);
          // console.log('start: ', start);

          var rect = e.target.getBoundingClientRect();
          var x = e.clientX - rect.left; //x position within the element.
          var y = e.clientY - rect.top; //y position within the element.

          // console.log('debug>y: ', y);

          //   var minutes = Math.round(
          //     (onMouseDownPosition.y - mouseDownPosition.y) / zoom
          //   );
          //   minutes = Math.round(minutes / 5) * 5;
          //   console.log('minutes: ', minutes);
          //   if (minutes % 5) {
          //     return;
          //   }
          var minutes = y / zoom;
          minutes = Math.round(minutes / 5) * 5;

          let startDate = addMinutes(new Date(start), minutes - 60);

          setCreateNewTimeboxStartDate(startDate);

          setDisplayTimebox({
            startDateTime: startDate,
            endDateTime: addMinutes(startDate, minimumDuration)
          });
          //   timeboxId = uuidv4();
          //   const newTimebox = {
          //     _id: timeboxId,
          //     text: 'new timebox',
          //     startDateTime: startDate,
          //     endDateTime: addMinutes(startDate, 15)
          //   };

          //   setTimeboxes([...timeboxes, newTimebox]);
          //   setMouseDownAction('changeEndTime');
          break;

        case 'moveY':
        case 'changeStartTime':
        case 'changeEndTime':
          //   if (!mouseDownTimeboxIds.includes(timeboxId)) {
          //     setMouseDownTimeboxIds([timeboxId]);
          //     setIsShiftSelected(false);
          //     // setIsTimeboxesMoved(false);
          //     return;
          //   }
          //   timeboxId = _timeboxId;
          //   const originalTimeboxes = timeboxes.filter(
          //     (obj) => obj._id === timeboxId
          //   )[0];
          setOriginalTimeboxes(timeboxes.map((obj) => ({ ...obj })));

          //   setDisplayTimeboxes(originalTimebox_);

          break;
      }
      //   console.log('action: ', action);

      setOnMouseDownPosition({ ...mousePosition });

      // console.log('debug30>e.shiftKey: ', e.shiftKey);

      switch (true) {
        case Boolean(timeboxId) && !Boolean(mouseDownTimeboxIds.length):
          setMouseDownTimeboxIds([timeboxId]);
          break;
        case Boolean(timeboxId) &&
          Boolean(mouseDownTimeboxIds.length) &&
          !mouseDownTimeboxIds.includes(timeboxId) &&
          !e.shiftKey:
          setMouseDownTimeboxIds([timeboxId]);
          setIsShiftSelected(false);
          break;

        // case Boolean(timeboxId) &&
        //   mouseDownTimeboxIds.includes(timeboxId) &&
        //   e.shiftKey:
        //   setMouseDownTimeboxIds([...mouseDownTimeboxIds, timeboxId]);
        //   break;
        // case !mouseDownTimeboxIds.includes(timeboxId):
        //   setMouseDownTimeboxIds([timeboxId]);
        //   break;

        // case mouseDownTimeboxIds.includes(timeboxId + e.shiftKey):
        //   setMouseDownTimeboxIds(
        //     mouseDownTimeboxIds.filter((id) => id !== timeboxId)
        //   );
        //   break;
        // case mouseDownTimeboxIds.includes(timeboxId) &&
        //   mouseDownTimeboxIds.length > 1:
        //   setMouseDownTimeboxIds([timeboxId]);
        //   break;
      }

      setMouseDownAction(action);
      setMouseDownPosition({ ...mousePosition });
      setIsMouseDown(true);
    }
  };

  useEffect(() => {
    console.log('debug30>mouseDownTimeboxIds: ', mouseDownTimeboxIds);
  }, [mouseDownTimeboxIds]);

  const onMouseUpHandler = async (e, elementType, timeboxId, trigger) => {
    setIsMouseDown(false);
    if (mouseDownAction === 'create' && trigger === 'onMouseLeave') {
      return;
    }

    setOriginalTimeboxes([]);

    // console.log('debug30>elementType: ', elementType);

    // console.log('debug30>isTimeboxesMoved: ', isTimeboxesMoved);

    if (isMouseDown) {
      if (mouseDownAction === 'create' && isTimeboxesMoved) {
        let minutes = 0;
        minutes =
          (mouseDownPosition.y - onMouseDownPosition.y) /
          timeboxListScale /
          zoom;

        minutes = Math.round(minutes / 5) * 5;
        minutes =
          minimumDuration > Math.abs(minutes) ? minimumDuration : minutes;
        // console.log('debug34>minutes: ', minutes);

        var start = new Date(createNewTimeboxStartDate);
        let endDate = addMinutes(new Date(start), minutes);
        // console.log('debug34>endDate>row271: ', endDate);
        // timeboxId = uuidv4();
        // console.log(
        //   'debug34>createNewTimeboxStartDate: ',
        //   createNewTimeboxStartDate
        // );
        // console.log('createNewTimeboxStartDate: ', createNewTimeboxStartDate);
        const newTimebox = {
          status: 'TIMEBOX_LIST',
          // _id: timeboxId,
          // text: 'new timebox',
          startDateTime:
            endDate < createNewTimeboxStartDate
              ? endDate
              : createNewTimeboxStartDate,
          endDateTime:
            createNewTimeboxStartDate < endDate
              ? endDate
              : createNewTimeboxStartDate
        };

        newTimebox.startDate = formatDateYYYYMMDD(newTimebox.startDateTime);
        newTimebox.endDate = formatDateYYYYMMDD(newTimebox.endDateTime);

        const input = {
          managerAccountId: currentManagerAccount._id,
          selectedUserId: selectedUser?._id,
          timeboxes: [
            newTimebox
            // {
            //   text: 'String',
            //   nexusDocumentIds: [],
            //   startDateTime: '2023-01-18',
            //   endDateTime: '2023-01-18',
            //   startDate: '2023-01-18',
            //   endDate: '2023-01-18'
            // }
          ],
          selectedTimeboxListId: selectedTimeboxList._id
        };

        await addTimeboxes({ variables: { input } });
        // setTimeboxes([...timeboxes, newTimebox]);
        setMouseDownTimeboxIds([]);
        setIsTimeboxesMoved(false);
        if (!e.shiftKey) {
          setIsShiftSelected(false);
        }
      }

      let shouldUpdateTimebox = false;

      if (isTimeboxesMoved) {
        try {
          let updateKeys = [];
          switch (true) {
            case mouseDownAction === 'changeStartTime':
              updateKeys = [...updateKeys, 'startDate', 'startDateTime'];
              shouldUpdateTimebox = true;
              break;
            case mouseDownAction === 'changeEndTime':
              updateKeys = [...updateKeys, 'endDate', 'endDateTime'];
              shouldUpdateTimebox = true;
              break;
            case mouseDownAction === 'moveY':
              updateKeys = [
                ...updateKeys,
                'startDate',
                'startDateTime',
                'endDate',
                'endDateTime'
              ];
              shouldUpdateTimebox = true;
              break;
            default:
              return;
          }

          if (shouldUpdateTimebox) {
            let newTimeboxes = timeboxes.filter((timebox) =>
              mouseDownTimeboxIds.includes(timebox._id)
            );

            // console.log(
            //   "debug49> newTimeboxes.filter((tb) => tb.type === 'outlookCalendarEvent')?.length: ",
            //   newTimeboxes.filter((tb) => tb.type === 'outlookCalendarEvent')
            //     ?.length
            // );
            if (
              newTimeboxes.filter((tb) => tb.type === 'outlookCalendarEvent')
                ?.length
            ) {
              setOnMouseUpPosition({ ...mousePosition });
              setIsMouseDown(false);
              setLastUpdatedMinutes(0);
              setCreateNewTimeboxStartDate(new Date(selectedDateStartTimebox));
              setRefetchTimeboxesCounter2(refetchTimeboxesCounter2 + 1);
              return;
            }

            newTimeboxes = newTimeboxes.map((timebox) => {
              const timeboxUpdate = {
                _id: timebox._id,
                accountId: timebox.accountId,
                managerAccountId: timebox.managerAccountId
              };

              for (let updateKey of updateKeys) {
                timeboxUpdate[updateKey] = timebox[updateKey];
              }
              // let copyOfTimebox = { ...timebox };
              // delete copyOfTimebox.userId;
              // delete copyOfTimebox.nexusDocuments;
              // delete copyOfTimebox.accountName;
              // delete copyOfTimebox.accountColor;
              // delete copyOfTimebox.isMutable;
              return timeboxUpdate;
            });

            const input = {
              managerAccountId: currentManagerAccount._id,
              selectedUserId: selectedUser?._id,
              updateKeys,
              timeboxes: newTimeboxes,
              selectedTimeboxListId: selectedTimeboxList._id
            };

            // console.log('update!!!');

            await updateTimeboxes({ variables: { input } });
          }
        } catch (error) {
          // return;
        }
      }

      setOnMouseUpPosition({ ...mousePosition });
      setIsMouseDown(false);
      setLastUpdatedMinutes(0);
      setCreateNewTimeboxStartDate(new Date(selectedDateStartTimebox));

      //   setMouseDownPosition({});

      // save to DB
    }

    // if (!e.shiftKey) {
    //   setMouseDownTimeboxIds([]);
    // }

    // console.log('debug30>Boolean(timeboxId): ', Boolean(timeboxId));
    // console.log(
    //   'debug30>123: ',
    //   Boolean(timeboxId) &&
    //     !mouseDownTimeboxIds.includes(timeboxId) &&
    //     e.shiftKey
    // );
    if (Boolean(timeboxId)) {
      switch (true) {
        // case Boolean(timeboxId) && !Boolean(mouseDownTimeboxIds.length):
        //   setMouseDownTimeboxIds([timeboxId]);
        //   break;

        case Boolean(timeboxId) &&
          !mouseDownTimeboxIds.includes(timeboxId) &&
          isShiftSelected &&
          e.shiftKey:
          setMouseDownTimeboxIds([...mouseDownTimeboxIds, timeboxId]);

          setIsShiftSelected(true);
          break;

        case mouseDownTimeboxIds.includes(timeboxId) &&
          isShiftSelected &&
          e.shiftKey:
          const newMouseDownTimeboxIds = mouseDownTimeboxIds.filter(
            (id) => id !== timeboxId
          );
          setMouseDownTimeboxIds(newMouseDownTimeboxIds);
          if (!newMouseDownTimeboxIds.length) {
            setIsShiftSelected(false);
          }
          break;

        case !isShiftSelected && e.shiftKey:
          // console.log(
          //   'debug30>case !mouseDownTimeboxIds.includes(timeboxId) &&!isShiftSelected && e.shiftKey:'
          // );
          setMouseDownTimeboxIds([timeboxId]);

          setIsShiftSelected(true);
          break;

        case mouseDownTimeboxIds.includes(timeboxId) &&
          mouseDownTimeboxIds.length === 1 &&
          !isTimeboxesMoved:
          setMouseDownTimeboxIds([]);
          setIsShiftSelected(false);

          break;

        case e.shiftKey:
          setIsShiftSelected(false);
          setMouseDownTimeboxIds([timeboxId]);

          break;

        case !e.shiftKey && !isShiftSelected:
          setMouseDownTimeboxIds([]);
          break;

        case !e.shiftKey && !isTimeboxesMoved:
          setMouseDownTimeboxIds([]);
          setIsShiftSelected(false);
          break;

        default:
          setIsTimeboxesMoved(false);

        // case !mouseDownTimeboxIds.includes(timeboxId) &&
        //   !isShiftSelected &&
        //   e.shiftKey:
        //   setMouseDownTimeboxIds([timeboxId]);
        //   break;

        // case !mouseDownTimeboxIds.includes(timeboxId):
        //   setMouseDownTimeboxIds([timeboxId]);
        //   break;

        // case mouseDownTimeboxIds.includes(timeboxId + e.shiftKey):
        //   setMouseDownTimeboxIds(
        //     mouseDownTimeboxIds.filter((id) => id !== timeboxId)
        //   );
        //   break;
        // case mouseDownTimeboxIds.includes(timeboxId) &&
        //   mouseDownTimeboxIds.length > 1:
        //   setMouseDownTimeboxIds([timeboxId]);
        //   break;
      }
    }

    if (!isTimeboxesMoved && elementType === 'timeboxer') {
      setMouseDownTimeboxIds([]);
      setIsShiftSelected(false);
      // setIsTimeboxesMoved(false);
      return;
    }
    setIsTimeboxesMoved(false);

    // else {
    //   setMouseDownPosition({});
    // }

    // console.log('isMouseDown: ', isMouseDown);
  };
  const [originalTimeboxes, setOriginalTimeboxes] = useState({});

  const [lastUpdatedMinutes, setLastUpdatedMinutes] = useState(0);

  useEffect(() => {
    if (isMouseDown) {
      let minutes = 0;
      //   const timebox = timeboxes.filter(
      //     (timebox) => timebox._id === mouseDownTimeboxId
      //   )[0];

      //   const newTimebox = { ...timebox };
      if (mouseDownAction === 'create') {
        // console.log('debug45>mouseDownAction2: ', mouseDownAction);

        minutes =
          (onMouseDownPosition.y - mouseDownPosition.y) /
          timeboxListScale /
          zoom;
        minutes = Math.round(minutes / 5) * 5;

        if (lastUpdatedMinutes === minutes) {
          return;
        }

        const dateA = createNewTimeboxStartDate;
        const dateB = addMinutes(dateA, minutes * -1);

        const startDate = dateA < dateB ? dateA : dateB;
        const endDate = dateB < dateA ? dateA : dateB;
        // setDisplayDateRange(
        //   Number(startDate.getHours())?.toLocaleString('en-US', {
        //     minimumIntegerDigits: 2,
        //     useGrouping: false
        //   }) +
        //     ':' +
        //     Number(startDate.getMinutes())?.toLocaleString('en-US', {
        //       minimumIntegerDigits: 2,
        //       useGrouping: false
        //     }) +
        //     ' - ' +
        //     Number(endDate.getHours())?.toLocaleString('en-US', {
        //       minimumIntegerDigits: 2,
        //       useGrouping: false
        //     }) +
        //     ':' +
        //     Number(endDate.getMinutes())?.toLocaleString('en-US', {
        //       minimumIntegerDigits: 2,
        //       useGrouping: false
        //     })
        // );
        setDisplayTimebox({
          startDateTime: startDate,
          endDateTime: endDate
        });

        let newDuration = (endDate - startDate) / 60000;

        setDisplayDuration(
          newDuration < minimumDuration ? minimumDuration : newDuration
        );
      } else {
        const copyOfTimeboxes = timeboxes.map((obj) => ({ ...obj }));

        // console.log('copyOfTimeboxes: ', copyOfTimeboxes);
        // console.log('mouseDownTimeboxIds: ', mouseDownTimeboxIds);
        const newTimeboxes = copyOfTimeboxes.filter((timebox) =>
          mouseDownTimeboxIds.includes(timebox._id)
        );

        // const newTimeboxes = { ...timebox };
        // console.log('newTimeboxes: ', newTimeboxes);

        minutes = Math.round(
          (onMouseDownPosition.y - mouseDownPosition.y) / zoom
        );
        minutes = Math.round(minutes / 5) * 5;

        if (minutes % 5) {
          return;
        }

        if (lastUpdatedMinutes === minutes) {
          return;
        }

        for (let newTimebox of newTimeboxes || []) {
          // console.log('debug102>newTimebox: ', newTimebox);
          const originalTimebox = originalTimeboxes.filter(
            (obj) => obj._id === newTimebox._id
          )[0];

          if (!originalTimebox) {
            continue;
          }
          // console.log('debug103>originalTimeboxes: ', originalTimeboxes);
          const timeboxIndex = copyOfTimeboxes.findIndex(
            (obj) => obj._id === newTimebox._id
          );
          // console.log('debug95>isMouseDown: ', isMouseDown);
          // console.log('debug95>mouseDownTimeboxId: ', mouseDownTimeboxId);
          // console.log('debug95>newTimebox.duration: ', newTimebox.duration);
          // console.log('debug95>mousePosition.y: ', mousePosition.y);
          // console.log('debug95>mouseDownPosition.y: ', mouseDownPosition.y);
          // console.log('debug95>onMouseDownPosition.y: ', onMouseDownPosition.y);
          // console.log(
          //   'debug95>mouseDownPosition.y: ',
          //   mouseDownPosition.y
          // );

          // console.log('minutes: ', minutes);

          switch (mouseDownAction) {
            case 'create':
              break;
            case 'moveY':
              newTimebox.startDateTime = addMinutes(
                originalTimebox.startDateTime,
                minutes * -1
              );

              newTimebox.endDateTime = addMinutes(
                originalTimebox.endDateTime,
                minutes * -1
              );

              break;
            case 'changeStartTime':
              // var minutes = Math.round(
              //   (onMouseDownPosition.y - mouseDownPosition.y) / zoom
              // );
              // minutes = Math.round(minutes / 5) * 5;
              // console.log('minutes: ', minutes);
              // if (minutes % 5) {
              //   return;
              // }
              newTimebox.startDateTime = addMinutes(
                originalTimebox.startDateTime,
                minutes * -1
              );
              // console.log('newTimebox.startDateTime: ', newTimebox.startDateTime);
              //   newTimebox.duration =
              //     newTimebox.duration +
              //     (onMouseDownPosition.y - mouseDownPosition.y) / 2;
              break;
            case 'changeEndTime':
              newTimebox.endDateTime = addMinutes(
                originalTimebox.endDateTime,
                minutes * -1
              );
              // newTimebox.duration =
              //   newTimebox.duration +
              //   (mouseDownPosition.y - onMouseDownPosition.y) / 4;
              break;
          }

          // console.log('debug45>mouseDownAction1: ', mouseDownAction);

          // setDisplayDateRange(
          //   Number(newTimebox.startDateTime.getHours())?.toLocaleString('en-US', {
          //     minimumIntegerDigits: 2,
          //     useGrouping: false
          //   }) +
          //     ':' +
          //     Number(newTimebox.startDateTime.getMinutes())?.toLocaleString(
          //       'en-US',
          //       {
          //         minimumIntegerDigits: 2,
          //         useGrouping: false
          //       }
          //     ) +
          //     ' - ' +
          //     Number(newTimebox.endDateTime.getHours())?.toLocaleString('en-US', {
          //       minimumIntegerDigits: 2,
          //       useGrouping: false
          //     }) +
          //     ':' +
          //     Number(newTimebox.endDateTime.getMinutes())?.toLocaleString(
          //       'en-US',
          //       {
          //         minimumIntegerDigits: 2,
          //         useGrouping: false
          //       }
          //     )
          // );

          setDisplayTimebox({ ...newTimebox });

          const newDuration =
            (newTimebox.endDateTime - newTimebox.startDateTime) / 60000;

          if (newDuration < minimumDuration) {
            switch (mouseDownAction) {
              case 'changeStartTime':
                newTimebox.startDateTime = addMinutes(
                  originalTimebox.endDateTime,
                  minimumDuration * -1
                );
                newTimebox.startDate = formatDateYYYYMMDD(
                  newTimebox.startDateTime
                );
                break;
              case 'changeEndTime':
                newTimebox.endDateTime = addMinutes(
                  originalTimebox.startDateTime,
                  minimumDuration
                );
                newTimebox.endDate = formatDateYYYYMMDD(newTimebox.endDateTime);
                break;
            }
            //   return;
          }
          setDisplayDuration(
            newDuration < minimumDuration ? minimumDuration : newDuration
          );

          copyOfTimeboxes.splice(timeboxIndex, 1, newTimebox);
        }

        setTimeboxes(copyOfTimeboxes);
      }
      if (minutes) {
        setIsTimeboxesMoved(true);
      }

      setLastUpdatedMinutes(minutes);
    } else {
    }
  }, [mouseDownPosition]);

  const onContextHandler = (e, timebox) => {
    // console.log('onContextHandler>e: ', e);

    e.preventDefault();
    setTimeboxIdThatTriggeredContextMenu(timebox._id);
    setContextMenuAnchorPosition({ left: e.clientX, top: e.clientY });
  };

  // ############################

  //   console.log('mouseDownAction: ', mouseDownAction);

  let displayCursorTimeboxArray = [];

  if (mouseDownAction === 'create') {
    displayCursorTimeboxArray = [displayTimebox];
  } else {
    displayCursorTimeboxArray = timeboxes.filter((obj) =>
      mouseDownTimeboxIds.includes(obj._id)
    );
  }

  useEffect(() => {
    // console.log('debug411>isDragging: ', isDragging);

    if (!isDragging) {
      return;
    }

    setIsMouseDown(false);
    setMouseDownPosition({
      x: null,
      y: null
    });
    setOnMouseDownPosition({
      x: null,
      y: null
    });
    setOnMouseUpPosition({
      x: null,
      y: null
    });

    setMouseDownTimeboxIds([]);
    setMouseDownAction(null);
  }, [isDragging]);

  const [isTimeboxerManuallyOpened, setIsTimeboxerManuallyOpened] =
    useState(false);

  useEffect(() => {
    if (isTimeboxListOpen && isTimeboxerOpen) {
      setIsTimeboxerManuallyOpened(true);
    }
    console.log('isTimeboxerManuallyOpened: ', isTimeboxerManuallyOpened);
  }, [isTimeboxListOpen, isTimeboxerOpen]);

  return (
    <>
      {
        // isTimeboxerOpen && isTimeboxListOpen
        true && (
          <>
            {/* <TimeboxList selectedUser={selectedUser} /> */}
            <div
              id="timeboxlist"
              className={
                isTimeboxerManuallyOpened
                  ? isTimeboxerOpen && isTimeboxListOpen
                    ? 'isTimeboxListOpenTrue'
                    : 'isTimeboxListOpenFalse'
                  : 'timeboxListDefault'
              }
              onContextMenu={(e) => e.preventDefault()}
              ref={timeboxerRef}
              style={{
                transform: `scale(${timeboxListScale})`,
                transformOrigin: 'right',
                position: 'absolute',
                // right:
                //   (numDays < 4 ? numDays : 4) * 300 -
                //   (10 - 10 * timeboxListScale) * 15 +
                //   'px',
                right: right + 'px',
                // top: '0px',
                border:
                  theme?.palette?.mode === 'dark' &&
                  '1px solid rgb(32 32 41 / 60%)', //'1px solid rgba(255,255,255,0.05)',
                width: '300px',
                overflow: 'hidden',
                height: window.innerHeight - 50 + 'px',
                // height: '100%',
                // background:
                //   theme?.palette?.mode === 'dark'
                //     ? 'rgb(32 32 41 / 60%)' // 'rgba(255,255,255,0.1)'
                //     : 'rgba(255,255,255,0.3)',
                // background: 'red',
                backdropFilter: 'blur(10px)',
                // background: 'red',
                position: 'absolute',
                // right: '0px',
                // top: '-700px',
                // top: '0px',
                zIndex: '15000',
                borderRadius: '5px',
                // padding: '10px',
                boxShadow:
                  theme?.palette?.mode === 'dark'
                    ? '0px 3px 1px -2px rgb(128 128 164 / 20%), 0px 2px 2px 0px rgb(128 128 164 / 14%), 0px 1px 5px 0px rgb(128 128 164 / 12%)' // '0px 3px 1px -2px rgb(255 255 255 / 20%), 0px 2px 2px 0px rgb(255 255 255 / 14%), 0px 1px 5px 0px rgb(255 255 255 / 12%)'
                    : '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
                // boxShadow:
                //   '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'
              }}
              // onMouseMove={updateMousePosition}
              // //   onMouseDown={onMouseDownHandler}
              // onMouseDown={(e) => {
              //   // e.preventDefault();
              //   e.stopPropagation();
              //   onMouseDownHandler(e, null, 'create');
              // }}
              // onMouseUp={(e) => {
              //   e.stopPropagation();
              //   // e.preventDefault();
              //   onMouseUpHandler(e, 'timeboxer', null);
              // }}
            >
              <div
                style={{
                  background:
                    theme?.palette?.mode === 'dark'
                      ? 'rgb(28 28 41 / 40%)' // 'rgb(32 32 41 / 60%)' // 'rgba(255,255,255,0.1)'
                      : 'rgba(255,255,255,0.4)',
                  height: '50px',
                  width: '100%',
                  padding: '5px',
                  display: 'flex',
                  flexDirection: 'row',
                  // flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontSize: 12 / timeboxListScale + 'px'
                }}
              >
                <div
                  style={{
                    width: 54 / timeboxListScale + 'px'
                    // position: 'absolute'
                  }}
                ></div>
                <div>
                  <TimeboxListSelectPopover
                  // selectedTimeboxList={selectedTimeboxList}
                  // setSelectedTimeboxList={setSelectedTimeboxList}
                  />
                </div>
                {/* <div style={{ width: 60 / timeboxListScale + 'px' }}></div> */}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Button
                    onClick={() => {
                      if (timeboxListScale >= 0.5) {
                        setTimeboxListScale(timeboxListScale - 0.1);
                      }
                    }}
                    style={{
                      minWidth: 18 / timeboxListScale + 'px',
                      width: 18 / timeboxListScale + 'px',
                      minHeight: 18 / timeboxListScale + 'px',
                      height: 18 / timeboxListScale + 'px'
                    }}
                  >
                    <ZoomInMapIcon
                      style={{ fontSize: 14 / timeboxListScale + 'px' }}
                    />
                  </Button>
                  <Button
                    onClick={() => {
                      if (timeboxListScale <= 0.9) {
                        setTimeboxListScale(timeboxListScale + 0.1);
                      }
                    }}
                    style={{
                      minWidth: 18 / timeboxListScale + 'px',
                      width: 18 / timeboxListScale + 'px',
                      minHeight: 18 / timeboxListScale + 'px',
                      height: 18 / timeboxListScale + 'px'
                    }}
                  >
                    <ZoomOutMapIcon
                      style={{ fontSize: 14 / timeboxListScale + 'px' }}
                    />
                  </Button>
                  <Button
                    onClick={() => {
                      setIsTimeboxListOpen(!isTimeboxListOpen);
                    }}
                    style={{
                      minWidth: 18 / timeboxListScale + 'px',
                      width: 18 / timeboxListScale + 'px',
                      minHeight: 18 / timeboxListScale + 'px',
                      height: 18 / timeboxListScale + 'px'
                    }}
                  >
                    <CloseIcon
                      style={{ fontSize: 14 / timeboxListScale + 'px' }}
                    />
                  </Button>
                </div>
                {/* <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <div>
                  <Button
                    style={{
                      minWidth: '20px',
                      width: '20px',
                      minHeight: '20px',
                      height: '20px'
                    }}
                  >
                    <AssessmentIcon
                      style={{ fontSize: '12px' }}
                      onClick={() => setIsTimeboxViewDialogOpen(true)}
                    />
                  </Button>
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center'
                  }}
                ></div>
                <TimeboxerMenu calendarUserId={selectedUser?._id} />
              </div>
              <div
                style={{
                  width: '120px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Button
                  style={{
                    width: '20px',
                    minWidth: '20px',
                    height: '20px',
                    minHeight: '20px',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  onClick={(e) => onPressDateArrowHandler(-1)}
                >
                  <ArrowBackIosIcon
                    style={{
                      fontSize: '10px',
                      position: 'relative',
                      right: '-2px'
                    }}
                  />
                </Button>
                <span>{formatDateYYYYMMDD(selectedDateStartTimebox)}</span>
                <Button
                  style={{
                    width: '20px',
                    minWidth: '20px',
                    height: '20px',
                    minHeight: '20px',
                    borderRadius: '15px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                  onClick={(e) => onPressDateArrowHandler(1)}
                >
                  <ArrowForwardIosIcon
                    style={{
                      fontSize: '10px',
                      position: 'relative',
                      right: '0px'
                    }}
                  />
                </Button>
              </div> */}
              </div>
              <div
                onMouseMove={updateMousePosition}
                //   onMouseDown={onMouseDownHandler}
                onMouseDown={(e) => {
                  // e.preventDefault();
                  e.stopPropagation();
                  onMouseDownHandler(e, null, 'create');
                }}
                onMouseUp={(e) => {
                  e.stopPropagation();
                  // e.preventDefault();
                  onMouseUpHandler(e, 'timeboxer', null);
                }}
                onMouseLeave={(e) => {
                  // console.log('debug49>onMouseLeave');

                  if (isMouseDown) {
                    onMouseUpHandler(e, 'timeboxer', null, 'onMouseLeave');
                  }

                  setOnMouseUpPosition({ ...mousePosition });
                  setIsMouseDown(false);
                  // setLastUpdatedMinutes(0);
                  // setCreateNewTimeboxStartDate(
                  //   new Date(selectedDateStartTimebox)
                  // );
                  // setRefetchTimeboxesCounter(refetchTimeboxesCounter + 1);
                }}
                ref={ref}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '300px',
                  height: '100%',
                  //   maxHeight: '80%',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  position: 'relative',
                  background:
                    theme?.palette?.mode === 'dark'
                      ? 'rgb(28 28 41 / 40%)' // 'rgba(255,255,255,0.1)'
                      : 'rgba(255,255,255,0.3)'
                }}
              >
                {/* <div
                style={{
                  position: 'absolute',
                  borderTop:
                    theme?.palette?.mode === 'dark'
                      ? '5px dotted rgba(255,255,255,0.2)'
                      : '5px dotted rgba(48,48,48,0.3)',
                  // borderStyle: 'dotted',
                  height: '1px',
                  width: '100%',
                  top:
                    (new Date().getHours() * 60 + new Date().getMinutes()) *
                      zoom +
                    'px'
                }}
              ></div> */}
                <div
                  onDrop={onDropHandler}
                  onDragOver={onDragOverHandler}
                  style={{
                    position: 'relative',
                    // height: 24 * 200 + 'px',
                    // background: 'blue',
                    height: 24 * 60 * zoom + 'px',
                    // display: 'flex',
                    // width: 'calc(100%-10px)'
                    // height: '1200px',
                    width: '270px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'start',
                    alignItems: 'center',
                    alignContent: 'center',
                    paddingLeft: '7px',
                    paddingTop: '15px',
                    backgroundSize: `${30 * zoom + 'px'} ${30 * zoom + 'px'}`,
                    backgroundPosition: 'center 0px',

                    backgroundImage:
                      'linear-gradient(to bottom, rgba(120, 120, 120, 0.15) 1px, rgba(229, 229, 247, 0) 1px)'
                  }}
                >
                  {timeboxes.map((timebox) => {
                    // const newTimebox = { ...timebox };

                    // if (isMouseDown && timebox._id === mouseDownTimeboxId) {
                    //   console.log('debug95>isMouseDown: ', isMouseDown);
                    //   console.log(
                    //     'debug95>mouseDownTimeboxId: ',
                    //     mouseDownTimeboxId
                    //   );
                    //   console.log(
                    //     'debug95>newTimebox.duration: ',
                    //     newTimebox.duration
                    //   );
                    //   // console.log('debug95>mousePosition.y: ', mousePosition.y);
                    //   console.log(
                    //     'debug95>mouseDownPosition.y: ',
                    //     mouseDownPosition.y
                    //   );
                    //   console.log(
                    //     'debug95>onMouseDownPosition.y: ',
                    //     onMouseDownPosition.y
                    //   );
                    //   // console.log(
                    //   //   'debug95>mouseDownPosition.y: ',
                    //   //   mouseDownPosition.y
                    //   // );
                    //   switch (mouseDownAction) {
                    //     case 'changeStartTime':
                    //       newTimebox.startDateTime = addMinutes(
                    //         timebox.startDateTime,
                    //         mouseDownPosition.y - onMouseDownPosition.y
                    //       );
                    //       newTimebox.duration =
                    //         newTimebox.duration +
                    //         (onMouseDownPosition.y - mouseDownPosition.y) / 2;
                    //       break;
                    //     case 'changeEndTime':
                    //       newTimebox.duration =
                    //         newTimebox.duration +
                    //         (mouseDownPosition.y - onMouseDownPosition.y) / 2;
                    //       break;
                    //   }
                    // }

                    // console.log('newTimebox.duration: ', newTimebox.duration);
                    return (
                      <Timebox
                        setIsDragging={setIsDragging}
                        timeboxListScale={timeboxListScale}
                        onContextHandler={onContextHandler}
                        selectedUser={selectedUser}
                        isMouseDown={isMouseDown}
                        setIsMouseDown={setIsMouseDown}
                        setIsTimeboxesMoved={setIsTimeboxesMoved}
                        zoom={zoom}
                        isShiftSelected={isShiftSelected}
                        isTimeboxesMoved={isTimeboxesMoved}
                        mouseDownTimeboxIds={mouseDownTimeboxIds}
                        // setOriginalTimeboxes={setOriginalTimeboxes}
                        timebox={timebox}
                        mousePosition={mousePosition}
                        onMouseDownHandler={onMouseDownHandler}
                        onMouseUpHandler={onMouseUpHandler}
                      />
                    );
                  })}
                </div>
                <div
                  style={{
                    // // height: '100%',
                    // width: '30px',
                    // height: '800px',
                    // // background: 'green',
                    // display: 'flex',
                    // flexDirection: 'column',
                    fontSize: '11px'
                    // width: '65px'
                    // alignItems: 'start'
                  }}
                >
                  {hours.map((hour) => (
                    <div
                      style={{
                        userSelect: 'none',
                        // display: 'flex',
                        // flexDirection: 'row',
                        height: 30 * zoom + 'px',
                        // background: 'red',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <span
                        style={{
                          color: 'rgba(120, 120, 120, 0.1)',
                          position: 'relative',
                          top: '-8.5px',
                          paddingLeft: '5px'
                          // visibility: 'hidden'
                        }}
                      >
                        -
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div
                style={{
                  fontSize: '12px',
                  position: 'absolute',
                  //   background: 'rgba(255,255,255,0.1)',
                  padding: '5px',
                  borderRadius: '5px',
                  //   top: mousePosition.y - 70 + 'px',
                  //   left: mousePosition.x - 1200 + 'px',
                  top:
                    mousePosition.y -
                    timeboxerRef?.current?.offsetTop -
                    90 * timeboxListScale +
                    'px',
                  // left:
                  //   mousePosition.x -
                  //   timeboxerRef?.current?.offsetLeft +
                  //   -90 +
                  //   'px',
                  left:
                    mousePosition.x -
                    window.innerWidth +
                    // timeboxerRef?.current?.offsetLeft
                    300 +
                    200 * timeboxListScale +
                    'px',
                  visibility:
                    isMouseDown && mousePosition.x && mousePosition.y
                      ? 'visible'
                      : 'hidden',
                  userSelect: 'none'
                }}
              >
                {displayCursorTimeboxArray.map((displayTimebox) => (
                  <div
                    style={{
                      margin: '3px 0px',

                      // background: 'rgba(255,255,255,0.1)',
                      background:
                        theme?.palette?.mode === 'dark'
                          ? 'rgba(255,255,255,0.1)'
                          : 'rgba(255,255,255,0.9)',
                      padding: '5px',
                      borderRadius: '5px'
                    }}
                  >
                    {/* <div>
                    {Number(
                      displayTimebox?.startDateTime?.getHours()
                    )?.toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                      useGrouping: false
                    }) +
                      ':' +
                      Number(
                        displayTimebox?.startDateTime?.getMinutes()
                      )?.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false
                      }) +
                      ' - ' +
                      Number(
                        displayTimebox?.endDateTime?.getHours()
                      )?.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false
                      }) +
                      ':' +
                      Number(
                        displayTimebox?.endDateTime?.getMinutes()
                      )?.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                        useGrouping: false
                      })}
                  </div> */}
                    <div>
                      {(displayTimebox?.endDateTime -
                        displayTimebox?.startDateTime) /
                        60000}
                      min
                    </div>
                  </div>
                ))}
              </div>

              <TimeboxContextMenu
                mouseDownTimeboxIds={mouseDownTimeboxIds}
                contextMenuAnchorPosition={contextMenuAnchorPosition}
                setContextMenuAnchorPosition={setContextMenuAnchorPosition}
                timeboxIdThatTriggeredContextMenu={
                  timeboxIdThatTriggeredContextMenu
                }
                setTimeboxIdThatTriggeredContextMenu={
                  setTimeboxIdThatTriggeredContextMenu
                }
                timeboxes={timeboxes}
                selectedUser={selectedUser}
              />
              {/* <TimeboxerViewDialog
              open={isTimeboxViewDialogOpen}
              setOpen={setIsTimeboxViewDialogOpen}
              selectedDateStartTimebox={selectedDateStartTimebox}
              selectedDateStopTimebox={selectedDateStopTimebox}
            /> */}
            </div>
          </>
        )
      }
    </>
  );
}

export default TimeboxList;
