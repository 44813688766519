import React from 'react';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import InputIcon from '@mui/icons-material/Input';
import FolderIcon from '@mui/icons-material/Folder';
// import LinkIcon from '@mui/icons-material/Link';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ListIcon from '@mui/icons-material/List';
import PublicIcon from '@mui/icons-material/Public';
import StorageIcon from '@mui/icons-material/Storage';

import DynamicFormIcon from '@mui/icons-material/DynamicForm';

import facebookLogo from './../../../../item-components/body/typeItem/f_logo_RGB-Blue_1024.png';
import googleAdsLogo from './../../../../item-components/body/typeItem/Google-Ads-Logo.png';

import cm360Logo from './../../../../item-components/body/typeItem/logo_campaign_manager_192px.svg';
import dv360Logo from './../../../../item-components/body/typeItem/dv360logo.svg';

import snapchatAdsLogo from './../../../../item-components/body/typeItem/ghostlogo.svg';

import tikTokAdsLogo from './../../../../item-components/body/typeItem/tiktok_logo.svg';
import pinterestAdsLogo from './../../../../item-components/body/typeItem/pinterest_logo.svg';
import readpeakLogo from './../../../../item-components/body/typeItem/readpeak_logo.png';
import strossleIcon from './../../../../item-components/body/typeItem/strossle_icon.png';
import amazonDspLogo from './../../../../item-components/body/typeItem/amazon_logo.png';

import taboolaAdsLogo from './../../../../item-components/body/typeItem/taboolaIcon.png';

import microsoftAdvertisingLogo from './../../../../item-components/body/typeItem/microsoftAdvertisingLogo.png';

import AppleIcon from '@mui/icons-material/Apple';

import adformIcon from './../../../../item-components/body/typeItem/adformicon.png';

import linkedInAdsLogo from './../../../../item-components/body/typeItem/LI-In-Bug.png';

import PowerInputIcon from '@mui/icons-material/PowerInput';

function CardIcon({ itemType }) {
  // ####################################################

  let displayValue = '';

  const itemValue = '';

  // const marginTop = -2;
  const marginBottom = 3;

  const width = 3;

  const iconStyle = {
    // color: 'rgb(150,150,150)',
    width: width + 10 + 'px',
    margin: 0,
    padding: 0,
    height: '15px'
  };

  switch (itemType) {
    case 'account':
      displayValue = <AccountBalanceIcon style={{ ...iconStyle }} />;
      break;
    case 'totalActionsDocument':
      displayValue = <PublicIcon style={{ ...iconStyle }} />;
      break;
    case 'folder':
      displayValue = (
        <FolderIcon
          style={{
            ...iconStyle,
            // marginTop: marginTop + 1 + 'px',
            position: 'relative',
            bottom: '-3px'
          }}
        />
      );
      break;
    case 'manualDataEntry':
      displayValue = (
        <InputIcon
          style={{
            ...iconStyle,
            // marginTop: marginTop + 1 + 'px',
            position: 'relative',
            bottom: '-3px'
          }}
        />
      );
      break;
    case 'customForm':
      displayValue = (
        <DynamicFormIcon
          style={{
            ...iconStyle,
            // marginTop: marginTop + 1 + 'px',
            position: 'relative',
            bottom: '-3px'
          }}
        />
      );
      break;
    case 'facebook_ads_campaign':
      displayValue = (
        <img
          draggable={false}
          // id={item._id}

          src={facebookLogo}
          alt="Logo"
          style={{
            width: width + 9 + 'px',
            // alignSelf: 'baseline',
            margin: '0px 3px',
            // marginTop: marginTop + '2px',
            position: 'relative',
            bottom: '-2px'
            // userSelect: 'none'
          }}
        />
      );
      break;
    case 'facebook_ads_ad_set':
      displayValue = (
        <img
          draggable={false}
          src={facebookLogo}
          alt="Logo"
          style={{
            width: width + 4 + 'px',
            // alignSelf: 'baseline',
            margin: '0px 3px',
            // marginTop: marginTop + '3px',
            position: 'relative',
            bottom: '-2px'
          }}
        />
      );
      break;

    case 'google_ads_campaign_set':
    case 'google_ads_campaign':
    case 'googleAds':
      displayValue = (
        <img
          draggable={false}
          src={googleAdsLogo}
          alt="Logo"
          style={{
            width: width + 8 + 'px',
            // alignSelf: 'baseline',
            margin: '0px 3px',
            // marginTop: marginTop + '2px',
            position: 'relative'
            // bottom: '-1px'
          }}
        />
      );
      break;
    case 'google_ads_ad_group':
      displayValue = (
        <img
          draggable={false}
          src={googleAdsLogo}
          alt="Logo"
          style={{
            width: width + 4 + 'px',
            // alignSelf: 'baseline',
            margin: '0px 3px',
            // marginTop: marginTop + '3px',
            position: 'relative'
            // bottom: '-1px'
          }}
        />
      );
      break;

    case 'cm360_campaign':
      displayValue = (
        <img
          draggable={false}
          src={cm360Logo}
          alt="Logo"
          style={{
            width: width + 10 + 'px',
            // alignSelf: 'baseline',
            margin: '0px 3px',
            // marginTop: marginTop + 2 + 'px',
            position: 'relative',
            bottom: '-1px'
          }}
        />
      );
      break;

    case 'dv360_insertion_order':
      displayValue = (
        <img
          draggable={false}
          src={dv360Logo}
          alt="Logo"
          style={{
            width: width + 10 + 'px',
            // alignSelf: 'baseline',
            margin: '0px 3px',
            // marginTop: marginTop + 2 + 'px',
            position: 'relative',
            bottom: '-1px'
          }}
        />
      );
      break;
    case 'dv360_line_item':
      displayValue = (
        <img
          draggable={false}
          src={dv360Logo}
          alt="Logo"
          style={{
            width: width + 4 + 'px',
            // alignSelf: 'baseline',
            margin: '0px 3px',
            // marginTop: marginTop + 2 + 'px',
            position: 'relative',
            bottom: '-1px'
          }}
        />
      );
      break;

    case 'snapchat_ads_campaign':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            background: 'yellow',
            borderRadius: '4px',
            width: '15px',
            height: '15px',
            border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={snapchatAdsLogo}
            alt="Logo"
            style={{
              width: '15px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'tiktok_ads_campaign':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '15px',
            height: '15px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={tikTokAdsLogo}
            alt="Logo"
            style={{
              width: '15px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'pinterest_ads_campaign':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '15px',
            height: '15px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={pinterestAdsLogo}
            alt="Logo"
            style={{
              width: '15px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;
    case 'readpeak_campaign':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '15px',
            height: '15px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={readpeakLogo}
            alt="Logo"
            style={{
              width: '15px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'strossle_campaign':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '15px',
            height: '15px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={strossleIcon}
            alt="Logo"
            style={{
              width: '15px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'amazon_dsp_order':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '15px',
            height: '15px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={amazonDspLogo}
            alt="Logo"
            style={{
              width: '15px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'taboola_ads_campaign':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '15px',
            height: '15px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={taboolaAdsLogo}
            alt="Logo"
            style={{
              width: '15px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'microsoft_advertising_campaign_set':
    case 'microsoft_advertising_campaign':
    case 'microsoftAdvertising':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px'
            // width: '15px',
            // height: '15px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={microsoftAdvertisingLogo}
            alt="Logo"
            style={{
              // width: '1px',
              height: '12px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'apple_search_ads_campaign_set':
    case 'apple_search_ads_campaign':
    case 'appleSearchAds':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px'
            // width: '15px',
            // height: '15px'
            // border: '1px solid black'
          }}
        >
          <AppleIcon
            draggable={false}
            style={{
              // width: '1px',
              height: '12px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'adform_order':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '15px',
            height: '15px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={adformIcon}
            alt="Logo"
            style={{
              width: '15px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'linkedin_ads_campaign_group':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '15px',
            height: '15px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={linkedInAdsLogo}
            alt="Logo"
            style={{
              width: '15px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'task':
      displayValue = (
        <ListAltIcon
          style={{
            ...iconStyle,
            // marginTop: marginTop + 1 + 'px',
            position: 'relative',
            bottom: '-3px'
          }}
        />
      );
      break;
    case 'subTask':
      displayValue = (
        <ListIcon
          style={{
            ...iconStyle,
            // marginTop: marginTop + 1 + 'px',
            position: 'relative',
            bottom: '-3px'
          }}
        />
      );
      break;

    case 'taxonomy':
      displayValue = (
        <PowerInputIcon
          style={{
            ...iconStyle,
            // marginTop: marginTop + 1 + 'px',
            position: 'relative',
            top: '4px',
            transform: 'rotate(180deg)'
          }}
        />
      );
      break;
    default:
      displayValue = itemValue;
  }

  return (
    <>
      <div
        style={{
          padding: '0px',
          margin: '0px 5px 0px 0px',
          height: '14px'
          // position: 'relative',
          // bottom: '-1px'
          // overflow: 'hidden'
        }}
      >
        {displayValue}
      </div>
    </>
  );
}

export default CardIcon;
