import React, { useState, useContext } from 'react';

// import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import { useQuery } from '@apollo/client';
import { accountInfoQuery } from '../../../../graphql/queries';
import Button from '@mui/material/Button';

import AddButton from './AddButton';
import EditButton from './EditButton';
import RemoveButton from './RemoveButton';
import AddAccountUserRelationModal from './add-account-user-relation-modal/AddAccountUserRelationModal';

// import AccountList from './AccountList';
import UserList from './UserList';
import ManagerAccountList from './ManagerAccountList';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AccountContext } from '../../../../context/AccountContext';
import { AuthContext } from '../../../../context/AuthContext';

import TimeboxAutoTaggingKeyWords from './timebox-auto-tagging-keywords/TimeboxAutoTaggingKeywords';

function Account({
  selectedAccountId,
  stylesStoredInReduxManagerAccountSettings
}) {
  const { currentManagerAccount } = useContext(AccountContext);
  const { user: currentUser } = useContext(AuthContext);
  const [skip, toggleSkip] = useState('false');

  const variables = {
    accountInfosInput: {
      managerAccountId: currentManagerAccount._id,
      selectedAccountId: selectedAccountId
    }
  };

  const { data, refetch: refetchAccountInfo } = useQuery(accountInfoQuery, {
    variables,
    fetchPolicy: 'no-cache',
    skip: !currentManagerAccount?._id || !selectedAccountId
  });

  console.log('Account>data: ', data);

  const name = data && data.accountInfo && data.accountInfo.name;
  const id = data && data.accountInfo && data.accountInfo._id;

  const managerAccounts = data?.accountInfo?.managerAccounts || [];

  const users = data?.accountInfo?.users || [];

  const role = data && data.accountInfo && data.accountInfo.role;

  const email =
    data && data.userInfoAndAccounts && data.userInfoAndAccounts.email;

  const accounts = data?.userInfoAndAccounts?.accounts
    ? data.userInfoAndAccounts.accounts
    : [];
  const selectedUserId =
    data && data.userInfoAndAccounts && data.userInfoAndAccounts._id
      ? data.userInfoAndAccounts._id
      : '';

  accounts?.sort(function (a, b) {
    var nameA = a?.name?.toUpperCase(); // ignore upper and lowercase
    var nameB = b?.name?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  users.sort(function (a, b) {
    var nameA = a?.name?.toUpperCase(); // ignore upper and lowercase
    var nameB = b?.name?.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  return (
    // <div style={{display: 'grid', gridColumnStart: 2, gridColumnEnd: 2, gridTemplateRows: '50px repeat(auto-fill, minmax(46px, 46px))'}}>
    <div
      style={{
        overflow: 'hidden',
        display: 'grid',
        gridColumnStart: 2,
        gridColumnEnd: 2,
        gridTemplateRows: '50px auto'
        // height: 'calc(100vh - 143.78px)'
      }}
    >
      <div
        style={{
          gridRowStart: 1,
          gridRowEnd: 1,
          fontSize: '16px',
          padding: '15px 10px'
        }}
      >
        {/* <SettingsIcon style={{verticalAlign: 'bottom'}} /> Settings */}
      </div>
      <div
        style={{
          display: 'grid',
          gridColumnStart: 1,
          gridColumnEnd: 1,
          gridRowStart: 2,
          gridRowEnd: 3,
          gridTemplateRows: '70px auto',
          margin: '4px',
          borderRadius: '5px',
          boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
        }}
      >
        <div
          style={{
            display: 'grid',
            gridColumnStart: 1,
            gridColumnEnd: 1,
            gridRowStart: 1,
            gridRowEnd: 1,
            gridTemplateColumns: 'auto 100px',
            gridTemplateRows: '50px 20px',
            height: '100%',
            backgroundColor:
              stylesStoredInReduxManagerAccountSettings.cardBackgroundColor,
            borderRadius: '5px 5px 0px 0px'
          }}
        >
          <div
            style={{
              gridRowStart: 1,
              gridRowEnd: 1,
              gridColumnStart: 1,
              gridColumnEnd: 1,
              height: '100%',
              justifyContent: 'flex-start',
              backgroundColor:
                stylesStoredInReduxManagerAccountSettings.cardTopBackgroundColor,
              borderRadius: '5px 0px 0px 0px'
            }}
          >
            <div style={{ padding: '25px 30px', fontSize: '16px' }}>
              <AccountBalanceIcon style={{ verticalAlign: 'bottom' }} />
              <span style={{ marginLeft: '10px' }}>{name}</span>
            </div>
          </div>
          <div
            style={{
              gridRowStart: 2,
              gridRowEnd: 2,
              gridColumnStart: 1,
              gridColumnEnd: 1,
              height: '100%',
              justifyContent: 'flex-start',
              backgroundColor:
                stylesStoredInReduxManagerAccountSettings.cardTopBackgroundColor
            }}
          >
            <div style={{ fontSize: '10px' }}>
              <span style={{ paddingLeft: '55px', marginLeft: '10px' }}>
                Role: {role}
              </span>{' '}
              <span style={{ paddingLeft: '5px', marginLeft: '10px' }}>
                Account Id: {id}
              </span>
            </div>
          </div>
          <div
            style={{
              gridRowStart: 1,
              gridRowEnd: 3,
              gridColumnStart: 2,
              gridColumnEnd: 2,
              height: '100%',
              justifyContent: 'flex-end',
              backgroundColor:
                stylesStoredInReduxManagerAccountSettings.cardTopBackgroundColor,
              paddingTop: '9px',
              borderRadius: '0px 5px 0px 0px'
            }}
          >
            {/* <EditButton /> */}
          </div>
        </div>

        <div style={{ gridRow: 2, display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'grid',
              // gridColumnStart: 1,
              // gridColumnEnd: 1,
              gridRowStart: 2,
              gridRowEnd: 2,
              // gridTemplateRows: '70px repeat(auto-fill, minmax(60px, 60px))',
              height: 'calc(100vh - 213.78px)',
              backgroundColor:
                stylesStoredInReduxManagerAccountSettings.cardBackgroundColor,
              borderRadius: '0px 0px 5px 5px',
              overflowY: 'scroll'
              // width: '100%'
            }}
          >
            <ManagerAccountList
              managerAccounts={managerAccounts}
              // currentManagerAccount={currentManagerAccount}
              selectedAccountId={selectedAccountId}
              refetchAccountInfo={refetchAccountInfo}
              stylesStoredInReduxManagerAccountSettings={
                stylesStoredInReduxManagerAccountSettings
              }
            />
            <UserList
              users={users}
              // currentManagerAccount={currentManagerAccount}
              selectedAccountId={selectedAccountId}
              refetchAccountInfo={refetchAccountInfo}
              stylesStoredInReduxManagerAccountSettings={
                stylesStoredInReduxManagerAccountSettings
              }
            />
            {['system_admin'].includes(currentUser.role) && (
              <TimeboxAutoTaggingKeyWords
                selectedAccountId={selectedAccountId}
                stylesStoredInReduxManagerAccountSettings={
                  stylesStoredInReduxManagerAccountSettings
                }
              />
            )}
            {/* <AccountList users={users} currentManagerAccount={currentManagerAccount} selectedUserId={selectedUserId} refetchUserInfo={refetchUserInfo} /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

// export default Account;

Account.propTypes = {
  // currentManagerAccount: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired,
  stylesStoredInReduxManagerAccountSettings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // user: state.auth.user,
  stylesStoredInReduxManagerAccountSettings: state.style.ManagerAccountSettings
});

export default connect(mapStateToProps, {})(Account);
