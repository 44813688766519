import React, { Fragment, useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { styled, useTheme } from '@mui/material/styles';

// import KanBan from './Kanban';

// icons

import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined';

import PowerInputIcon from '@mui/icons-material/PowerInput';
import TableRowsIcon from '@mui/icons-material/TableRows';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';

import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import { DataContext } from '../../../context/DataContext';
import { FilterContext } from '../../../context/FilterContext';

import { DialogContext } from '../../../context/DialogContext';

import backgroundImagePattern from './../../../img/bg-container-purple.png';

// import backgroundBeachImage from './../../../../img/valentineblueimg.jpg';
import backgroundBeachImage from './../../../img/beachimg.jpg';

import ReportBuilderDialogContent from './ReportBuilderDialogContent';

function PaperComponent(props) {
  const isExpanded = false;
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const ReportBuilderDialog = (
  {
    // open,
    // setOpen,
    // item
    // arrayOfData,
    // rawArrayOfData,
    // checkedBoxes,
    // setCheckedBoxes,
    // names,
    // dataSourceNames
  }
) => {
  const theme = useTheme();
  const { listOfData } = useContext(DataContext);

  const { checkedBoxes, setCheckedBoxes } = useContext(FilterContext);

  const {
    reportBuilderDialogOpen: open,
    setReportBuilderDialogOpen: setOpen,

    itemId,
    setItemId
  } = useContext(DialogContext);

  const [isExpanded, setIsExpanded] = useState(false);

  const [displayView, setDisplayView] = useState('report-builder');
  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCheckedBoxes([]);
    setOpen(false);
  };

  // ##################################################################################
  // ##################################################################################
  // ##################################################################################

  const [item, setItem] = useState({});
  useEffect(() => {
    const filteredItem = listOfData.filter((obj) => obj._id === itemId)[0];
    if (filteredItem) {
      setItem(filteredItem);
    }
  }, [listOfData]);

  // ##################################################################################
  // ##################################################################################
  // ##################################################################################

  // linear-gradient(336deg, rgba(23,132,166,.8), rgba(23,132,166,0) 70.71%)
  // linear-gradient(336deg, rgba(247,247,247,.8), rgba(247,247,247,0) 70.71%)

  let headerText = '';
  let Icon = PowerInputIcon;

  switch (displayView) {
    case 'report-builder':
      headerText = 'Report Builder';
      break;
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullScreen={isExpanded}
        // paperFullWidth={true}
        // paper={{ height: '90hw', width: '1000px' }}
        // maxWidth="lg"
        maxWidth="80%"
      >
        <div
          style={{
            background: `
            linear-gradient(17deg, rgba(54,82,156,.8), rgba(54,82,156,0) 70.71%),
            linear-gradient(336deg, rgba(167,38,131,.8), rgba(167,38,131,0) 70.71%)

            `
            //     background: `
            // linear-gradient(17deg, rgba(54,82,156,.8), rgba(54,82,156,0) 70.71%),
            // linear-gradient(336deg, rgba(167,38,131,.8), rgba(167,38,131,0) 70.71%),
            // linear-gradient(236deg, rgba(23,132,166,.8), rgba(23,132,166,0) 70.71%)

            // `
          }}
        >
          <div
            style={{
              // backgroundImage: `url(${backgroundImagePattern})`,
              // backgroundImage: `url(${backgroundBeachImage})`,
              backgroundImage:
                theme.palette.mode === 'dark'
                  ? `url(${backgroundImagePattern})`
                  : `url(${backgroundBeachImage})`,
              backgroundSize: 'cover',
              backgroundAttachment: 'fixed'
            }}
          >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              <div
                style={{
                  // display: 'grid',
                  // gridTemplateColumns: 'auto 40px 40px 30px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <div
                  style={{
                    display: 'grid',
                    gridColumnStart: 1,
                    gridColumnEnd: 1
                  }}
                >
                  <span style={{ paddingLeft: '7px' }}>
                    <Icon
                      style={{
                        verticalAlign: 'text-bottom',
                        paddingRight: '5px',
                        transform: 'rotate(180deg)'
                      }}
                    />{' '}
                    {headerText}
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '65px'
                  }}
                >
                  <div
                    style={{
                      display: 'grid',
                      gridColumnStart: 2,
                      gridColumnEnd: 2,
                      alignItems: 'end'
                    }}
                  >
                    <div
                      style={{
                        cursor: 'pointer',
                        right: '0px',
                        textAlign: 'right'
                        // paddingRight: '25px'
                      }}
                    >
                      <span
                        style={{ width: '50px' }}
                        onClick={() => setIsExpanded(!isExpanded)}
                      >
                        {isExpanded ? (
                          <FullscreenExitIcon className="hover-light-grey" />
                        ) : (
                          <FullscreenIcon className="hover-light-grey" />
                        )}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'grid',
                      gridColumnStart: 3,
                      gridColumnEnd: 3,
                      alignItems: 'end'
                    }}
                  >
                    <div
                      style={{
                        cursor: 'pointer',
                        right: '0px',
                        textAlign: 'right'
                        // paddingRight: '25px'
                      }}
                    >
                      <span style={{ width: '50px' }} onClick={handleClose}>
                        <CloseIcon className="hover-light-grey" />
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div
              style={{
                display: 'grid',
                gridColumnStart: 1,
                gridColumnEnd: 1,
                gridRowStart: 2,
                gridRowEnd: 2
              }}
            >
              <span style={{ paddingLeft: '50px', fontSize: '12px' }}>
                {item?.path}
              </span>
            </div> */}
              </div>
            </DialogTitle>
            <DialogContent style={{ padding: 0, overflow: 'hidden' }}>
              <ReportBuilderDialogContent item={item} />
              {/* <KanBan
                isExpanded={isExpanded}
                filteredArrayOfData={filteredArrayOfData}
                rawArrayOfData={listOfData}
                checkedBoxes={checkedBoxes}
                setCheckedBoxes={setCheckedBoxes}
                names={names}
                dataSourceNames={dataSourceNames}
                displayView={displayView}
                setDisplayView={setDisplayView}
              /> */}
            </DialogContent>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ReportBuilderDialog;
