import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';

import PeopleIcon from '@mui/icons-material/People';

import TextField from '@mui/material/TextField';

import AddMenu from './invite-user/AddMenu';

import { useLazyQuery } from '@apollo/client';
import { managerAccountUsersQuery } from '../../../../graphql/queries';

import AddUserModal from './invite-user/AddUserModal';

// import AddUserButton from './invite-user/AddUserButton';
import AddUserButton from './invite-user/AddUserButton2';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AuthContext } from '../../../../context/AuthContext';
import { AccountContext } from '../../../../context/AccountContext';

function UserList({
  // currentManagerAccount,
  selectedUser,
  setSelectedUser,
  stylesStoredInReduxManagerAccountSettings
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [filterSearch, setFilterSearch] = useState('');

  const variables = {
    managerAccountUsersInput: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccount._id
      //   accountId
    }
  };

  const [loadUsers, { called, loading, data, refetch }] = useLazyQuery(
    managerAccountUsersQuery,
    {
      variables,
      fetchPolicy: 'no-cache'
    }
  );

  useEffect(() => {
    loadUsers();
  }, []);

  let listOfUsers =
    data && data.managerAccountUsers ? data.managerAccountUsers : [];

  listOfUsers = listOfUsers.filter((obj) => {
    if (
      (obj.name &&
        obj.name.toLowerCase().includes(filterSearch.toLowerCase())) ||
      (obj.email &&
        obj.email.toLowerCase().includes(filterSearch.toLowerCase()))
    ) {
      return true;
    }
  });

  useEffect(() => {
    if (!selectedUser && listOfUsers?.length > 0 && listOfUsers[0]?._id) {
      setSelectedUser(listOfUsers[0]._id);
    }
  }, [selectedUser, listOfUsers]);

  const onClickHandler = (e, id) => {
    setSelectedUser(id);
  };

  function compare(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  listOfUsers.sort(compare);

  let i = 0;
  let userButtons = listOfUsers.map((obj) => {
    i++;
    return (
      <Button
        key={obj._id}
        style={{
          gridRowStart: i,
          gridRowEnd: i,
          height: '60px',
          justifyContent: 'flex-start',
          background:
            selectedUser === obj._id &&
            stylesStoredInReduxManagerAccountSettings.selectedListButton
        }}
        color="primary"
        onClick={(e) => onClickHandler(e, obj._id)}
      >
        <div
          style={{
            display: 'grid',
            color: stylesStoredInReduxManagerAccountSettings.color
          }}
        >
          <div
            style={{
              gridRowStart: 1,
              gridRowEnd: 1,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left'
            }}
          >
            {obj.name}
          </div>
          <div
            style={{
              display: 'grid',
              gridRowStart: 2,
              gridRowEnd: 2,
              fontSize: '10px',
              textAlign: 'left',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left'
            }}
          >
            {obj.email}
          </div>
        </div>
      </Button>
    );
  });

  const onChangeHandler = (e) => {
    setFilterSearch(e.target.value);
  };

  return (
    <div
      style={{
        display: 'grid',
        gridColumnStart: 1,
        gridColumnEnd: 1,
        gridTemplateRows: '50px auto'
      }}
    >
      <div
        style={{
          gridRowStart: 1,
          gridRowEnd: 1,
          height: '100%',
          verticalAlign: 'bottom',
          fontSize: '16px',
          padding: '18px 10px'
        }}
      >
        <PeopleIcon style={{ verticalAlign: 'bottom' }} /> Users
      </div>

      <div
        style={{
          overflow: 'hidden',
          display: 'grid',
          gridRowStart: 2,
          gridRowEnd: 2,
          gridTemplateRows: '70px auto',
          height: 'calc(100vh - 143.78px)',
          backgroundColor:
            stylesStoredInReduxManagerAccountSettings.cardBackgroundColor,
          margin: '4px',
          borderRadius: '5px',
          boxShadow:
            '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
        }}
      >
        <div
          style={{
            gridRowStart: 1,
            gridRowEnd: 1,
            height: '100%',
            justifyContent: 'flex-start',
            backgroundColor:
              stylesStoredInReduxManagerAccountSettings.cardTopBackgroundColor,
            padding: '5px 30px',
            fontSize: '16px'
          }}
        >
          <div style={{ display: 'grid', gridTemplateColumns: '145px 132px' }}>
            <div style={{ gridColumnStart: 1, gridColumnEnd: 1 }}>
              <TextField
                variant="standard"
                style={{
                  display: 'grid',
                  gridRowStart: 1,
                  gridRowEnd: 1,
                  width: '90%'
                }}
                label="Search"
                value={filterSearch}
                onChange={(e) => onChangeHandler(e)}
              />
            </div>
            <div
              style={{
                display: 'grid',
                gridColumnStart: 2,
                gridColumnEnd: 2,
                paddingTop: '15px',
                width: '32px'
              }}
            >
              <AddUserButton setOpen={setOpenAddUserModal} />
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridRowStart: 2,
            gridRowEnd: 2,
            gridTemplateRows: 'repeat(auto-fill, minmax(60px, 60px))',
            padding: '25px 20px',
            overflowY: 'scroll'
          }}
        >
          {userButtons}
        </div>
      </div>
      <AddUserModal
        refetchUserList={refetch}
        open={openAddUserModal}
        setOpen={setOpenAddUserModal}
        currentManagerAccount={currentManagerAccount}
      />
    </div>
  );
}

// export default UserList

UserList.propTypes = {
  // currentManagerAccount: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired,
  stylesStoredInReduxManagerAccountSettings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // user: state.auth.user,
  stylesStoredInReduxManagerAccountSettings: state.style.ManagerAccountSettings
});

export default connect(mapStateToProps, {})(UserList);
