import React from 'react';

import Column from './Column';

function Columns({
  columns,
  filteredArrayOfData,
  isExpanded,
  rawArrayOfData,
  account,
  setAccount,
  hideSelected
}) {
  const renderColumns = columns.map((column) => {
    const filteredColumns = filteredArrayOfData.filter(
      (row) =>
        row.status === column.string &&
        !hideSelected.includes(row.documentType) &&
        !row.template &&
        (row.personal && !hideSelected.includes('personal')
          ? true
          : !row.personal) &&
        ([
          'folder',
          'manualDataEntry',
          'customForm',
          'facebook_ads_campaign',
          'google_ads_campaign',
          'cm360_campaign',
          'dv360_insertion_order',
          'snapchat_ads_campaign',
          'tiktok_ads_campaign',
          'pinterest_ads_campaign',
          'readpeak_campaign',
          'strossle_campaign',
          'amazon_dsp_order',
          'taboola_ads_campaign',
          'microsoft_advertising_campaign',
          'apple_search_ads_campaign',
          'adform_order',
          'linkedin_ads_campaign_group',
          'task',
          'taxonomy'
        ].includes(row.type) ||
          row.isSet)
    );
    return (
      <Column
        isExpanded={isExpanded}
        column={column}
        filteredColumns={filteredColumns}
        rawArrayOfData={rawArrayOfData}
        account={account}
        setAccount={setAccount}
        hideSelected={hideSelected}
      />
    );
  });
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: '10px 20px',
        // overflowX: 'scroll',
        height: isExpanded ? 'calc(100vh - 125px)' : 'calc(90vh - 125px)',
        maxHeight: isExpanded ? 'calc(100vh - 125px)' : 'calc(90vh - 125px)'
      }}
    >
      {renderColumns}
    </div>
  );
}

export default Columns;
