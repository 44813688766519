import React, { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';

import WidgetDescriptionMenu from './WidgetDescriptionMenu';

function TextWidget({
  widget,
  displayMode,
  updateWidget,
  widgetIndex,
  editMode,
  dropOnWidget,
  dragOver,
  dragStart,
  dragEnd,
  isDraggingWidget,
  setIsDraggingWidget
  // widgetType
}) {
  const [isDraggingThisWidget, setIsDraggingThisWidget] = useState(false);
  const [edit, toggleEdit] = useState(false);
  const [isWidgetDescriptionMenuOpen, setIsWidgetDescriptionMenuOpen] =
    useState(false);

  const [textInput, setTextInput] = useState('');

  const [valueIsEdited, setValueIsEdited] = useState(false);

  const [originalWidgetCopy, setOriginalWidgetCopy] = useState({ ...widget });

  useEffect(() => {
    if (textInput) {
      setValueIsEdited(true);
    }
    setTextInput(widget.value);
  }, [widget.value]);
  const onChangeHandler = (e) => {
    setTextInput(e.target.value);
  };

  // const onClickHandler = (e) => {
  //   var isRightMB;
  //   var isLeftMB;
  //   e = e || window.event;

  //   if ('which' in e) {
  //     // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
  //     isRightMB = e.which == 3;
  //     isLeftMB = e.which == 1;
  //   } else if ('button' in e) {
  //     // IE, Opera
  //     isRightMB = e.button == 2;
  //     isLeftMB = e.button == 1;
  //   }
  //   console.log('isRightMB: ', isRightMB);
  // };

  const onSaveHandler = () => {
    updateWidget(
      { ...widget, value: textInput || originalWidgetCopy.value },
      widgetIndex
    );
    // setTextInput('');

    if (!textInput) {
      setValueIsEdited(false);
    }
    toggleEdit(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      setTextInput(widget.value);
      toggleEdit(false);
      return;
    }

    if (event.key === 'Enter') {
      console.log('do validate');
      updateWidget(
        { ...widget, value: textInput || originalWidgetCopy.value },
        widgetIndex
      );
      // setTextInput('');

      if (!textInput) {
        setValueIsEdited(false);
      }

      toggleEdit(false);
      return;
    }
  };

  const numUpper = (textInput?.match(/[A-Z]/g) || []).length;
  const numLarge = (textInput?.match(/[MmWw]/g) || []).length;
  const theRest = textInput?.length - numUpper;
  console.log('numUpper: ', numUpper);
  let cursor = 'pointer';

  switch (true) {
    case isDraggingWidget:
      cursor = 'grabbing';
      break;
    // case !isDraggingWidget:
    //   cursor = 'grab';
    //   break;
    case !editMode && widget.type === 'FREETEXT':
      cursor = 'text';
      break;
    case editMode && widget.type === 'FREETEXT':
      cursor = 'grab';
      break;
    case editMode:
      cursor = 'grab';
      break;
    default:
      cursor = 'not-allowed';
  }

  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
        console.log('on right click');
        setIsWidgetDescriptionMenuOpen(true);
        return false;
      }}
      // onClick={onClickHandler}
      key={widgetIndex}
      onDrop={(e) => dropOnWidget(e, widget, widgetIndex)}
      style={{ paddingLeft: '3px' }}
    >
      <div
        style={{
          padding: '4px',
          // color: 'black',
          background: 'white',
          // border: '1px solid lightgrey',
          display: 'inline-block',
          borderRadius: '5px',
          margin: '0px 0.5px',
          transform: 'translate(0, 0)',
          boxShadow:
            !isDraggingThisWidget && 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
        }}
        onDragOver={(e) => dragOver(e, widget, widgetIndex)}
        draggable={editMode}
        onDragStart={(e) => {
          dragStart(e, widget, 'item_widgets', widgetIndex);
          setIsDraggingThisWidget(true);
        }}
        onDragEnd={(e) => {
          dragEnd(e, widget, widgetIndex);
          setIsDraggingThisWidget(false);
        }}
      >
        <div
          onClick={() => {
            if (editMode || widget.type === 'FREETEXT') {
              toggleEdit(true);

              if (!editMode && !valueIsEdited) {
                setTextInput('');
              }
            }
          }}
          onBlur={(e) => onSaveHandler(e)}
        >
          {/* {displayMode === 'EXAMPLE' ? widget.value : widget.name} */}
          {edit ? (
            <input
              autoFocus
              style={{
                fontFamily: 'Roboto, Raleway, sans-serif',
                lineHeight: '1.5',
                fontSize: '12px',
                letterSpacing: '0.2px',
                color: 'black',
                // width: textInput.length * 1 + 'ch',
                width:
                  numUpper * 0.35 +
                  numLarge * 1 +
                  textInput.length * 0.925 +
                  'ch',
                minWidth: '1ch',
                outline: 'none',
                border: 'none'
                // fontSize: '11.5px'
              }}
              value={textInput}
              onChange={onChangeHandler}
              onKeyDown={handleKeyDown}
            />
          ) : (
            <Tooltip
              title={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                    maxWidth: '200px'
                    // padding: '2px'
                  }}
                >
                  <span style={{ width: '100%', textAlign: 'center' }}>
                    {widget.type === 'FREETEXT'
                      ? '* Free text *'
                      : '* Static text *'}
                  </span>

                  <span
                    style={{
                      maxWidth: '190px',
                      whiteSpace: 'pre-line'
                      // paddingRight: '10px'
                      // padding: '5px'
                    }}
                  >
                    {widget.description}
                  </span>
                </div>
              }
            >
              <span
                style={{
                  cursor,
                  color: 'black'
                  // cursor: editMode
                  //   ? isDraggingWidget
                  //     ? 'grabbing'
                  //     : 'grab'
                  //   : editMode || widget.type === 'FREETEXT'
                  //   ? 'text'
                  //   : 'not-allowed'
                }}
                onMouseDown={() => setIsDraggingWidget(true)}
                onMouseUp={() => setIsDraggingWidget(false)}
              >
                {/* {displayMode === 'EXAMPLE' ? widget.value : widget.name} */}
                {widget.value ? (
                  widget.value
                ) : (
                  <span style={{ visibility: 'hidden' }}>a</span>
                )}
              </span>
            </Tooltip>
          )}

          {isWidgetDescriptionMenuOpen && (
            <WidgetDescriptionMenu
              isWidgetDescriptionMenuOpen={isWidgetDescriptionMenuOpen}
              setIsWidgetDescriptionMenuOpen={setIsWidgetDescriptionMenuOpen}
              // inputRef={inputRef}
              // textInput={textInput}
              // setTextInput={setTextInput}
              widgetIndex={widgetIndex}
              // options={options}
              // setOptions={setOptions}
              editMode={editMode}
              widget={widget}
              updateWidget={updateWidget}
              toggleEdit={toggleEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TextWidget;
