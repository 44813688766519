import React, { useState, useEffect, useContext } from 'react';

import OutOfDateRangeTooltip from './OutOfDateRangeTooltip';

import { useInView } from 'react-intersection-observer';
import { InterfaceContext } from '../../../../context/InterfaceContext';

import { useTheme } from '@emotion/react';

import WarningDotToolTip from './WarningDotToolTip';

function PaceItem({
  item,
  itemKey,
  itemValue,
  rowNumber,
  keyForMap,
  itemStyle,
  checked
}) {
  const theme = useTheme();
  const { exportMode, advancedMode } = useContext(InterfaceContext);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  let displayValue = '';
  if (itemValue === '?') {
    displayValue = <OutOfDateRangeTooltip />;
  }

  // if ((itemValue || item.budgetUntillNow) && itemValue !== '?') {
  //   displayValue = itemValue + '%';
  // }
  // console.log('item.calcDailyBudgetYesterday: ', item.calcDailyBudgetYesterday);

  // console.log(
  //   "itemKey === 'calcPaceYesterday'; ",
  //   itemKey === 'calcPaceYesterday'
  // );
  // console.log('itemKey: ', itemKey);
  // console.log('item._id: ', item._id);
  // if (item._id === '6656f58a6862533237ee698f') {
  //   console.log(
  //     '1typeof itemValue :',
  //     typeof itemValue,
  //     '+ itemValue: ',
  //     itemValue
  //   );
  //   console.log('item.calcPaceYesterday: ', item.calcPaceYesterday);
  // }

  // console.log('itemValue: ', itemValue);

  let backgroundColor = null;

  // console.log('itemValue: ', itemValue);

  switch (true) {
    case !itemValue && typeof itemValue !== 'number':
      break;
    case itemValue < 80:
    case itemValue === 0:
      // backgroundColor = `rgba(0,0,255,${(itemValue - 1) * -1 * 1})`;
      backgroundColor =
        theme.palette.mode === 'dark' ? 'rgb(100,100,255' : 'blue';
      break;

    case itemValue > 120:
      // backgroundColor = `rgba(255,0,0,${(itemValue - 1) * 1})`;
      backgroundColor = 'red';
      break;
  }

  // console.log('backgroundColor: ', backgroundColor);

  switch (true) {
    // case itemKey === 'calcPaceYesterday' && !item.calcDailyBudgetYesterday:
    //   console.log(
    //     '11item.calcDailyBudgetYesterday: ',
    //     item.calcDailyBudgetYesterday
    //   );
    //   break;
    case itemValue === 0:
    case Boolean(itemValue):
    case Boolean(item.budgetUntillNow) && typeof itemValue === 'number':
      displayValue = itemValue + '%';
      break;
    default:
  }
  return (
    <div
      ref={ref}
      key={keyForMap}
      style={{
        maxHeight: '46px',
        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],
        color: itemStyle['color'],
        // backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRight: itemStyle['border'],
        borderBottom: itemStyle['border'],
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        display: 'grid',
        height: '100%',
        width: '100%',
        cursor: 'default'
        // padding: '12px 15px',
        // whiteSpace: 'nowrap'
      }}
    >
      {(inView || exportMode) && (
        <div
          style={{
            // background: backgroundColor,

            // maxHeight: '46px',
            // backgroundColor: checked
            //   ? itemStyle['checkedBackgroundColor']
            //   : itemStyle['backgroundColor'],
            // color: itemStyle['color'],
            // backgroundColor: 'rgba(255, 255, 255, 1)',
            // borderRight: itemStyle['border'],
            // borderBottom: itemStyle['border'],
            // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
            // fontSize: '12px',
            // gridRowStart: rowNumber + 2,
            // gridRowEnd: rowNumber + 2,
            // display: 'grid',
            height: '100%',
            width: '100%',
            padding: '0px 14px 0px 0px',
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div style={{ paddingBottom: '3px' }}>
            {/* <span
              style={{
                display: 'flex',
                backgroundColor,
                height: '3px',
                width: '3px',
                borderRadius: '15px 15px 15px 15px'

                // marginRight: '10px'
              }}
            ></span> */}
            <WarningDotToolTip item={item} itemKey={itemKey} />
          </div>

          <div
            style={{
              // margin: 'auto',
              textAlign: 'right',
              paddingBottom: '3px'
            }}
          >
            <p>{displayValue}</p>
            {/* <p
              style={{
                fontSize: '8px',
                color: '#bcbcbc',
                visibility: 'hidden'
              }}
            >
              {' '}
              test
            </p> */}
          </div>
          <div style={{ width: '3px' }}></div>
        </div>
      )}
    </div>
  );
}

export default PaceItem;
