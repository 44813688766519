import React, { useContext } from 'react';

import TimeboxerButton from '../timeboxer/TimeboxerButton';
import ReportBuilderButton from '../report-builder-2/ReportBuilderButton';
import { AuthContext } from '../../../context/AuthContext';

function BottomRightCornerMenu() {
  const { user } = useContext(AuthContext);
  return (
    <div
      style={{
        // background: 'red',
        width: 'fit-content',
        height: '60px',
        position: 'absolute',
        right: '0px',
        bottom: '0px',
        zIndex: '16000'
      }}
    >
      {['system_admin', 'debug'].includes(user?.role) && (
        <ReportBuilderButton />
      )}
      <TimeboxerButton />
    </div>
  );
}

export default BottomRightCornerMenu;
