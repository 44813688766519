import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import VisibilityIcon from '@mui/icons-material/Visibility';

import Divider from '@mui/material/Divider';

import { InterfaceContext } from '../../../../context/InterfaceContext';
import { StyleContext } from '../../../../context/StyleContext';

import { Tooltip } from '@mui/material';

export default function BasicMenu({ view }) {
  //   const [selected, setSelected] = useState([]);

  const { hideSelected, setHideSelected } = useContext(InterfaceContext);

  const { customStyleSelected, setCustomStyleSelected } =
    useContext(StyleContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (e, value) => {
    let newSelected = [];
    if (hideSelected.includes(value)) {
      const copyOfSelected = [...hideSelected];
      copyOfSelected.splice(copyOfSelected.indexOf(value), 1);
      newSelected = copyOfSelected;
      setHideSelected(newSelected);
    } else {
      newSelected = [...hideSelected, value];
      setHideSelected(newSelected);
    }
    localStorage.setItem('hideSelected', JSON.stringify(newSelected));
  };

  const handleSelectCustomStyle = (e, key, value) => {
    const newCustomStyleSelected = { ...customStyleSelected, [key]: value };
    setCustomStyleSelected(newCustomStyleSelected);
    localStorage.setItem(
      'customStyleSelected',
      JSON.stringify(newCustomStyleSelected)
    );
    console.log('newCustomStyleSelected: ', newCustomStyleSelected);
  };

  return (
    <div>
      <Tooltip title="Display menu">
        <Button
          style={{ minWidth: '0px', width: '40px' }}
          variant="standard"
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <VisibilityIcon />
        </Button>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <Divider>
          <span style={{ fontSize: '12px' }}>Color coding (beta)</span>
        </Divider>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={
            customStyleSelected?.checkBoxLeftBorderColor === 'accountColor'
          }
          onClick={(e) =>
            handleSelectCustomStyle(
              e,
              'checkBoxLeftBorderColor',
              customStyleSelected?.checkBoxLeftBorderColor === 'accountColor'
                ? 'classic'
                : 'accountColor'
            )
          }
        >
          Account color border
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={customStyleSelected?.iconColor === 'barColor'}
          onClick={(e) =>
            handleSelectCustomStyle(
              e,
              'iconColor',
              customStyleSelected?.iconColor === 'barColor'
                ? 'classic'
                : 'barColor'
            )
          }
        >
          Bar color as icon color
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={customStyleSelected?.iconColor === 'accountColor'}
          onClick={(e) =>
            handleSelectCustomStyle(
              e,
              'iconColor',
              customStyleSelected?.iconColor === 'accountColor'
                ? 'classic'
                : 'accountColor'
            )
          }
        >
          Account color as icon color
        </MenuItem>
        <Divider>
          <span style={{ fontSize: '12px' }}>Card Elements</span>
        </Divider>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('accountName')}
          onClick={(e) => handleSelect(e, 'accountName')}
        >
          Account Name
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('path')}
          onClick={(e) => handleSelect(e, 'path')}
        >
          Path
        </MenuItem>
        <Divider>
          <span style={{ fontSize: '12px' }}>Visible Cards/Rows</span>
        </Divider>
        {['gantt'].includes(view) && (
          <MenuItem
            style={{ fontSize: '15px' }}
            selected={!hideSelected.includes('totalActionsDocument')}
            onClick={(e) => handleSelect(e, 'totalActionsDocument')}
          >
            Total Actions Document
          </MenuItem>
        )}
        {['gantt'].includes(view) && (
          <MenuItem
            style={{ fontSize: '15px' }}
            selected={!hideSelected.includes('globalTasks')}
            onClick={(e) => handleSelect(e, 'globalTasks')}
          >
            Global Tasks
          </MenuItem>
        )}
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('templates')}
          onClick={(e) => handleSelect(e, 'templates')}
        >
          Templates
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('task')}
          onClick={(e) => handleSelect(e, 'task')}
        >
          Tasks
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('subTask')}
          onClick={(e) => handleSelect(e, 'subTask')}
        >
          Sub Tasks
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('taxonomy')}
          onClick={(e) => handleSelect(e, 'taxonomy')}
        >
          Taxonomy
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('folder')}
          onClick={(e) => handleSelect(e, 'folder')}
        >
          Folder
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('adform')}
          onClick={(e) => handleSelect(e, 'adform')}
        >
          Adform
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('cm360')}
          onClick={(e) => handleSelect(e, 'cm360')}
        >
          Campaign Manager 360
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('dv360')}
          onClick={(e) => handleSelect(e, 'dv360')}
        >
          Display Video 360
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('facebookAds')}
          onClick={(e) => handleSelect(e, 'facebookAds')}
        >
          Facebook Ads
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('googleAds')}
          onClick={(e) => handleSelect(e, 'googleAds')}
        >
          Google Ads
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('linkedInAds')}
          onClick={(e) => handleSelect(e, 'linkedInAds')}
        >
          LinkedIn Ads
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('pinterestAds')}
          onClick={(e) => handleSelect(e, 'pinterestAds')}
        >
          Pinterest Ads
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('readpeak')}
          onClick={(e) => handleSelect(e, 'readpeak')}
        >
          Readpeak
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('strossle')}
          onClick={(e) => handleSelect(e, 'strossle')}
        >
          Strossle
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('amazonDsp')}
          onClick={(e) => handleSelect(e, 'amazonDsp')}
        >
          Amazon DSP
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('taboolaAds')}
          onClick={(e) => handleSelect(e, 'taboolaAds')}
        >
          Taboola Ads
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('microsoftAdvertising')}
          onClick={(e) => handleSelect(e, 'microsoftAdvertising')}
        >
          Microsoft Advertising
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('appleSearchAds')}
          onClick={(e) => handleSelect(e, 'appleSearchAds')}
        >
          Apple Search Ads
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('snapchatAds')}
          onClick={(e) => handleSelect(e, 'snapchatAds')}
        >
          Snapchat Ads
        </MenuItem>

        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('tikTokAds')}
          onClick={(e) => handleSelect(e, 'tikTokAds')}
        >
          TikTok Ads
        </MenuItem>

        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('manualDataEntry')}
          onClick={(e) => handleSelect(e, 'manualDataEntry')}
        >
          Manual Data Entries
        </MenuItem>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('customForm')}
          onClick={(e) => handleSelect(e, 'customForm')}
        >
          Custom Forms
        </MenuItem>
        <Divider>
          <span style={{ fontSize: '12px' }}>Relevance</span>
        </Divider>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('personal')}
          onClick={(e) => handleSelect(e, 'personal')}
        >
          Personal Cards
        </MenuItem>
        <Divider>
          <span style={{ fontSize: '12px' }}>Status</span>
        </Divider>
        <MenuItem
          style={{ fontSize: '15px' }}
          selected={!hideSelected.includes('STATUS_COMPLETED')}
          onClick={(e) => handleSelect(e, 'STATUS_COMPLETED')}
        >
          Completed
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
      </Menu>
    </div>
  );
}
