import React, { useContext } from 'react';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import InputIcon from '@mui/icons-material/Input';
import FolderIcon from '@mui/icons-material/Folder';
// import LinkIcon from '@mui/icons-material/Link';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ListIcon from '@mui/icons-material/List';
import PublicIcon from '@mui/icons-material/Public';
import StorageIcon from '@mui/icons-material/Storage';
import AssignmentIcon from '@mui/icons-material/Assignment';

import DynamicFormIcon from '@mui/icons-material/DynamicForm';

import facebookLogo from './f_logo_RGB-Blue_1024.png';
import googleAdsLogo from './Google-Ads-Logo.png';

import cm360Logo from './logo_campaign_manager_192px.svg';
import dv360Logo from './dv360logo.svg';

import snapchatAdsLogo from './ghostlogo.svg';
import tikTokAdsLogo from './tiktok_logo.svg';

import pinterestAdsLogo from './pinterest_logo.svg';

import readpeakLogo from './readpeak_logo.png';
import strossleIcon from './strossle_icon.png';

import amazonDspLogo from './amazon_logo.png';

import taboolaAdsLogo from './taboolaIcon.png';

import microsoftAdvertisingLogo from './microsoftAdvertisingLogo.png';

import AppleIcon from '@mui/icons-material/Apple';

import adformIcon from './adformicon.png';

import linkedInAdsLogo from './LI-In-Bug.png';

import ga4Icon from './ga4icon.svg';

import { useMutation } from '@apollo/client';

import { moveDocumentMutation } from './../../../../../graphql/queries';

// import Draggable from './Draggable';

import MenuListTrigger from './MenuListTrigger';

import { styled, useTheme } from '@mui/material/styles';

import { useInView } from 'react-intersection-observer';

import { InterfaceContext } from '../../../../../context/InterfaceContext';

import { StyleContext } from '../../../../../context/StyleContext';

function TypeItem({
  currentManagerAccountId,
  item,
  itemKey,
  itemValue,
  rowNumber,
  keyForMap,
  itemStyle,
  checked,
  open,
  setOpen,
  typeItemAnchorRef,
  setTypeItemAnchorRef,
  typeItemToggledItem,
  setTypeItemToggledItem
}) {
  const { customStyleSelected, setCustomStyleSelected } =
    useContext(StyleContext);

  const { exportMode } = useContext(InterfaceContext);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const [moveDocument, { called }] = useMutation(moveDocumentMutation);

  const theme = useTheme();

  // ################# draggable ########################

  // ####################################################

  let displayValue = '';
  let iconColor = '';

  switch (true) {
    case theme?.palette?.mode === 'dark':
      iconColor = theme?.palette?.icon?.main;
      break;
    case item.type === 'account' &&
      customStyleSelected.iconColor === 'barColor':
      iconColor = item.accountColor;
      break;
    case customStyleSelected.iconColor === 'accountColor':
      iconColor = item.accountColor || theme?.palette?.icon?.main;
      break;
    case customStyleSelected.iconColor === 'barColor':
      iconColor =
        item?.style?.gantt?.bar?.backgroundColor || theme?.palette?.icon?.main;
      break;
    default:
      iconColor = theme?.palette?.icon?.main;
  }
  console.log('theme?.palette?.icon?.main: ', theme?.palette?.icon?.main);

  const iconStyle = {
    color: iconColor
    // color: item.accountColor ? item.accountColor : theme?.palette?.icon?.main
    // color: 'rgb(150,150,150)'
    // color: itemStyle['color']
    // color: 'rgba(54, 82, 156,1)'
    // color: 'rgba(33,40,90, 0.7)'
  };
  // if (theme?.palette?.mode === 'dark') {
  //   iconStyle.color = theme?.palette?.icon?.main;
  // }

  switch (itemValue) {
    // case 'account':
    //   displayValue = <AccountBalanceIcon style={{ ...iconStyle }} />;
    //   break;
    // case 'totalActionsDocument':
    //   displayValue = <PublicIcon style={{ ...iconStyle }} />;
    //   break;
    // case 'folder':
    //   displayValue = (
    //     <FolderIcon
    //       style={{
    //         ...iconStyle
    //         // color: item.accountColor ? item.accountColor : iconStyle.color
    //       }}
    //     />
    //   );
    //   break;
    // case 'manualDataEntry':
    //   displayValue = <InputIcon style={{ ...iconStyle }} />;
    //   break;
    // case 'customForm':
    //   displayValue = <DynamicFormIcon style={{ ...iconStyle }} />;
    //   break;

    case 'Google Analytics 4':
      displayValue = (
        <img
          draggable={false}
          // id={item._id}

          src={ga4Icon}
          alt="Logo"
          style={{
            width: '20px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
            // userSelect: 'none'
          }}
        />
      );
      break;

    case 'Facebook Ads':
      displayValue = (
        <img
          draggable={false}
          // id={item._id}

          src={facebookLogo}
          alt="Logo"
          style={{
            width: '20px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
            // userSelect: 'none'
          }}
        />
      );
      break;

    case 'Google Ads':
      displayValue = (
        <img
          draggable={false}
          src={googleAdsLogo}
          alt="Logo"
          style={{
            width: '20px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'Campaign Manager 360':
      displayValue = (
        <img
          draggable={false}
          src={cm360Logo}
          alt="Logo"
          style={{
            width: '20px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'Display & Video 360':
      displayValue = (
        <img
          draggable={false}
          src={dv360Logo}
          alt="Logo"
          style={{
            width: '20px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'Adform':
      displayValue = (
        <img
          draggable={false}
          src={adformIcon}
          alt="Logo"
          style={{
            width: '20px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'Snapchat Ads':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            background: 'yellow',
            borderRadius: '4px',
            width: '20px',
            height: '20px',
            border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={snapchatAdsLogo}
            alt="Logo"
            style={{
              width: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'TikTok Ads':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '20px',
            height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={tikTokAdsLogo}
            alt="Logo"
            style={{
              width: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'Pinterest Ads':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '20px',
            height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={pinterestAdsLogo}
            alt="Logo"
            style={{
              width: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'Readpeak':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '20px',
            height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={readpeakLogo}
            alt="Logo"
            style={{
              width: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'Strossle':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '20px',
            height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={strossleIcon}
            alt="Logo"
            style={{
              width: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'Amazon DSP':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '20px',
            height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={amazonDspLogo}
            alt="Logo"
            style={{
              width: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'Taboola Ads':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '20px',
            height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={taboolaAdsLogo}
            alt="Logo"
            style={{
              width: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'Microsoft Advertising':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px'
            // width: '20px',
            // height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={microsoftAdvertisingLogo}
            alt="Logo"
            style={{
              // width: '15px',
              height: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'Apple Search Ads':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px'
            // width: '20px',
            // height: '20px'
            // border: '1px solid black'
          }}
        >
          <AppleIcon
            draggable={false}
            style={{
              // width: '15px',
              height: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'LinkedIn Ads':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '20px',
            height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={linkedInAdsLogo}
            alt="Logo"
            style={{
              width: '20px',
              marginLeft: '3px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    default:
      displayValue = itemValue;
  }

  return (
    <div
      ref={ref}
      key={keyForMap}
      style={{
        zIndex: '1000',
        maxHeight: '46px',
        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],

        // color: itemStyle['color'],
        // backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRight: itemStyle['border'],
        borderBottom: itemStyle['border'],
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        position: 'sticky',
        left: '46px',
        // display: 'grid',
        height: '100%',
        width: '100%',
        // paddingTop: '12px',
        whiteSpace: 'nowrap',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center'
      }}
    >
      {/* <div
        style={{
          overflow: 'hidden',
          position: 'absolute',
          left: '0px',
          width: '10px',
          height: '100%'
        }}
      >
        <div
          style={{
            height: '10px',
            width: '10px',
            position: 'absolute',
            top: '-5px',
            left: '-5px',
            backgroundColor: item.accountColor,
            transform: 'rotate(45deg)'
          }}
        ></div>
      </div> */}
      {(inView || exportMode) && (
        <MenuListTrigger
          open={open}
          setOpen={setOpen}
          typeItemAnchorRef={typeItemAnchorRef}
          setTypeItemAnchorRef={setTypeItemAnchorRef}
          typeItemToggledItem={typeItemToggledItem}
          setTypeItemToggledItem={setTypeItemToggledItem}
          item={item}
        >
          <div
            id={item.dataSourceId}
            // onDrop={drop}
            // onDragOver={dragOver}
            // draggable={true}
            // onDragStart={dragStart}
            // onDragEnd={dragEnd}
            // onMouseEnter={() => {
            //   if (document.body.style.cursor !== 'grabbing')
            //     document.body.style.cursor = 'grab';
            // }}
            // onMouseLeave={() => {
            //   if (document.body.style.cursor === 'grab')
            //     document.body.style.cursor = '';
            // }}
            // onMouseDown={() => {
            //   document.body.style.cursor = 'grabbing';
            // }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              // paddingLeft: '11px',
              cursor: [
                'folder',
                'manualDataEntry',
                'customForm',
                'facebook_ads_campaign',
                'google_ads_campaign',
                'cm360_campaign',
                'dv360_insertion_order',
                'snapchat_ads_campaign',
                'tiktok_ads_campaign',
                'pinterest_ads_campaign',
                'readpeak_campaign',
                'strossle_campaign',
                'amazon_dsp_order',
                'taboola_ads_campaign',
                'microsoft_advertising_campaign',
                'apple_search_ads_campaign',
                'adform_order',
                'linkedin_ads_campaign_group'
              ].includes(itemValue)
                ? 'grab'
                : ''
            }}
          >
            {displayValue}
            {item?.template && <div style={{ fontSize: '8px' }}>Template</div>}
            {!item?.template && item?.templateDescendant && (
              <div
                style={{ fontSize: '6px', fontStyle: 'italic', color: 'grey' }}
              >
                Templ. desc.
              </div>
            )}
            {item?.isSet && (
              <div
                style={{ fontSize: '6px', fontStyle: 'italic', color: 'grey' }}
              >
                SET
              </div>
            )}
          </div>
        </MenuListTrigger>
      )}
    </div>
  );
}

export default React.memo(TypeItem);
