import React, { useContext, useState } from 'react';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import KeyboardReturnOutlinedIcon from '@mui/icons-material/KeyboardReturnOutlined';
import InputIcon from '@mui/icons-material/Input';
import FolderIcon from '@mui/icons-material/Folder';
// import LinkIcon from '@mui/icons-material/Link';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ListIcon from '@mui/icons-material/List';
import PublicIcon from '@mui/icons-material/Public';
import StorageIcon from '@mui/icons-material/Storage';
import AssignmentIcon from '@mui/icons-material/Assignment';

import DynamicFormIcon from '@mui/icons-material/DynamicForm';

import facebookLogo from './f_logo_RGB-Blue_1024.png';
import googleAdsLogo from './Google-Ads-Logo.png';

import cm360Logo from './logo_campaign_manager_192px.svg';
import dv360Logo from './dv360logo.svg';

import snapchatAdsLogo from './ghostlogo.svg';
import tikTokAdsLogo from './tiktok_logo.svg';

import pinterestAdsLogo from './pinterest_logo.svg';

import readpeakLogo from './readpeak_logo.png';

import strossleIcon from './strossle_icon.png';

import amazonDspLogo from './amazon_logo.png';

import taboolaAdsLogo from './taboolaIcon.png';

import microsoftAdvertisingLogo from './microsoftAdvertisingLogo.png';

import AppleIcon from '@mui/icons-material/Apple';

import adformIcon from './adformicon.png';

import linkedInAdsLogo from './LI-In-Bug.png';

import PowerInputIcon from '@mui/icons-material/PowerInput';

import { useMutation } from '@apollo/client';

import { moveDocumentMutation } from './../../../../../graphql/queries';

// import Draggable from './Draggable';

// import MenuListTrigger from './MenuListTrigger';

import { styled, useTheme } from '@mui/material/styles';

import { useInView } from 'react-intersection-observer';

import { InterfaceContext } from '../../../../../context/InterfaceContext';

import { StyleContext } from '../../../../../context/StyleContext';

import { MutationContext } from '../../../../../context/MutationContext';

// import Tooltip from '@mui/material/Tooltip';
import Tooltip from '../ganttItem/SimpleTooltip';

function TypeItem({
  currentManagerAccountId,
  item,
  itemKey,
  itemValue,
  rowNumber,
  keyForMap,
  itemStyle,
  checked,
  open,
  setOpen,
  typeItemAnchorRef,
  setTypeItemAnchorRef,
  typeItemToggledItem,
  setTypeItemToggledItem
}) {
  const anchorRef = React.useRef(null);
  const { customStyleSelected, setCustomStyleSelected } =
    useContext(StyleContext);

  const { moveDocument } = useContext(MutationContext);

  const { exportMode } = useContext(InterfaceContext);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });

  const [isDragging, setIsDragging] = useState(false);

  // const [moveDocument, { called }] = useMutation(moveDocumentMutation);

  const theme = useTheme();

  // ################# draggable ########################

  const drop = (e) => {
    e.preventDefault();
    const droppedItemString = e.dataTransfer.getData('droppedItem');
    const droppedItem = JSON.parse(droppedItemString);

    if (!['account', 'folder'].includes(item.documentType)) {
      return;
    }

    const droppedItemElement = document.getElementById(droppedItem.id);

    if (
      item.documentType === 'account' &&
      droppedItem.documentType !== 'folder'
    ) {
      return;
    }

    if (
      ![
        'folder',
        'manualDataEntry',
        'customForm',
        'facebook_ads_campaign',
        'google_ads_campaign',
        'cm360_campaign',
        'dv360_insertion_order',
        'snapchat_ads_campaign',
        'tiktok_ads_campaign',
        'pinterest_ads_campaign',
        'readpeak_campaign',
        'strossle_campaign',
        'amazon_dsp_order',
        'taboola_ads_campaign',
        'microsoft_advertising_campaign',
        'apple_search_ads_campaign',
        'adform_order',
        'linkedin_ads_campaign_group',
        'task',
        'subTask',
        'taxonomy'
      ].includes(droppedItem.type) &&
      !droppedItem.isSet
    ) {
      droppedItemElement.style.display = 'flex';
      console.log('Current item does not support drag and drop.');
      return;
    }

    if (droppedItem.id === item._id) {
      droppedItemElement.style.display = 'flex';
      console.log('same');
      return;
    }
    droppedItemElement.style.display = 'flex';

    // console.log('droppedItemId', droppedItem.id);
    // console.log('droppedItemDocumentType: ', droppedItem.documentType);
    // console.log('landingItemId: ', item._id);
    // console.log('landingItemDocumentType: ', item.documentType);

    // ############## check so that you dont put folder in a child of that same folder

    const containerIds = [];
    for (let num of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]) {
      // console.log('TypeItem>num: ', num);
      // console.log('TypeItem>droppedItem.level: ', droppedItem.level);
      if (
        droppedItem.id &&
        item['level_' + num + '_container_id'] &&
        droppedItem.id === item['level_' + num + '_container_id']
      ) {
        // console.log('TypeItem>droppedItem._id: ', droppedItem._id);
        // console.log(
        //   "TypeItem>item['level_' + num + '_container_id']: ",
        //   item['level_' + num + '_container_id']
        // );

        console.log('TypeItem: cant add into a child');
        return;
      }
    }

    if (containerIds.includes(item._id)) {
      return;
    }

    // e.target.appendChild(card);

    // #####################################################################################

    // console.log('droppedItem: ', droppedItem);
    const oldParentDocumentId =
      droppedItem[`level_${droppedItem.level - 1}_container_id`];

    const input = {
      managerAccountId: currentManagerAccountId,
      oldAccountId: droppedItem.accountId,
      newAccountId: item.accountId,
      oldParentDocumentType: droppedItem.level === 2 ? 'account' : 'folder',
      oldParentDocumentId: oldParentDocumentId,
      newParentDocumentType: item.documentType,
      newParentDocumentId: item._id,
      childDocumentType: droppedItem.documentType,
      childDocumentId: droppedItem.id
    };

    if (
      input.childDocumentId == input.oldParentDocumentId ||
      input.childDocumentId == input.newParentDocumentId ||
      input.newParentDocumentId == input.oldParentDocumentId
    ) {
      console.log('move error');
      return;
    }

    if (
      input.oldParentDocumentType != 'folder' &&
      input.newParentDocumentType == 'account'
    ) {
      console.log('move error');
      return;
    }

    moveDocument({ variables: { input } });

    console.log('droppedItemElement: ', droppedItemElement);
  };

  const dragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragEnd = (e, value) => {
    setIsDragging(false);
    console.log('dragEnd');
    const target = e.target;

    setTimeout(() => {
      target.style.display = 'flex';
    }, 0);
  };

  // ####################################################

  const dragStart = (e) => {
    setIsDragging(true);
    const target = e.target;

    // console.log('target: ', target);
    e.dataTransfer.setData(
      'droppedItem',
      JSON.stringify({
        id: target.id, //item._id,
        name: item.name,
        // id: item._id,
        accountId: item.accountId,
        type: item.type,
        level: item.level,
        documentType: item.documentType,
        isSet: item.isSet,
        level_1_container_id: item.level_1_container_id,
        level_2_container_id: item.level_2_container_id,
        level_3_container_id: item.level_3_container_id,
        level_4_container_id: item.level_4_container_id,
        level_5_container_id: item.level_5_container_id,
        level_6_container_id: item.level_6_container_id,
        level_7_container_id: item.level_7_container_id,
        level_8_container_id: item.level_8_container_id,
        level_9_container_id: item.level_9_container_id,
        level_10_container_id: item.level_10_container_id
      })
    );

    setTimeout(() => {
      target.style.display = 'none';
    }, 0);
  };

  // ####################################################

  let displayValue = '';
  let iconColor = '';

  switch (true) {
    case theme?.palette?.mode === 'dark':
      iconColor = theme?.palette?.icon?.main;
      break;
    case item.type === 'account' &&
      customStyleSelected.iconColor === 'barColor':
      iconColor = item.accountColor;
      break;
    case customStyleSelected.iconColor === 'accountColor':
      iconColor = item.accountColor || theme?.palette?.icon?.main;
      break;
    case customStyleSelected.iconColor === 'barColor':
      iconColor =
        item?.style?.gantt?.bar?.backgroundColor || theme?.palette?.icon?.main;
      break;
    default:
      iconColor = theme?.palette?.icon?.main;
  }
  // console.log('theme?.palette?.icon?.main: ', theme?.palette?.icon?.main);

  const iconStyle = {
    color: iconColor
    // color: item.accountColor ? item.accountColor : theme?.palette?.icon?.main
    // color: 'rgb(150,150,150)'
    // color: itemStyle['color']
    // color: 'rgba(54, 82, 156,1)'
    // color: 'rgba(33,40,90, 0.7)'
  };
  // if (theme?.palette?.mode === 'dark') {
  //   iconStyle.color = theme?.palette?.icon?.main;
  // }

  switch (itemValue) {
    case 'account':
      displayValue = <AccountBalanceIcon style={{ ...iconStyle }} />;
      break;
    case 'totalActionsDocument':
      displayValue = <PublicIcon style={{ ...iconStyle }} />;
      break;
    case 'folder':
      displayValue = (
        <FolderIcon
          style={{
            ...iconStyle
            // color: item.accountColor ? item.accountColor : iconStyle.color
          }}
        />
      );
      break;
    case 'manualDataEntry':
      displayValue = <InputIcon style={{ ...iconStyle }} />;
      break;
    case 'customForm':
      displayValue = <DynamicFormIcon style={{ ...iconStyle }} />;
      break;
    case 'facebook_ads_campaign':
      displayValue = (
        <img
          draggable={false}
          // id={item._id}

          src={facebookLogo}
          alt="Logo"
          style={{
            width: '20px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
            // userSelect: 'none'
          }}
        />
      );
      break;
    case 'facebook_ads_ad_set':
      displayValue = (
        <img
          draggable={false}
          src={facebookLogo}
          alt="Logo"
          style={{
            width: '14px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '13px'
          }}
        />
      );
      break;

    case 'google_ads_campaign_set':
    case 'google_ads_campaign':
    case 'googleAds':
      displayValue = (
        <img
          draggable={false}
          src={googleAdsLogo}
          alt="Logo"
          style={{
            width: '20px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;
    case 'google_ads_ad_group':
      displayValue = (
        <img
          draggable={false}
          src={googleAdsLogo}
          alt="Logo"
          style={{
            width: '14px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '13px'
          }}
        />
      );
      break;

    case 'cm360_campaign':
      displayValue = (
        <img
          draggable={false}
          src={cm360Logo}
          alt="Logo"
          style={{
            width: '22px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'dv360_insertion_order':
      displayValue = (
        <img
          draggable={false}
          src={dv360Logo}
          alt="Logo"
          style={{
            width: '19px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'dv360_line_item':
      displayValue = (
        <img
          draggable={false}
          src={dv360Logo}
          alt="Logo"
          style={{
            width: '14px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'adform_order':
      displayValue = (
        <img
          draggable={false}
          src={adformIcon}
          alt="Logo"
          style={{
            width: '20px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'adform_line_item':
      displayValue = (
        <img
          draggable={false}
          src={adformIcon}
          alt="Logo"
          style={{
            width: '14px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'snapchat_ads_campaign':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            background: 'yellow',
            borderRadius: '4px',
            width: '20px',
            height: '20px',
            border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={snapchatAdsLogo}
            alt="Logo"
            style={{
              width: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'snapchat_ads_ad_squad':
      displayValue = (
        <img
          draggable={false}
          src={snapchatAdsLogo}
          alt="Logo"
          style={{
            width: '14px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'tiktok_ads_campaign':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '20px',
            height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={tikTokAdsLogo}
            alt="Logo"
            style={{
              width: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'tiktok_ads_ad_group':
      displayValue = (
        <img
          draggable={false}
          src={tikTokAdsLogo}
          alt="Logo"
          style={{
            width: '14px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'pinterest_ads_campaign':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '20px',
            height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={pinterestAdsLogo}
            alt="Logo"
            style={{
              width: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'pinterest_ads_ad_group':
      displayValue = (
        <img
          draggable={false}
          src={pinterestAdsLogo}
          alt="Logo"
          style={{
            width: '14px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'readpeak_campaign':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '20px',
            height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={readpeakLogo}
            alt="Logo"
            style={{
              width: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'readpeak_ad_group':
      displayValue = (
        <img
          draggable={false}
          src={readpeakLogo}
          alt="Logo"
          style={{
            width: '14px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'strossle_campaign':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '20px',
            height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={strossleIcon}
            alt="Logo"
            style={{
              width: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'strossle_ad_set':
      displayValue = (
        <img
          draggable={false}
          src={strossleIcon}
          alt="Logo"
          style={{
            width: '14px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'amazon_dsp_order':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '20px',
            height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={amazonDspLogo}
            alt="Logo"
            style={{
              width: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'amazon_dsp_line_item':
      displayValue = (
        <img
          draggable={false}
          src={amazonDspLogo}
          alt="Logo"
          style={{
            width: '14px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;

    case 'taboola_ads_campaign':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px',
            width: '20px',
            height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={taboolaAdsLogo}
            alt="Logo"
            style={{
              width: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'microsoft_advertising_campaign_set':
    case 'microsoft_advertising_campaign':
    case 'microsoftAdvertising':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px'
            // width: '20px',
            // height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={microsoftAdvertisingLogo}
            alt="Logo"
            style={{
              // width: '15px',
              height: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'apple_search_ads_campaign_set':
    case 'apple_search_ads_campaign':
    case 'appleSearchAds':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px'
            // width: '20px',
            // height: '20px'
            // border: '1px solid black'
          }}
        >
          <AppleIcon
            draggable={false}
            style={{
              // width: '15px',
              height: '20px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'linkedin_ads_campaign_group':
      displayValue = (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: ' center',
            padding: '1px',
            // background: 'yellow',
            borderRadius: '4px'
            // width: '20px',
            // height: '20px'
            // border: '1px solid black'
          }}
        >
          <img
            draggable={false}
            src={linkedInAdsLogo}
            alt="Logo"
            style={{
              width: '23px',
              marginLeft: '3px'
              // alignSelf: 'baseline',
              // margin: '0px 3px',
              // marginTop: '12px'
            }}
          />
        </div>
      );
      break;

    case 'linkedin_ads_campaign':
      displayValue = (
        <img
          draggable={false}
          src={linkedInAdsLogo}
          alt="Logo"
          style={{
            width: '14px',
            marginLeft: '2px'
            // alignSelf: 'baseline',
            // margin: '0px 3px',
            // marginTop: '12px'
          }}
        />
      );
      break;
    case 'globalTasks':
      displayValue = <AssignmentIcon style={{ ...iconStyle }} />;
      break;

    case 'task':
      displayValue = <ListAltIcon style={{ ...iconStyle }} />;
      break;
    case 'subTask':
      displayValue = <ListIcon style={{ ...iconStyle }} />;
      break;

    case 'taxonomy':
      displayValue = (
        <PowerInputIcon style={{ ...iconStyle, transform: 'rotate(180deg)' }} />
      );
      break;
    default:
      displayValue = itemValue;
  }

  return (
    <div
      ref={ref}
      key={keyForMap}
      style={{
        zIndex: '1000',
        maxHeight: '46px',
        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],

        // color: itemStyle['color'],
        // backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRight: itemStyle['border'],
        borderBottom: itemStyle['border'],
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        position: 'sticky',
        left: '46px',
        // display: 'grid',
        height: '100%',
        width: '100%',
        // paddingTop: '12px',
        whiteSpace: 'nowrap',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center'
      }}
    >
      {/* <div
        style={{ height: '100%', width: '100%' }}
        ref={anchorRef}
        onContextMenu={(e) => {
          // colHandlerOnRelease();
          if (!open) {
            setTypeItemToggledItem(item);
            setTypeItemAnchorRef(anchorRef);
          }
          setOpen((prevOpen) => !prevOpen);
          e.preventDefault();
          return false;
        }}
      > */}
      {/* <div
        style={{
          overflow: 'hidden',
          position: 'absolute',
          left: '0px',
          width: '10px',
          height: '100%'
        }}
      >
        <div
          style={{
            height: '10px',
            width: '10px',
            position: 'absolute',
            top: '-5px',
            left: '-5px',
            backgroundColor: item.accountColor,
            transform: 'rotate(45deg)'
          }}
        ></div>
      </div> */}
      {(inView || exportMode) && (
        // <MenuListTrigger
        //   open={open}
        //   setOpen={setOpen}
        //   typeItemAnchorRef={typeItemAnchorRef}
        //   setTypeItemAnchorRef={setTypeItemAnchorRef}
        //   typeItemToggledItem={typeItemToggledItem}
        //   setTypeItemToggledItem={setTypeItemToggledItem}
        //   item={item}
        // >
        <Tooltip
          item={item}
          hideTooltip={Boolean(typeItemAnchorRef)}
          // followCursor
          // PopperProps={{
          //   style: {
          //     visibility: isDragging ? 'hidden' : 'visible'
          //   }
          // }}
          // title={item.type}
          // placement="right"
          // arrow
          // enterDelay={200}
          // leaveDelay={0}
        >
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            ref={anchorRef}
            onContextMenu={(e) => {
              // colHandlerOnRelease();
              if (!open) {
                setTypeItemToggledItem(item);
                setTypeItemAnchorRef(anchorRef);
              }
              setOpen((prevOpen) => !prevOpen);
              e.preventDefault();
              return false;
            }}
          >
            <div
              id={item._id}
              onDrop={drop}
              onDragOver={dragOver}
              draggable={true}
              onDragStart={dragStart}
              onDragEnd={dragEnd}
              // onMouseEnter={() => {
              //   if (document.body.style.cursor !== 'grabbing')
              //     document.body.style.cursor = 'grab';
              // }}
              // onMouseLeave={() => {
              //   if (document.body.style.cursor === 'grab')
              //     document.body.style.cursor = '';
              // }}
              // onMouseDown={() => {
              //   document.body.style.cursor = 'grabbing';
              // }}
              style={{
                transform: 'translate(0, 0)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
                // paddingLeft: '11px',
                cursor:
                  [
                    'folder',
                    'manualDataEntry',
                    'customForm',
                    'facebook_ads_campaign',
                    'google_ads_campaign',
                    'cm360_campaign',
                    'dv360_insertion_order',
                    'snapchat_ads_campaign',
                    'tiktok_ads_campaign',
                    'pinterest_ads_campaign',
                    'readpeak_campaign',
                    'strossle_campaign',
                    'amazon_dsp_order',
                    'taboola_ads_campaign',
                    'microsoft_advertising_campaign',
                    'apple_search_ads_campaign',
                    'adform_order',
                    'linkedin_ads_campaign_group',
                    'task',
                    'taxonomy'
                  ].includes(itemValue) || item.isSet
                    ? 'grab'
                    : ''
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                  paddingTop: (item?.isSet || item?.isSetChild) && '5px'
                }}
              >
                {displayValue}
                {item?.template && (
                  <div style={{ fontSize: '8px' }}>Template</div>
                )}
                {!item?.template && item?.templateDescendant && (
                  <div
                    style={{
                      fontSize: '6px',
                      fontStyle: 'italic',
                      color: 'grey'
                    }}
                  >
                    Templ. desc.
                  </div>
                )}
                {item?.isSet && (
                  <div
                    style={{
                      fontSize: '6px',
                      fontStyle: 'italic',
                      color: 'grey'
                      // position: 'absolute'
                    }}
                  >
                    SET
                  </div>
                )}
                {item?.isSetChild && (
                  <div
                    style={{
                      fontSize: '6px',
                      fontStyle: 'italic',
                      color: 'grey'
                      // position: 'absolute'
                    }}
                  >
                    CHILD
                  </div>
                )}
              </div>
              {typeItemToggledItem?._id === item?._id && (
                <div
                  style={{
                    // position: 'absolute',
                    // border: 'solid black',
                    // borderWidth: '0 3px 3px 0',
                    // display: 'inline-block',
                    // padding: '3px',
                    // transform: 'rotate(135deg)',
                    // right: '0px'
                    width: '0',
                    height: '0',
                    borderTop: '7px solid transparent',
                    borderBottom: '7px solid transparent',

                    borderRight: '7px solid #a72683',
                    position: 'absolute',
                    right: '0px'
                  }}
                ></div>
              )}
            </div>
            {/* // </MenuListTrigger> */}
          </div>
        </Tooltip>
      )}
    </div>
  );
}

export default React.memo(TypeItem);
