import formatDate from './formatDate';

import evaluateFormula from './evaluateFormula2';

// calc sparkline/graph data

const calcFields2 = (
  propDataToCalculate,
  calculatedPropList,
  compiledExpressions,
  formulaFields,
  calculatedFields,
  fieldsNotDoneCalculating,
  generateScope,
  rowObj,
  fieldsToBeManuallyCalculated,
  calcRounds,
  warnings
) => {
  calcRounds = calcRounds - 1;

  const newObj = {};

  for (let formulaField of formulaFields) {
    if (fieldsToBeManuallyCalculated?.includes(formulaField)) {
      fieldsNotDoneCalculating.splice(
        fieldsNotDoneCalculating.indexOf(formulaField),
        1
      );
      continue;
    }
    if (!fieldsNotDoneCalculating.includes(formulaField)) {
      continue;
    }
    const copyOfFormulaFields = [...formulaFields];
    const fieldIndex = copyOfFormulaFields.indexOf(formulaField);
    copyOfFormulaFields.splice(fieldIndex, 1);

    const filteredCopyOfFormulaFields = copyOfFormulaFields.filter((field) => {
      return (
        rowObj[formulaField + 'Formula']?.includes(field) &&
        fieldsNotDoneCalculating.includes(field)
      );
    });

    if (filteredCopyOfFormulaFields.length > 0) {
      continue;
    }

    // console.log('mon1tor>formulaField: ', formulaField);

    // console.log(
    //   'compiledExpressions[formulaField].evaluate: ',
    //   compiledExpressions[formulaField].evaluate(
    //     generateScope(formulaField, calculatedFields)
    //   )
    // );

    if (!compiledExpressions[formulaField]) {
      // for (let row of rowObj.total_list_of_propagation_data) {
      //   row[formulaField] = compiledExpressions[formulaField].evaluate(
      //     generateScope(formulaField, row)
      //   );
      //   // evaluateFormula(
      //   //   compiledExpressions[formulaField].evaluate,
      //   //   rowObj[formulaField + 'Formula'],
      //   //   generateScope(formulaField, calculatedFields)
      //   // );
      // }

      const formula = rowObj[formulaField + 'Formula'];
      // console.log('hejhopp123: ', formula);
      if (!formula) {
        for (let row of propDataToCalculate) {
          row[formulaField] = 0;
        }
      }
    } else {
      for (let row of propDataToCalculate) {
        try {
          const evalRes = compiledExpressions[formulaField].evaluate(
            generateScope(formulaField, row)
          );
          row[formulaField] = evalRes !== Infinity ? evalRes : 0;
        } catch (error) {
          row[formulaField] = 0;
        }

        // evaluateFormula(
        //   compiledExpressions[formulaField].evaluate,
        //   rowObj[formulaField + 'Formula'],
        //   generateScope(formulaField, calculatedFields)
        // );
      }
    }

    // for (let row of rowObj.total_list_of_propagation_data) {
    //   row[formulaField] = compiledExpressions[formulaField].evaluate(
    //     generateScope(formulaField, row)
    //   );

    //   // evaluateFormula(
    //   //   compiledExpressions[formulaField].evaluate,
    //   //   rowObj[formulaField + 'Formula'],
    //   //   generateScope(formulaField, calculatedFields)
    //   // );
    // }
    // calculatedFields[formulaField] = evaluateFormula(
    //   compiledExpressions[formulaField],
    //   rowObj[formulaField + 'Formula'],
    //   generateScope(formulaField, calculatedFields)
    // );

    fieldsNotDoneCalculating.splice(
      fieldsNotDoneCalculating.indexOf(formulaField),
      1
    );
  }
  // console.log('calcRounds: ', calcRounds);

  if (calcRounds === 0) {
    warnings.stackOverFlow = true;
  }

  if (fieldsNotDoneCalculating.length !== 0 && calcRounds > 0) {
    calcFields2(
      propDataToCalculate,
      calculatedPropList,
      compiledExpressions,
      formulaFields,
      calculatedFields,
      fieldsNotDoneCalculating,
      generateScope,
      rowObj,
      fieldsToBeManuallyCalculated,
      calcRounds,
      warnings
    );
    //   calcRounds = calcRounds - 1;
  }
};

const calcFields = (
  propDataToCalculate,
  calculatedPropList,
  compiledExpressions,
  formulaFields,
  calculatedFields,
  fieldsNotDoneCalculating,
  generateScope,
  rowObj,
  fieldsToBeManuallyCalculated,
  warnings
) => {
  let calcRounds = 10;
  //   let warnForStackOverFlow = false;
  //   const warnings = { stackOverFlow: false };

  calcFields2(
    propDataToCalculate,
    calculatedPropList,
    compiledExpressions,
    formulaFields,
    calculatedFields,
    fieldsNotDoneCalculating,
    generateScope,
    rowObj,
    fieldsToBeManuallyCalculated,
    calcRounds,
    warnings
  );

  if (warnings.stackOverFlow) {
    console.log('warning stack overflow');
  }
};

export default calcFields;
