import React from 'react';

import Fab from '@mui/material/Fab';

import xandrLogo from './xandr_logo.png';

function TermsAndConditions({ setShowScreen }) {
  return (
    <div>
      <form noValidate autoComplete="off">
        <div style={{ display: 'grid', gridTemplateRows: 'Auto 60px' }}>
          <div
            style={{
              display: 'grid',
              gridRowStart: 1,
              gridRowEnd: 1,
              gridColumnStart: 1,
              gridColumnEnd: 1,
              justifyContent: 'left',
              marginTop: '20px'
            }}
          >
            <p>
              <strong>
                By logging into Xandr, with the button below, you let NexusTable
                download your ad performance data and store it in our database.
              </strong>
            </p>
            <br />
            <p>
              When you click the button "Login with Xandr" you will be
              redirected to Xandr where you will be able to login and accept
              that NexusTable get access to your ads data.
            </p>
            <br />
            <p>
              Once you accept the connection you will be redirected back to
              NexusTable where you will be able to choose which Xandr accounts
              you wish us to add as a connection. The accounts' performance data
              will be downloaded frequently and stored in our database.
            </p>
          </div>

          <div
            style={{
              display: 'grid',
              gridRowStart: 2,
              gridRowEnd: 2,
              gridColumnStart: 1,
              gridColumnEnd: 1,
              justifyContent: 'center',
              marginTop: '70px'
            }}
          >
            <div
            // className={classes.root}
            >
              <Fab
                className="text-transform-unset"
                // ref={anchorRef}
                // aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                aria-label="add"
                style={{
                  borderRadius: '5px',
                  height: '60px',
                  minHeight: '60px',
                  width: '250px',
                  minWidth: '250px',
                  // backgroundColor: true
                  //     ? 'rgba(0, 181, 8, 0.9)'
                  //     : 'rgba(0, 181, 8, 0.3)',
                  color: 'black',
                  // fontSize: '14px',
                  fontWeight: '800'
                }}
                onClick={(e) => setShowScreen('tokens')}
              >
                <img
                  src={xandrLogo}
                  alt="Logo"
                  style={{
                    width: '30px',
                    // alignSelf: 'baseline',
                    margin: '0px 5px'
                  }}
                />

                <span style={{ width: '170px', fontSize: '16px' }}>
                  Proceed
                </span>
                <span style={{ width: '20px' }}></span>
              </Fab>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default TermsAndConditions;
