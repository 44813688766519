import React, { useState, useEffect, useContext } from 'react';
// import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';

import { useSnackbar } from 'notistack';

import { useInView } from 'react-intersection-observer';

import { InterfaceContext } from '../../../../context/InterfaceContext';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(0),
//       width: '100%'
//     }
//   }
// }));

function FloatItem({
  currentManagerAccountId,
  updateDocument,
  itemStyle,
  keyForMap,
  item,
  itemKey,
  itemValue,
  rowNumber,
  showItemsHandler,
  openRows,
  checked,
  float,
  int
}) {
  const { exportMode } = useContext(InterfaceContext);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0
  });
  const { enqueueSnackbar } = useSnackbar();

  // ################### update #######################

  // const classes = useStyles();
  const [showTextField, toggleShowTextField] = useState(false);
  const onClickHandler = () => {
    toggleShowTextField(true);
  };

  let subValue = '';
  let dataSource = '';

  switch (itemKey) {
    case 'action01Count':
    case 'action01Cvr':
    case 'action01Cpa':
    case 'action01Value':
    case 'action01Roas':
    case 'action01Vpa':
      subValue = item?.action01?.name || '';
      dataSource = item?.action01?.dataSource;
      break;

    case 'totalAction01Count':
    case 'totalaction01Cvr':
    case 'totalAction01Cpa':
    case 'totalAction01Value':
    case 'totalAction01Roas':
    case 'totalAction01Vpa':
      subValue = item?.totalAction01?.name || '';
      // dataSource = item?.action01?.dataSource;
      break;

    case 'action02Count':
    case 'action02Cvr':
    case 'action02Cpa':
    case 'action02Value':
    case 'action02Roas':
    case 'action02Vpa':
      subValue = item?.action02?.name || '';
      dataSource = item?.action02?.dataSource;
      break;

    case 'totalAction02Count':
    case 'totalaction02Cvr':
    case 'totalAction02Cpa':
    case 'totalAction02Value':
    case 'totalAction02Roas':
    case 'totalAction02Vpa':
      subValue = item?.totalAction02?.name || '';
      // dataSource = item?.action02?.dataSource;
      break;

    case 'action03Count':
    case 'action03Cvr':
    case 'action03Cpa':
    case 'action03Value':
    case 'action03Roas':
    case 'action03Vpa':
      subValue = item?.action03?.name || '';
      dataSource = item?.action03?.dataSource;
      break;

    case 'totalAction03Count':
    case 'totalaction03Cvr':
    case 'totalAction03Cpa':
    case 'totalAction03Value':
    case 'totalAction03Roas':
    case 'totalAction03Vpa':
      subValue = item?.totalAction03?.name || '';
      // dataSource = item?.action03?.dataSource;
      break;

    case 'total_budget':
      if (
        item.sum_child_budgets &&
        item.sum_child_budgets !== 0 &&
        item.sum_child_budgets !== undefined
      ) {
        subValue =
          'sum. child.' + parseInt(item.sum_child_budgets).toLocaleString();
      }
      break;
    default:
      subValue = '';
      break;
  }

  const isManualDataEntry =
    (['manualDataEntry'].includes(item.type) &&
      [
        'budget',
        'cost',
        'clicks',
        'impressions',
        'viewableImpressions',
        'measurableImpressions',
        'eligibleImpressions'
      ].includes(itemKey)) ||
    (dataSource === 'manualDataEntry' &&
      [
        'action01Count',
        'action02Count',
        'action03Count',
        'action01Value',
        'action02Value',
        'action03Value'
      ].includes(itemKey));

  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    if (isManualDataEntry) {
      setInputValue(
        item['MDE' + itemKey[0].toUpperCase() + itemKey.substring(1)]
      );
    } else {
      setInputValue(item[itemKey]);
    }
  }, [showTextField, toggleShowTextField]);

  let valueAsFloatOrNull = null;

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (typeof inputValue === 'string' && inputValue) {
      const regExBudget = new RegExp('^[0-9 ,.-]*$');
      const regExBudgetResult = regExBudget.exec(inputValue);
      if (!regExBudgetResult) {
        enqueueSnackbar('Invalid chacters. Only 0-9 are allowed.', {
          variant: 'error'
        });
      }
      try {
        valueAsFloatOrNull = Number(inputValue);

        if (isNaN(valueAsFloatOrNull)) {
          return '';
        }
      } catch (error) {
        enqueueSnackbar('Error', {
          variant: 'error'
        });
        return '';
      }
    }

    if (inputValue < 0 && ['budget'].includes(itemKey)) {
      enqueueSnackbar(
        "Budget can't be less than 0" + inputValue.length + '/100 used',
        {
          variant: 'error'
        }
      );

      return console.log('name too short.');
    }

    if (inputValue === item[itemKey]) {
      toggleShowTextField(false);
      return '';
    }

    const updateItemKey =
      item.type === 'manualDataEntry'
        ? 'MDE' + itemKey[0].toUpperCase() + itemKey.substring(1)
        : itemKey;

    const documentInput = {
      accountId: item.accountId,
      documentId: item._id,
      document: { [itemKey]: valueAsFloatOrNull },
      updateKey: itemKey,
      action: 'UPDATE_DOCUMENT',
      managerAccountId: currentManagerAccountId,
      documentType: item.documentType,
      level: item.level
    };

    updateDocument({ variables: { input: { ...documentInput } } });
    toggleShowTextField(false);
  };
  const onBlurHandler = (e) => {
    // e.preventDefault();
    toggleShowTextField(false);
  };
  const onKeyDownHandler = (e) => {
    if (e.key === 'Escape') {
      toggleShowTextField(false);
    }
  };

  const onChangeHandler = (e) => {
    setInputValue(e.target.value);
  };

  const textField = (
    <form
      // style={{ padding: '0px 7px' }}
      onSubmit={onSubmitHandler}
      onBlur={onSubmitHandler}
      // className={classes.root}
      noValidate
      autoComplete="off"
    >
      <TextField
        variant="standard"
        autoFocus
        id="standard-basic"
        value={inputValue}
        inputProps={{
          min: 0,
          style: { textAlign: 'right', padding: '0px', fontSize: '12px' }
        }}
        onBlur={onBlurHandler}
        onKeyDown={onKeyDownHandler}
        onChange={onChangeHandler}
      />
      {/* <TextField id="filled-basic" label="Filled" variant="filled" />
    <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
    </form>
  );

  let displayItemValue = null;
  let displayItemValueColor = null;

  if (
    itemValue === null ||
    itemValue === undefined ||
    itemValue === '' ||
    itemValue == '0'
  ) {
    displayItemValue = '-';
    displayItemValueColor = 'grey';
  } else if (isNaN(itemValue)) {
    displayItemValue = itemValue;
  } else {
    displayItemValue = (
      Math.round((itemValue + Number.EPSILON) * 100) / 100
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  // if (itemValue) {
  //   if (!int) {
  //     switch (itemKey) {
  //       // case 'action_01_total_cost_per_action':
  //       // case 'action_02_total_cost_per_action':
  //       // case 'action_03_total_cost_per_action':

  //       // case 'action_01_total_roas':
  //       // case 'action_02_total_roas':
  //       // case 'action_03_total_roas':
  //       //   mainValue = parseFloat(itemValue).toLocaleString();
  //       //   break;
  //       default:
  //         mainValue = parseFloat(itemValue).toLocaleString(undefined, {
  //           minimumFractionDigits: 2,
  //           maximumFractionDigits: 2
  //         });
  //     }
  //   } else {
  //     switch (itemKey) {
  //       // case 'action_01_total_cost_per_action':
  //       // case 'action_02_total_cost_per_action':
  //       // case 'action_03_total_cost_per_action':
  //       // case 'action_01_total_roas':
  //       // case 'action_02_total_roas':
  //       // case 'action_03_total_roas':
  //       //   mainValue = parseInt(itemValue).toLocaleString();
  //       //   break;
  //       default:
  //         mainValue = parseInt(itemValue.toFixed(0)).toLocaleString(undefined, {
  //           minimumFractionDigits: 2,
  //           maximumFractionDigits: 2
  //         });
  //     }
  //   }
  // }

  // ##################################################

  let spanStyle = {
    margin: 'auto',
    color: displayItemValueColor
  };
  if (true) {
    spanStyle = { textAlign: 'right' };
  }

  if (isManualDataEntry) {
    return (
      <div
        ref={ref}
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          borderStyle: 'solid',
          borderWidth: '0px 1px 1px 0px',
          borderColor: itemStyle['borderColor'],
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          left: '53px',
          display: 'grid',
          height: '100%',
          width: '100%',
          // padding: '10px 7px',
          padding: '9px 15px',
          // padding: '9px 7px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}
      >
        {(inView || exportMode) && (
          <span style={{ textAlign: 'right' }}>
            {showTextField ? (
              textField
            ) : (
              <p style={{ color: displayItemValueColor }}>
                <i
                  className="fas fa-pen"
                  style={{
                    fontSize: '8px',
                    // color: 'grey',
                    padding: '4px 4px 4px 2px',
                    cursor: 'pointer'
                  }}
                  onClick={onClickHandler}
                ></i>{' '}
                {displayItemValue}{' '}
              </p>
            )}
            <p
              style={{
                fontSize: '9px',
                color: '#bcbcbc'
                // visibility: 'hidden'
              }}
            >
              {' '}
              {showTextField ? 'total' : subValue}
            </p>
          </span>
        )}
      </div>
    );
  } else {
    return (
      <div
        ref={ref}
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          // borderRight: '1px solid rgba(200, 200, 200, 0.5)',
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          borderRight: itemStyle['border'],
          borderBottom: itemStyle['border'],
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          display: 'grid',
          height: '100%',
          width: '100%',
          //padding: '12px 15px',
          padding: '9px 15px',
          whiteSpace: 'nowrap'
        }}
      >
        {(inView || exportMode) && (
          <span style={spanStyle}>
            {displayItemValue ? (
              <p style={{ color: displayItemValueColor }}>{displayItemValue}</p>
            ) : (
              <p style={{ visibility: 'hidden' }}>action</p>
            )}
            <p
              style={{
                fontSize: '9px',
                color: '#bcbcbc',
                overflow: 'hidden'
                //visibility: 'hidden'
              }}
            >
              {' '}
              {subValue.length > 15 ? subValue.slice(0, 15) + '...' : subValue}
            </p>
          </span>
        )}
      </div>
    );
  }
}

export default FloatItem;
