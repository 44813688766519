import React, { useState, useContext, useEffect } from 'react';

import EditIcon from '@mui/icons-material/Edit';

import { useSnackbar } from 'notistack';

// import DisabledDatePickerStart from './DisabledDatePickerStart';
// import DisabledDatePickerStop from './DisabledDatePickerStop';

import BasicDatePicker from './BasicDatePicker';
import TextField from '@mui/material/TextField';

import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import Checkbox from '@mui/material/Checkbox';
// import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

import Button from '@mui/material/Button';

import SelectStatus from './SelectStatus';
import { useMutation } from '@apollo/client';
import { emailPokeMutation } from '../../../graphql/queries';

import { AccountContext } from '../../../context/AccountContext';
import Members from './Members';

import { InterfaceContext } from '../../../context/InterfaceContext';

import { FilterContext } from '../../../context/FilterContext';

function PokeEmailDialogContent({ open, setOpen }) {
  const { selectedDateStart, selectedDateStop } = useContext(InterfaceContext);
  const { checkedBoxes, setCheckedBoxes } = useContext(FilterContext);
  const { currentManagerAccount } = useContext(AccountContext);

  const checkedAccounts = [];

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [accountId, setAccountId] = useState(null);

  const [message, setMessage] = useState('');

  const [displayAccountWarning, setDisplayAccountWarning] = useState(false);
  useEffect(() => {
    const accountIds = checkedBoxes.map((obj) => obj.accountId);

    const uniqueAccountIds = accountIds.filter(
      (v, i, a) => a.findIndex((t) => t === v) === i
    );

    if (uniqueAccountIds.length === 1) {
      setAccountId(uniqueAccountIds[0]);
    } else {
      setDisplayAccountWarning(true);
      console.log('too many accounts selected');
    }
  }, [checkedBoxes]);

  const { enqueueSnackbar } = useSnackbar();

  const [emailPoke, { called }] = useMutation(emailPokeMutation);

  const handleClose = () => {
    setOpen(false);
  };

  const onSaveHandler = async () => {
    if (checkedBoxes.length === 0) {
      enqueueSnackbar('No checkboxes checked.', {
        variant: 'error'
      });
      return;
    }

    if (selectedUsers.length === 0) {
      enqueueSnackbar('No users selected.', {
        variant: 'error'
      });
      return;
    }

    if (checkedAccounts.length > 1) {
      enqueueSnackbar('You can only poke one account at the time.', {
        variant: 'error'
      });
      return;
    }

    for (let obj of checkedBoxes) {
      if (
        ![
          'folder',
          'manualDataEntry',
          'facebookAds',
          'googleAds',
          'cm360',
          'dv360',
          'snapchatAds',
          'tikTokAds',
          'pinterestAds',
          'readpeak',
          'strossle',
          'amazonDsp',
          'taboolaAds',
          'microsoftAdvertising',
          'appleSearchAds',
          'adform',
          'linkedInAds',
          'task',
          'subTask',
          'taxonomy'
        ].includes(obj?.documentType)
      ) {
        enqueueSnackbar(
          'Only Folders, Facebook Camppaigns, Snapchat ads, TikTok ads, Google Ads campaigns, Campaign Manager 360 campaigns, DV360 campaigns, Adform campaigns, Manual Data Entries todo-lists and todos support pokes atm.',
          {
            variant: 'error'
          }
        );
        return;
      }
    }
    console.log('checkedBoxes: ', checkedBoxes);
    const documents = checkedBoxes.map((obj) => {
      const warnings = [];

      for (let warning of obj.warnings || []) {
        for (let warn of warning.messages) {
          warnings.push(warn.rawMessage);
        }
      }

      const newObj = {
        accountId: obj.accountId,
        documentId: obj._id,
        documentType: obj.documentType,
        path: obj.path,
        name: obj.name,
        startDate: obj.startDate,
        endDate: obj.endDate,
        status: obj.status,
        warnings
      };

      return newObj;
    });

    const userIds = selectedUsers.map((obj) => obj._id);

    let input = {
      managerAccountId: currentManagerAccount?._id,
      userIds,
      documents,
      message,
      datePickerStartDate: selectedDateStart,

      datePickerEndDate: selectedDateStop
    };

    console.log('email-poke-menu>input: ', input);

    const res = await emailPoke({ variables: { input } });
    console.log('res: ', res);
    if (res?.data?.emailPoke?.response === 'Success') {
      enqueueSnackbar('Poke sent', {
        variant: 'success'
      });
      setCheckedBoxes([]);
    }

    handleClose();
  };

  console.log('displayAccountWarning: ', displayAccountWarning);
  if (displayAccountWarning) {
    return <div>Please select documents from the same account</div>;
  }

  return (
    <>
      <div
        // className={classes.paper}
        style={{
          //   width: '100%',
          // height: '630px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <div style={{ width: '100%' }}>
          <Members
            accountId={accountId}
            selectedUsers={selectedUsers}
            setSelectedUsers={setSelectedUsers}
          />
        </div>
        <div style={{ width: '100%' }}>
          <TextField
            style={{ width: '100%', marginTop: '20px' }}
            id="outlined-multiline-flexible"
            label="Message"
            multiline
            rows="3"
            maxRows={6}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>

        <div
          style={{ alignSelf: 'start', fontSize: '14px', marginTop: '20px' }}
        >
          Selected rows:
        </div>
        <div
          style={{
            // width: '600px',
            width: '100%',
            height: 'auto',
            backgroundColor: 'rgba(170,170,170,0.1)',
            borderRadius: '5px',
            overflow: 'scroll',
            padding: '20px'
            // marginBottom: '25px'
          }}
        >
          {checkedBoxes.map((row) => (
            <div>{row.name}</div>
          ))}
        </div>

        <div
          style={{
            gridColumn: 4,
            textAlign: 'center',
            marginTop: '50px',
            alignSelf: 'end',
            paddingRight: '25px'
          }}
        >
          <Button variant="outlined" size="small" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            style={{ minWidth: '91px', marginLeft: '20px' }}
            variant="contained"
            size="small"
            color="primary"
            onClick={onSaveHandler}
          >
            Poke
          </Button>
        </div>
      </div>
    </>
  );
}

export default PokeEmailDialogContent;
