import React, { useState, useEffect, useContext } from 'react';

import { DialogContext } from '../../../context/DialogContext';

import { styled, useTheme } from '@mui/material/styles';

import TextWidget from './widgets/TextWidget';
import DateWidget from './widgets/DateWidget';
import ListWidget from './widgets/list-widget/ListWidget';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import WorkIcon from '@mui/icons-material/Work';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import EditLocationIcon from '@mui/icons-material/EditLocation';

import DeleteIcon from '@mui/icons-material/Delete';

import { Button } from '@mui/material';

import { SnackbarProvider, useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';

import { useLazyQuery, useMutation } from '@apollo/client';

import { AccountContext } from '../../../context/AccountContext';

import { MutationContext } from '../../../context/MutationContext';

import {
  accountUsersQuery,
  updateDocumentMutation
} from '../../../graphql/queries';

function TaxonomyField({
  taxonomyFieldIdsInEditMode,
  setTaxonomyFieldIdsInEditMode,
  // editMode,
  // setEditMode,
  displayMode,
  // item,
  // rowIndex,
  deleted,
  setDeleted,
  isDraggingWidget,
  setIsDraggingWidget,

  setTaxonomyString

  // grabbing,
  // toggleGrabbing
  // itemStyle,
  // checked,
  // rowNumber
}) {
  let zoom = 0;

  const [mouseDownWidgetIndex, setMouseDownWidgetIndex] = useState(null);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const [mousePosition, setMousePosition] = React.useState({
    x: null,
    y: null
  });

  const [mouseDownPosition, setMouseDownPosition] = React.useState({
    x: null,
    y: null
  });

  const [onMouseDownPosition, setOnMouseDownPosition] = React.useState({
    x: null,
    y: null
  });

  const [mouseDownTimeboxIds, setMouseDownTimeboxIds] = useState([]);
  const [isShiftSelected, setIsShiftSelected] = useState(false);
  const [mouseDownAction, setMouseDownAction] = useState();

  const [originalWidgets, setOriginalWidgets] = useState([]);
  const [originalWidget, setOriginalWidget] = useState(null);

  const onMouseDownHandler = (e, widgetIndex, action) => {
    e.stopPropagation();

    const widget = widgets[widgetIndex];

    if (!widget?.type) {
      return;
    }
    // if (action === 'create') {
    //   return;
    // }
    updateMousePosition(e);
    console.log('originalWidget: ', originalWidget);

    // console.log('debug532>isMouseDown: ', isMouseDown);
    // console.log('debug532>mousePosition: ', mousePosition);
    if (!isMouseDown && !action) {
      setOnMouseDownPosition({ ...mousePosition });
    }

    if (!isMouseDown && action) {
      switch (action) {
        case 'moveY':
        case 'resize':
          // case 'changeEndTime':
          //   if (!mouseDownTimeboxIds.includes(timeboxId)) {
          //     setMouseDownTimeboxIds([timeboxId]);
          //     setIsShiftSelected(false);
          //     // setIsTimeboxesMoved(false);
          //     return;
          //   }
          //   timeboxId = _timeboxId;
          //   const originalTimeboxes = timeboxes.filter(
          //     (obj) => obj._id === timeboxId
          //   )[0];
          setOriginalWidget({
            ...widgets[widgetIndex],
            style: { ...widgets[widgetIndex].style }
          });
          setOriginalWidgets(widgets.map((obj) => ({ ...obj })));

          //   setDisplayTimeboxes(originalTimebox_);

          break;
      }
    }
    setOnMouseDownPosition({ ...mousePosition });
    setMouseDownAction(action);
    setMouseDownPosition({ ...mousePosition });
    setIsMouseDown(true);
  };

  const [lastUpdatedPixels, setLastUpdatedPixels] = useState(0);

  useEffect(() => {
    console.log('isMouseDown: ', isMouseDown);
    if (isMouseDown) {
      console.log('mouseDownPosition: ', mouseDownPosition);
      let pixelsMovedX = 0;
      let pixelsMovedY = 0;
      //   const timebox = timeboxes.filter(
      //     (timebox) => timebox._id === mouseDownTimeboxId
      //   )[0];

      const widget = widgets[mouseDownWidgetIndex];
      const newWidget = { ...widget };

      const copyOfWidgets = widgets.map((obj) => ({ ...obj }));

      // console.log('copyOfTimeboxes: ', copyOfTimeboxes);
      // console.log('mouseDownTimeboxIds: ', mouseDownTimeboxIds);
      // const newTimeboxes = copyOfTimeboxes.filter((timebox) =>
      //   mouseDownTimeboxIds.includes(timebox._id)
      // );

      // const newTimeboxes = { ...timebox };
      // console.log('newTimeboxes: ', newTimeboxes);
      console.log('onMouseDownPosition: ', onMouseDownPosition);
      pixelsMovedX = Math.round(onMouseDownPosition.x - mouseDownPosition.x);
      pixelsMovedX = Math.round(pixelsMovedX / 10) * 10;

      pixelsMovedY = Math.round(onMouseDownPosition.y - mouseDownPosition.y);
      pixelsMovedY = Math.round(pixelsMovedY / 10) * 10;

      console.log('debug93>pixelsMovedX: ', pixelsMovedX);
      console.log('debug93>pixelsMovedY: ', pixelsMovedY);

      // if (pixelsMovedX % 5 || pixelsMoveY % 5) {
      //   return;
      // }
      console.log('debug93>newWidget: ', newWidget);

      // if (!pixelsMovedX % 5) {
      newWidget.style.width =
        originalWidget?.style?.width?.split('px')?.[0] - pixelsMovedX + 'px';
      // }
      // if (!pixelsMovedY % 5) {
      newWidget.style.height =
        originalWidget?.style?.height?.split('px')?.[0] - pixelsMovedY + 'px';
      // }
      copyOfWidgets[mouseDownWidgetIndex] = newWidget;
      console.log('debug94>newWidget: ', newWidget);

      setWidgets(copyOfWidgets);
      //   if (lastUpdatedPixels === pixels) {
      //     return;
      //   }

      //   // console.log('debug102>newTimebox: ', newTimebox);
      //   const originalWidget = originalTimeboxes[mouseDownWidgetIndex];

      //   if (!originalWidget) {
      //     return;
      //   }

      //   switch (mouseDownAction) {
      //     case 'create':
      //       break;
      //     case 'moveTop':
      //       newWidget.style.startDateTime = addMinutes(
      //         originalTimebox.startDateTime,
      //         minutes * -1
      //       );

      //       newTimebox.endDateTime = addMinutes(
      //         originalTimebox.endDateTime,
      //         minutes * -1
      //       );

      //       break;
      //     case 'changeStartTime':
      //       // var minutes = Math.round(
      //       //   (onMouseDownPosition.y - mouseDownPosition.y) / zoom
      //       // );
      //       // minutes = Math.round(minutes / 5) * 5;
      //       // console.log('minutes: ', minutes);
      //       // if (minutes % 5) {
      //       //   return;
      //       // }
      //       newTimebox.startDateTime = addMinutes(
      //         originalTimebox.startDateTime,
      //         minutes * -1
      //       );
      //       // console.log('newTimebox.startDateTime: ', newTimebox.startDateTime);
      //       //   newTimebox.duration =
      //       //     newTimebox.duration +
      //       //     (onMouseDownPosition.y - mouseDownPosition.y) / 2;
      //       break;
      //     case 'changeEndTime':
      //       newTimebox.endDateTime = addMinutes(
      //         originalTimebox.endDateTime,
      //         minutes * -1
      //       );
      //       // newTimebox.duration =
      //       //   newTimebox.duration +
      //       //   (mouseDownPosition.y - onMouseDownPosition.y) / 4;
      //       break;
      //   }

      //   setDisplayTimebox({ ...newTimebox });

      //   const newDuration =
      //     (newTimebox.endDateTime - newTimebox.startDateTime) / 60000;

      //   if (newDuration < minimumDuration) {
      //     switch (mouseDownAction) {
      //       case 'changeStartTime':
      //         newTimebox.startDateTime = addMinutes(
      //           originalTimebox.endDateTime,
      //           minimumDuration * -1
      //         );
      //         newTimebox.startDate = formatDateYYYYMMDD(newTimebox.startDateTime);
      //         break;
      //       case 'changeEndTime':
      //         newTimebox.endDateTime = addMinutes(
      //           originalTimebox.startDateTime,
      //           minimumDuration
      //         );
      //         newTimebox.endDate = formatDateYYYYMMDD(newTimebox.endDateTime);
      //         break;
      //     }
      //     //   return;
      //   }
      //   setDisplayDuration(
      //     newDuration < minimumDuration ? minimumDuration : newDuration
      //   );

      //   copyOfTimeboxes.splice(timeboxIndex, 1, newTimebox);

      //   setTimeboxes(copyOfTimeboxes);

      //   if (minutes) {
      //     setIsTimeboxesMoved(true);
      //   }

      //   setLastUpdatedMinutes(minutes);
      // } else {
    }
  }, [mouseDownPosition]);

  const onMouseUpHandler = async (e, elementType, timeboxId, trigger) => {
    // console.log('debug533>isMouseDown: ', isMouseDown);
    setIsMouseDown(false);
  };

  const updateMousePosition = (ev) => {
    if (isMouseDown) {
      console.log('updateMousePosition>ev: ', ev);
      setMouseDownPosition({ x: ev.clientX, y: ev.clientY });
    }

    setMousePosition({ x: ev.clientX, y: ev.clientY });
    // }
  };

  // const [isEditSettingsMode, setIsEditSettingsMode] = useState(false);

  const [displayError, setDisplayError] = useState(null);

  const { currentManagerAccountId } = useContext(AccountContext);
  // const [updateDocument] = useMutation(updateDocumentMutation);
  const { updateDocument } = useContext(MutationContext);
  const [editMode, setEditMode] = useState(false);
  const [numberOfReset, setNumberOfReset] = useState(0);
  const action = (key) => (
    <>
      {/* <button onClick={() => { alert(`I belong to snackbar with key ${key}`); }}>
        Alert
      </button> */}
      <Button
        style={{ maxWidth: '40px', minWidth: '40px', width: '40px' }}
        onClick={() => {
          closeSnackbar(key);
        }}
      >
        <CloseIcon style={{ color: 'black' }} />
      </Button>
    </>
  );
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();

  const [widgets, setWidgets] = useState([]);

  console.log('widgets: ', widgets);

  const updateWidget = (widget, index) => {
    const copyOfWidgets = [...widgets];
    copyOfWidgets.splice(index, 1, widget);
    setWidgets(copyOfWidgets);
  };

  const onContentCopyHandler = () => {
    navigator.clipboard.writeText(
      widgets.map((widget) => widget.value).join('')
    );
    enqueueSnackbar('Copied to clipboard', {
      action
      // variant: 'error',
      // persist: true
    });
  };

  const dragStart = (e, value, origin, originIndex) => {
    // toggleGrabbing(true);
    setIsDraggingWidget(true);
    e.stopPropagation();
    console.log('origin: ', origin);
    const target = e.target;
    e.dataTransfer.setData(
      'droppedItem',
      JSON.stringify({
        value,
        origin,
        originIndex,
        eNativeEventOffsetX: e.nativeEvent.offsetX,
        eNativeEventOffsetY: e.nativeEvent.offsetY
      })
    );
    // console.log('{ value, origin, originIndex }: ', {
    //   value,
    //   origin,
    //   originIndex
    // });
    // console.log(
    //   'JSON.stringify({ value, origin, originIndex }): ',
    //   JSON.stringify({ value, origin, originIndex })
    // );
    setTimeout(() => {
      target.style.display = 'none';
    }, 0);
  };

  const dragEnd = (e, value) => {
    setIsDraggingWidget(false);
    console.log('dragEnd');
    const target = e.target;

    setTimeout(() => {
      target.style.display = 'flex';
    }, 0);
  };

  const dragOver = (e, value) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dropOnField = (e, value) => {
    console.log('debug76>dropOnField');
    // toggleGrabbing(false);
    setIsDraggingWidget(false);
    console.log('value: ', value);
    e.preventDefault();
    e.stopPropagation();
    const droppedItemString = e.dataTransfer.getData('droppedItem');
    const droppedItem = JSON.parse(droppedItemString);
    console.log('debug>droppedItem: ', droppedItem);
    // console.log(
    //   'debug>droppedItem.originRowIndex:',
    //   droppedItem.originRowIndex
    // );
    // console.log('debug>rowIndex: ', rowIndex);
    // console.log(
    //   'debug>droppedItem.originRowIndex !== 0: ',
    //   droppedItem.originRowIndex !== 0
    // );
    // if (
    //   (droppedItem.originRowIndex || droppedItem.originRowIndex === 0) &&
    //   droppedItem.originRowIndex !== rowIndex
    // ) {
    //   return;
    // }
    let copyOfWidgets = [...widgets];

    console.log('droppedItem.origin: ', droppedItem.origin);
    if (droppedItem.origin === 'field_widgets') {
      console.log('droppedItem.index: ', droppedItem.originIndex);
      copyOfWidgets.splice(droppedItem.originIndex, 1);

      console.log('copyOfWidgets: ', copyOfWidgets);
    }
    console.log('copyOfWidgets: ', copyOfWidgets);
    setWidgets([
      ...copyOfWidgets,
      {
        ...droppedItem.value,
        style: {
          ...droppedItem.value.style,
          left:
            Math.round(
              (e.nativeEvent.offsetX - droppedItem.eNativeEventOffsetX) / 10
            ) *
              10 +
            'px',
          top:
            Math.round(
              (e.nativeEvent.offsetY - droppedItem.eNativeEventOffsetY) / 10
            ) *
              10 +
            'px'
        }
      }
    ]);

    // const droppedItemElement = document.getElementById(droppedItem);
  };
  const dropOnWidget = (e, value, index) => {
    console.log('debug55>dropOnWidget');
    // toggleGrabbing(false);
    console.log('debug55>dropOnWidget 1');
    setIsDraggingWidget(false);
    console.log('debug55>dropOnWidget 2');
    e.preventDefault();
    console.log('debug55>dropOnWidget 3');
    e.stopPropagation();
    console.log('debug55>dropOnWidget 4');
    const droppedItemString = e.dataTransfer.getData('droppedItem');
    console.log('debug55>dropOnWidget 5');
    console.log('debug55>droppedItemString: ', droppedItemString);
    const droppedItem = JSON.parse(droppedItemString);
    console.log('debug55>dropOnWidget 6');
    // if (
    //   (droppedItem.originRowIndex || droppedItem.originRowIndex === 0) &&
    //   droppedItem.originRowIndex !== rowIndex
    // ) {
    //   return;
    // }
    let copyOfWidgets = [...widgets];
    console.log('debug55>droppedItem.origin: ', droppedItem.origin);

    let indexToSplice = index;
    if (droppedItem.origin === 'item_widgets') {
      console.log('droppedItem.index: ', droppedItem.originIndex);
      copyOfWidgets.splice(droppedItem.originIndex, 1);

      console.log('copyOfWidgets: ', copyOfWidgets);
      indexToSplice = droppedItem.originIndex < index ? index - 1 : index;
    }

    copyOfWidgets.splice(indexToSplice, 0, droppedItem.value);

    setWidgets(copyOfWidgets);
    // console.log('droppedItem1: ', droppedItem);

    const droppedItemElement = document.getElementById(droppedItem);
  };

  // const dropOnDelete = (e) => {
  //   // e.preventDefault();
  //   // e.stopPropagation();
  //   console.log('dropOnDelete>1');

  //   console.log('dropOnDelete>3');
  //   setIsDraggingWidget(false);

  //   e.preventDefault();

  //   e.stopPropagation();

  //   const droppedItemString = e.dataTransfer.getData('droppedItem');

  //   const droppedItem = JSON.parse(droppedItemString);

  //   if (droppedItem.origin !== 'item_widgets') {
  //     console.log('dropOnDelete>2');
  //     return;
  //   }

  //   let copyOfWidgets = [...widgets];
  //   console.log('debug55>droppedItem.origin: ', droppedItem.origin);

  //   console.log('droppedItem.index: ', droppedItem.originIndex);
  //   copyOfWidgets.splice(droppedItem.originIndex, 1);

  //   console.log('copyOfWidgets: ', copyOfWidgets);
  //   // let indexToSplice = droppedItem.originIndex < index ? index - 1 : index;
  //   // copyOfWidgets.splice(indexToSplice, 0, droppedItem.value);

  //   setWidgets(copyOfWidgets);
  //   console.log('droppedItem1: ', droppedItem);
  // };
  return (
    <div
      style={{
        zIndex: '1000'
      }}
    >
      <div
        onMouseUp={onMouseUpHandler}
        onMouseMove={updateMousePosition}
        // onDrop={(e) => dropOnField(e, item, item)}
        onDragOver={(e) => dragOver(e)}
        // draggable={true}
        // onDragStart={(e) => dragStart(e, row, 'taxonomy_table', rowIndex)}
        // onDragEnd={(e) => dragEnd(e, row, rowIndex)}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          // columnGap: '3px',

          alignItems: 'center',

          fontSize: '12px',

          height: '800px',
          width: '100%',
          minWidth: '1200px',

          whiteSpace: 'nowrap',
          background:
            theme?.palette?.mode === 'dark'
              ? 'rgba(255,255,255,0.1)'
              : 'rgba(255,255,255,0.6)',
          // background: 'red',
          borderRadius: '5px',

          backdropFilter: 'blur(10px)',
          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
        }}
      >
        <div
          onDrop={(e) => dropOnField(e)}
          // onDragOver={(e) => dragOver(e, item)}
          style={{
            height: '100%',
            // background: 'green',
            // display: 'contents',
            // flexDirection: 'row',
            // justifyContent: 'start',
            // background: 'red',
            width: '100%'
            // minWidth: '58vw'
            // padding: '12px 15px'
          }}
        >
          {widgets.map((widget, index) => {
            switch (widget.type) {
              case 'TEXT':
                return (
                  <TextWidget
                    onMouseUpHandler={onMouseUpHandler}
                    mouseDownWidgetInde={mouseDownWidgetIndex}
                    setMouseDownWidgetIndex={setMouseDownWidgetIndex}
                    widget={widget}
                    widgetIndex={index}
                    widgets={widgets}
                    setWidgets={setWidgets}
                    updateWidget={updateWidget}
                    // displayMode={displayMode}
                    // editMode={editMode}
                    dropOnWidget={dropOnWidget}
                    dragOver={dragOver}
                    dragStart={dragStart}
                    dragEnd={dragEnd}
                    setIsDraggingWidget={setIsDraggingWidget}
                    isDraggingWidget={isDraggingWidget}
                    onMouseDownHandler={onMouseDownHandler}
                  />
                );
              // case 'STATIC_TEXT':
              //   return (
              //     <TextWidget
              //       widget={widget}
              //       widgetIndex={index}
              //       widgets={widgets}
              //       setWidgets={setWidgets}
              //       updateWidget={updateWidget}
              //       displayMode={displayMode}
              //       editMode={editMode}
              //       dropOnWidget={dropOnWidget}
              //       dragOver={dragOver}
              //       dragStart={dragStart}
              //       dragEnd={dragEnd}
              //       setIsDraggingWidget={setIsDraggingWidget}
              //       isDraggingWidget={isDraggingWidget}
              //     />
              //   );

              // case 'YEAR_YYYY':
              // case 'YEAR_YY':
              // case 'MONTH_MM':
              // case 'DAY_DD':
              //   return (
              //     <DateWidget
              //       widget={widget}
              //       widgetIndex={index}
              //       widgets={widgets}
              //       setWidgets={setWidgets}
              //       updateWidget={updateWidget}
              //       displayMode={displayMode}
              //       editMode={editMode}
              //       dropOnWidget={dropOnWidget}
              //       dragOver={dragOver}
              //       dragStart={dragStart}
              //       dragEnd={dragEnd}
              //       setIsDraggingWidget={setIsDraggingWidget}
              //       isDraggingWidget={isDraggingWidget}
              //     />
              //   );

              // case 'LIST':
              //   return (
              //     // <div
              //     //   onDrop={(e) => dropOnWidget(e, widget, index)}
              //     //   onDragOver={(e) => dragOver(e, widget, index)}
              //     //   draggable={true}
              //     //   onDragStart={(e) =>
              //     //     dragStart(e, widget, 'item_widgets', index)
              //     //   }
              //     //   onDragEnd={(e) => dragEnd(e, widget, index)}
              //     //   style={{ paddingLeft: '3px' }}
              //     // >
              //     //   <div
              //     //     style={{
              //     //       padding: '3px',
              //     //       color: 'black',
              //     //       background: 'white',
              //     //       // border: '1px solid lightgrey',
              //     //       display: 'inline-block',
              //     //       borderRadius: '5px',
              //     //       margin: '0px 0.5px',
              //     //       transform: 'translate(0, 0)',
              //     //       boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
              //     //     }}
              //     //   >
              //     <ListWidget
              //       numberOfReset={numberOfReset}
              //       widget={widget}
              //       widgetIndex={index}
              //       widgets={widgets}
              //       setWidgets={setWidgets}
              //       updateWidget={updateWidget}
              //       displayMode={displayMode}
              //       editMode={editMode}
              //       dropOnWidget={dropOnWidget}
              //       dragOver={dragOver}
              //       dragStart={dragStart}
              //       dragEnd={dragEnd}
              //       setIsDraggingWidget={setIsDraggingWidget}
              //       isDraggingWidget={isDraggingWidget}
              //     />
              //     //   </div>

              //     // </div>
              //   );
            }
          })}
        </div>

        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '12px 15px'
          }}
        > */}
        {/* {editMode ? (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Tooltip title="Location">
                <Button
                  style={{ width: '40px', minWidth: '40px', cursor: 'pointer' }}
                  // onClick={() => {
                  //   console.log('reset1234');
                  //   setTaxonomyRowObj({ ...item });
                  // }}
                >
                  <EditLocationIcon style={{}} />
                </Button>
              </Tooltip>
              <Tooltip title="Drop widget here to delete">
                <Button
                  style={{ width: '40px', minWidth: '40px', cursor: 'default' }}
                  onDrop={(e) => dropOnDelete(e)}
                >
                  <DeleteIcon style={{}} />
                </Button>
              </Tooltip>
              <Tooltip title="Cancel">
                <Button
                  style={{
                    width: '40px',
                    minWidth: '40px',
                    color: 'red',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    // setTaxonomyRowObj({ ...item });
                    closeEditMode();
                    // setEditMode(false);
                    // setIsEditSettingsMode(false);
                  }}
                >
                  <CancelIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Save settings">
                <Button
                  style={{
                    width: '40px',
                    minWidth: '40px',
                    color: 'green',
                    cursor: 'pointer'
                  }}
                  onClick={onSaveHandler}
                >
                  <SaveIcon />
                </Button>
              </Tooltip>
            </div>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Tooltip title="Copy taxonomy string">
                <Button
                  style={{ width: '40px', minWidth: '40px', cursor: 'copy' }}
                  onClick={onContentCopyHandler}
                >
                  <ContentCopyIcon style={{}} />
                </Button>
              </Tooltip>
              <Tooltip title="Reset">
                <Button
                  style={{ width: '40px', minWidth: '40px', cursor: 'pointer' }}
                  onClick={() => {
                    console.log('reset1234');
                    setNumberOfReset(numberOfReset + 1);
                    // setTaxonomyRowObj({ ...item });
                  }}
                >
                  <RestartAltIcon style={{}} />
                </Button>
              </Tooltip>
              <Tooltip title="History">
                <Button
                  style={{ width: '40px', minWidth: '40px', cursor: 'zoom-in' }}
                  // onClick={() => {
                  //   console.log('reset1234');
                  //   setTaxonomyRowObj({ ...item });
                  // }}
                >
                  <WorkIcon style={{}} />
                </Button>
              </Tooltip>
              <Tooltip title="Location">
                <Button
                  style={{ width: '40px', minWidth: '40px', cursor: 'pointer' }}
                  // onClick={() => {
                  //   console.log('reset1234');
                  //   setTaxonomyRowObj({ ...item });
                  // }}
                >
                  <LocationOnIcon style={{}} />
                </Button>
              </Tooltip>

              <Tooltip title="Settings">
                <Button
                  style={{ width: '40px', minWidth: '40px', cursor: 'pointer' }}
                  onClick={() => {
                    onTaxonomyEditHandler();
                  }}
                >
                  <SettingsIcon style={{}} />
                </Button>
              </Tooltip>
            </div>
          )} */}
        {/* </div> */}
      </div>
      {displayError && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end'
          }}
        >
          <div
            style={{
              background: 'red',
              height: '20px',
              width: '50px',
              borderRadius: '0px 0px 5px 5px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '10px',
              marginRight: '20px',
              color: 'white'
            }}
          >
            Error!
          </div>
        </div>
      )}

      {/* <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            columnGap: '10px',
            marginTop: '15px'
          }}
        >
          <Button
            disableRipple
            size="small"
            style={{
              // width: '100%',
              fontSize: '10px',
              position: 'inherit'
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disableRipple
            size="small"
            style={{
              // width: '100%',
              background: 'rgba(0,255,0,0.7)',
              fontSize: '10px',
              position: 'inherit',
              color: 'black'
            }}
            variant="contained"
          >
            Save
          </Button>
        </div>
      </div> */}
    </div>
  );
}

export default TaxonomyField;
