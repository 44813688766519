import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CheckboxesTags({
  componentIndex,
  fields,
  setFields,
  columnDictionary
  // axis,
  // axisTitle
}) {
  const currentField = fields[componentIndex];

  const selectedMetrics = currentField.metrics;

  const availableMetrics = [
    {
      name: 'Cost',
      type: 'monotone',
      dataKey: 'cost',
      stackId: '1',
      stroke: '#8884d8',
      fill: '#8884d8',
      yAxisId: 'left',
      yAxisOrientation: 'left',
      hideYAxis: false
    },
    {
      name: 'Impressions',
      type: 'monotone',
      dataKey: 'impressions',
      stackId: '1',
      stroke: '#8884d8',
      fill: '#8884d8',
      yAxisOrientation: 'left',
      yAxisId: 'left',
      hideYAxis: false
    },
    {
      name: 'Viewable Impressions',
      type: 'monotone',
      dataKey: 'viewableImpressions',
      stackId: '1',
      stroke: '#8884d8',
      fill: '#8884d8',
      yAxisOrientation: 'left',
      yAxisId: 'left',
      hideYAxis: false
    },
    {
      name: 'Measurable Impressions',
      type: 'monotone',
      dataKey: 'measurableImpressions',
      stackId: '1',
      stroke: '#8884d8',
      fill: '#8884d8',
      yAxisOrientation: 'left',
      yAxisId: 'left',
      hideYAxis: false
    },
    {
      name: 'Eligible Impressions',
      type: 'monotone',
      dataKey: 'eligibleImpressions',
      stackId: '1',
      stroke: '#8884d8',
      fill: '#8884d8',
      yAxisOrientation: 'left',
      yAxisId: 'left',
      hideYAxis: false
    },
    {
      name: 'Clicks',
      type: 'monotone',
      dataKey: 'clicks',
      stackId: '1',
      stroke: '#8884d8',
      fill: '#8884d8',
      yAxisOrientation: 'left',
      yAxisId: 'left',
      hideYAxis: false
    },
    {
      name: 'Action 01 Count',
      type: 'monotone',
      dataKey: 'action01Count',
      stackId: '1',
      stroke: '#8884d8',
      fill: '#8884d8',
      yAxisOrientation: 'left',
      yAxisId: 'left',
      hideYAxis: false
    },
    {
      name: 'Action 01 Value',
      type: 'monotone',
      dataKey: 'action01Value',
      stackId: '1',
      stroke: '#8884d8',
      fill: '#8884d8',
      yAxisOrientation: 'left',
      yAxisId: 'left',
      hideYAxis: false
    }
  ];

  const onChangeHandler = (e, newValue) => {
    const copyOfFields = [...fields];
    const copyOfCurrentField = { ...currentField };
    // const copyOfCurrentMetrics = [...selectedMetrics];

    copyOfCurrentField.metrics = newValue;

    copyOfFields.splice(componentIndex, 1, copyOfCurrentField);

    setFields(copyOfFields);

    // if()
  };

  //   const [value, setValue] = useState([]);

  //   const onChangeHandler = (e, newValue, test) => {

  //     setValue(newValue);
  //   };

  return (
    <Autocomplete
      size="small"
      multiple
      id="checkboxes-tags-demo"
      value={selectedMetrics}
      onChange={onChangeHandler}
      options={availableMetrics}
      disableCloseOnSelect
      getOptionLabel={(option) => option.dataKey}
      isOptionEqualToValue={(option, value) => option.dataKey === value.dataKey}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      style={{ width: 400 }}
      renderInput={(params) => (
        <TextField {...params} label="Metrics" placeholder="Add more metrics" />
      )}
      renderTags={() => <span></span>}
    />
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  {
    title: 'The Lord of the Rings: The Return of the King',
    year: 2003
  },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  {
    title: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001
  },
  {
    title: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980
  },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  {
    title: 'The Lord of the Rings: The Two Towers',
    year: 2002
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  {
    title: 'Star Wars: Episode IV - A New Hope',
    year: 1977
  },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 }
];
