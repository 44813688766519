import { useMutation } from '@apollo/client';
import React, { useEffect, useState, useContext } from 'react';

import { UpdateAccountToDataSourceSettingsMutation } from '../../../../graphql/queries';

import { SnackbarProvider, useSnackbar } from 'notistack';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import SelectStatus from './SelectStatus';

import BasicDatePicker from '../../utils/BasicDatePicker';

import ActionSettings from './ActionSettings';

import { Button } from '@mui/material';

import { AccountContext } from '../../../../context/AccountContext';

const fieldToNameDict = {
  allowMissingBudget: 'Allow Missing Budget',
  allowMissingClicks: 'Allow Missing Clicks',
  allowAbnormallyHighCtr: 'Allow Abnormally High Ctr',
  allowMissingImpressionsYesterday: 'Allow Missing Impressions Yesterday',
  allowImpressionsBeforeStartDate: 'Allow Impressions Before Start Date',
  allowImpressionsAfterEndDate: 'Allow Impressions After End Date',
  action01: 'Action 01',
  action02: 'Action 02',
  action03: 'Action 03'
};

const documentTypeDict = {
  GoogleAdsDataSource: 'googleAds',
  DV360DataSource: 'dv360',
  FacebookAdsDataSource: 'facebookAds',
  SnapchatAdsDataSource: 'snapchatAds',
  TikTokAdsDataSource: 'tikTokAds',
  PinterestAdsDataSource: 'pinterestAds',
  ReadpeakDataSource: 'readpeak',
  StrossleDataSource: 'strossle',
  AmazonDspDataSource: 'amazonDsp',
  TaboolaAdsDataSource: 'taboolaAds',
  MicrosoftAdvertisingDataSource: 'microsoftAdvertising',
  AppleSearchAdsDataSource: 'appleSearchAds',
  LinkedInAdsDataSource: 'linkedInAds',
  CM360DataSource: 'cm360',
  AdformDataSource: 'adform'
};

function ShowMoreOptions({
  currentManagerAccountId,
  accountId,
  dataSource,
  status,
  setStatus,
  overrideStatus,
  setOverrideStatus,
  limitDates,
  setLimitDates,
  startDate,
  setStartDate,
  endDate,
  setEndDate,

  overrideFields,
  setOverrideFields,
  overrideValues,
  setOverrideValues
}) {
  const { currentManagerAccount } = useContext(AccountContext);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [updateAccountToDataSourceSettingsMutation] = useMutation(
    UpdateAccountToDataSourceSettingsMutation
  );
  // #################### emulate item for action menu ###################

  const [item, setItem] = useState();
  console.log('ShowMoreOptions>dataSource: ', dataSource);

  useEffect(() => {
    let newItem = {
      documentType: documentTypeDict?.[dataSource?.dataSourceRef],
      accountId
    };

    switch (documentTypeDict[dataSource.dataSourceRef]) {
      case 'strossle':
        newItem.dataSourceGroupId = dataSource.dataSourceAccountId;
        break;
      case 'cm360':
      case 'dv360':
      case 'tikTokAds':
        newItem.dataSourceAdvertiserId = dataSource.dataSourceAccountId;
        break;
      case 'snapchatAds':
      case 'facebookAds':
      case 'linkedInAds':
      case 'pinterestAds':

      case 'amazonDsp':
      case 'taboolaAds':
      case 'microsoftAdvertising':
      case 'appleSearchAds':
        newItem.dataSourceAccountId = dataSource.dataSourceAccountId;
        break;
      case 'googleAds':
        newItem.dataSourceCustomerId = dataSource.dataSourceAccountId;
        break;
      case 'adform':
      case 'readpeak':
        newItem.dataSourceClientId = dataSource.dataSourceAccountId;
        break;
    }

    // case 'strossle':
    //   dataSourceAccountId_ = item.dataSourceGroupId;
    //   break;
    // case 'adform':
    // case 'readpeak':
    //   dataSourceAccountId_ = item.dataSourceClientId;
    //   break;
    // case 'dv360':
    // case 'cm360':
    // case 'tikTokAds':
    //   dataSourceAccountId_ = item.dataSourceAdvertiserId;
    //   break;
    // case 'snapchatAds':
    // case 'facebookAds':
    // case 'linkedInAds':
    // case 'pinterestAds':

    // case 'amazonDsp':
    // case 'taboolaAds':
    // case 'microsoftAdvertising':
    // case 'appleSearchAds':
    //   dataSourceAccountId_ = item.dataSourceAccountId;
    //   break;
    // case 'googleAds':
    //   dataSourceAccountId_ = item.dataSourceCustomerId;
    //   break;

    setItem(newItem);
  }, [dataSource]);
  // console.log('ShowMoreOptions>dataSource: ', dataSource);
  // console.log('ShowMoreOptions>item: ', item);

  // #####################################################################
  const [document, setDocument] = useState({});
  const [showMoreOptions, toggleShowMoreOptions] = useState(false);

  const clickShowMoreOptionsHandler = () => {
    toggleShowMoreOptions(!showMoreOptions);
  };

  const toggleOverrideFields = (key) => {
    // console.log(
    //   'test: ',
    //   overrideFields.includes(key)
    //     ? overrideFields.filter((field_) => field_ !== key)
    //     : [...overrideFields, key]
    // );
    setOverrideFields(
      overrideFields.includes(key)
        ? overrideFields.filter((field_) => field_ !== key)
        : [...overrideFields, key]
    );
  };

  // console.log('ShowMoreOptions>overrideValues: ', overrideValues);
  // console.log('ShowMoreOptions>overrideFields: ', overrideFields);
  const onClickSaveAsDefaultHandler = async () => {
    const defaultDocumentValuesToSave = {};
    for (let itemKey of [
      'status',
      'allowAbnormallyHighCtr',
      'allowImpressionsBeforeStartDate',
      'allowImpressionsAfterEndDate',
      'allowMissingBudget',
      'allowMissingClicks',
      'allowMissingImpressionsYesterday',
      'action01',
      'action02',
      'action03'
    ]) {
      if (!overrideFields.includes(itemKey)) {
        continue;
      }
      defaultDocumentValuesToSave[itemKey] =
        overrideValues[itemKey] || undefined;
    }
    const input = {
      managerAccountId: currentManagerAccount?._id,
      accountId,
      // templateDocumentId: value._id,
      accountToDataSourceRelationId: dataSource?.accountToDataSourceRelationId,
      updateKeys: ['defaultNexusDocumentValues'],
      defaultNexusDocumentValues: defaultDocumentValuesToSave
    };

    console.log('onClickSaveAsDefaultHandler>input: ', input);

    try {
      const res = await updateAccountToDataSourceSettingsMutation({
        variables: { input }
      });

      console.log('res: ', res);
      if (res.data.updateAccountToDataSourceSettings.response === 'success') {
        enqueueSnackbar('Saved', {
          // variant: 'error'
        });
      }
    } catch (error) {
      enqueueSnackbar('Error', {
        variant: 'error'
      });
    }
  };

  // useEffect(() => {
  //   for (let itemKey of ['action01', 'action02', 'action03']) {
  //     const copyOfDocument = { ...document };
  //     delete copyOfDocument[itemKey];
  //     // if (!document[itemKey]?.dataSource) {
  //     //   delete copyOfDocument[itemKey];
  //     // }
  //     if (document[itemKey]?.dataSource) {
  //       copyOfDocument[itemKey] = {
  //         dataSource: document[itemKey]?.dataSource,
  //         dataSourceRef: document[itemKey]?.dataSourceRef,
  //         // name: String,
  //         dataSourceId: document[itemKey]?.dataSourceId,
  //         dataSourceAccountId: document[itemKey]?.dataSourceAccountId,

  //         name: document[itemKey]?.name
  //       };
  //     }
  //   }
  //   setDocument();
  // }, [document]);

  useEffect(() => {
    let copyOfOverrideFields = [...overrideFields];
    for (let itemKey of ['action01', 'action02', 'action03']) {
      if (
        !overrideValues[itemKey]?.dataSource &&
        overrideFields.includes(itemKey)
      ) {
        copyOfOverrideFields = copyOfOverrideFields.filter(
          (field) => field !== itemKey
        );
      }
      if (
        overrideValues[itemKey]?.dataSource &&
        !overrideFields.includes(itemKey)
      ) {
        copyOfOverrideFields.push(itemKey);
      }
    }
    setOverrideFields(copyOfOverrideFields);
  }, [overrideValues]);

  console.log('overrideFields: ', overrideFields);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        // backgroundColor: showMoreOptions && 'rgba(150,150,150,0.1)',
        // borderRadius: '5px',
        // padding: '20px',
        marginTop: '40px',
        marginBottom: '15px'
      }}
    >
      <div
        style={{
          cursor: 'pointer'
          // paddingTop: '40px',
          // paddingBottom: '15px'
        }}
        onClick={clickShowMoreOptionsHandler}
      >
        <span style={{ marginRight: '5px' }}>Show more options</span>{' '}
        {showMoreOptions ? (
          <i className="fas fa-caret-down"></i>
        ) : (
          <i className="fas fa-caret-right"></i>
        )}
      </div>
      {showMoreOptions && (
        <>
          <div
            style={{
              marginTop: '20px',
              display: 'grid',
              // flexDirection: 'row',
              // justifyContent: 'start',
              // alignItems: 'end'
              gridTemplateColumns: '200px 200px 200px',
              backgroundColor: showMoreOptions && 'rgba(150,150,150,0.1)',
              borderRadius: '5px',
              padding: '10px 20px'
              // gridRowStart: 3,
              // gridRowEnd: 3
            }}
          >
            <div
              style={{
                gridColumn: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '72px'
              }}
            >
              <FormControlLabel
                value="Limit Dates"
                control={
                  <Checkbox
                    checked={overrideFields.includes('dates')}
                    onChange={() => {
                      toggleOverrideFields('dates');
                    }}
                    label="Limit Dates"
                  />
                }
                label={<span style={{ fontSize: '12px' }}>Limit Dates</span>}
                labelPlacement="end"
              />
            </div>
            <div
              style={{
                // display: 'grid',
                // gridColumnStart: 2,
                // gridColumnEnd: 2,
                position: 'relative',
                transform: 'scale(0.9)',
                left: '-25px',
                top: '10px'
                // marginLeft: '20px'
              }}
            >
              <BasicDatePicker
                disabled={!overrideFields.includes('dates')}
                value={overrideValues['startDate']}
                setValue={(value) =>
                  setOverrideValues({
                    ...overrideValues,
                    startDate: value
                  })
                }
                label="Start date"
              />
            </div>
            <div
              style={{
                position: 'relative',
                transform: 'scale(0.9)',
                left: '-25px',
                top: '10px'
              }}
            >
              <BasicDatePicker
                disabled={!overrideFields.includes('dates')}
                value={overrideValues['endDate']}
                setValue={(value) =>
                  setOverrideValues({
                    ...overrideValues,
                    endDate: value
                  })
                }
                label="End date"
              />
            </div>
          </div>
          <div
            style={{
              marginTop: '10px',
              backgroundColor: showMoreOptions && 'rgba(150,150,150,0.1)',
              borderRadius: '5px',
              padding: '20px'
            }}
          >
            {/* <div
              style={{
                position: 'relative',
                right: '0px',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end'
              }}
            >
              <Button size="small" onClick={onClickSaveAsDefaultHandler}>
                Save as default
              </Button>
            </div> */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <div
                style={{
                  display: 'grid',
                  // flexDirection: 'row',
                  // justifyContent: 'start',
                  // alignItems: 'end',
                  gridTemplateColumns: '200px 200px'
                  // gridRowStart: 3,
                  // gridRowEnd: 3
                }}
              >
                <div
                  style={{
                    gridColumn: 1,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '72px'
                  }}
                >
                  <FormControlLabel
                    value="Status"
                    control={
                      <Checkbox
                        checked={overrideFields.includes('status')}
                        onChange={() => {
                          toggleOverrideFields('status');
                        }}
                        label="Status"
                      />
                    }
                    label={<span style={{ fontSize: '12px' }}>Status</span>}
                    labelPlacement="end"
                  />
                </div>
                <div
                  style={{
                    display: 'grid',
                    gridColumnStart: 2,
                    gridColumnEnd: 2
                    // marginLeft: '20px'
                  }}
                >
                  <SelectStatus
                    overrideStatus={overrideFields.includes('status')}
                    status={overrideValues['status']}
                    setStatus={(value) =>
                      setOverrideValues({
                        ...overrideValues,
                        status: value
                      })
                    }
                  />
                </div>
              </div>
              <div>
                <Button
                  variant="outlined"
                  size="small"
                  // style={{ width: '130px' }}
                  onClick={onClickSaveAsDefaultHandler}
                >
                  Save as default
                </Button>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start'
                // marginTop: '20px'
              }}
            >
              {[
                'allowAbnormallyHighCtr',
                'allowImpressionsBeforeStartDate',
                'allowImpressionsAfterEndDate',
                'allowMissingBudget',
                'allowMissingClicks',
                'allowMissingImpressionsYesterday'
              ]?.map((field) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    width: '500px',
                    margin: '10px 0px'
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={
                          false
                          // !overrideFields.includes(field)
                        }
                        checked={Boolean(overrideValues[field])}
                        onChange={(e, value) => {
                          if (value) {
                            if (!overrideFields.includes(field)) {
                              setOverrideFields([...overrideFields, field]);
                            }
                          } else {
                            setOverrideFields(
                              overrideFields.filter((str) => str !== field)
                            );
                          }
                          // console.log('value: ', value);
                          setOverrideValues({
                            ...overrideValues,
                            [field]: value
                          });
                        }}
                        label="Status"
                      />
                    }
                    label={
                      <span style={{ fontSize: '12px' }}>
                        {fieldToNameDict[field] || field}
                      </span>
                    }
                    labelPlacement="end"
                  />
                </div>
              ))}
            </div>
            <div>
              {['action01', 'action02', 'action03'].map((itemKey) => {
                const action = overrideValues?.[itemKey];

                let actionName = '';
                switch (true) {
                  // case nexusDocument?.[action].dataSource === 'googleAnalytics4':
                  //   nexusDocument[action].name =
                  //     nexusDocument?.[action]?.googleAnalytics4?.eventName;
                  //   break;

                  case action?.dataSource === 'facebookAds':
                    actionName = action?.[action?.dataSource]?.name;
                    break;

                  case [
                    'adform',
                    'appleSearchAds',
                    'googleAds',
                    'snapchatAds',
                    'tikTokAds',
                    'pinterestAds',
                    'readpeak',
                    'strossle',
                    'amazonDsp',
                    'taboolaAds',
                    'microsoftAdvertising',
                    'linkedInAds'
                  ].includes(action?.dataSource):
                    actionName = action?.[action?.dataSource]?.name;
                    break;

                  case action?.dataSource === 'cm360':
                  case action?.dataSource === 'dv360':
                    actionName = action?.[action?.dataSource]?.activityName;
                    break;
                }

                // console.log('actionName: ', actionName);
                return (
                  <div
                    key={itemKey}
                    style={{
                      // display: 'grid',
                      // // flexDirection: 'row',
                      // // justifyContent: 'start',
                      // alignItems: 'center',
                      // gridTemplateColumns: '33px 100px 100px 100px'
                      // gridRowStart: 3,
                      // gridRowEnd: 3
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'start',
                      alignItems: 'center',
                      width: '500px',
                      margin: '10px 0px',
                      left: '-10px',
                      padding: '5px 0px'
                    }}
                  >
                    {/* <div
                    style={{
                      gridColumn: 1,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      height: '72px'
                    }}
                  >
                    <FormControlLabel
                      value="Status"
                      control={
                        <Checkbox
                          checked={overrideFields?.includes(itemKey)}
                          onChange={() => {
                            toggleOverrideFields(itemKey);
                          }}
                          label={itemKey}
                        />
                      }
                      // label={<span style={{ fontSize: '12px' }}>{itemKey}</span>}
                      labelPlacement="end"
                    />
                  </div> */}
                    <div
                      style={
                        {
                          // display: 'grid',
                          // gridColumnStart: 1,
                          // gridColumnEnd: 1
                          // marginLeft: '20px'
                        }
                      }
                    >
                      <ActionSettings
                        currentManagerAccountId={currentManagerAccount?._id}
                        item={item}
                        itemKey={itemKey}
                        document={overrideValues}
                        setDocument={setOverrideValues}
                        disabled={
                          false
                          // !overrideFields?.includes(itemKey)
                        }
                      />
                    </div>
                    <div style={{ fontSize: '12px', marginLeft: '2px' }}>
                      {fieldToNameDict[itemKey] || itemKey}
                    </div>
                    <div style={{ fontSize: '12px', marginLeft: '150px' }}>
                      {actionName || ''}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ShowMoreOptions;

{
  /* <div style={{ width: '100%', margin: '10px 0px 50px 0px' }}>
<SelectTemplate
  managerAccountId={currentManagerAccount?._id}
  accountId={accountId}
  value={templateDocument}
  setValue={setTemplateDocument}
/>
</div> */
}
