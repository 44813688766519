import React, { useState, useContext } from 'react';
import CommentIcon from '@mui/icons-material/Comment';

// import CommentDraggableDialog from './CommentDraggableDialog';

import { DialogContext } from './../../../../../context/DialogContext';

function CommentsItem({
  itemKey,
  itemValue,
  rowNumber,
  keyForMap,
  itemStyle,
  item,
  currentManagerAccountId,
  checked,
  rawArrayOfData
}) {
  const { setItemId, setNewsFeedDialogOpen } = useContext(DialogContext);

  // const [open, setOpen] = useState(false);

  // const handleClose = () => {
  //   setOpen(false);
  // };

  if (
    ([
      'folder',
      'facebook_ads_campaign',
      'google_ads_campaign',
      'cm360_campaign',
      'dv360_insertion_order',
      'snapchat_ads_campaign',
      'tiktok_ads_campaign',
      'pinterest_ads_campaign',
      'readpeak_campaign',
      'strossle_campaign',
      'amazon_dsp_order',
      'taboola_ads_campaign',
      'microsoft_advertising_campaign',
      'apple_search_ads_campaign',
      'adform_order',
      'linkedin_ads_campaign_group',
      'manualDataEntry',
      'task',
      'subTask',
      'taxonomy'
    ].includes(item.type) ||
      item.isSet) &&
    !item.isSetChild
  ) {
    return (
      <div
        key={keyForMap}
        style={{
          maxHeight: '46px',
          backgroundColor: checked
            ? itemStyle['checkedBackgroundColor']
            : itemStyle['backgroundColor'],
          color: itemStyle['color'],
          // backgroundColor: 'rgba(255, 255, 255, 1)',
          borderRight: itemStyle['border'],
          borderBottom: itemStyle['border'],
          // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
          fontSize: '12px',
          gridRowStart: rowNumber + 2,
          gridRowEnd: rowNumber + 2,
          display: 'grid',
          height: '100%',
          width: '100%',
          padding: '12px 15px',
          whiteSpace: 'nowrap'
        }}
      >
        <span
          style={{
            margin: 'auto'
          }}
        >
          {/* {open && (
            <CommentDraggableDialog
              open={open}
              setOpen={setOpen}
              handleClose={handleClose}
              item={item}
              currentManagerAccountId={currentManagerAccountId}
              rawArrayOfData={rawArrayOfData}
            >
          
            </CommentDraggableDialog>
          )} */}
          <p
            className="hover-grey"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setItemId(item._id);
              setNewsFeedDialogOpen(true);
            }}
          >
            <span
              style={{
                visibility:
                  item.comments && parseInt(item.comments) > 0
                    ? 'visible'
                    : 'hidden'
              }}
            >
              {item.comments && parseInt(item.comments) > 0 ? item.comments : 0}
            </span>{' '}
            <CommentIcon style={{ fontSize: '10px' }} />
          </p>
          <p
            style={{
              fontSize: '8px',
              color: '#bcbcbc',
              visibility: 'hidden'
            }}
          >
            {' '}
            test
          </p>
        </span>
      </div>
    );
  }

  return (
    <div
      key={keyForMap}
      style={{
        maxHeight: '46px',
        backgroundColor: checked
          ? itemStyle['checkedBackgroundColor']
          : itemStyle['backgroundColor'],
        color: itemStyle['color'],
        // backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRight: itemStyle['border'],
        borderBottom: itemStyle['border'],
        // borderBottom: '1px solid rgba(200, 200, 200, 0.5)',
        fontSize: '12px',
        gridRowStart: rowNumber + 2,
        gridRowEnd: rowNumber + 2,
        display: 'grid',
        height: '100%',
        width: '100%',
        padding: '12px 15px',
        whiteSpace: 'nowrap'
      }}
    ></div>
  );
}

export default CommentsItem;
