import React, { Fragment, useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import SelectSource from './SelectSource';
// import SelectCampaign from './SelectCampaign';

// import { makeStyles } from '@mui/styles';

// import '../draggableDialog.css';

// import DisabledSimpleSelect from './DisabledSimpleSelect';
// import DisabledDatePickerStart from './DisabledDatePickerStart';
// import DisabledDatePickerStop from './DisabledDatePickerStop';

import StorageIcon from '@mui/icons-material/Storage';

import { useSnackbar } from 'notistack';

import { useQuery, useMutation } from '@apollo/client';

import { addDataSourceRelationMutation } from './../../../graphql/queries';

import Facebook from './facebook/Facebook';
import Snapchat from './snapchat/Snapchat';
import TikTok from './tiktok/TikTok';
import LinkedIn from './linkedin/LinkedIn';
import Google from './google/Google';
import CM360 from './cm360/CM360';
import DV360 from './dv360/DV360';

import Adform from './adform/Adform';

import SelectSelector from './generic/SelectSelector';

import formatDateYYYYMMDD from './../roitable_functions/formatDateYYYYMMDD';

import { InterfaceContext } from '../../../context/InterfaceContext';

import { useTheme } from '@emotion/react';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        // style={
        //   {
        //     // maxWidth: '860px',
        //     // width: '860px',
        //     // height: '90vh',
        //     // maxHeight: '910px'
        //     // maxHeight: '60vh'
        //     // maxHeight: '800px'
        //   }
        // }
      />
    </Draggable>
  );
}

// const useStyles = makeStyles((theme) => ({
//   paper: {
//     position: 'absolute',
//     width: 760,
//     // height: 800,
//     height: '83%',
//     // border: '1px solid grey',
//     // padding: '20px 20px',
//     // backgroundColor: theme.palette.background.paper,
//     // border: '2px solid #000',
//     // border: '1px solid rgba(200, 200, 200, 0.5)',
//     // boxShadow: theme.shadows[5],
//     //padding: '20px',
//     // padding: theme.spacing(2, 4, 3),
//     // padding: theme.spacing(4),
//     // borderRadius: '5px',
//     outline: 0
//     // overflow: 'scroll'
//   },
//   root: {
//     '& > *': {
//       margin: theme.spacing(1)
//     }
//   }
// }));

export default function DraggableDialog({
  children,
  open,
  setOpen,
  currentManagerAccountId,
  checkedBoxes
  // handleClose
}) {
  const theme = useTheme();
  // const classes = useStyles();
  // const [open, setOpen] = React.useState(false);

  const { setTypeItemToggledItem, setTypeItemAnchorRef } =
    useContext(InterfaceContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setTypeItemToggledItem(null);
    setTypeItemAnchorRef(null);
    setOpen(false);
    setDataSource();
    setCampaign({});
    // setStartDate(formatDateYYYYMMDD(new Date()));
    // setEndDate(formatDateYYYYMMDD(new Date()));
    toggleDatePickerDisabled(true);

    console.log('cloooose');
  };

  const [addDataSourcerelation, { called }] = useMutation(
    addDataSourceRelationMutation
  );

  // ############ calc first and last day of month:

  var todaysDate = new Date();
  var firstDayOfMonth = new Date(
    todaysDate.getFullYear(),
    todaysDate.getMonth(),
    1
  );
  // firstDayOfMonth.setHours(5);

  var lastDayOfMonth = new Date(
    todaysDate.getFullYear(),
    todaysDate.getMonth() + 1,
    0
  );
  // lastDayOfMonth.setHours(5);

  const default_date_start_first_date_of_month_as_string =
    formatDateYYYYMMDD(firstDayOfMonth);
  // .toISOString()
  // .split('T')[0];

  const default_date_stop_last_date_of_month_as_string =
    formatDateYYYYMMDD(lastDayOfMonth);
  // .toISOString()
  // .split('T')[0];

  // const onSaveHandler = () => {
  //   const documentType = 'facebookAds';
  //   // const dataSource = 'data_source';

  //   let addDocumentInput = {
  //     action: 'CREATE_DOCUMENT',
  //     managerAccountId: currentManagerAccountId,
  //     // documentType: 'container',
  //     documentType,
  //     newDocumentAccountAccessLevel: 1,
  //     checkedDocumentId: checkedBoxes[0]._id
  //     // document: {}
  //   };

  //   if (checkedBoxes[0].level === 1) {
  //     addDocumentInput = {
  //       ...addDocumentInput,
  //       level: checkedBoxes[0].level,
  //       level_1_container_id: checkedBoxes[0].level_1_container_id,
  //       dataSourceRelation: {
  //         data_source: dataSource.dataSourceRef,
  //         dataSourceAccountId: dataSource.dataSourceAccountId,
  //         dataSourceCampaignId: dataSource.dataSourceCampaignId,
  //         startDate: startDate,
  //         endDate: endDate
  //       }
  //     };
  //   }

  //   if (checkedBoxes[0].level > 1) {
  //     addDocumentInput = {
  //       ...addDocumentInput,
  //       level: checkedBoxes[0].level,
  //       level_1_container_id: checkedBoxes[0].level_1_container_id,
  //       level_2_container_id: checkedBoxes[0].level_2_container_id,
  //       level_3_container_id: checkedBoxes[0].level_3_container_id,
  //       level_4_container_id: checkedBoxes[0].level_4_container_id,
  //       level_5_container_id: checkedBoxes[0].level_5_container_id,
  //       level_6_container_id: checkedBoxes[0].level_6_container_id,
  //       level_7_container_id: checkedBoxes[0].level_7_container_id,
  //       level_8_container_id: checkedBoxes[0].level_8_container_id,
  //       dataSourceRelation: {
  //         data_source: dataSource.dataSourceRef,
  //         dataSourceAccountId: dataSource.dataSourceAccountId,
  //         dataSourceCampaignId: campaign.campaign_id,
  //         startDate: startDate,
  //         endDate: endDate
  //       }
  //     };
  //   }

  //   if (
  //     !dataSource.dataSourceRef ||
  //     !dataSource.dataSourceAccountId ||
  //     !campaign.campaign_id ||
  //     !startDate ||
  //     !endDate
  //   ) {
  //     return;
  //   }

  //   addDataSourcerelation({ variables: { input: addDocumentInput } });

  //   handleClose();
  // };

  const [dataSource, setDataSource] = useState();

  const [campaign, setCampaign] = useState({});

  const [datePickerDisabled, toggleDatePickerDisabled] = useState(true);

  // const [startDate, setStartDate] = useState(
  //   new Date().toISOString().split('T')[0]
  // );

  // const [endDate, setEndDate] = useState(
  //   new Date().toISOString().split('T')[0]
  // );

  useEffect(() => {
    if (campaign && campaign.campaign_id) {
      toggleDatePickerDisabled(false);
    } else {
      toggleDatePickerDisabled(true);
    }
  }, [campaign]);

  let renderSource;

  let dataSourceRef =
    dataSource && dataSource.dataSourceRef ? dataSource.dataSourceRef : '';

  const documentTypeDict = {
    GoogleAdsDataSource: 'googleAds',
    AppleSearchAdsDataSource: 'appleSearchAds',
    DV360DataSource: 'dv360',
    FacebookAdsDataSource: 'facebookAds',
    SnapchatAdsDataSource: 'snapchatAds',
    TikTokAdsDataSource: 'tikTokAds',
    PinterestAdsDataSource: 'pinterestAds',
    ReadpeakDataSource: 'readpeak',
    StrossleDataSource: 'strossle',
    AmazonDspDataSource: 'amazonDsp',
    TaboolaAdsDataSource: 'taboolaAds',
    MicrosoftAdvertisingDataSource: 'microsoftAdvertising',
    LinkedInAdsDataSource: 'linkedInAds',
    CM360DataSource: 'cm360',
    AdformDataSource: 'adform'
  };

  switch (dataSourceRef) {
    case 'GoogleAdsDataSource':
    case 'AppleSearchAdsDataSource':
    // case 'DV360DataSource':
    case 'FacebookAdsDataSource':
    case 'SnapchatAdsDataSource':
    case 'TikTokAdsDataSource':
    case 'PinterestAdsDataSource':
    case 'ReadpeakDataSource':
    case 'StrossleDataSource':
    case 'AmazonDspDataSource':
    case 'TaboolaAdsDataSource':
    case 'MicrosoftAdvertisingDataSource':
    case 'AppleSearchAdsDataSource':
    case 'LinkedInAdsDataSource':
    case 'CM360DataSource':
      // case 'AdformDataSource':
      renderSource = (
        <SelectSelector
          checkedBoxes={checkedBoxes}
          currentManagerAccountId={currentManagerAccountId}
          dataSource={dataSource}
          setOpen={setOpen}
          setDataSource={setDataSource}
          default_date_start_first_date_of_month_as_string={
            default_date_start_first_date_of_month_as_string
          }
          default_date_stop_last_date_of_month_as_string={
            default_date_stop_last_date_of_month_as_string
          }
          documentType={documentTypeDict[dataSourceRef]}
        />
      );

      break;

    case 'AdformDataSource':
      renderSource = (
        <Adform
          checkedBoxes={checkedBoxes}
          currentManagerAccountId={currentManagerAccountId}
          dataSource={dataSource}
          setOpen={setOpen}
          setDataSource={setDataSource}
          default_date_start_first_date_of_month_as_string={
            default_date_start_first_date_of_month_as_string
          }
          default_date_stop_last_date_of_month_as_string={
            default_date_stop_last_date_of_month_as_string
          }
          documentType={documentTypeDict[dataSourceRef]}
        />
      );
      break;

    // case 'FacebookAdsDataSource':
    //   renderSource = (
    //     <Facebook
    //       checkedBoxes={checkedBoxes}
    //       currentManagerAccountId={currentManagerAccountId}
    //       dataSource={dataSource}
    //       setOpen={setOpen}
    //       setDataSource={setDataSource}
    //       default_date_start_first_date_of_month_as_string={
    //         default_date_start_first_date_of_month_as_string
    //       }
    //       default_date_stop_last_date_of_month_as_string={
    //         default_date_stop_last_date_of_month_as_string
    //       }
    //     />
    //   );
    //   break;
    // case 'SnapchatAdsDataSource':
    //   renderSource = (
    //     <Snapchat
    //       checkedBoxes={checkedBoxes}
    //       currentManagerAccountId={currentManagerAccountId}
    //       dataSource={dataSource}
    //       setOpen={setOpen}
    //       setDataSource={setDataSource}
    //       default_date_start_first_date_of_month_as_string={
    //         default_date_start_first_date_of_month_as_string
    //       }
    //       default_date_stop_last_date_of_month_as_string={
    //         default_date_stop_last_date_of_month_as_string
    //       }
    //     />
    //   );
    //   break;

    // case 'TikTokAdsDataSource':
    //   renderSource = (
    //     <TikTok
    //       checkedBoxes={checkedBoxes}
    //       currentManagerAccountId={currentManagerAccountId}
    //       dataSource={dataSource}
    //       setOpen={setOpen}
    //       setDataSource={setDataSource}
    //       default_date_start_first_date_of_month_as_string={
    //         default_date_start_first_date_of_month_as_string
    //       }
    //       default_date_stop_last_date_of_month_as_string={
    //         default_date_stop_last_date_of_month_as_string
    //       }
    //     />
    //   );
    //   break;

    // case 'LinkedInAdsDataSource':
    //   renderSource = (
    //     <LinkedIn
    //       checkedBoxes={checkedBoxes}
    //       currentManagerAccountId={currentManagerAccountId}
    //       dataSource={dataSource}
    //       setOpen={setOpen}
    //       setDataSource={setDataSource}
    //       default_date_start_first_date_of_month_as_string={
    //         default_date_start_first_date_of_month_as_string
    //       }
    //       default_date_stop_last_date_of_month_as_string={
    //         default_date_stop_last_date_of_month_as_string
    //       }
    //     />
    //   );
    //   break;
    // case 'GoogleAdsDataSource':
    //   renderSource = (
    //     <Google
    //       checkedBoxes={checkedBoxes}
    //       currentManagerAccountId={currentManagerAccountId}
    //       dataSource={dataSource}
    //       setOpen={setOpen}
    //       setDataSource={setDataSource}
    //       default_date_start_first_date_of_month_as_string={
    //         default_date_start_first_date_of_month_as_string
    //       }
    //       default_date_stop_last_date_of_month_as_string={
    //         default_date_stop_last_date_of_month_as_string
    //       }
    //     />
    //   );
    //   break;
    // case 'CM360DataSource':
    //   renderSource = (
    //     <CM360
    //       checkedBoxes={checkedBoxes}
    //       currentManagerAccountId={currentManagerAccountId}
    //       dataSource={dataSource}
    //       setOpen={setOpen}
    //       setDataSource={setDataSource}
    //       default_date_start_first_date_of_month_as_string={
    //         default_date_start_first_date_of_month_as_string
    //       }
    //       default_date_stop_last_date_of_month_as_string={
    //         default_date_stop_last_date_of_month_as_string
    //       }
    //     />
    //   );
    //   break;
    case 'DV360DataSource':
      renderSource = (
        <DV360
          checkedBoxes={checkedBoxes}
          currentManagerAccountId={currentManagerAccountId}
          dataSource={dataSource}
          setOpen={setOpen}
          setDataSource={setDataSource}
          default_date_start_first_date_of_month_as_string={
            default_date_start_first_date_of_month_as_string
          }
          default_date_stop_last_date_of_month_as_string={
            default_date_stop_last_date_of_month_as_string
          }
        />
      );
      break;
  }

  const body = (
    <div
    // className={classes.paper}
    >
      <div
        className="dataSource"
        style={{
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'column'
          // gridTemplateRows: '80px auto'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {open && (
            <SelectSource
              dataSource={dataSource}
              setDataSource={setDataSource}
              currentManagerAccountId={currentManagerAccountId}
              checkedBoxes={checkedBoxes}
            />
          )}
          <div
            style={{ padding: '5px', textAlign: 'right', visibility: 'hidden' }}
          >
            + Add new Data Source
          </div>
        </div>

        <div style={{ display: 'grid', gridRowStart: 2, gridRowEnd: 2 }}>
          {renderSource}
          {/* <Facebook checkedBoxes={checkedBoxes} currentManagerAccountId={currentManagerAccountId} dataSource={dataSource} setOpen={setOpen} setDataSource={setDataSource}/> */}
        </div>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Dialog
        className="draggable-dialog"
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={{
          style: {
            maxWidth: '860px',
            width: '860px',
            height: '90vh',
            maxHeight: '910px',
            backgroundImage:
              theme?.palette?.mode === 'dark' &&
              'linear-gradient(to top, rgb(55, 55, 66), rgb(75, 75, 86))'
          }
        }}
      >
        <DialogTitle
          style={{ cursor: 'move', paddingTop: '40px' }}
          id="draggable-dialog-title"
        >
          <span style={{ paddingLeft: '23px' }}>
            <StorageIcon
              style={{ verticalAlign: 'text-bottom', paddingRight: '5px' }}
            />{' '}
            Add a Data Source
          </span>
        </DialogTitle>
        <DialogContent style={{ padding: '0px 50px' }}>{body}</DialogContent>
        {/* <DialogActions style={{ padding: '20px' }}>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Save
          </Button>
        </DialogActions> */}
      </Dialog>
    </Fragment>
  );
}
