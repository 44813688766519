import React, { useState, useEffect, useRef } from 'react';

import { styled, useTheme } from '@mui/material/styles';

import Tooltip from '@mui/material/Tooltip';
import ListWidgetMenu from './ListWidgetMenu';

import WidgetDescriptionMenu from './../WidgetDescriptionMenu';

function ListWidget({
  numberOfReset,
  item,
  widget,
  displayMode,
  updateWidget,
  widgetIndex,
  editMode,
  dropOnWidget,
  dragOver,
  dragStart,
  dragEnd,
  isDraggingWidget,
  setIsDraggingWidget
}) {
  const [isWidgetDescriptionMenuOpen, setIsWidgetDescriptionMenuOpen] =
    useState(false);
  const [isDraggingThisWidget, setIsDraggingThisWidget] = useState(false);
  console.log('widget: ', widget);
  const [displaySaveButton, setDisplaySaveButton] = useState(false);
  const inputRef = useRef(null);

  const theme = useTheme();
  const [edit, toggleEdit] = useState(false);

  const [valueInput, setValueInput] = useState('');
  const textInput = valueInput;
  const setTextInput = setValueInput;

  const [nameInput, setNameInput] = useState(widget.name);

  const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);

  const options = widget.options;

  const [valueIsEdited, setValueIsEdited] = useState(false);

  const [defaultValue, setDefaultValue] = useState(widget.value);
  const [displayedOptions, setDisplayedOptions] = useState([]);

  const [originalWidgetCopy, setOriginalWidgetCopy] = useState({ ...widget });

  useEffect(() => {
    let displayedOptions1 = options;
    let displayedOptions2 = [];
    if (textInput && !editMode) {
      displayedOptions1 = options.filter((option) =>
        option.value.includes(textInput)
      );
      displayedOptions2 = options.filter(
        (option) => !option.value.includes(textInput)
      );
    }
    console.log('test1234123>row135');
    setDisplayedOptions([...displayedOptions1, ...displayedOptions2]);

    // setDisplaySaveButton(displayedOptions.length || options);
  }, [options, textInput]);

  useEffect(() => {
    if (textInput) {
      setValueIsEdited(true);
    }

    setValueInput(widget.value);
  }, [widget.value]);
  const setOptions = (options) => {
    updateWidget({ ...widget, options }, widgetIndex);
  };

  useEffect(() => {
    setValueIsEdited(false);
  }, [numberOfReset]);

  // const [options, setOptions] = useState([...widget.options]);

  const onChangeHandler = (e) => {
    setValueInput(e.target.value);
    // if (editMode) {
    //   setNameInput(e.target.value);
    // } else {
    //   setValueInput(e.target.value);
    // }
  };

  // const onClickHandler = () => {};

  // const onSaveHandler = () => {
  //   console.log('onSaveHandler');
  //   updateWidget({ ...widget, value: textInput }, index);
  //   toggleEdit(false);
  // };

  const handleKeyDown = (event) => {
    // event.preventDefault();
    console.log('event: ', event);
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      setTextInput(widget.value);
      toggleEdit(false);
      return;
    }

    if (event.key === 'Enter') {
      console.log('do validate');

      updateWidget(
        { ...widget, ['value']: textInput || originalWidgetCopy.value },
        widgetIndex
      );

      if (!textInput) {
        setValueIsEdited(false);
      }

      toggleEdit(false);
      return;
    }

    // if (editMode) {
    //   return;
    // }
    console.log('test1234123>row108');
    if (
      ['Tab', 'ArrowDown'].includes(event.key) &&
      options.length - 1 > selectedOptionIndex
    ) {
      console.log('tab');
      setSelectedOptionIndex(selectedOptionIndex + 1);
      setValueInput(displayedOptions[selectedOptionIndex + 1].value);
      return;
    }
    if (['ArrowUp'].includes(event.key) && 0 < selectedOptionIndex) {
      console.log('tab');
      setSelectedOptionIndex(selectedOptionIndex - 1);
      setValueInput(displayedOptions[selectedOptionIndex - 1].value);
      return;
    }
    console.log('test1234123>row133');
    setSelectedOptionIndex(-1);
    console.log('test1234123>row125');
    // let displayedOptions1 = options;
    // let displayedOptions2 = [];
    // if (textInput && !editMode) {
    //   displayedOptions1 = options.filter((option) =>
    //     option.value.includes(textInput)
    //   );
    //   displayedOptions2 = options.filter(
    //     (option) => !option.value.includes(textInput)
    //   );
    // }
    // console.log('test1234123>row135');
    // setDisplayedOptions([...displayedOptions1, ...displayedOptions2]);

    // console.log('set input');
    // setTextInput(event.target.value);
  };

  // const onBlurHandler = (event) => {
  //   console.log('event: ', event);
  //   event.preventDefault();
  //   toggleEdit(false);
  // };

  console.log('options123: ', options);

  // const textInput = editMode ? nameInput : valueInput;

  const numUpper = (textInput?.match(/[A-Z]/g) || []).length;
  const numLarge = (textInput?.match(/[MmWw]/g) || []).length;
  const theRest = textInput?.length - numUpper;

  return (
    <div
      key={widgetIndex}
      onDrop={(e) => dropOnWidget(e, widget, widgetIndex)}
      onContextMenu={(e) => {
        e.preventDefault();
        console.log('on right click');
        setIsWidgetDescriptionMenuOpen(true);
        return false;
      }}
      style={{ paddingLeft: '3px' }}
    >
      <div
        style={{
          padding: '4px',
          // color: 'black',
          background: 'white',
          // border: '1px solid lightgrey',
          display: 'inline-block',
          borderRadius: '5px',
          margin: '0px 0.5px',
          transform: 'translate(0, 0)',
          boxShadow:
            !isDraggingThisWidget && 'rgba(0, 0, 0, 0.2) 0px 3px 5px 1px'
        }}
        onDragOver={(e) => dragOver(e, widget, widgetIndex)}
        draggable={!edit && editMode}
        onDragStart={(e) => {
          dragStart(e, widget, 'item_widgets', widgetIndex);
          setIsDraggingThisWidget(true);
        }}
        onDragEnd={(e) => {
          dragEnd(e, widget, widgetIndex);
          setIsDraggingThisWidget(false);
        }}
      >
        <div
          onClick={() => {
            toggleEdit(true);
            if (!editMode && !valueIsEdited) {
              setTextInput('');
            }
          }}
          // onBlur={onBlurHandler}
          // onMouseDown={(e) => {
          //   e.preventDefault();
          // }}
        >
          {/* {displayMode === 'EXAMPLE' ? widget.value : widget.name} */}
          {edit ? (
            <div
            // onBlur={onBlurHandler}
            // onBlur={(e) => {
            //   e.stopPropagation();
            // }}
            // onMouseDown={(e) => {
            //   e.preventDefault();
            // }}
            >
              <div>
                <input
                  ref={inputRef}
                  // onBlur={(e) => {
                  //   e.stopPropagation();
                  // }}
                  autoFocus
                  style={{
                    fontFamily: 'Roboto, Raleway, sans-serif',
                    lineHeight: '1.5',
                    fontSize: '12px',
                    letterSpacing: '0.2px',
                    color: 'black',
                    width:
                      numUpper * 0.35 +
                      numLarge * 1 +
                      textInput.length * 0.925 +
                      'ch',
                    minWidth: '1ch',
                    outline: 'none',
                    border: 'none'
                  }}
                  value={textInput}
                  onChange={onChangeHandler}
                  onKeyDown={handleKeyDown}
                  // onMouseDown={(e) => {
                  //   e.preventDefault();
                  // }}
                />
                {/* <SaveIcon style={{ fontSize: '10px' }} /> */}
              </div>
              <ListWidgetMenu
                setValueIsEdited={setValueIsEdited}
                originalWidgetCopy={originalWidgetCopy}
                displayedOptions={displayedOptions}
                valueIsEdited={valueIsEdited}
                setDisplaySaveButton={setDisplaySaveButton}
                inputRef={inputRef}
                textInput={textInput}
                setTextInput={setTextInput}
                widgetIndex={widgetIndex}
                options={options}
                setOptions={setOptions}
                editMode={editMode}
                widget={widget}
                updateWidget={updateWidget}
                toggleEdit={toggleEdit}
              />
            </div>
          ) : (
            <Tooltip
              title={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '5px',
                    maxWidth: '200px'
                    // padding: '2px'
                  }}
                >
                  <span style={{ width: '100%', textAlign: 'center' }}>
                    {'* List *'}
                  </span>

                  <span
                    style={{
                      maxWidth: '190px',
                      whiteSpace: 'pre-line'
                      // paddingRight: '10px'
                      // padding: '5px'
                    }}
                  >
                    {widget.description}
                  </span>
                </div>
              }
            >
              <span
                style={{
                  color: 'black',
                  cursor: editMode
                    ? isDraggingWidget
                      ? 'grabbing'
                      : 'grab'
                    : 'text'
                }}
                onMouseDown={() => setIsDraggingWidget(true)}
                onMouseUp={() => setIsDraggingWidget(false)}
              >
                {/* {displayMode === 'EXAMPLE' ? widget.value : widget.name} */}
                {widget.value || (
                  <span style={{ visibility: 'hidden' }}>X</span>
                )}
              </span>
            </Tooltip>
          )}
          {isWidgetDescriptionMenuOpen && (
            <WidgetDescriptionMenu
              isWidgetDescriptionMenuOpen={isWidgetDescriptionMenuOpen}
              setIsWidgetDescriptionMenuOpen={setIsWidgetDescriptionMenuOpen}
              // inputRef={inputRef}
              // textInput={textInput}
              // setTextInput={setTextInput}
              widgetIndex={widgetIndex}
              // options={options}
              // setOptions={setOptions}
              editMode={editMode}
              widget={widget}
              updateWidget={updateWidget}
              toggleEdit={toggleEdit}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ListWidget;
