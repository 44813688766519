import React, { useContext } from 'react';
import { withStyles, makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { columnDictionary } from './../../column-menu/columns';

import { InterfaceContext } from '../../../../context/InterfaceContext';

export default function CustomizedTooltips({ itemKey, children }) {
  const { rightClickMenuOpen } = useContext(InterfaceContext);

  // if (
  //   [
  //     'members',
  //     'labels',
  //     'pace',
  //     'paceYesterday',
  //     'calcPaceYesterday',
  //     'calcDailyBudget',
  //     'allowMissingBudget',
  //     'allowMissingClicks'
  //     // 'allowImpressionsBeforeStartDate',
  //     // 'allowImpressionsAfterEndDate'
  //   ].includes(itemKey)
  // ) {
  //   showTooltip = true;
  // }

  const typographyStyle = { fontSize: '14px' };
  let title = (
    <React.Fragment>
      <Typography style={typographyStyle} color="inherit">
        Tooltip with HTML
      </Typography>
      <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
      {"It's very engaging. Right?"}
    </React.Fragment>
  );

  switch (itemKey) {
    case 'members':
      title = (
        <React.Fragment>
          <Typography style={typographyStyle} color="inherit">
            Members
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {'Add Members in order to filter by member.'}
        </React.Fragment>
      );

      break;
    case 'labels':
      title = (
        <React.Fragment>
          <Typography style={typographyStyle} color="inherit">
            Labels
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {'Add labels in order to filter by label.'}
        </React.Fragment>
      );

      break;
    case 'budgetLeft':
      title = (
        <React.Fragment>
          <Typography style={typographyStyle} color="inherit">
            Budget Left
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'Budget - Cost. The date picker needs to cover the whole date span in order for the budget left to be calculated.'
            // 'Cost / (Budget / (Total number of days) * Days since start). The date picker needs to cover the whole date span in order for the pace to be calculated.'
          }
        </React.Fragment>
      );

      break;
    case 'pace':
      title = (
        <React.Fragment>
          <Typography style={typographyStyle} color="inherit">
            Total Pace
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'The date picker needs to cover the whole date span in order for the total pace to be calculated.'
            // 'Cost / (Budget / (Total number of days) * Days since start). The date picker needs to cover the whole date span in order for the pace to be calculated.'
          }
        </React.Fragment>
      );

      break;

    case 'paceYesterday':
      title = (
        <React.Fragment>
          <Typography style={typographyStyle} color="inherit">
            Pace Yesterday
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'Cost Yesterday / Daily Budget Yesterday. The date picker needs to cover the whole date span in order for the total pace to be calculated.'
            // 'Cost / (Budget / (Total number of days) * Days since start). The date picker needs to cover the whole date span in order for the pace to be calculated.'
          }
        </React.Fragment>
      );

      break;

    case 'calcPaceYesterday':
      title = (
        <React.Fragment>
          <Typography style={typographyStyle} color="inherit">
            Calculated Pace Yesterday
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'Cost Yesterday / Calculated Daily Budget Yesterday. The date picker needs to cover the whole date span in order for the total pace to be calculated.'
            // 'Cost / (Budget / (Total number of days) * Days since start). The date picker needs to cover the whole date span in order for the pace to be calculated.'
          }
        </React.Fragment>
      );

      break;

    case 'calcDailyBudget':
      title = (
        <React.Fragment>
          <Typography style={typographyStyle} color="inherit">
            Calculated Daily Budget
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            '(Budget - Cost)/Number of days left. The date picker needs to cover the whole date span in order for the pace to be calculated.'
          }
        </React.Fragment>
      );

      break;

    case 'searchImpressionShare':
      title = (
        <React.Fragment>
          <Typography style={typographyStyle} color="inherit">
            Calculated Daily Budget
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'Due to that Google Ads aint reporting <10% search impressions share, NexusTable is unable to 100% accurately display this value when aggregating daily data. NexusTable seems to get this figure pretty close though.'
          }
        </React.Fragment>
      );

      break;
    case 'allowMissingBudget':
      title = (
        <React.Fragment>
          <Typography style={typographyStyle} color="inherit">
            Allow missing budget
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'This is purely to stop warnings related to data sources without budget and to prevent NexusAssistant to take actions on those warnings.'
          }
        </React.Fragment>
      );

      break;
    case 'allowMissingClicks':
      title = (
        <React.Fragment>
          <Typography style={typographyStyle} color="inherit">
            Allow missing clicks
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'This is purely to stop warnings related to data sources without clicks.'
          }
        </React.Fragment>
      );

      break;

    case 'allowAbnormallyHighCtr':
      title = (
        <React.Fragment>
          <Typography style={typographyStyle} color="inherit">
            Allow abnormally high ctr
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {'This is purely to stop warnings related to abnormally high ctr.'}
        </React.Fragment>
      );

      break;

    case 'allowMissingImpressionsYesterday':
      title = (
        <React.Fragment>
          <Typography style={typographyStyle} color="inherit">
            Allow missing impressions yesterday
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'This is purely to stop warnings related to data sources without impressions yesterday.'
          }
        </React.Fragment>
      );

      break;
    case 'allowImpressionsBeforeStartDate':
      title = (
        <React.Fragment>
          <Typography style={typographyStyle} color="inherit">
            Allow impressions before start date
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'This is purely to stop warnings related to impressions before start date and to prevent NexusAssistant to take actions on those warnings.'
          }
        </React.Fragment>
      );

      break;
    case 'allowImpressionsAfterEndDate':
      title = (
        <React.Fragment>
          <Typography style={typographyStyle} color="inherit">
            Allow impressions after end date
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '} */}
          {
            'This is purely to stop warnings related to impressions after end date and to prevent NexusAssistant to take actions on those warnings.'
          }
        </React.Fragment>
      );

      break;

    default:
      title = (
        <React.Fragment>
          <Typography style={typographyStyle} color="inherit">
            {columnDictionary[itemKey]}
          </Typography>
          {/* <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
          {"It's very engaging. Right?"} */}
        </React.Fragment>
      );
  }

  // if (!showTooltip) {
  //   return <React.Fragment></React.Fragment>;
  // }
  return (
    <Tooltip
      title={title}
      followCursor
      arrow
      // placement="right"
      PopperProps={{
        style: {
          visibility: rightClickMenuOpen ? 'hidden' : 'visible'
        }
      }}
    >
      {children}
    </Tooltip>
  );
}
