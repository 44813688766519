import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';

import Button from '@mui/material/Button';

import Checkbox from '@mui/material/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';

import Spinner from './../../../layout/SpinnerSmall';

import {
  ListGoogleAdsCampaignsQuery,
  ListAppleSearchAdsCampaignsQuery,
  ListFacebookAdsCampaignsQuery,
  ListLinkedInAdsCampaignGroupsQuery,
  ListSnapchatAdsCampaignsQuery,
  ListTikTokAdsCampaignsQuery,
  ListPinterestAdsCampaignsQuery,
  ListReadpeakCampaignsQuery,
  ListStrossleCampaignsQuery,
  ListAmazonDspOrdersQuery,
  ListTaboolaAdsCampaignsQuery,
  ListMicrosoftAdvertisingCampaignsQuery,
  ListDV360InsertionOrdersQuery,
  ListCM360CampaignsQuery,
  addDataSourceRelationMutation,
  listAddedDataSourceIdsQuery,
  ListAdformCampaignsQuery,
  scanDocumentMutation,
  AccountToDataSourceSettingsQuery
} from './../../../../graphql/queries';

import { MutationContext } from '../../../../context/MutationContext';

// import { makeStyles } from '@mui/styles';

import Checklist from './../CheckList';

import { useSnackbar } from 'notistack';

import SelectStatus from '../show-more-options/SelectStatus';

import BasicDatePicker from '../../utils/BasicDatePicker';

import SelectTemplate from './../SelectTemplate';

import SelectFilter from './../SelectFilter';

import ScanDocument from '../scan-document/ScanDocument';

import { InterfaceContext } from '../../../../context/InterfaceContext';

// import ScanAndSaveButton from './../scan-document/ScanAndSaveButton';

import { AuthContext } from './../../../../context/AuthContext';
import { AccountContext } from './../../../../context/AccountContext';

// import AdvertisingChannelTypeFilter from '../google/AdvertisingChannelTypeFilter';

import SetFilterComponent from './../set-filter/SetFiltersComponent';
import ShowMoreOptions from '../show-more-options/ShowMoreOptions';

function SelectSelector({
  dataSource,
  currentManagerAccountId,
  checkedBoxes,
  setOpen,
  setDataSource,
  default_date_start_first_date_of_month_as_string,
  default_date_stop_last_date_of_month_as_string,
  documentType
}) {
  const { user } = useContext(AuthContext);
  const { currentManagerAccount } = useContext(AccountContext);
  const [showMore, setShowMore] = useState(false);

  const [templateDocument, setTemplateDocument] = useState({});
  const [listOfCampaigns, setListOfCampaigns] = useState([]);
  const [listOfCampaignsExAdded, setListOfCampaignsExAdded] = useState([]);
  const [campaignFilter, setCampaignFilter] = useState('');

  const [scanResponse, setScanResponse] = useState({});

  const [countries, setCountries] = useState([]);
  const [budgetTypes, setBudgetTypes] = useState([]);

  const [campaign, setCampaign] = useState({});

  const [datePickerDisabled, toggleDatePickerDisabled] = useState(true);

  const [isSet, toggleIsSet] = useState(false);
  const [sFilters, setSFilters] = useState([]);

  const { addDocument } = useContext(MutationContext);

  const { setTypeItemToggledItem, setTypeItemAnchorRef } =
    useContext(InterfaceContext);

  const [awaitingResponse, toggleAwaitingResponse] = useState(false);

  // const [sFilters, setSFilters] = useState([]);

  // const updateSFilters = (filter) => {
  //   let copyOfSFilters = [...sFilters];
  //   const index = copyOfSFilters.findIndex((obj) => obj.type === filter.type);

  //   if (index === -1) {
  //     setSFilters([...copyOfSFilters, filter]);
  //   } else {
  //     copyOfSFilters.splice(index, 1, filter);
  //     setSFilters(copyOfSFilters);
  //   }
  // };

  const [overrideFields, setOverrideFields] = useState([
    // 'allowImpressionsBeforeStartDate',
    // 'allowImpressionsAfterEndDate',
    // 'allowMissingBudget',
    // 'allowMissingClicks',
    // 'allowAbnormallyHighCtr',
    // 'allowMissingImpressionsYesterday'
  ]);

  const essentialDefaultOverrideValues = {
    status: 'READY',
    startDate: default_date_start_first_date_of_month_as_string,
    endDate: default_date_stop_last_date_of_month_as_string
  };
  const [overrideValues, updateOverrideValues] = useState(
    essentialDefaultOverrideValues
  );

  const setOverrideValues = (newState) => {
    const copyOfOverrideValues = { ...newState };

    for (let itemKey of ['action01', 'action02', 'action03']) {
      delete copyOfOverrideValues[itemKey];
      // if (!document[itemKey]?.dataSource) {
      //   delete copyOfOverrideValues[itemKey];
      // }
      if (newState[itemKey]?.dataSource) {
        copyOfOverrideValues[itemKey] = {
          dataSource: newState[itemKey]?.dataSource,
          dataSourceRef: newState[itemKey]?.dataSourceRef,
          [newState[itemKey]?.dataSource]:
            newState[itemKey]?.[newState[itemKey]?.dataSource],
          // name: String,
          dataSourceId: newState[itemKey]?.dataSourceId,
          dataSourceAccountId: newState[itemKey]?.dataSourceAccountId,

          name: newState[itemKey]?.name
        };
      }
    }
    updateOverrideValues(copyOfOverrideValues);
  };
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [overrideStatus, setOverrideStatus] = useState(false);
  const [status, setStatus] = useState('DRAFT');
  const [limitDates, setLimitDates] = useState(false);
  // const [startDate, setStartDate] = useState(
  //   default_date_start_first_date_of_month_as_string
  // );
  // const [endDate, setEndDate] = useState(
  //   default_date_stop_last_date_of_month_as_string
  // );

  const { enqueueSnackbar } = useSnackbar();

  const [scanDocument, { called: scanDocumentCalled }] =
    useMutation(scanDocumentMutation);

  // const useStyles = makeStyles((theme) => ({
  //   paper: {
  //     position: 'absolute',
  //     width: 760,
  //     height: '85%',
  //     border: '1px solid grey',
  //     backgroundColor: theme.palette.background.paper,
  //     outline: 0
  //   },
  //   root: {
  //     '& > *': {
  //       margin: theme.spacing(1)
  //     }
  //   }
  // }));

  // const classes = useStyles();

  // const [addDataSourcerelation, { called }] = useMutation(
  //   addDataSourceRelationMutation
  // );

  useEffect(() => {
    campaign && campaign.campaign_id
      ? toggleDatePickerDisabled(false)
      : toggleDatePickerDisabled(true);
  }, [campaign]);

  const [isSaving, setIsSaving] = useState(false);

  const onSaveHandler = async () => {
    if (awaitingResponse) {
      return;
    }
    setIsSaving(true);
    // const documentType = 'googleAds';

    console.log('isSaving: ', isSaving);

    if (templateDocument?.string === 'MISSING') {
      enqueueSnackbar('Template document missing', {
        variant: 'error'
      });
      return;
    }

    let dataSourceObjects;

    if (sFilters.length) {
      for (let sFilter of sFilters) {
        console.log('sFilter.type:', sFilter.type);
        if (sFilter.string && sFilter.type !== 'STRING') {
          enqueueSnackbar('Error, please reload page and try again.', {
            variant: 'error'
          });
          return;
        }
        if (!sFilter.string && sFilter.type === 'STRING') {
          enqueueSnackbar('Error, empty text field not allowed.', {
            variant: 'error'
          });
          return;
        }
        if (!sFilter?.values?.length && sFilter.type === 'ARRAY') {
          enqueueSnackbar('Error, please select atleast one option.', {
            variant: 'error'
          });
          return;
        }
        if (sFilter.string) {
          const regExName = new RegExp(
            '^[a-zA-Z0-9åäöÅÄÖæøÆØéÉúÚ -_.,+-/\\|*()=:#%&:]*$'
          );
          const regExNameResult = regExName.exec(sFilter.string);
          if (!regExNameResult) {
            enqueueSnackbar(
              'Unallowed characters were used. Only a-zA-Z0-9åäöÅÄÖæøÆØúÚ _.- are allowed.',
              {
                variant: 'error'
              }
            );

            return console.log('Unallowed characters were used.');
          }
        }
      }
    }

    if (!isSet) {
      dataSourceObjects = listOfCampaigns.filter((obj) =>
        checked.includes(obj.id)
      );
      dataSourceObjects = dataSourceObjects.map((obj) => ({
        documentType,
        dataSourceAccountId: dataSource.dataSourceAccountId,
        dataSourceAccountNumber: dataSource.dataSourceAccountNumber,
        dataSourceCampaignId: obj.id,
        dataSourceInsertionOrderId: obj.insertionOrderId,
        dataSourceFilter: campaignFilter,
        startDate: obj.startDate,
        endDate: obj.endDate,
        // status: obj.status === 'ENTITY_STATUS_ACTIVE' ? 'ACTIVE' : 'DRAFT'
        status: 'DRAFT'
      }));
    } else {
      dataSourceObjects = [
        {
          documentType,
          dataSourceAccountId: dataSource.dataSourceAccountId,
          // dataSourceCampaignId: obj.id,
          // dataSourceInsertionOrderId: obj.insertionOrderId,
          // startDate: obj.startDate,
          // endDate: obj.endDate,
          isSet,
          setFilters: sFilters,
          // status: obj.status === 'ENTITY_STATUS_ACTIVE' ? 'ACTIVE' : 'DRAFT'
          status: 'DRAFT',
          dataSourceFilter: campaignFilter
        }
      ];
    }

    for (let field of overrideFields) {
      switch (true) {
        case field === 'dates':
          for (let obj of dataSourceObjects) {
            if (overrideValues['startDate']) {
              if (
                (obj.startDate &&
                  new Date(obj.startDate) <
                    new Date(overrideValues['startDate'])) ||
                (!obj.startDate && overrideValues['startDate'])
              ) {
                obj.startDate = overrideValues['startDate'];
              }
            }
            if (overrideValues['endDate']) {
              if (
                (obj.endDate &&
                  new Date(obj.endDate) >
                    new Date(overrideValues['endDate'])) ||
                (!obj.endDate && overrideValues['endDate'])
              ) {
                obj.endDate = overrideValues['endDate'];
              }
            }
          }
          break;

        case field === 'status':
          for (let obj of dataSourceObjects) {
            obj.status = overrideValues['status'];
          }
          break;
        case [
          'allowImpressionsBeforeStartDate',
          'allowImpressionsAfterEndDate',
          'allowMissingBudget',
          'allowMissingClicks',
          'allowAbnormallyHighCtr',
          'allowMissingImpressionsYesterday'
        ].includes(field):
          for (let obj of dataSourceObjects) {
            obj[field] = Boolean(overrideValues[field]);
          }
          break;
        case ['action01', 'action02', 'action03'].includes(field):
          for (let obj of dataSourceObjects) {
            obj[field] = overrideValues[field];
          }
          break;
      }
    }

    let addDocumentInput = {
      managerAccountId: currentManagerAccountId,
      accountId: checkedBoxes?.[0]?.accountId,
      checkedDocumentId: checkedBoxes?.[0]?._id,
      level: checkedBoxes?.[0]?.level,
      newDocuments: dataSourceObjects,
      templateDescendant: checkedBoxes?.[0]?.templateDescendant
    };
    if (templateDocument?._id) {
      addDocumentInput.nexusDocumentTemplateId = templateDocument?._id;
    }

    // for (let obj of dataSourceObjects) {
    //   if (limitDates && startDate) {
    //     if (
    //       (obj.startDate && new Date(obj.startDate) < new Date(startDate)) ||
    //       (!obj.startDate && startDate)
    //     ) {
    //       obj.startDate = startDate;
    //     }
    //   }
    //   if (limitDates && endDate) {
    //     if (
    //       (obj.endDate && new Date(obj.endDate) > new Date(endDate)) ||
    //       (!obj.endDate && endDate)
    //     ) {
    //       obj.endDate = endDate;
    //     }
    //   }

    //   if (overrideStatus) {
    //     obj.status = status;
    //   }
    // }

    if (checked.length === 0 && !isSet) {
      console.log('list too short');
      return;
    }

    if (
      limitDates &&
      overrideValues['startDate'] &&
      overrideValues['endDate'] &&
      new Date(overrideValues['startDate']) >
        new Date(overrideValues['endDate'])
    ) {
      enqueueSnackbar('End date must be later than or equal to start date', {
        variant: 'error'
      });
      console.log('End date must be later than or equal to start date');
      return;
    }

    try {
      toggleAwaitingResponse(true);
      const res = await addDocument({
        variables: { input: addDocumentInput }
      });
      console.log('SelectSelector>res: ', res);
      if (res?.data?.addDocument?.response === 'Success') {
        toggleAwaitingResponse(false);
        handleClose();
      } else {
        toggleAwaitingResponse(false);
        enqueueSnackbar('Error, please contact support.', {
          variant: 'error'
        });
        throw new Error();
      }
    } catch (error) {
      toggleAwaitingResponse(false);
      console.log('error: ', error);
      // enqueueSnackbar('Error, please contact support.', {
      //   variant: 'error'
      // });
    }
  };

  const onScanHandler = async (action) => {
    const newDocuments = listOfCampaigns
      .filter((obj) => checked.includes(obj.id))
      .map((obj) => ({
        documentType,
        dataSourceAccountId: dataSource.dataSourceAccountId,
        dataSourceCampaignId: obj.id,
        dataSourceInsertionOrderId: obj.insertionOrderId,
        dataSourceFilter: campaignFilter,
        startDate: obj.startDate,
        endDate: obj.endDate,
        // status: obj.status === 'ENTITY_STATUS_ACTIVE' ? 'ACTIVE' : 'DRAFT'
        status: 'DRAFT'
      }));

    if (!newDocuments?.length) {
      enqueueSnackbar('Please select data sources to import.', {
        variant: 'default'
        // persist: true
      });
      return;
    }

    if (!budgetTypes?.length) {
      enqueueSnackbar('Please select atleast one budget type.', {
        variant: 'default'
        // persist: true
      });
      return;
    }

    if (!countries?.length) {
      enqueueSnackbar('Please select atleast one country.', {
        variant: 'default'
        // persist: true
      });
      return;
    }

    const scanDocumentInput = {
      managerAccountId: currentManagerAccountId,
      accountId,
      checkedDocumentId: checkedBoxes[0]._id,
      newDocuments,
      scanSettings: {
        countries,
        budgetTypes
      }
    };

    const res = await scanDocument({ variables: { input: scanDocumentInput } });

    // console.log('res?.data?.scanDocument: ', res?.data?.scanDocument);

    setScanResponse(res?.data?.scanDocument);

    if (action === 'SCANANDSAVE' && res?.data?.scanDocument.response === 'ok') {
      enqueueSnackbar('Scan ok', {
        variant: 'success'
      });
      await onSaveHandler();
    } else if (action === 'SCANANDSAVE') {
      enqueueSnackbar('Scan failed', {
        variant: 'error'
      });
    }
  };

  const handleClose = () => {
    setOverrideValues({});
    setOverrideFields([]);
    setOpen(false);
    setDataSource();
    setCampaign({});
    // setStartDate(new Date().toISOString().split('T')[0]);
    // setEndDate(new Date().toISOString().split('T')[0]);
    toggleDatePickerDisabled(true);
    setTypeItemToggledItem(null);
    setTypeItemAnchorRef(null);
  };

  const accountId = checkedBoxes?.[0]?.accountId;

  const variables = {
    dataSourceInput: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccountId,
      accountId,
      dataSourceId: dataSource._id,
      showMore
    }
  };

  const [skip, toggleSkip] = useState(false);

  let query = '';

  let dataKey = '';
  console.log('documentType: ', documentType);
  switch (documentType) {
    case 'googleAds':
      query = ListGoogleAdsCampaignsQuery;
      dataKey = 'listgoogleadscampaigns';
      break;
    case 'appleSearchAds':
      query = ListAppleSearchAdsCampaignsQuery;
      dataKey = 'listapplesearchadscampaigns';
      break;
    case 'facebookAds':
      query = ListFacebookAdsCampaignsQuery;
      dataKey = 'listfacebookadscampaigns';
      break;
    case 'linkedInAds':
      query = ListLinkedInAdsCampaignGroupsQuery;
      dataKey = 'listlinkedinadscampaigngroups';
      break;
    case 'snapchatAds':
      query = ListSnapchatAdsCampaignsQuery;
      dataKey = 'listsnapchatadscampaigns';
      break;
    case 'tikTokAds':
      query = ListTikTokAdsCampaignsQuery;
      dataKey = 'listtiktokadscampaigns';
      break;

    case 'pinterestAds':
      query = ListPinterestAdsCampaignsQuery;
      dataKey = 'listpinterestadscampaigns';
      break;

    case 'readpeak':
      query = ListReadpeakCampaignsQuery;
      dataKey = 'listreadpeakcampaigns';
      break;

    case 'strossle':
      query = ListStrossleCampaignsQuery;
      dataKey = 'liststrosslecampaigns';
      break;

    case 'amazonDsp':
      query = ListAmazonDspOrdersQuery;
      dataKey = 'listamazondsporders';
      break;

    case 'taboolaAds':
      query = ListTaboolaAdsCampaignsQuery;
      dataKey = 'listtaboolaadscampaigns';
      break;

    case 'microsoftAdvertising':
      query = ListMicrosoftAdvertisingCampaignsQuery;
      dataKey = 'listmicrosoftadvertisingcampaigns';
      break;

    case 'dv360':
      query = ListDV360InsertionOrdersQuery;
      dataKey = 'listdv360insertionorders';
      break;

    case 'cm360':
      query = ListCM360CampaignsQuery;
      dataKey = 'listcm360campaigns';
      break;

    case 'adform':
      query = ListAdformCampaignsQuery;
      dataKey = 'listadformcampaigns';
      break;
  }

  const { data, refetch, error } = useQuery(query, {
    variables,
    fetchPolicy: 'no-cache',
    skip
  });

  useEffect(() => {
    try {
      const mappedListOfDataCampaigns = data[dataKey]?.map((obj) => {
        return {
          ...obj
          //   id: obj.campaignId,
          //   name: `${obj.campaignName}`,
          //   startDate: obj.startDate ? obj.startDate : null,
          //   endDate: obj.endDate ? obj.endDate : null
        };
      });

      mappedListOfDataCampaigns.sort(function (a, b) {
        var nameA = new Date(a.startDate); // ignore upper and lowercase
        var nameB = new Date(b.startDate); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      setListOfCampaigns(mappedListOfDataCampaigns);

      if (mappedListOfDataCampaigns.length > 0) {
        setListOfCampaigns(mappedListOfDataCampaigns);

        // toggleSkip(true);
      }
    } catch (error) {}
  }, [data, dataSource]);

  const [isLoading, toggleIsLoading] = useState(true);
  const [checked, setChecked] = useState([]);

  const clickShowMoreOptionsHandler = () => {
    setShowMoreOptions(!showMoreOptions);
  };

  const [unAddedDataSources30Days, setUnAddedDataSources30Days] =
    useState(true);

  const listAddedDataSouceVariables = {
    input: {
      //managerAccountId: '5e942e92a4f236765ebfb4eb',
      managerAccountId: currentManagerAccountId,
      accountId: checkedBoxes[0]?.accountId,
      dataSourceAccountId: dataSource?.dataSourceAccountId,
      dataSourceType: documentType
    }
  };

  const [
    loadAddedDataSourceIds,
    { called: addedDataSourceIdsCalled, loading, data: addedDataSourceIdsData }
  ] = useLazyQuery(listAddedDataSourceIdsQuery, {
    variables: listAddedDataSouceVariables,
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    loadAddedDataSourceIds();
  }, [dataSource?.dataSourceAccountId]);

  useEffect(() => {
    if (
      unAddedDataSources30Days &&
      dataSource &&
      listOfCampaigns
      // !addedDataSourceIdsCalled
    ) {
      // loadAddedDataSourceIds();

      if (listOfCampaigns && addedDataSourceIdsData?.listAddedDataSourceIds) {
        var thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        console.log('thirtyDaysAgo: ', thirtyDaysAgo);

        setListOfCampaignsExAdded(
          listOfCampaigns.filter(
            (campaign) =>
              !addedDataSourceIdsData?.listAddedDataSourceIds.includes(
                campaign.id
              ) && new Date(campaign.startDate) >= new Date(thirtyDaysAgo)
          )
        );
      }
    } else if (
      !unAddedDataSources30Days &&
      Boolean(listOfCampaignsExAdded.length)
    ) {
      setListOfCampaignsExAdded([]);
    }
  }, [
    unAddedDataSources30Days,
    dataSource,
    addedDataSourceIdsData?.listAddedDataSourceIds,
    listOfCampaigns
  ]);

  const [
    loadAccountToDataSourceSettings,
    {
      called: accountToDataSourceSettingsCalled,
      loading: loadingAccountToDataSourceSettings,
      data: accountToDataSourceSettingsData
    }
  ] = useLazyQuery(AccountToDataSourceSettingsQuery, {
    variables: {
      input: {
        managerAccountId: currentManagerAccountId,
        accountId: checkedBoxes?.[0]?.accountId,
        accountToDataSourceRelationId:
          dataSource?.accountToDataSourceRelationId,
        dataSourceId: dataSource?._id
      }
    },
    fetchPolicy: 'no-cache'
  });

  const [acconuntToDataSourceSettings, setAccountToDataSourceSettings] =
    useState({});

  useEffect(() => {
    if (dataSource.accountToDataSourceRelationId) {
      loadAccountToDataSourceSettings();
    }
  }, [dataSource?.accountToDataSourceRelationId]);

  useEffect(() => {
    if (dataSource.accountToDataSourceRelationId) {
      setAccountToDataSourceSettings(
        accountToDataSourceSettingsData?.accountToDataSourceSettings
      );
      setBudgetTypes(
        accountToDataSourceSettingsData?.accountToDataSourceSettings
          ?.defaultScanSettings?.budgetTypes
      );
      setCountries(
        accountToDataSourceSettingsData?.accountToDataSourceSettings
          ?.defaultScanSettings?.countries
      );

      // ######################

      const defaultNexusDocumentValues =
        accountToDataSourceSettingsData?.accountToDataSourceSettings
          ?.defaultNexusDocumentValues;
      const fields =
        defaultNexusDocumentValues && Object.keys(defaultNexusDocumentValues);
      if (fields?.length) {
        const newOverrideFields = [];
        const newOverrideValues = {};
        for (let field of fields) {
          if (defaultNexusDocumentValues?.[field]) {
            newOverrideFields.push(field);
            newOverrideValues[field] = defaultNexusDocumentValues[field];
          }
        }
        setOverrideFields(newOverrideFields);
        setOverrideValues({
          ...essentialDefaultOverrideValues,
          ...newOverrideValues
        });
      }
    }
  }, [accountToDataSourceSettingsData, dataSource]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
        // gridTemplateRows: '500px auto 100px'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column'
          // gridRowStart: 1,
          // gridRowEnd: 1
        }}
      >
        {['googleAds', 'microsoftAdvertising', 'appleSearchAds'].includes(
          documentType
        ) && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '42px',
                alignItems: 'center'
              }}
            >
              <Checkbox
                checked={isSet}
                onChange={() => toggleIsSet(!isSet)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <span>
                Import a set of ALL campaigns from the selected data source.
              </span>
            </div>
            {isSet && (
              <div style={{ marginTop: '10px', marginLeft: '40px' }}>
                <SetFilterComponent
                  documentType={documentType}
                  sFilters={sFilters}
                  setSFilters={setSFilters}
                />
              </div>
            )}
          </>
        )}
        {!isSet && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '42px',
                alignItems: 'center'
              }}
            >
              <Checkbox
                checked={unAddedDataSources30Days}
                onChange={() =>
                  setUnAddedDataSources30Days(!unAddedDataSources30Days)
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <span>
                Only show data sources that aren't added to account yet, start
                date less than 30 days ago.
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '42px',
                alignItems: 'center'
              }}
            >
              <Checkbox
                checked={showMore}
                onChange={() => setShowMore(!showMore)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <span>Show more campaigns.</span>
            </div>
          </>
        )}
        {['dv360'].includes(documentType) && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '42px',
                alignItems: 'center'
              }}
            >
              <Checkbox
                checked={showMore}
                onChange={() => setShowMore(!showMore)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <span>Show more campaigns.</span>
            </div>
          </>
        )}
        {!isSet && dataSource && dataSource._id && (
          <Checklist
            list={
              unAddedDataSources30Days
                ? listOfCampaignsExAdded
                : listOfCampaigns
            }
            checked={checked}
            setChecked={setChecked}
            isLoading={isLoading}
            toggleIsLoading={toggleIsLoading}
          />
        )}
        {['adform'].includes(documentType) && (
          <div style={{ marginTop: '40px' }}>
            <SelectFilter
              campaignFilter={campaignFilter}
              setCampaignFilter={setCampaignFilter}
            />
          </div>
        )}
      </div>
      <div style={{ gridRow: 2, minHeight: '90px' }}>
        <div style={{ width: '100%', margin: '50px 0px 0px 0px' }}>
          <SelectTemplate
            managerAccountId={currentManagerAccountId}
            accountId={checkedBoxes?.[0]?.accountId}
            value={templateDocument}
            setValue={setTemplateDocument}
            dataSource={dataSource}
          />
        </div>
        {/* <div
          style={{
            cursor: 'pointer',
            paddingTop: '20px',
            paddingBottom: '15px'
          }}
          onClick={clickShowMoreOptionsHandler}
        >
          <span style={{ marginRight: '5px' }}>Show more options</span>{' '}
          {showMoreOptions ? (
            <i className="fas fa-caret-down"></i>
          ) : (
            <i className="fas fa-caret-right"></i>
          )}
        </div> */}

        {/* {showMoreOptions && (
          <>
            <ShowMoreOptions />
          </>
        )} */}
        <ShowMoreOptions
          overrideFields={overrideFields}
          setOverrideFields={setOverrideFields}
          overrideValues={overrideValues}
          setOverrideValues={setOverrideValues}
          managerAccountId={currentManagerAccountId}
          accountId={checkedBoxes?.[0]?.accountId}
          dataSource={dataSource}
        />
      </div>

      {['facebookAds'].includes(documentType) && (
        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
          <ScanDocument
            dataSource={dataSource}
            managerAccountId={currentManagerAccountId}
            accountId={checkedBoxes?.[0]?.accountId}
            checkedDocumentId={checkedBoxes?.[0]?._id}
            level={checkedBoxes?.[0]?.level}
            scanResponse={scanResponse}
            countries={countries}
            setCountries={setCountries}
            budgetTypes={budgetTypes}
            setBudgetTypes={setBudgetTypes}
            // newDocuments={listOfCampaigns
            //   .filter((obj) => checked.includes(obj.id))
            //   .map((obj) => ({
            //     documentType,
            //     dataSourceAccountId: dataSource.dataSourceAccountId,
            //     dataSourceCampaignId: obj.id,
            //     dataSourceInsertionOrderId: obj.insertionOrderId,
            //     dataSourceFilter: campaignFilter,
            //     startDate: obj.startDate,
            //     endDate: obj.endDate,
            //     // status: obj.status === 'ENTITY_STATUS_ACTIVE' ? 'ACTIVE' : 'DRAFT'
            //     status: 'DRAFT'
            //   }))}
          />
        </div>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          alignItems: 'end',
          // gridTemplateColumns: '50px 200px 200px 310px',
          gridRowStart: 3,
          gridRowEnd: 3,
          paddingTop: '20px',
          paddingBottom: '20px'
        }}
      >
        <div
          // className={classes.root}
          style={{ gridColumn: 4, textAlign: 'center' }}
        >
          {awaitingResponse ? (
            <Spinner />
          ) : (
            <>
              <Button
                variant="outlined"
                style={{ marginRight: '10px' }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              {['facebookAds'].includes(documentType) && (
                <Button
                  style={{ minWidth: '91px' }}
                  variant="outlined"
                  color="primary"
                  onClick={onScanHandler}
                >
                  Scan
                </Button>
              )}

              {['facebookAds'].includes(documentType) &&
              currentManagerAccount.scanMode !== 'OPTIONAL' ? (
                <>
                  <Button
                    style={{ minWidth: '91px', marginLeft: '10px' }}
                    variant="contained"
                    color="primary"
                    onClick={() => onScanHandler('SCANANDSAVE')}
                  >
                    Scan & Save
                  </Button>

                  {/* <ScanAndSaveButton
                style={{ minWidth: '91px' }}
                variant="contained"
                color="primary"
                onScanHandler={onScanHandler}
                // onSaveHandler={onSaveHandler}
              >
                Scan & Save
              </ScanAndSaveButton> */}
                </>
              ) : (
                <Button
                  style={{ minWidth: '91px' }}
                  variant="contained"
                  color="primary"
                  onClick={onSaveHandler}
                >
                  Save
                </Button>
              )}
              {/* <Button
            style={{ minWidth: '91px' }}
            variant="contained"
            color="primary"
            onClick={onSaveHandler}
          >
            Save
          </Button> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default SelectSelector;
