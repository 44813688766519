import React, { useState, useEffect, useContext } from 'react';
// import { useQuery, useMutation } from '@apollo/client';

// import {
//   GoogleAdsActionTypesQuery,
//   dataSourcesQuery,
//   updateDocumentMutation
// } from '../../../../../graphql/queries';

import SimpleSelectActionsCM360 from './../../item-components/body/actionTypeItem/cm360/SimpleSelectActions';
import SimpleSelectAttributionTypeCM360 from './../../item-components/body/actionTypeItem/cm360/SimpleSelectAttributionType';
import SimpleSelectActionsDV360 from './../../item-components/body/actionTypeItem/dv360/SimpleSelectActions';
import SimpleSelectAttributionTypeDV360 from './../../item-components/body/actionTypeItem/dv360/SimpleSelectAttributionType';

import SimpleSelectActionsGoogleAds from './../../item-components/body/actionTypeItem/google_ads_campaign/SimpleSelectActions';

import SimpleSelectActionsSnapchatAds from './../../item-components/body/actionTypeItem/snapchat-ads/SimpleSelectActions';
import SimpleSelectActionsTikTokAds from './../../item-components/body/actionTypeItem/tiktok-ads/SimpleSelectActions';
import SimpleSelectActionsPinterestAds from './../../item-components/body/actionTypeItem/pinterest-ads/SimpleSelectActions';
import SimpleSelectActionsReadpeak from './../../item-components/body/actionTypeItem/readpeak/SimpleSelectActions';
import SimpleSelectActionsStrossle from './../../item-components/body/actionTypeItem/strossle/SimpleSelectActions';
import SimpleSelectActionsAmazonDsp from './../../item-components/body/actionTypeItem/amazon-dsp/SimpleSelectActions';
import SimpleSelectActionsTaboolaAds from './../../item-components/body/actionTypeItem/taboola-ads/SimpleSelectActions';

import SimpleSelectActionsMicrosoftAdvertising from './../../item-components/body/actionTypeItem/microsoft-advertising/SimpleSelectActions';
import SimpleSelectActionsAppleSearchAds from './../../item-components/body/actionTypeItem/apple-search-ads/SimpleSelectActions';

import SimpleSelectActionsFacebookAds from './../../item-components/body/actionTypeItem/facebook-ads/SimpleSelectActions';
import SimpleSelectActionsAdform from './../../item-components/body/actionTypeItem/adform/SimpleSelectActions';
import SimpleSelectAdformAdInteraction from './../../item-components/body/actionTypeItem/adform/SimpleSelectAdInteraction';
import SimpleSelectPinterestAdsAdInteraction from './../../item-components/body/actionTypeItem/pinterest-ads/SimpleSelectAdInteraction';
import SimpleSelectConversionType from './../../item-components/body/actionTypeItem/adform/SimpleSelectConversionType';

import SimpleSelectActionsLinkedInAds from './../../item-components/body/actionTypeItem/linkedin-ads/SimpleSelectActions';
import SimpleSelectLinkedInAdsAdInteraction from './../../item-components/body/actionTypeItem/linkedin-ads/SimpleSelectAdInteraction';

import SimpleSelectFacebokAdsConversionWindow from './../../item-components/body/actionTypeItem/facebook-ads/SimpleSelectConversionWindow';
import SimpleSelectActionsGoogleAnalytics4 from './../../item-components/body/actionTypeItem/google-analytics-4/SimpleSelectActions';
import FreeSolo from './../../item-components/body/actionTypeItem/google-analytics-4/FreeSolo';
import SimpleSelectContainsOrEquals from './../../item-components/body/actionTypeItem/google-analytics-4/SimpleSelectContainsOrEquals';
import SimpleSelectDataSources from './../../item-components/body/actionTypeItem/SimpleSelectDataSources';
import Button from '@mui/material/Button';

import GoogleAnalytics4Selects from './../../item-components/body/actionTypeItem/google-analytics-4/GoogleAnalytics4Selects';
import Checkbox from '@mui/material/Checkbox';

import ManualTextInput from './../../item-components/body/actionTypeItem/manual-data-entry/ManualTextInput';

// import { MutationContext } from '../../../../../context/MutationContext';

// import { updateDocumentMutation } from './../../../../../graphql/queries';

// import { useQuery } from '@apollo/client';

// import { dataSourcesQuery } from '../../../../../../graphql/queries';

function PopContent({
  item,
  itemKey,
  currentManagerAccountId,
  setOpen,
  document,
  setDocument
}) {
  // const [skip, setSkip] = useState(false);

  const [inputValue, setInputValue] = useState({});

  const [actionType, setActionType] = useState(null);

  // const [dataSource, setDataSource] = useState(null);

  const [attributionWindow, setAttributionWindow] = useState('');

  const [source, setSource] = useState(null);
  const [medium, setMedium] = useState(null);
  const [campaignName, setCampaignName] = useState(null);
  const [campaignId, setCampaignId] = useState(null);
  const [eventName, setEventName] = useState(null);

  const [action, setAction] = useState({});

  useEffect(() => {
    setAction(document[itemKey] || item[itemKey]);
  }, [item[itemKey]]);

  // useEffect(() => {
  //   if (dataSource && dataSource.name !== '') {
  //     setActionType({ id: null, name: null, type: null });
  //   }
  // }, [dataSource]);

  // ################### data sources ##################################

  // const variables = {
  //   managerAccountId: currentManagerAccountId,
  //   accountId: item.accountId
  // };

  // useEffect(() => {
  //   if (item[itemKey] && item[itemKey].dataSourceRef) {
  //     setDataSource(item[itemKey].dataSourceRef);
  //   }
  // });

  // const { data: dataSources } = useQuery(dataSourcesQuery, {
  //   variables: {
  //     dataSourcesInput: {
  //       managerAccountId: currentManagerAccountId,
  //       accountId: item.accountId
  //     }
  //   },
  //   fetchPolicy: 'no-cache',
  //   skip: false
  // });

  let actionSelect = <div></div>;

  const dataSource = action && action.dataSource;
  const dataSourceAccountId = action?.dataSourceAccountId;

  switch (dataSource) {
    case 'googleAnalytics4':
      actionSelect = (
        <GoogleAnalytics4Selects
          currentManagerAccountId={currentManagerAccountId}
          item={item}
          label={'Action Type'}
          default_value={item[itemKey] && item[itemKey].type}
          // value={actionType}
          // setValue={setActionType}
          actionType={actionType}
          setActionType={setActionType}
          eventName={eventName}
          setEventName={setEventName}
          source={source}
          setSource={setSource}
          medium={medium}
          setMedium={setMedium}
          campaignName={campaignName}
          setCampaignName={setCampaignName}
          campaignId={campaignId}
          setCampaignId={setCampaignId}
          inputValue={inputValue}
          setInputValue={setInputValue}
          action={action}
          setAction={setAction}
          dataSourceAccountId={dataSourceAccountId}
        />
      );
      break;

    case 'googleAds':
      actionSelect = (
        <SimpleSelectActionsGoogleAds
          currentManagerAccountId={currentManagerAccountId}
          item={item}
          itemKey={itemKey}
          label={'Action Type'}
          default_value={item[itemKey] && item[itemKey].type}
          // menuItems={action_types}
          value={actionType}
          setValue={setActionType}
          action={action}
          setAction={setAction}
        />
      );
      break;

    case 'snapchatAds':
      actionSelect = (
        <SimpleSelectActionsSnapchatAds
          currentManagerAccountId={currentManagerAccountId}
          item={item}
          itemKey={itemKey}
          label={'Action Type'}
          default_value={item[itemKey] && item[itemKey].type}
          // menuItems={action_types}
          value={actionType}
          setValue={setActionType}
          action={action}
          setAction={setAction}
        />
      );
      break;

    case 'tikTokAds':
      actionSelect = (
        <SimpleSelectActionsTikTokAds
          currentManagerAccountId={currentManagerAccountId}
          item={item}
          itemKey={itemKey}
          label={'Action Type'}
          default_value={item[itemKey] && item[itemKey].type}
          // menuItems={action_types}
          value={actionType}
          setValue={setActionType}
          action={action}
          setAction={setAction}
        />
      );
      break;
    case 'pinterestAds':
      actionSelect = (
        <>
          <SimpleSelectActionsPinterestAds
            currentManagerAccountId={currentManagerAccountId}
            item={item}
            itemKey={itemKey}
            label={'Action Type'}
            default_value={item[itemKey] && item[itemKey].type}
            // menuItems={action_types}
            value={actionType}
            setValue={setActionType}
            action={action}
            setAction={setAction}
          />
          <SimpleSelectPinterestAdsAdInteraction
            label={'Ad Interaction'}
            // default_value={
            //   item[itemKey] && item[itemKey]?.facebookAds?.attributionWindow
            // }
            // menuItems={windowOptions}
            // value={attributionWindow}
            // setValue={setAttributionWindow}
            item={item}
            itemKey={itemKey}
            action={action}
            setAction={setAction}
          />
        </>
      );
      break;

    case 'readpeak':
      actionSelect = (
        <SimpleSelectActionsReadpeak
          currentManagerAccountId={currentManagerAccountId}
          item={item}
          itemKey={itemKey}
          label={'Action Type'}
          default_value={item[itemKey] && item[itemKey].type}
          // menuItems={action_types}
          value={actionType}
          setValue={setActionType}
          action={action}
          setAction={setAction}
        />
      );
      break;
    case 'strossle':
      actionSelect = (
        <SimpleSelectActionsStrossle
          currentManagerAccountId={currentManagerAccountId}
          item={item}
          itemKey={itemKey}
          label={'Action Type'}
          default_value={item[itemKey] && item[itemKey].type}
          // menuItems={action_types}
          value={actionType}
          setValue={setActionType}
          action={action}
          setAction={setAction}
        />
      );
      break;
    case 'amazonDsp':
      actionSelect = (
        <SimpleSelectActionsAmazonDsp
          currentManagerAccountId={currentManagerAccountId}
          item={item}
          itemKey={itemKey}
          label={'Action Type'}
          default_value={item[itemKey] && item[itemKey].type}
          // menuItems={action_types}
          value={actionType}
          setValue={setActionType}
          action={action}
          setAction={setAction}
        />
      );
      break;
    case 'taboolaAds':
      actionSelect = (
        <SimpleSelectActionsTaboolaAds
          currentManagerAccountId={currentManagerAccountId}
          item={item}
          itemKey={itemKey}
          label={'Action Type'}
          default_value={item[itemKey] && item[itemKey].type}
          // menuItems={action_types}
          value={actionType}
          setValue={setActionType}
          action={action}
          setAction={setAction}
        />
      );
      break;

    case 'microsoftAdvertising':
      actionSelect = (
        <SimpleSelectActionsMicrosoftAdvertising
          currentManagerAccountId={currentManagerAccountId}
          item={item}
          itemKey={itemKey}
          label={'Action Type'}
          default_value={item[itemKey] && item[itemKey].type}
          // menuItems={action_types}
          value={actionType}
          setValue={setActionType}
          action={action}
          setAction={setAction}
        />
      );
      break;
    case 'appleSearchAds':
      actionSelect = (
        <SimpleSelectActionsAppleSearchAds
          currentManagerAccountId={currentManagerAccountId}
          item={item}
          itemKey={itemKey}
          label={'Action Type'}
          default_value={item[itemKey] && item[itemKey].type}
          // menuItems={action_types}
          value={actionType}
          setValue={setActionType}
          action={action}
          setAction={setAction}
        />
      );
      break;
    case 'facebookAds':
      actionSelect = (
        <React.Fragment>
          <SimpleSelectActionsFacebookAds
            currentManagerAccountId={currentManagerAccountId}
            item={item}
            label={'Action Type'}
            // default_value={item[itemKey] && item[itemKey]?.facebookAds?.type}
            // menuItems={action_types}
            // value={actionType}
            // setValue={setActionType}
            action={action}
            setAction={setAction}
          />
          <SimpleSelectFacebokAdsConversionWindow
            label={'Window'}
            // default_value={
            //   item[itemKey] && item[itemKey]?.facebookAds?.attributionWindow
            // }
            // menuItems={windowOptions}
            // value={attributionWindow}
            // setValue={setAttributionWindow}
            item={item}
            itemKey={itemKey}
            action={action}
            setAction={setAction}
          />
        </React.Fragment>
      );
      break;
    case 'adform':
      actionSelect = (
        <React.Fragment>
          <SimpleSelectActionsAdform
            currentManagerAccountId={currentManagerAccountId}
            item={item}
            label={'Tracking Filter'}
            // default_value={item[itemKey] && item[itemKey]?.facebookAds?.type}
            // menuItems={action_types}
            // value={actionType}
            // setValue={setActionType}
            action={action}
            setAction={setAction}
          />
          <SimpleSelectAdformAdInteraction
            label={'Ad Interaction'}
            // default_value={
            //   item[itemKey] && item[itemKey]?.facebookAds?.attributionWindow
            // }
            // menuItems={windowOptions}
            // value={attributionWindow}
            // setValue={setAttributionWindow}
            item={item}
            itemKey={itemKey}
            action={action}
            setAction={setAction}
          />
          <SimpleSelectConversionType
            label={'Ad Interaction'}
            // default_value={
            //   item[itemKey] && item[itemKey]?.facebookAds?.attributionWindow
            // }
            // menuItems={windowOptions}
            // value={attributionWindow}
            // setValue={setAttributionWindow}
            item={item}
            itemKey={itemKey}
            action={action}
            setAction={setAction}
          />
        </React.Fragment>
      );
      break;

    case 'linkedInAds':
      actionSelect = (
        <React.Fragment>
          <SimpleSelectActionsLinkedInAds
            currentManagerAccountId={currentManagerAccountId}
            item={item}
            label={'Conversion'}
            // default_value={item[itemKey] && item[itemKey]?.facebookAds?.type}
            // menuItems={action_types}
            // value={actionType}
            // setValue={setActionType}
            action={action}
            setAction={setAction}
          />
          <SimpleSelectLinkedInAdsAdInteraction
            label={'Ad Interaction'}
            // default_value={
            //   item[itemKey] && item[itemKey]?.facebookAds?.attributionWindow
            // }
            // menuItems={windowOptions}
            // value={attributionWindow}
            // setValue={setAttributionWindow}
            item={item}
            itemKey={itemKey}
            action={action}
            setAction={setAction}
          />
        </React.Fragment>
      );
      break;
    case 'cm360':
      actionSelect = (
        <React.Fragment>
          <SimpleSelectActionsCM360
            currentManagerAccountId={currentManagerAccountId}
            item={item}
            label={'Action Type'}
            // default_value={item[itemKey] && item[itemKey]?.facebookAds?.type}
            // menuItems={action_types}
            // value={actionType}
            // setValue={setActionType}
            action={action}
            setAction={setAction}
          />
          <SimpleSelectAttributionTypeCM360
            label={'Window'}
            // default_value={
            //   item[itemKey] && item[itemKey]?.facebookAds?.attributionWindow
            // }
            // menuItems={windowOptions}
            // value={attributionWindow}
            // setValue={setAttributionWindow}
            item={item}
            itemKey={itemKey}
            action={action}
            setAction={setAction}
          />
        </React.Fragment>
      );
      break;

    case 'dv360':
      actionSelect = (
        <React.Fragment>
          <SimpleSelectActionsDV360
            currentManagerAccountId={currentManagerAccountId}
            item={item}
            label={'Action Type'}
            // default_value={item[itemKey] && item[itemKey]?.facebookAds?.type}
            // menuItems={action_types}
            // value={actionType}
            // setValue={setActionType}
            action={action}
            setAction={setAction}
          />
          <SimpleSelectAttributionTypeDV360
            label={'Window'}
            // default_value={
            //   item[itemKey] && item[itemKey]?.facebookAds?.attributionWindow
            // }
            // menuItems={windowOptions}
            // value={attributionWindow}
            // setValue={setAttributionWindow}
            item={item}
            itemKey={itemKey}
            action={action}
            setAction={setAction}
          />
        </React.Fragment>
      );
      break;

    case 'manualDataEntry':
      actionSelect = (
        <React.Fragment>
          <ManualTextInput
            currentManagerAccountId={currentManagerAccountId}
            item={item}
            itemKey={itemKey}
            label={'Action Type'}
            // default_value={item[itemKey] && item[itemKey]?.facebookAds?.type}
            // menuItems={action_types}
            // value={actionType}
            // setValue={setActionType}
            inputvalue={inputValue}
            setInputValue={setInputValue}
            action={action}
            setAction={setAction}
          />
        </React.Fragment>
      );
      break;
    default:
      actionSelect = <div></div>;
  }

  // const [updateDocument, { called }] = useMutation(updateDocumentMutation);

  // const { updateDocument } = useContext(MutationContext);

  const handleSave = () => {
    // console.log('click!!!');
    const addActionTypeInput = {};

    let name = null;

    switch (action.dataSource) {
      case 'cm360':
        name = action.dataSource
          ? action[action.dataSource].activityName
          : null;
        break;
      default:
        name = action.dataSource ? action[action.dataSource].name : null;
    }

    const newAction = {
      ...action,
      name
      // dataSourceRef: dataSource && dataSource.dataSourceRef,
      // dataSourceId: dataSource && dataSource._id,
      // dataSourceAccountId: dataSource && dataSource.dataSourceAccountId
    };

    setDocument({ ...document, [itemKey]: newAction });
    // const input = {
    //   managerAccountId: currentManagerAccountId,
    //   documents: [
    //     {
    //       accountId: item.accountId,
    //       documentId: item._id,
    //       documentType: item.documentType,
    //       [itemKey]: document
    //     }
    //   ],
    //   updateKeys: [itemKey]
    // };
    // console.log('actoinTypeItem>input: ', input);

    // updateDocument({ variables: { input } });

    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const action_types = [];

  return (
    <div
      style={{
        minwidth: '200px',
        minHeight: '150px',
        // width: '210px',
        padding: '10px'
        // textAlign: 'center'
      }}
    >
      <SimpleSelectDataSources
        currentManagerAccountId={currentManagerAccountId}
        item={item}
        itemKey={itemKey}
        label={'Data Source'}
        data={dataSource}
        // setDataSource={setDataSource}
        dataSource={dataSource}
        action={action}
        setAction={setAction}
      />
      {actionSelect}
      <div>
        <Checkbox
          disabled
          label="Custom Date Range"
          inputProps={{ 'aria-label': 'Custom Date Range' }}
        />{' '}
        <span style={{ color: 'rgba(150,150,150,0.5)', fontSize: '12px' }}>
          Custom Date Range
        </span>
      </div>

      {/* <SimpleSelectActions
        currentManagerAccountId={currentManagerAccountId}
        item={item}
        label={'Action Type'}
        default_value={item[itemKey] && item[itemKey].type}
        // menuItems={action_types}
        value={actionType}
        setValue={setActionType}
      /> */}
      {/* <SimpleSelect label={"Window"} default_value={default_attribution_window} menuItems={windowOptions} value={actionWindow} setValue={setActionWindow} /> */}
      <Button
        onClick={handleCancel}
        variant="outlined"
        size="small"
        color="primary"
        style={{ fontSize: '10px', margin: '15px' }}
      >
        Cancel
      </Button>
      <Button
        onClick={handleSave}
        variant="contained"
        size="small"
        style={{ fontSize: '10px', margin: '15px' }}
      >
        Apply
      </Button>
    </div>
  );
}

export default PopContent;
