import React, { useContext } from 'react';
// import { makeStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import { useMutation } from '@apollo/client';

import { getDataSourceOAuthUrlMutation } from './../../../../../graphql/queries';

import { useSnackbar } from 'notistack';

import { AuthContext } from '../../../../../context/AuthContext';
import { AccountContext } from '../../../../../context/AccountContext';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     '& > *': {
//       margin: theme.spacing(1),
//       borderRadius: '5px',
//       height: '20px',
//       width: '80px',
//       fontSize: '12px',
//       backgroundColor: 'rgba(0, 181, 8, 1)',
//       '&:hover': {
//         background: 'rgba(20, 201, 28, 1)'
//       }
//     }
//   },
//   extendedIcon: {
//     color: 'white',
//     marginRight: theme.spacing(1)
//   }
// }));

const FloatingActionButtons = ({ dataSource }) => {
  const { user } = useContext(AuthContext);
  const { currentManagerAccount, refetchAccounts } = useContext(AccountContext);
  // const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [addDataSource, { called }] = useMutation(
    getDataSourceOAuthUrlMutation
  );

  const dataSourceDict = {
    googleAds: 'google_ads',
    facebookAds: 'facebook_ads',
    tikTokAds: 'tiktok_ads',
    pinterestAds: 'pinterest_ads',
    readpeak: 'readpeak',
    amazonDsp: 'amazon_dsp',
    taboolaAds: 'taboola_ads',
    microsoftAdvertising: 'microsoft_advertising',
    snapchatAds: 'snapchat_ads',
    linkedInAds: 'linkedin_ads',
    adform: 'adform',
    dv360: 'dv360',
    cm360: 'cm360'
  };

  console.log('RefreshTokenButton>dataSource: ', dataSource);

  const onClickHandler = async (e) => {
    const addDataSourceInput = {
      managerAccountId: currentManagerAccount._id,
      dataSource: dataSourceDict[dataSource],
      refresh: true
    };

    const res = await addDataSource({
      variables: { input: addDataSourceInput }
    });
    console.log('RefreshTokenButton>res: ', res);

    if (
      res &&
      res.data &&
      res.data.getDataSourceOAuthUrl &&
      res.data.getDataSourceOAuthUrl.response &&
      res.data.getDataSourceOAuthUrl.url
    ) {
      window.location.replace(res.data.getDataSourceOAuthUrl.url);
    }
  };

  return (
    <div
    // className={classes.root}
    >
      {/* <Fab color="primary" aria-label="add">
        <AddIcon />
      </Fab> */}
      <Fab
        onClick={onClickHandler}
        aria-label="edit"
        style={{
          color: 'white',

          // margin: theme.spacing(1),
          borderRadius: '5px',
          height: '20px',
          width: '80px',
          fontSize: '12px',
          backgroundColor: 'rgba(0, 181, 8, 1)'
        }}
      >
        <span>Refresh</span>
        {/* <DeleteIcon style={{ color: 'white' }} /> */}
      </Fab>
      {/* <Fab variant="extended">
        <NavigationIcon className={classes.extendedIcon} />
        Navigate
      </Fab>
      <Fab disabled aria-label="like">
        <FavoriteIcon />
      </Fab> */}
    </div>
  );
};

FloatingActionButtons.propTypes = {
  // userId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  // userId_: state.auth.user._id
});

export default FloatingActionButtons;
