import React, { useState, useContext, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import EditIcon from '@mui/icons-material/Edit';

import { AuthContext } from '../../../../../context/AuthContext';

import Checkbox from '@mui/material/Checkbox';

import { useMutation } from '@apollo/client';

import { updateUserToManagerAccountSettingsMutation } from './../../../../../graphql/queries';

import { useSnackbar } from 'notistack';

import { AccountContext } from '../../../../../context/AccountContext';

// import PhoneNumberInput from './PhoneNumberInput';
// import VerifyPhoneNumberInput from './VerifyPhoneNumber';

import Fab from '@mui/material/Fab';

const documentTypeToNameDict = {
  adform: 'Adform',
  facebookAds: 'Facebook Ads',
  googleAds: 'Google Ads',
  linkedInAds: 'LinkedIn Ads',
  dv360: 'Display & Video 360',
  cm360: 'Campaign Manager 360',
  snapchatAds: 'Snapchat Ads',
  tikTokAds: 'TikTok Ads',
  pinterestAds: 'Pinterest Ads',
  readpeak: 'Readpeak',
  strossle: 'Strossle',
  amazonDsp: 'Amazon DSP',
  taboolaAds: 'Taboola Ads',
  microsoftAdvertising: 'Microsoft Advertising',
  appleSearchAds: 'Apple Search Ads'
};

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog({
  refetchUserInfo,
  userInfoAndAccounts
}) {
  const [open, setOpen] = React.useState(false);

  const { currentManagerAccount, refetchAccounts } = useContext(AccountContext);

  const { user: currentUser } = useContext(AuthContext);

  const { enqueueSnackbar } = useSnackbar();

  const [updateUserUserToManagerAccountSettings, { called }] = useMutation(
    updateUserToManagerAccountSettingsMutation
  );

  const [emergencyContact, setEmergencyContact] = useState(
    Boolean(userInfoAndAccounts?.emergencyContact)
  );
  const [emergencyDocumentTypes, setEmergencyDocumentTypes] = useState(
    userInfoAndAccounts?.emergencyDocumentTypes || []
  );

  const handleClickOpen = () => {
    if (!userInfoAndAccounts?.phoneNumber) {
      enqueueSnackbar('Please add a phone number', {
        variant: 'error'
      });
      return;
    }
    setEmergencyContact(Boolean(userInfoAndAccounts?.emergencyContact));
    setEmergencyDocumentTypes(
      userInfoAndAccounts?.emergencyDocumentTypes || []
    );

    setOpen(true);
  };

  const handleClose = () => {
    setEmergencyContact(Boolean(userInfoAndAccounts?.emergencyContact));
    setEmergencyDocumentTypes(
      userInfoAndAccounts?.emergencyDocumentTypes || []
    );
    // setView('SEND_VERIFIATION_CODE');
    setOpen(false);
  };

  const onSaveHandler = async (e) => {
    const input = {
      managerAccountId: currentManagerAccount?._id,
      update: { emergencyContact, emergencyDocumentTypes },
      updateKeys: ['emergencyContact', 'emergencyDocumentTypes']
    };

    let res;
    try {
      res = await updateUserUserToManagerAccountSettings({
        variables: { input }
      });
    } catch (error) {
      // console.log('error.message: ', error.message);
      try {
        enqueueSnackbar('Error, please contact support.', {
          variant: 'error'
        });
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error'
        });
      }
    }
    console.log('res?.data: ', res?.data);
    if (res?.data?.updateUserToManagerAccountSettings?.response === 'success') {
      enqueueSnackbar('Emergency Settings Saved', {
        variant: 'success'
      });
      refetchUserInfo();
      // handleClose();
      setOpen(false);
      // setView('VERIFY_PHONE_NUMBER');
      //   refetchUserInfo();
      //   refetchAccounts();

      if (userInfoAndAccounts?._id === currentUser?._id) {
        // refetchUsersAccountsList();
      }
    }
  };

  const [view, setView] = useState('SEND_VERIFIATION_CODE');

  let DisplayComponent = <div></div>;
  let dialogTitle = 'Emergency Contact Settings';
  // switch (true) {
  //   case view === 'SEND_VERIFIATION_CODE':
  //     DisplayComponent = PhoneNumberInput;
  //     dialogTitle = 'Enter the new phone number';
  //     break;

  //   case view === 'VERIFY_PHONE_NUMBER':
  //     DisplayComponent = VerifyPhoneNumberInput;
  //     dialogTitle = 'Enter verification code';
  //     break;
  // }

  // const [emergencyContactSettings, setEmergencyContactSettings] = useState({
  //   emergencyContact: userInfoAndAccounts?.emergencyContact,
  //   emergencyDocumentTypes: userInfoAndAccounts?.emergencyDocumentTypes
  // });

  // useEffect(() => {
  //   setEmergencyContactSettings({
  //     emergencyContact: userInfoAndAccounts?.emergencyContact,
  //     emergencyDocumentTypes: userInfoAndAccounts?.emergencyDocumentTypes
  //   });
  // }, [
  //   userInfoAndAccounts.emergencyContact,
  //   userInfoAndAccounts.emergencyDocumentTypes
  // ]);

  useEffect(() => {
    setEmergencyContact(userInfoAndAccounts?.emergencyContact);
  }, [userInfoAndAccounts?.emergencyContact]);

  useEffect(() => {
    setEmergencyDocumentTypes(
      userInfoAndAccounts?.emergencyDocumentTypes || []
    );
  }, [userInfoAndAccounts?.emergencyDocumentTypes]);

  return (
    <React.Fragment>
      {/* <Button
        variant="outlined"
        size="small"
        onClick={handleClickOpen}
        style={{
          width: '32px',
          minWidth: '32px',
          height: '32px',
          minHeight: '32px'
        }}
      >
        <EditIcon />
      </Button> */}
      <Fab
        onClick={handleClickOpen}
        aria-label="edit"
        style={{
          marginLeft: '20px',
          color: 'white',

          // margin: theme.spacing(1),
          borderRadius: '5px',
          height: '20px',
          width: '37px',
          backgroundColor: userInfoAndAccounts?.phoneNumber
            ? 'rgba(150, 150, 150, 1)'
            : 'rgb(0, 181, 8)'
        }}
      >
        <EditIcon style={{ color: 'white' }} />
      </Fab>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              width: '400px',
              height: '400px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontWeight: 'bold'
              }}
            >
              <span>Emergency Contact: </span>
              <Checkbox
                checked={emergencyContact}
                onChange={() => {
                  if (currentUser?._id === userInfoAndAccounts?._id) {
                    setEmergencyContact(!emergencyContact);
                  } else {
                    enqueueSnackbar(
                      'You can only edit your own emergency contact settings',
                      {
                        variant: 'error'
                      }
                    );
                  }
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
            <div
              style={{
                marginTop: '20px',
                visibility: !emergencyContact && 'hidden'
                // background: 'rgba(150,150,150,0.1)',
                // borderRadius: '5px',
                // padding: '20px'
              }}
            >
              <div style={{ fontWeight: 'bold' }}>
                Emergency Document Types:
              </div>
              <div style={{ fontSize: '12px' }}>
                ({emergencyDocumentTypes?.length} selected)
              </div>

              <div
                style={{
                  marginTop: '10px',
                  color: 'green',
                  visibility:
                    Boolean(emergencyDocumentTypes?.length) && 'hidden'
                }}
              >
                0 selected means all
              </div>

              <div
                style={{
                  marginTop: '0px',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {[
                  'facebookAds',
                  'googleAds',
                  'cm360',
                  'dv360',
                  'snapchatAds',
                  'tikTokAds',
                  'pinterestAds',
                  'readpeak',
                  'strossle',
                  'amazonDsp',
                  'taboolaAds',
                  'microsoftAdvertising',
                  'appleSearchAds',
                  'adform',
                  'linkedInAds'
                ]
                  ?.sort()
                  ?.map((documentType) => (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '5px'
                      }}
                    >
                      <span>
                        {documentTypeToNameDict[documentType] || documentType}
                      </span>{' '}
                      <Checkbox
                        checked={emergencyDocumentTypes?.includes(documentType)}
                        onChange={() => {
                          if (currentUser?._id === userInfoAndAccounts?._id) {
                            setEmergencyDocumentTypes(
                              emergencyDocumentTypes.includes(documentType)
                                ? [...emergencyDocumentTypes].filter(
                                    (docT) => docT !== documentType
                                  )
                                : [...emergencyDocumentTypes, documentType]
                            );
                          } else {
                            enqueueSnackbar(
                              'You can only edit your own emergency contact settings',
                              {
                                variant: 'error'
                              }
                            );
                          }
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
          {/* <PhoneNumberInput /> */}
          {/* <DisplayComponent
            view={view}
            setView={setView}
            handleClose={handleClose}
            refetchUserInfo={refetchUserInfo}
            userInfoAndAccounts={userInfoAndAccounts}
          /> */}
        </DialogContent>
        <DialogActions>
          {currentUser?._id === userInfoAndAccounts?._id ? (
            <>
              <Button size="small" autoFocus onClick={handleClose}>
                Cancel
              </Button>
              <Button
                size="small"
                onClick={onSaveHandler}
                style={{ background: 'rgb(0, 181, 8)', color: 'white' }}
              >
                Save
              </Button>
            </>
          ) : (
            <Button size="small" autoFocus onClick={handleClose}>
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
