// import './App.css';

import { ApolloProvider } from '@apollo/client';
import client from './graphql/client';
import { SnackbarProvider, useSnackbar } from 'notistack';

import React, { Fragment, useEffect, useLocation, useState } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// routes

import PrivateRoute from './components/routing/PrivateRoute';

import Landing from './components/layout/Landing';
import RoutesList from './components/routing/Routes';

import Login from './components/auth/Login';

// import ChooseManagerAccount from './components/manager-account/ChooseManagerAccount';
// import PrivacyPolicy from './components/privacy-policy/PrivacyPolicy';
// import Dashboard from './components/dashboard/Dashboard';
// import NotFound from './components/layout/NotFound';

// Layout
import MiniDrawer from './components/layout/MiniDrawer';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import {
  getManagerAccounts,
  getCurrentManagerAccount
} from './actions/manageraccount';
import setAuthToken from './utils/setAuthToken';

// alerts
import AlertSnackbar from './components/layout/AlertSnackbar';

import './App.css';
import { useTheme, useMediaQuery } from '@mui/material';

import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { setStyle } from './actions/style';

import { AccountProvider } from './context/AccountContext';

import { FilterProvider } from './context/FilterContext';

import { InterfaceProvider } from './context/InterfaceContext';
import { DialogProvider } from './context/DialogContext';

import { ColumnProvider } from './context/ColumnContext';
import { StyleProvider } from './context/StyleContext';

import { AuthProvider } from './context/AuthContext';

import { DataProvider } from './context/DataContext';
import { MutationProvider } from './context/MutationContext';

import Alert from './components/layout/Alert';

// import DateAdapter from '@mui/lab/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // console.log('prefersDarkMode: ', prefersDarkMode);

  if (prefersDarkMode) {
    store.dispatch(setStyle('dark'));

    // console.log('store.getState(): ', store.getState().style.styleName);
  } else {
    store.dispatch(setStyle('light'));
  }
  // let styleName = 'light';

  const [userTheme, setUserTheme] = useState('');

  const theme = React.useMemo(() => {
    if (
      false
      // userTheme === ''
    ) {
      const MuiThemeStyle = {
        // palette: {
        //   type: prefersDarkMode ? 'dark' : 'light'
        // }
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light'
          // primary: {
          //   // light: will be calculated from palette.primary.main,
          //   main: '#FFFFFF'
          //   // dark: will be calculated from palette.primary.main,
          //   // contrastText: will be calculated to contrast with palette.primary.main
          // },
          // secondary: {
          //   light: '#0066ff',
          //   main: '#0044ff',
          //   // dark: will be calculated from palette.secondary.main,
          //   contrastText: '#ffcc00'
          // },
          // // Used by `getContrastText()` to maximize the contrast between
          // // the background and the text.
          // contrastThreshold: 3,
          // // Used by the functions below to shift a color's luminance by approximately
          // // two indexes within its tonal palette.
          // // E.g., shift from Red 500 to Red 300 or Red 700.
          // tonalOffset: 0.2
        }
      };
      // return createMuiTheme(MuiThemeStyle, userTheme);
      return createTheme(MuiThemeStyle, userTheme);
    } else if (userTheme === 'dark') {
      const MuiThemeStyle = {
        // palette: {
        //   type: prefersDarkMode ? 'dark' : 'light'
        // }
        palette: {
          mode: 'dark',
          primary: {
            // main: '#fafafa'
            main: '#ffffff'
          },
          // secondary: {
          //   main: '#00ff00'
          // },
          background: {
            // default: '#eeeeee',
            // default: '#303030',
            default: 'rgba(55,55,66,1)',
            // paper: "white"
            // paper: '#303030'
            paper: 'rgba(55,55,66,1)'
          },
          button: {
            color: '#ff00ee'
          },
          navbar: {
            main: '#000000',
            // background: '#303030'
            background: 'rgba(55,55,66,0)'
            // background: 'rgba(58,58,66,0.1)'
          },
          drawer: {
            main: '#000000',
            // background: '#424242'
            background: 'rgba(56,56,66,0)'
            // background: 'rgba(56,56,64,0.1)'
          },
          icon: {
            main: '#f7f7f7'
          }
          // primary: {
          //   // light: will be calculated from palette.primary.main,
          //   main: '#FFFFFF'
          //   // dark: will be calculated from palette.primary.main,
          //   // contrastText: will be calculated to contrast with palette.primary.main
          // },
          // secondary: {
          //   // light: '#0066ff',
          //   light: '#FFFFFF',
          //   // main: '#0044ff',
          //   main: '#FFFFFF',
          //   // dark: will be calculated from palette.secondary.main,
          //   contrastText: '#ffcc00'
          // },
          // // Used by `getContrastText()` to maximize the contrast between
          // // the background and the text.
          // contrastThreshold: 3,
          // // Used by the functions below to shift a color's luminance by approximately
          // // two indexes within its tonal palette.
          // // E.g., shift from Red 500 to Red 300 or Red 700.
          // tonalOffset: 0.2
        }
      };
      return createTheme(MuiThemeStyle, userTheme);
    } else {
      const MuiThemeStyle = {
        // palette: {
        //   type: prefersDarkMode ? 'dark' : 'light'
        // }
        palette: {
          mode: 'light',
          text: {
            primary: '#21285a',
            secondary: '#21285a'
          },
          primary: {
            // main: '#fafafa'
            // main: '#000000'
            main: '#21285a'
            // light: '#FF0000'
            // main: '#3150a1'
          },
          secondary: {
            main: '#21285a'
          },
          // secondary: {
          //   main: '#00ff00'
          // },
          background: {
            // default: '#eeeeee',
            default: '#f7f7f7',
            // paper: "white"
            // paper: '#fafafa'
            paper: '#f7f7f7'
          },
          button: {
            color: '#ff00ee'
          },
          navbar: {
            main: '#21285a',
            // background: '#fcfcfc'
            background: 'rgba(255,255,255,0.3)'
          },
          drawer: {
            main: '#21285a',
            // background: '#fcfcfc',
            background: 'rgba(255,255,255,0.1)'
          },
          icon: {
            // main: '#3150a1'
            main: '#21285a'
          }

          // primary: {
          //   // light: will be calculated from palette.primary.main,
          //   main: '#000000'
          //   // dark: will be calculated from palette.primary.main,
          //   // contrastText: will be calculated to contrast with palette.primary.main
          // },
          // secondary: {
          //   // light: '#0066ff',
          //   // main: '#0044ff',
          //   light: 'rgb(54 91 167)',
          //   main: 'rgb(54 91 167)',

          //   // dark: will be calculated from palette.secondary.main,
          //   contrastText: '#ffcc00'
          // },
          // background: {
          //   // default: '#eeeeee',
          //   default: '#f5f5f5',
          //   // paper: "white"
          //   paper: '#fafafa'
          // },
          // // Used by `getContrastText()` to maximize the contrast between
          // // the background and the text.
          // contrastThreshold: 3,
          // // Used by the functions below to shift a color's luminance by approximately
          // // two indexes within its tonal palette.
          // // E.g., shift from Red 500 to Red 300 or Red 700.
          // tonalOffset: 0.2
        }
      };
      return createTheme(MuiThemeStyle, userTheme);
    }

    // return createMuiTheme(MuiThemeStyle);
  }, [prefersDarkMode, userTheme]);

  useEffect(() => {
    // setUserTheme('light');
    if (
      store.getState() &&
      store.getState().style &&
      store.getState().style.styleName === 'dark'
    ) {
      setUserTheme('dark');
    } else {
      setUserTheme('light');
    }
  });
  // useEffect(() => {
  //   if (localStorage.token) {
  //     setAuthToken(localStorage.token);
  //     store.dispatch(loadUser());
  //   }
  // }, []);

  console.log('app renders');

  // if (localStorage.token) {
  //   setAuthToken(localStorage.token);
  //   store.dispatch(loadUser());
  // }
  // useEffect(() => {
  //   if (localStorage.token) {
  //     store.dispatch(getManagerAccounts());
  //   }
  // });

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={6}>
            <CssBaseline />
            <AuthProvider>
              <AccountProvider>
                <ColumnProvider>
                  <StyleProvider>
                    <FilterProvider>
                      <DialogProvider>
                        <DataProvider>
                          <InterfaceProvider>
                            {/* <LocalizationProvider dateAdapter={DateAdapter}> */}
                            <MutationProvider>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Router>
                                  <Fragment>
                                    {/* <Navbar />
          <Sidenav /> */}
                                    <MiniDrawer>
                                      <AlertSnackbar />
                                      <section
                                        className="container"
                                        style={{ height: '100%' }}
                                      >
                                        <Alert />

                                        <RoutesList />
                                      </section>
                                      {/* <Routes>
                    <Route exact path="/" element={<Landing />} />
                    <RoutesList />
                  </Routes> */}
                                      {/* </ModalProvider>
                      </FilterProvider> */}
                                      {/* </section> */}
                                      {/* </Routes> */}
                                    </MiniDrawer>
                                  </Fragment>
                                </Router>
                              </LocalizationProvider>
                            </MutationProvider>
                          </InterfaceProvider>
                        </DataProvider>
                      </DialogProvider>
                    </FilterProvider>
                  </StyleProvider>
                </ColumnProvider>
              </AccountProvider>
            </AuthProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </Provider>
    </ApolloProvider>
  );
};

export default App;

// export default App;
