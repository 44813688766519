import React, { useState, useEffect, useContext } from 'react';

import { useParams, useNavigate, useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';

import Divider from '@mui/material/Divider';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import PersonIcon from '@mui/icons-material/Person';

import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';

// import TextField from '@material-ui/core/TextField';

import Users from './users/Users';
import Accounts from './accounts/Accounts';
import DataSources from './data-sources/DataSources';

import TimeboxLists from './timebox-lists/TimeboxLists';

import { AuthContext } from '../../context/AuthContext';
import { AccountContext } from '../../context/AccountContext';

import TokenRefresheDialog from './TokenRefreshedDialog';

function ManagerAccountSettings({
  // currentManagerAccount,
  // user,
  stylesStoredInReduxManagerAccountSettings
}) {
  const { user } = useContext(AuthContext);
  const { currentManagerAccount } = useContext(AccountContext);

  const [code, setCode] = useState('');
  const [dataSource, setDataSource] = useState('');

  const [tokenRefreshedModalOpen, setTokenRefreshedModalOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState('users');

  let {
    setting,
    code: codeParameter,
    datasource: dataSourceParameter,
    dialog
  } = useParams();

  useEffect(() => {
    switch (true) {
      case setting === 'users' && dialog === 'tokenrefreshed':
        window.history.pushState(
          '',
          'Manager Account Settings',
          `/manager-account-settings/users/?manager_account_id=${currentManagerAccount._id}`
        );
        setTokenRefreshedModalOpen(true);
        console.log('data source saved');
        break;

      case setting === 'data-sources' &&
        Boolean(codeParameter) &&
        Boolean(dataSourceParameter):
        setCode(codeParameter);
        setDataSource(dataSourceParameter);
        window.history.pushState(
          '',
          'Manager Account Settings',
          `/manager-account-settings/data-sources/?manager_account_id=${currentManagerAccount._id}`
        );
        break;
    }
    // if (codeParameter && dataSourceParameter) {

    //   setCode(codeParameter);
    //   setDataSource(dataSourceParameter);
    //   window.history.pushState(
    //     '',
    //     'Manager Account Settings',
    //     `/manager-account-settings/data-sources/?manager_account_id=${currentManagerAccount._id}`
    //   );
    // }
  }, [codeParameter, dataSourceParameter, currentManagerAccount._id]);

  // const decodedCode = code ? decodeURIComponent(code) : '';

  let renderMenu = '';

  useEffect(() => {
    switch (setting) {
      case 'users':
      case 'accounts':
      case 'data-sources':
      case 'timebox-lists':
        setSelectedMenu(setting);

        break;
    }
  }, []);

  switch (selectedMenu) {
    case 'users':
      renderMenu = (
        <Users
          currentManagerAccount={currentManagerAccount}
          user={user}
          stylesStoredInReduxManagerAccountSettings={
            stylesStoredInReduxManagerAccountSettings
          }
        />
      );
      break;
    case 'accounts':
      renderMenu = (
        <Accounts
          currentManagerAccount={currentManagerAccount}
          user={user}
          stylesStoredInReduxManagerAccountSettings={
            stylesStoredInReduxManagerAccountSettings
          }
        />
      );
      break;
    case 'timebox-lists':
      renderMenu = (
        <TimeboxLists
          currentManagerAccount={currentManagerAccount}
          user={user}
          stylesStoredInReduxManagerAccountSettings={
            stylesStoredInReduxManagerAccountSettings
          }
        />
      );
      break;
    case 'data-sources':
      renderMenu = (
        <DataSources
          currentManagerAccount={currentManagerAccount}
          user={user}
          code={code}
          setCode={setCode}
          dataSource={dataSource}
          setDataSource={setDataSource}
          stylesStoredInReduxManagerAccountSettings={
            stylesStoredInReduxManagerAccountSettings
          }
        />
      );
      break;
  }

  return (
    <div style={{ padding: '15px' }}>
      <div
        style={{
          display: 'grid',
          height: 'calc(100vh - 93.78px)',
          gridTemplateRows: '50px auto',
          // borderRadius: '5px',
          gridTemplateColumns: '300px auto'
        }}
      >
        {/* <div style={{display: 'grid', gridColumnStart: 1, gridColumnEnd: 1, gridTemplateColumns: '250px 250px auto', gridTemplateRows: '70px repeat(auto-fill, minmax(46px, 46px))', borderRadius: '5px', borderRadius: '5px'}}> */}

        <div
          style={{
            display: 'grid',
            gridColumnStart: 1,
            gridColumnEnd: 1,
            gridTemplateRows: '50px auto'
          }}
        >
          <div
            style={{
              gridRowStart: 1,
              gridRowEnd: 1,
              fontSize: '16px',
              padding: '15px 10px'
            }}
          >
            <SettingsIcon style={{ verticalAlign: 'bottom' }} /> Settings
          </div>

          <div
            style={{
              overflow: 'hidden',
              display: 'grid',
              gridRowStart: 2,
              gridRowEnd: 2,
              gridTemplateRows: '70px auto',
              height: 'calc(100vh - 143.78px)',
              backgroundColor:
                stylesStoredInReduxManagerAccountSettings.cardBackgroundColor,
              margin: '4px',
              borderRadius: '5px',
              boxShadow:
                '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
            }}
          >
            <div
              style={{
                gridRowStart: 1,
                gridRowEnd: 1,
                height: '100%',
                justifyContent: 'flex-start',
                backgroundColor:
                  stylesStoredInReduxManagerAccountSettings.cardTopBackgroundColor,
                padding: '25px 30px',
                fontSize: '16px'
              }}
            >
              {currentManagerAccount?.name || ''}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                // gridTemplateRows: 'repeat(auto-fill, minmax(46px, 46px))',
                padding: '25px 20px',
                overflowY: 'scroll'
              }}
            >
              <Button
                style={{
                  gridRowStart: 1,
                  gridRowEnd: 1,
                  height: '40px',
                  justifyContent: 'flex-start',
                  background:
                    selectedMenu === 'users' &&
                    stylesStoredInReduxManagerAccountSettings.selectedListButton
                }}
                onClick={() => setSelectedMenu('users')}
              >
                Users
              </Button>
              <Button
                style={{
                  gridRowStart: 2,
                  gridRowEnd: 2,
                  height: '40px',
                  justifyContent: 'flex-start',
                  background:
                    selectedMenu === 'accounts' &&
                    stylesStoredInReduxManagerAccountSettings.selectedListButton
                }}
                onClick={() => setSelectedMenu('accounts')}
              >
                Accounts
              </Button>
              <Button
                style={{
                  gridRowStart: 3,
                  gridRowEnd: 3,
                  height: '40px',
                  justifyContent: 'flex-start',
                  background:
                    selectedMenu === 'data-sources' &&
                    stylesStoredInReduxManagerAccountSettings.selectedListButton
                }}
                onClick={() => setSelectedMenu('data-sources')}
              >
                Data Connections
              </Button>
              {(currentManagerAccount?.isTimeboxingOn ||
                ['system_admin', 'debug'].includes(user?.role) ||
                [
                  '6167f2a56a44185b71fd0bcc',
                  '61a917e9d86ba532fcee25a9',
                  '63ea0160f4ab796775dbe0da',
                  '640b425a1b1064af10bc2918',
                  '640b3de89db4bd2b100bdf58'
                ].includes(user?._id)) && (
                <Button
                  style={{
                    gridRowStart: 2,
                    gridRowEnd: 2,
                    height: '40px',
                    justifyContent: 'flex-start',
                    background:
                      selectedMenu === 'timebox-lists' &&
                      stylesStoredInReduxManagerAccountSettings.selectedListButton
                  }}
                  onClick={() => setSelectedMenu('timebox-lists')}
                >
                  Timebox Lists
                </Button>
              )}
              {/* <Button style={{ gridRowStart: 4, gridRowEnd: 4, height: '40px', justifyContent: "flex-start", background: selectedMenu === "billing" && stylesStoredInReduxManagerAccountSettings.selectedListButton}} onClick={()=>setSelectedMenu('billing')}>Billing</Button> */}
            </div>
          </div>
        </div>

        {renderMenu}
      </div>
      {tokenRefreshedModalOpen && (
        <TokenRefresheDialog
          open={tokenRefreshedModalOpen}
          setOpen={setTokenRefreshedModalOpen}
        />
      )}
    </div>
  );
}

ManagerAccountSettings.propTypes = {
  // currentManagerAccount: PropTypes.object.isRequired,
  stylesStoredInRedux: PropTypes.object.isRequired,
  // user: PropTypes.object.isRequired,
  stylesStoredInReduxManagerAccountSettings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  // currentManagerAccount: state.managerAccount.currentManagerAccount,
  // currentAccountIds: state.account.currentAccountIds,
  stylesStoredInRedux: state.style,
  // user: state.auth.user,
  stylesStoredInReduxManagerAccountSettings: state.style.ManagerAccountSettings
});

export default connect(mapStateToProps, {})(ManagerAccountSettings);
